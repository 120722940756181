import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import Strings from '../../../../i18n';

interface ReportEffectWithButtonProps {
  reportHandler: (text: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const ReportEffectDialog = ({
  reportHandler,
  open,
  setOpen
}: ReportEffectWithButtonProps) => {
  const [reasonText, setReasonText] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    reportHandler(reasonText);
    handleClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435
        }
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {Strings.effects.reportEffect}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {Strings.effects.reportEffectText}
          <InputTextarea
            value={reasonText}
            placeholder="Reason..."
            onChange={(e) => setReasonText(e.target.value)}
            rows={5}
            cols={30}
            className="w-100 mt-3"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDelete()}
          color="success"
          variant="contained"
        >
          {Strings.common.ok}
        </Button>
        <Button onClick={() => handleClose()} color="error" variant="contained">
          {Strings.common.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
