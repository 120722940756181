import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Folder } from '../../../types/folders/folder';

const sliceFolders = createSlice({
  name: 'Folders',
  initialState: {
    selectedFolder: { folders: null, idFolder: null }
  } as {
    selectedFolder: {
      folders: Folder[] | null;
      idFolder: number | null;
      parentIdFolder: number | null;
      idFolderToAssignLocation: number | null;
    };
  },
  reducers: {
    setSelectedFolder(
      state,

      {
        payload: { folders, idFolder }
      }: PayloadAction<{
        folders: Folder[] | null;
        idFolder: number | null;
      }>
    ) {
      state.selectedFolder.folders = folders;
      state.selectedFolder.idFolder = idFolder;
    },
    setIdFolder(
      state,
      {
        payload: { idFolderToAssignLocation }
      }: PayloadAction<{
        idFolderToAssignLocation: number | null;
      }>
    ) {
      state.selectedFolder.idFolderToAssignLocation = idFolderToAssignLocation;
    }
  }
});
export default sliceFolders.reducer;
export const selectedFolder = (state: RootState) =>
  state.folders.selectedFolder;
export const folderIdToAssignLocation = (state: RootState) =>
  state.folders.selectedFolder.idFolderToAssignLocation;
export const { setSelectedFolder, setIdFolder } = sliceFolders.actions;
