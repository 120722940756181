/* eslint-disable no-shadow */
export enum PieChartStatus {
  onlineMoreThan15 = 1,
  onlineLessThan15,
  offlineMoreThan15,
  offlineLessThan15
}

export enum PieChartOrder {
  onlineMoreThan15,
  onlineLessThan15,
  offlineMoreThan15,
  offlineLessThan15
}
