import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetSystemRolesMutation } from '../../../services/systemPermissions/systemPermissionsService';
import { ButtonNewRole, Title } from './manageRolesUserStyles';
import { ManageRolesUserProps } from './manageRolesUserProps';
import { RoleItem } from '../../../components/roleItem/roleItem';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import Strings from '../../../i18n';

export const ManageRolesUser = ({
  openRightSideBarForMethod,
  setOpenSideBar
}: ManageRolesUserProps) => {
  const [
    getSystemRoles,
    { isSuccess: isSuccessGetSystemRoles, data: dataSystemRoles }
  ] = useGetSystemRolesMutation();

  useEffect(() => {
    getSystemRoles('');
  }, []);
  return (
    <Box height="100%">
      <Title>{Strings.manageRoles.manageRoles}</Title>

      {dataSystemRoles && (
        <Grid container marginTop={5}>
          {dataSystemRoles.map((role) => {
            return (
              <Box key={role.id}>
                <RoleItem role={role} />
              </Box>
            );
          })}
        </Grid>
      )}

      <ButtonNewRole
        startIcon={<AddCircleOutlineIcon />}
        onClick={() =>
          openRightSideBarForMethod(viewRightSideBarEnum.createUserRole)
        }
      >
        {Strings.manageRoles.addRole}
      </ButtonNewRole>
    </Box>
  );
};
