import React, { useEffect } from 'react';
import {
  Tune,
  ArrowBackIos,
  AddCircleOutline,
  Edit,
  UploadFile
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { Header } from '../../../components/header/header';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import Strings from '../../../i18n';
import {
  useEditFirmwareVersionMutation,
  useDeleteUploadedFilesFirmwareVersionMutation,
  useGetFirmwareVersionsDetailsForFilesMutation
} from '../../../services/firmwareVersions/firmwareVersionsService';
import { IFirmwareFile } from '../../../types/firmwareVersions/firmwareVersionsInterface';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  TooltipStyled
} from '../../../components/layout/main/mainStyles';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { DeleteButtonWithoutText } from '../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { FirmwareVersionFilesList } from '../firmwareVersionFilesList/firmwareVersionFilesList';

export const FWVersionUploadedFiles = () => {
  const { versionId } = useParams();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = React.useState<IFirmwareFile[]>([]);
  const [
    getFwVersionDetails,
    {
      data: firmwareVersion,
      isLoading: isLoadingFirmwareVersions,
      isSuccess: isSuccessFirmwareVersions
    }
  ] = useGetFirmwareVersionsDetailsForFilesMutation();

  const [deleteUploadedFiles, { isSuccess: successDeleteUploadedFiles }] =
    useDeleteUploadedFilesFirmwareVersionMutation();

  const [editFirmwareVersion, { isSuccess: successEdit, data: dataEdit }] =
    useEditFirmwareVersionMutation();

  useEffect(() => {
    if (versionId) {
      getFwVersionDetails(versionId);
    }
  }, []);

  const reloadTable = () => {
    if (versionId) {
      getFwVersionDetails(versionId);
      setSelectedFiles([]);
    }
  };

  const redirectToAddFile = () => {
    navigate(`/firmwareVersions/${versionId}/files/add`);
  };

  const redirectToEditFile = () => {
    navigate(
      `/firmwareVersions/${versionId}/files/edit/${selectedFiles[0].id}`
    );
  };

  const deleteMultipleVersions = () => {
    const ids = selectedFiles.map((version) => version.id);
    deleteUploadedFiles(ids);
  };

  const redirectToUploadedFiles = () => {
    navigate(`/firmwareVersions/${selectedFiles[0].id}/files`);
  };

  useEffect(() => {
    if (successDeleteUploadedFiles) {
      reloadTable();
      toast.success(Strings.versions.successDeleteUploadedFiles);
    }
  }, [successDeleteUploadedFiles]);

  useEffect(() => {
    if (successEdit) {
      reloadTable();
      toast.success(Strings.versions.statusChanged);
    }
  }, [successEdit]);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled
                size="small"
                onClick={() => navigate('/firmwareVersions')}
              >
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.versions.uploadedFiles}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip
                title={Strings.versions.uploadFile}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={redirectToAddFile}>
                  <AddCircleOutline />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.common.delete}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <DeleteButtonWithoutText
                  onDelete={deleteMultipleVersions}
                  id={selectedFiles[0]?.id}
                  textConfirmation={Strings.versions.confirmDeleteFiles}
                  titleConfirmation={Strings.versions.deleteFiles}
                  disabled={selectedFiles.length === 0}
                />
              </Tooltip>

              <ButtonIconStyled size="small">
                <Tune />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      {isLoadingFirmwareVersions && <LoadingRowsComponent />}
      {isSuccessFirmwareVersions && firmwareVersion && (
        <FirmwareVersionFilesList
          files={firmwareVersion.data?.firmwareFiles ?? []}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </>
  );
};
