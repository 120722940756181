import React, { useEffect, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { ElectricBolt } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {
  useExecuteEffectMutation,
  useGetEffectsByLocationMutation
} from '../../../../services/effects/effectsService';
import { LoadingRowsComponent } from '../../LoadingRows/LoadingRows';
import {
  StyledEffectSelectorItem,
  StyledMenuItem
} from './effectSelectorListStyles';
import { IEffectInLocation } from '../../../../types/effects/effectsInterface';
import Strings from '../../../../i18n';
import { useDeleteEffectMutation } from '../../../../services/effects/effectsStoreService';

interface EffectSelectorListProps {
  locationId: number;
  reloadList: boolean;
  selectedItem: { id: number; type: string };
  setVisible: (visible: boolean) => void;
}

export const EffectSelectorList = ({
  locationId,
  reloadList,
  selectedItem,
  setVisible
}: EffectSelectorListProps) => {
  const [selectedEffect, setSelectedEffect] =
    useState<IEffectInLocation | null>(null);
  const menuRight = useRef<Menu>(null);

  const [
    getEffectsByLocation,
    {
      isSuccess: isSuccessGetEffects,
      data: effectsData,
      isLoading: isLoadingGetEffects
    }
  ] = useGetEffectsByLocationMutation();

  const [executeEffectById, { isSuccess: isSuccessExecuteEffect }] =
    useExecuteEffectMutation();
  const [deleteEffectsById, { isSuccess: isSuccessDelete }] =
    useDeleteEffectMutation();

  const executeEffect = (item: IEffectInLocation) => {
    setSelectedEffect(item);
    executeEffectById({
      id: selectedItem.id,
      type: selectedItem.type,
      effectId: item.id
    });
  };

  const acceptDelete = () => {
    if (selectedEffect) {
      deleteEffectsById([selectedEffect.id]);
    }
  };

  const confirmDelete = () => {
    confirmDialog({
      message: 'Do you want to delete this effect?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: acceptDelete,
      reject: () => {
        setSelectedEffect(null);
      }
    });
  };

  const items = [
    {
      label: 'Delete',
      icon: 'pi pi-fw pi-trash',
      command: confirmDelete
    }
  ];

  useEffect(() => {
    if (reloadList) {
      getEffectsByLocation(locationId);
    }
  }, [reloadList]);

  useEffect(() => {
    if (isSuccessExecuteEffect) {
      toast.success(Strings.effects.successExecuteEffect);
      setVisible(false);
    }
  }, [isSuccessExecuteEffect]);

  useEffect(() => {
    if (isSuccessDelete) {
      getEffectsByLocation(locationId);
    }
  }, [isSuccessDelete]);

  return (
    <Grid container spacing={2}>
      {isLoadingGetEffects && <LoadingRowsComponent />}
      {isSuccessGetEffects &&
        effectsData &&
        effectsData.map((effect) => (
          <Grid item xs={6} sm={4} key={effect.id}>
            <StyledEffectSelectorItem>
              <div className="row align-items-center">
                <div className="col-12 col-md-8 text-center text-md-left">
                  <div>
                    <p className="name">{effect.name}</p>
                    <p className="type">{effect.effectType}</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 text-center text-md-left">
                  <Button
                    type="button"
                    className="execute-btn"
                    onClick={() => executeEffect(effect)}
                  >
                    <ElectricBolt sx={{ fontSize: '14px' }} />
                  </Button>
                  <Menu
                    model={items}
                    popup
                    ref={menuRight}
                    id="popup_menu_right"
                    popupAlignment="right"
                  />
                  <StyledMenuItem
                    label=""
                    icon="pi pi-ellipsis-h"
                    onClick={(event) => {
                      if (menuRight.current) {
                        menuRight.current.toggle(event);
                      }
                      setSelectedEffect(effect);
                    }}
                    aria-controls="popup_menu_right"
                    aria-haspopup
                  />
                </div>
              </div>
            </StyledEffectSelectorItem>
          </Grid>
        ))}
      <ConfirmDialog />
    </Grid>
  );
};
