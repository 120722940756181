import { Box, Button, Icon, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Chip } from 'primereact/chip';
import styled from 'styled-components';
import { Column } from 'primereact/column';
import { colors } from '../../../constants/colors';
import { PieChartStatus } from '../../../constants/enums/dashboardEnums';

export const Container = styled(Box)`
  && {
    height: 100%;
  }
`;

export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
  }
`;

export const StyledAddDeviceToLocationButton = styled(Button)`
  background: transparent;
  font-style: italic;
  color: ${colors.darkGray} !important;
  text-align: center;
  text-transform: capitalize !important;
`;

export const StyledTooltipContainer = styled(GridToolbarContainer)`
  justify-content: end;
  margin: 0 30px;
`;

export const StyledColumn = styled(Column)`
  && {
    .p-selection-column {
      opacity: 0;
    }
  }
`;
export const StyledChip = styled(Chip)`
  && {
    background-color: ${colors.green};
  }
`;

export const StyledIconStatus = styled.i`
  && {
    color: ${({ color }) => color};
    font-size: 1.2rem;
  }
`;

export const StyledStatusDevice = styled.div<{ status?: number }>`
  && {
    display: flex;
    align-items: center;
    color: ${({ status }) => {
      switch (status) {
        case PieChartStatus.onlineMoreThan15:
          return colors.darkGreen;
        case PieChartStatus.onlineLessThan15:
          return colors.yellow;
        case PieChartStatus.offlineMoreThan15:
          return colors.red;
        case PieChartStatus.offlineLessThan15:
          return colors.orange;
        default:
          return colors.mediumGray;
      }
    }};
  }
`;
