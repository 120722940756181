import { Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled(Box)`
  && {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.lightGray};
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
