/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Checkbox } from 'primereact/checkbox';
import { IPostRippleCommand } from '../../../../types/effects/effectsInterface';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { ButtonStyled } from '../../../locations/addLocationBar/addLocationBarStyles';
import {
  useAddEffectRippleMutation,
  useGetEffectValidTagsMutation
} from '../../../../services/effects/effectsService';
import { convertToFormData } from '../../../../utils/locations';

interface AddRippleEffectConfigurationProps {
  data: IPostRippleCommand;
  locationId: number;
  closeSideBar: () => void;
  redirectTo: () => void;
}

const StyledContainerCheckBox = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 1rem 0;
  && label {
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`;

export const AddRippleEffectConfiguration = ({
  data,
  locationId,
  closeSideBar,
  redirectTo
}: AddRippleEffectConfigurationProps) => {
  const [tags, setTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [addEffectInfo, setAddEffectInfo] = useState<{
    name: string;
    locationId: number;
    tags: string[];
    publishedInStore: boolean;
    videoFile: string;
  }>({
    name: '',
    locationId: 0,
    tags: [],
    publishedInStore: false,
    videoFile: ''
  });

  const [addEffectRipple, { isSuccess, isLoading }] =
    useAddEffectRippleMutation();
  const [getTags, { isSuccess: isSuccessTags, data: dataTags }] =
    useGetEffectValidTagsMutation();

  const addNewEffect = () => {
    const formData = convertToFormData(data);
    formData.append('name', addEffectInfo.name);
    formData.append('locationId', locationId.toString());
    formData.append('tags', tags.map((item) => item.name).join(','));
    formData.append(
      'publishedInStore',
      addEffectInfo.publishedInStore.toString()
    );
    if (fileUpload) {
      formData.append('videoFile', fileUpload);
    }
    addEffectRipple(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      closeSideBar();
      toast.success(Strings.effects.successAddEffect);
      redirectTo();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessTags && dataTags) {
      setTags(dataTags);
    }
  }, [isSuccessTags, dataTags]);

  const changeFileHandler = (event: any) => {
    setFileUpload(event.target.files[0]);
  };

  const getTagsArray = (newSearch: any) => {
    getTags(newSearch.query);
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.effects.addNewEffect}</StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: Strings.effects.name,
            type: 'inputText',
            currentValue: addEffectInfo.name,
            placeholder: Strings.effects.enterNameOfEffect,
            onChange: (
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setAddEffectInfo({
                ...addEffectInfo,
                name: event.target.value
              });
            }
          }}
        />
        <SideBarEditablePropertyItem
          item={{
            label: Strings.effects.tags,
            type: 'inputTags',
            currentValue: '',
            selectedItems: selectedTags,
            filteredSuggestions: tags,
            search: getTagsArray,
            setSelectedItems: (value: any) => {
              setSelectedTags(value);
              setAddEffectInfo({
                ...addEffectInfo,
                tags: value.map((item: any) => item.name)
              });
            },
            onChange: () => {}
          }}
        />
        <StyledContainerCheckBox className="d-flex align-items-center justify-content-between">
          <label htmlFor="publishedInStore" className="ml-2 mb-0">
            Publish in Store
          </label>
          <Checkbox
            inputId="publishedInStore"
            checked={addEffectInfo.publishedInStore}
            onChange={() =>
              setAddEffectInfo({
                ...addEffectInfo,
                publishedInStore: !addEffectInfo.publishedInStore
              })
            }
          />
        </StyledContainerCheckBox>
        {addEffectInfo.publishedInStore && (
          <SideBarEditablePropertyItem
            item={{
              label: 'Video file (optional)',
              type: 'inputFile',
              currentValue: fileUpload,
              fileName: fileUpload?.name ?? '',
              placeholder: Strings.effects.enterTags,
              onChange: changeFileHandler,
              acceptedFiles: 'video/*'
            }}
          />
        )}
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button type="button" className="btn-cancel" onClick={closeSideBar}>
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          disabled={!addEffectInfo.name.length}
          variant="contained"
          loading={isLoading}
          size="large"
          className="btn-save"
          onClick={addNewEffect}
        >
          {Strings.common.add}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
