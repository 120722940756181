import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { App } from './App';
import { store } from './store/store';
import { configAuth0 } from './config/config';
import ErrorBoundary from './components/ErrorBoundary/errorBoundary';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={configAuth0.domain}
    clientId={configAuth0.clientId}
    useRefreshTokens
    cacheLocation="localstorage"
    authorizationParams={{
      connection: configAuth0.bdd,
      audience: configAuth0.audience,
      redirect_uri: `${window.location.origin}/callback`,
      scope: configAuth0.scope
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
);
