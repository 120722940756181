import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { colors } from '../../../constants/colors';

export const ReOpenTooltipStyled = {
  tooltip: {
    sx: {
      bgcolor: colors.tooltipBG,
      color: colors.tooltipText
    }
  }
};

export const ReOpenButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;
