import React from 'react';
import { CreateUserTemplate } from '../../../components/auth/CreateUser/createUser';
import { ContainerImage } from '../registrationViewStyles';

export const CreateUserView = () => {
  return (
    <ContainerImage>
      <CreateUserTemplate />
    </ContainerImage>
  );
};
