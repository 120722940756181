import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { HealthProcess } from '../../types/healtProcess';
import { PropsDataResponse } from '../../types/apiCommonResponse/apiCommonResponse';
import customFetchBase from '../expiredToken/expiredToken';

const customFetch = customFetchBase(config.baseUrlDealers);

export const healthService = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getHealthMonitor: builder.mutation<PropsDataResponse<HealthProcess[]>, {}>({
      query: () => ({
        url: '/api/Report/ProcessMonitorLog',
        method: 'GET'
      })
    })
  })
});

export const { useGetHealthMonitorMutation } = healthService;
