import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;

export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
    text-align: center;
  }
`;
