import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, Cached } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { LoadingRowsComponent } from '../../../../components/commons/LoadingRows/LoadingRows';
import { Header } from '../../../../components/header/header';
import { useGetFilesSyncByDeviceIdMutation } from '../../../../services/device/deviceService';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  StyledBoxTable
} from '../../../../components/layout/main/mainStyles';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../locations/allLocationsList/allLocationListStyles';
import { StyledButtonAction } from '../../recordsDevice/recordsDeviceStyle';

export const FileSyncByDevice = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [getFilesSyncByDeviceId, { isSuccess, data, isLoading }] =
    useGetFilesSyncByDeviceIdMutation();

  const getDataTable = () => {
    if (deviceId) {
      getFilesSyncByDeviceId(deviceId);
      localStorage.clear();
    }
  };
  useEffect(() => {
    if (deviceId) {
      getDataTable();
    }
  }, []);
  const saveOnLocalStorageAndRedirect = (path: string) => {
    localStorage.setItem('path', path);
    navigate('/server-access/devices/customFiles/fileRetrieved');
  };
  const redirectToFile = (rowData: any) => {
    return (
      <StyledButtonAction
        onClick={() => saveOnLocalStorageAndRedirect(rowData.url)}
      >
        {Strings.common.view}
      </StyledButtonAction>
    );
  };
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.serverAccess.devices.customFiles}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <ButtonIconStyled size="small" onClick={() => getDataTable()}>
                <Cached />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.currentDevice} {deviceId}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          {isLoading && <LoadingRowsComponent />}
          {isSuccess && data && (
            <StyledBoxTable>
              <DataTable
                value={data}
                size="small"
                scrollable
                paginator={data && data.length >= 50}
                selectionPageOnly
                rows={50}
                rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
              >
                <Column field="fileName" header="File Name" />
                <Column header="CRC" sortable field="crc" />
                <Column
                  header="Url"
                  sortable
                  field="url"
                  body={redirectToFile}
                />
              </DataTable>
            </StyledBoxTable>
          )}
        </Grid>
      </Grid>
    </>
  );
};
