import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const TitleStyled = styled(Typography)`
  && {
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled(Box)`
  && {
    height: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.blue};
    color: ${colors.white};
  }
`;
