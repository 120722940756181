import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Tooltip } from '@mui/material';
import {
  CorporateFare,
  Launch,
  LocationCity,
  Person,
  Place,
  Start
} from '@mui/icons-material';
import { ISharedResultItem } from '../../../../types/Dashboard/globalSearchInterfaces';
import {
  StyledButtonItemWithIcon,
  StyledItemResult,
  styledTooltip
} from '../globalSearchStyles';
import Strings from '../../../../i18n';

interface SharedResultItemProps {
  data: ISharedResultItem;
  clearSearchAndClose: () => void;
  showOptions: boolean;
}
export const SharedResultItemComponent = ({
  data,
  clearSearchAndClose,
  showOptions
}: SharedResultItemProps) => {
  const navigate = useNavigate();

  const redirectToLocation = () => {
    navigate(`/locations/${data.locationId}/applications`);
    clearSearchAndClose();
  };
  const goToUserDetails = (event: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/users?searchUser=${data.ownerId}`);
    clearSearchAndClose();
  };

  const goToLocationNewTab = (event: React.MouseEvent) => {
    event?.stopPropagation();
    window.open(`/locations/${data.locationId}/applications`, '_blank');
  };

  return (
    <StyledItemResult onClick={redirectToLocation} showOptions={showOptions}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <h4>{data.name}</h4>
          <span className="d-flex">
            Owner:
            <b>{data.ownerName}</b>
          </span>
          <p>
            Shared with: <b>{data.sharedWith}</b>
          </p>
          {data.type}
        </Grid>
        <Grid
          item
          className="showOnHover"
          xs={12}
          md="auto"
          justifyContent="flex-end"
        >
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToLocation}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToLocation}
          >
            <Start />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToUserDetails}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToUserDetails}
          >
            <Person />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.openInExternalTab}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToLocationNewTab}
          >
            <Launch />
          </StyledButtonItemWithIcon>
        </Grid>
      </Grid>
    </StyledItemResult>
  );
};
