import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import {
  useGetFirmwareVersionsMutation,
  useScheduleFirmwareUpdateMutation
} from '../../../services/device/deviceService';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';

interface SendDirectFOTAProps {
  reloadTable: () => {};
  deviceIds: { deviceId: string; productTypeId: number }[];
  setOpenRightSideBar: (value: boolean) => void;
  clearSelectedDevices: () => void;
}
export const SendDirectFOTA = ({
  deviceIds,
  setOpenRightSideBar,
  clearSelectedDevices,
  reloadTable
}: SendDirectFOTAProps) => {
  const [
    sendDirectFOTA,
    { isSuccess: isSuccessSendDirectFOTA, isLoading: isLoadingSendDirectFOTA }
  ] = useScheduleFirmwareUpdateMutation();

  const [getVersions, { data: dataVersions }] =
    useGetFirmwareVersionsMutation();
  const [payloadFOTA, setPayloadFOTA] = useState({
    versionId: 0,
    deviceIds,
    upgradeTypeId: 1
  });

  const upgradeTypes = [
    { id: 1, name: 'Application Code' },
    { id: 2, name: 'Wifi Co-processor' }
  ];
  const handleSubmit = () => {
    const formattedPayload = {
      ...payloadFOTA,
      deviceIds: payloadFOTA.deviceIds.map((device) => device.deviceId)
    };
    sendDirectFOTA(formattedPayload);
  };

  useEffect(() => {
    const uniqueProductTypeIds = [
      ...new Set(deviceIds.map((item) => item.productTypeId))
    ];
    getVersions(uniqueProductTypeIds);
  }, []);

  useEffect(() => {
    if (isSuccessSendDirectFOTA) {
      toast.success(Strings.serverAccess.devices.successSendDirectFOTA);
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessSendDirectFOTA]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.serverAccess.devices.directFOTA}
        </StyledTitleSideBar>
        <SelectItemMethod
          item={{
            label: Strings.serverAccess.devices.compatibleVersions,
            options:
              dataVersions?.data
                ?.map((item: { id: number; name: string }) => ({
                  id: item.id,
                  name: item.name
                }))
                .sort((a, b) => b.name.localeCompare(a.name)) || [],
            currentValue: payloadFOTA.versionId,
            onChange: (e) =>
              setPayloadFOTA({ ...payloadFOTA, versionId: +e.target.value })
          }}
        />
        <SelectItemMethod
          item={{
            label: Strings.serverAccess.devices.upgradeBehavior,
            options: upgradeTypes,
            currentValue: payloadFOTA.upgradeTypeId,
            onChange: (e) =>
              setPayloadFOTA({ ...payloadFOTA, upgradeTypeId: +e.target.value })
          }}
        />
        <div className="alert alert-warning" role="alert">
          <h5>{Strings.serverAccess.devices.affectedControllers}</h5>
          <ul>
            {deviceIds.map((item) => (
              <li key={item.deviceId}>{item.deviceId}</li>
            ))}
          </ul>
        </div>
      </StyledContainerItems>

      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
            clearSelectedDevices();
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={payloadFOTA.versionId === 0 || isLoadingSendDirectFOTA}
          onClick={() => handleSubmit()}
        >
          {Strings.common.send}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
