import { styled } from '@mui/material';
import { Button } from 'primereact/button';
import { colors } from '../../constants/colors';
import { severityEnum } from '../../constants/commonEnums';

export const DashboardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
});

export const CardSubtitle = styled('span')<{ severity: number }>(
  ({ severity }) => ({
    fontSize: '0.8rem',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '0.5rem',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.2rem 0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: (() => {
      if (severity === severityEnum.low) return colors.green;
      if (severity === severityEnum.medium) return colors.yellow;
      if (severity === severityEnum.high) return colors.red;
      return colors.green;
    })()
  })
);

export const ViewNoteButton = styled(Button)<{ sev: number }>(({ sev }) => ({
  border: 'none',
  borderRadius: '0.5rem',
  fontSize: '0.6rem',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginLeft: '0.5rem',
  float: 'right',
  display: 'flex',
  alignItems: 'center',
  textWrap: 'nowrap',
  backgroundColor: (() => {
    if (sev === severityEnum.low) return colors.green;
    if (sev === severityEnum.medium) return colors.yellow;
    if (sev === severityEnum.high) return colors.red;
    return colors.green;
  })()
}));

export const LastTime = styled('span')({
  fontSize: '0.8rem',
  color: 'gray',
  marginLeft: '0.5rem',
  textWrap: 'nowrap'
});

export const CustomerActivityDetailsText = styled('p')({
  fontSize: '0.8rem',
  color: 'gray'
});

export const FlaggedLocationsDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
  fontSize: '0.8rem',
  color: 'gray',
  textWrap: 'nowrap',
  textAlign: 'right'
});

export const FlaggedLocationsOwnerName = styled('span')({
  fontSize: '0.8rem',
  color: 'gray',
  textWrap: 'nowrap'
});

export const MyActivityTargetName = styled('span')({
  fontSize: '0.8rem',
  color: 'gray',
  textWrap: 'nowrap'
});

export const CustomerActivityDetails = styled('span')({
  fontSize: '0.8rem',
  color: 'gray',
  textWrap: 'nowrap',
  textTransform: 'capitalize'
});
