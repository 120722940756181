import React from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { locationDetails } from '../../../../store/slices/locations/location';
import Strings from '../../../../i18n';

const StyledTitleAccordion = styled(Typography)`
  && {
    font-size: 13px;
  }
`;

const StyledAccordionDetails = styled(Typography)`
  && {
    font-size: 12px;
  }
`;

export const LocationInfoComponent = () => {
  const locationDetailsContext = useSelector(locationDetails);

  const shortString = (string: string) => {
    return string.length > 20 ? `${string.substring(0, 22)}...` : string;
  };
  return (
    <div className="mt-5">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="d-flex align-items-center">
            <Tooltip title={Strings.common.copyText} placement="top" arrow>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    `${locationDetailsContext?.name} (${locationDetailsContext?.ownerEmail})`
                  );
                  toast.success(Strings.common.copiedText);
                }}
              >
                <ContentCopyIcon
                  fontSize="small"
                  style={{ fontSize: '14px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`${locationDetailsContext?.name} (${locationDetailsContext?.ownerEmail})`}
              placement="top"
              arrow
            >
              <StyledTitleAccordion variant="subtitle2">
                {shortString(
                  `${locationDetailsContext?.name} (${locationDetailsContext?.ownerEmail})`
                )}
              </StyledTitleAccordion>
            </Tooltip>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <StyledAccordionDetails>
            <p>
              <b>Owner: </b>
              {locationDetailsContext?.ownerEmail}
            </p>
            <p>
              <b>Address: </b>
              {locationDetailsContext?.geoInfo?.address}
            </p>
          </StyledAccordionDetails>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
