import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import Strings from '../../../i18n';
import { useDeleteMultipleUsersMutation } from '../../../services/users/usersService';
import { multipleUsersSelectedMethodsProps } from './multipleUsersSelectedMethodsProps';

export const MultipleUsersSelectedMethods = ({
  selectedUsers,
  reloadTable,
  setOpenSideBar
}: multipleUsersSelectedMethodsProps) => {
  const [deleteMultipleUsers, { isSuccess: isSuccessDelete }] =
    useDeleteMultipleUsersMutation();
  const deleteUsers = () => {
    const usersIds = selectedUsers.map((user) => user.userId);
    deleteMultipleUsers(usersIds);
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success(Strings.users.successDeleteUsers);
      reloadTable();
    }
  }, [isSuccessDelete]);
  return (
    <>
      <StyledContainerItems>
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteUsers()}
          titleConfirmation={Strings.users.deleteUsersTitle}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.users.deleteUsers}
        />
      </StyledContainerItems>

      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => setOpenSideBar(false)}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
