import { AllColorList } from '../types/allColorListModel';
import { ColorV2 } from '../types/colorsModel';
import { LIGHT_COLOR_TYPE } from './commonEnums';

export const whiteColors: ColorV2[] = [
  { id: 1, name: '2700K', colorType: LIGHT_COLOR_TYPE.White, color: '#F8E96C' },
  { id: 2, name: '3000K', colorType: LIGHT_COLOR_TYPE.White, color: '#F6F08E' },
  { id: 3, name: '3500K', colorType: LIGHT_COLOR_TYPE.White, color: '#F4F4AC' },
  { id: 4, name: '3700K', colorType: LIGHT_COLOR_TYPE.White, color: '#F2F4C2' },
  { id: 5, name: '4000K', colorType: LIGHT_COLOR_TYPE.White, color: '#ECF5DA' },
  { id: 6, name: '4100K', colorType: LIGHT_COLOR_TYPE.White, color: '#E3F3E9' },
  { id: 7, name: '4700K', colorType: LIGHT_COLOR_TYPE.White, color: '#DDF1F2' },
  { id: 8, name: '5000K', colorType: LIGHT_COLOR_TYPE.White, color: '#D6EFF6' }
];

export const solidColors: ColorV2[] = [
  { id: 11, name: 'RED', colorType: LIGHT_COLOR_TYPE.Solid, color: '#FF0000' },
  { id: 12, name: 'FIRE', colorType: LIGHT_COLOR_TYPE.Solid, color: '#FF553F' },
  {
    id: 13,
    name: 'PUMPKIN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF553F'
  },
  {
    id: 14,
    name: 'AMBER',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FFA65E'
  },
  {
    id: 15,
    name: 'TANGERINE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FDCB5A'
  },
  {
    id: 16,
    name: 'MARIGOLD',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FFD776'
  },
  {
    id: 17,
    name: 'SUNSET',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FFDA49'
  },
  {
    id: 18,
    name: 'YELLOW',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#DCE160'
  },
  { id: 19, name: 'LIME', colorType: LIGHT_COLOR_TYPE.Solid, color: '#BBFF00' },
  {
    id: 20,
    name: 'LIGHT GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#8CFF00'
  },
  {
    id: 21,
    name: 'GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#20A301'
  },
  {
    id: 22,
    name: 'SEA FOAM',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#04F57B'
  },
  {
    id: 23,
    name: 'TURQUOISE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#00C7F3'
  },
  {
    id: 24,
    name: 'OCEAN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#1E98F3'
  },
  {
    id: 25,
    name: 'DEEP BLUE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#2160FF'
  },
  {
    id: 26,
    name: 'VIOLET',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#BC63F4'
  },
  {
    id: 27,
    name: 'PURPLE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#DF9FF5'
  },
  {
    id: 28,
    name: 'LAVENDER',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF56F8'
  },
  { id: 29, name: 'PINK', colorType: LIGHT_COLOR_TYPE.Solid, color: '#E71D94' },
  {
    id: 30,
    name: 'HOT PINK',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF31CD'
  }
];

export const extendSolidColors: ColorV2[] = [
  { id: 11, name: 'RED', colorType: LIGHT_COLOR_TYPE.Solid, color: '#FF0000' },
  { id: 12, name: 'FIRE', colorType: LIGHT_COLOR_TYPE.Solid, color: '#FF1500' },
  {
    id: 13,
    name: 'PUMPKIN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF3F04'
  },
  {
    id: 39,
    name: 'ORANGE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF5E00'
  },
  {
    id: 17,
    name: 'SUNSET',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF7800'
  },
  {
    id: 16,
    name: 'MARIGOLD',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF9C00'
  },
  {
    id: 40,
    name: 'LEMON',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FFC000'
  },
  {
    id: 18,
    name: 'YELLOW',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FFEA00'
  },
  { id: 19, name: 'LIME', colorType: LIGHT_COLOR_TYPE.Solid, color: '#BBFF00' },
  {
    id: 20,
    name: 'LIGHT GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#8CFF00'
  },
  {
    id: 41,
    name: 'PALE GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#59ED02'
  },
  {
    id: 56,
    name: 'APPLE GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#27D600'
  },
  {
    id: 42,
    name: 'EMERALD',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#23BD00'
  },
  {
    id: 21,
    name: 'GREEN',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#20A301'
  },
  {
    id: 22,
    name: 'SEA FOAM',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#04F57B'
  },
  {
    id: 44,
    name: 'ARCTIC',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#17E57D'
  },
  { id: 55, name: 'AQUA', colorType: LIGHT_COLOR_TYPE.Solid, color: '#17E5A8' },
  { id: 45, name: 'SKY', colorType: LIGHT_COLOR_TYPE.Solid, color: '#00FCFF' },
  {
    id: 46,
    name: 'WATER',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#00CCFF'
  },
  {
    id: 48,
    name: 'LIGHT BLUE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#2E8FFD'
  },
  {
    id: 47,
    name: 'SAPPHIRE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#217FFF'
  },
  {
    id: 25,
    name: 'DEEP BLUE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#2160FF'
  },
  {
    id: 57,
    name: 'ROYAL BLUE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#6141FF'
  },
  {
    id: 50,
    name: 'ORCHID',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#967FFE'
  },
  {
    id: 27,
    name: 'PURPLE',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#A66AFF'
  },
  {
    id: 28,
    name: 'LAVENDER',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#C26AFF'
  },
  {
    id: 51,
    name: 'LILAC',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#EC6AFF'
  },
  { id: 29, name: 'PINK', colorType: LIGHT_COLOR_TYPE.Solid, color: '#FF00EA' },
  {
    id: 52,
    name: 'BUBBLEGUM',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF00BB'
  },
  {
    id: 53,
    name: 'FLAMINGO',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#ED2693'
  },
  {
    id: 30,
    name: 'HOT PINK',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF008A'
  },
  {
    id: 54,
    name: 'DEEP PINK',
    colorType: LIGHT_COLOR_TYPE.Solid,
    color: '#FF3D8F'
  }
];
export const OFF_COLOR: ColorV2 = {
  id: 0,
  name: 'OFF',
  colorType: LIGHT_COLOR_TYPE.Solid,
  color: '#b8b7b7'
};

export const extendsPatternColors: ColorV2[] = [
  {
    id: 35,
    name: 'ALL COLORS',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#FF0000',
    icon: 'all_colors.png'
  },
  {
    id: 33,
    name: 'WARM COLORS',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#F8E96C',
    icon: 'warm_colors.png'
  },
  {
    id: 34,
    name: 'COOL COLORS',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#2E8FFD',
    icon: 'cool_colors.png'
  }
];

export const patternsSmartWhites: ColorV2[] = [
  {
    id: 58,
    name: 'LIGHT SPARKLE',
    colorType: LIGHT_COLOR_TYPE.Sparkle,
    color: '#C9C9C9',
    icon: 'light-sparkle.png'
  },
  {
    id: 59,
    name: 'MEDIUM SPARKLE',
    colorType: LIGHT_COLOR_TYPE.Sparkle,
    color: '#C9C9C9',
    icon: 'medium-sparkle.png'
  },
  {
    id: 60,
    name: 'HEAVY SPARKLE',
    colorType: LIGHT_COLOR_TYPE.Sparkle,
    color: '#C9C9C9',
    icon: 'heavy-sparkle.png'
  }
];

export const patterns: ColorV2[] = [
  ...extendsPatternColors,
  {
    id: 36,
    name: 'CHRISTMAS',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#FF0000',
    icon: 'christmas.png'
  },
  {
    id: 37,
    name: 'RED, WHITE AND BLUE',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#0044ff',
    icon: 'red_white_and_blue.png'
  },
  {
    id: 38,
    name: 'HALLOWEN',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#fc8404',
    icon: 'halloween.png'
  }
];

export const extraColors: ColorV2[] = [
  {
    id: 38,
    name: 'HALLOWEN',
    colorType: LIGHT_COLOR_TYPE.Pattern,
    color: '#fc8404',
    icon: 'halloween.png'
  }
];
export const defaultColor = {
  id: 0,
  name: 'PICK COLOR',
  colorType: LIGHT_COLOR_TYPE.White,
  color: '#fff1e3',
  icon: 'select_color.png'
};

export const extendColorsList: AllColorList = {
  whiteColors,
  solidColors: extendSolidColors,
  patterns: extendsPatternColors,
  patternsSmartWhites,
  allColors: [
    ...whiteColors,
    ...extendSolidColors,
    ...extendsPatternColors,
    ...patternsSmartWhites
  ]
};

export const standardColorList: AllColorList = {
  whiteColors,
  solidColors,
  patterns,
  patternsSmartWhites,
  allColors: [
    ...whiteColors,
    ...solidColors,
    ...patterns,
    ...patternsSmartWhites
  ]
};

export const allSolidColorList: ColorV2[] = [
  ...whiteColors,
  ...extendSolidColors,
  ...solidColors
];
