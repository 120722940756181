import React, { useState } from 'react';
import { StyledSwitch } from './switchOnOffStyles';

interface ISwitchOnOffProps {
  lightingStatusId: number;
  onSwitch: (value: boolean) => void;
}
export const SwitchOnOff = ({
  lightingStatusId,
  onSwitch
}: ISwitchOnOffProps) => {
  const [isOn, setIsOn] = useState<boolean>(lightingStatusId === 2);
  const handleSwitch = () => {
    setIsOn(!isOn);
    onSwitch(!isOn);
  };
  return <StyledSwitch checked={isOn} onChange={handleSwitch} />;
};
