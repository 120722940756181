import { createTheme } from '@mui/material/styles';
import { colors } from '../constants/colors';

export const theme = createTheme({
  palette: {
    common: {
      black: colors.black,
      white: colors.white
    },
    primary: {
      main: colors.lightGray
    },
    secondary: {
      main: colors.mediumGray
    },
    info: {
      main: colors.blue
    }
  }
});
