import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  AddBusinessAccount,
  IAddEditBusinessAccount,
  IBusinessAccountDetails,
  ICreateUserWithBA
} from '../../types/businessAccount/businessAccount';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiBusinessAccount = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    addBusinessAccount: builder.mutation<{}, IAddEditBusinessAccount>({
      query: (data) => ({
        url: `/api/BusinessAccount`,
        method: 'POST',
        body: data
      })
    }),
    deleteMultipleUsersBA: builder.mutation<null, number[]>({
      query: (ids) => ({
        url: `/api/BusinessAccountUser/Multi?Ids=${ids
          .map((id) => id)
          .join(',')}`,
        method: 'DELETE',
        body: ids
      })
    }),
    getBusinessAccountDetails: builder.mutation<
      IAddEditBusinessAccount,
      number
    >({
      query: (businessAccountId) => ({
        url: `/api/BusinessAccount/Details/${businessAccountId}`,
        method: 'GET'
      })
    }),
    putBusinessAccount: builder.mutation<{}, IAddEditBusinessAccount>({
      query: (data) => ({
        url: `/api/BusinessAccount`,
        method: 'PUT',
        body: data
      })
    }),
    createUserWithBA: builder.mutation<{}, ICreateUserWithBA>({
      query: (data) => ({
        url: `/api/BusinessAccount/NewUser`,
        method: 'POST',
        body: data
      })
    }),
    getLocationsByBusinessAccount: builder.mutation<
      IBusinessAccountDetails,
      number
    >({
      query: (businessAccountId) => ({
        url: `/api/BusinessAccount/Locations/${businessAccountId}`,
        method: 'GET'
      })
    }),
    removeLocationsFromBusinessAccount: builder.mutation<{}, number[]>({
      query: (data) => ({
        url: `/api/BusinessAccountLocation/Multi/${data
          .map((d) => d)
          .join(',')}`,
        method: 'DELETE',
        body: data
      })
    })
  })
});

export const {
  useDeleteMultipleUsersBAMutation,
  useAddBusinessAccountMutation,
  useGetBusinessAccountDetailsMutation,
  usePutBusinessAccountMutation,
  useCreateUserWithBAMutation,
  useGetLocationsByBusinessAccountMutation,
  useRemoveLocationsFromBusinessAccountMutation
} = apiBusinessAccount;
