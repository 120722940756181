import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledReadMoreButton = styled.button`
  color: ${colors.darkGray};
  background-color: transparent;
  border: none;
  font-size: 10px;
  padding: 0;
  margin-left: 5px;
  text-transform: none;
  &:hover {
    background-color: transparent;
    color: #0056b3;
  }
`;
