export const CursorStyleDataGrid = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer'
  }
};

export const QuitCheckboxHeader = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none'
    }
};
