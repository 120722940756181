import React from 'react';
import { MarginComponentProps } from './marginComponentProps';
import { Margin } from './marginComponentStyles';

export const MarginComponent = ({
  children,
  margin,
  mb,
  ml,
  mr,
  mt
}: MarginComponentProps) => {
  return (
    <Margin
      margin={margin || 0}
      mb={mb || 0}
      ml={ml || 0}
      mr={mr || 0}
      mt={mt || 0}
    >
      {children}
    </Margin>
  );
};
