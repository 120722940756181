import React, { useState } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LightsZonesList } from '../../../templates/applications/lightsZones/lightsZonesList/lightsZonesList';
import { Header } from '../../../components/header/header';
import {
  StyledBackButtonNavigator,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';

export const LightsZonesView = () => {
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <StyledBackButtonNavigator
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos />
              </StyledBackButtonNavigator>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.lightsZones.title}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      <LightsZonesList
        searchValue={searchValue}
        setSelectionModel={setSelectionModel}
        selectionModel={selectionModel}
      />
    </>
  );
};
