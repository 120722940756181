import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { colors } from '../../../constants/colors';

export const StyledBrightnessButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0 10px;
  outline: none;
  img {
    width: 100%;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledBrightnessDialog = styled(Dialog)`
  && .p-dialog-mask.p-component-overlay {
    z-index: 2000 !important;
  }

  && .p-dialog-title {
    text-align: center;
  }
`;

export const StyledBrightnessItem = styled.button<{ IsActive?: boolean }>`
  display: block;
  color: ${colors.blueTheme};
  background-color: ${({ IsActive }) =>
    IsActive ? colors.lightGray : 'transparent'};
  border: none;
  text-align: center;
  margin: 10px auto;
  width: 100%;
  border-bottom: 3px solid #f1f1f1;
  font-weight: bold;
`;
