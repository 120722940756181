import React, { useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import { styled, Switch, Tooltip } from '@mui/material';
import { Column } from 'primereact/column';
import { Check, Close, PlayCircleFilled } from '@mui/icons-material';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { IEvent } from '../../../types/events/eventsInterface';
import {
  useChangeEventStatusMutation,
  usePostEventExecuteMutation
} from '../../../services/events/eventService';
import Strings from '../../../i18n';
import { colors } from '../../../constants/colors';

interface IEventsListTemplateProps {
  selectedEvents: any[];
  setSelectedEvents: (selectedEvents: any[]) => void;
  eventsToExecute: number[];
  isLoading: boolean;
  events: IEvent[];
  reloadTable: () => void;
}
export const EventsListTemplate = ({
  selectedEvents,
  setSelectedEvents,
  isLoading,
  events,
  eventsToExecute,
  reloadTable
}: IEventsListTemplateProps) => {
  const [
    postEventExecute,
    { isLoading: isLoadingExecute, isSuccess: isSuccessExecute }
  ] = usePostEventExecuteMutation();
  const [
    changeEventStatus,
    { isLoading: isLoadingChangeStatus, isSuccess: isSuccessChangeStatus }
  ] = useChangeEventStatusMutation();

  const StyledSpanDisabled = styled('span')({
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: colors.mediumGray,
    padding: '5px',
    borderRadius: '5px',
    color: colors.white
  });

  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedEvents.length > 0 ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={selectedEvents.length > 0 ?? false}
        onChange={() => {
          setSelectedEvents([]);
        }}
      />
    );
  };
  const getEnabledColumn = (rowData: IEvent) => {
    if (!rowData.isEnabled) {
      return <StyledSpanDisabled>Disabled</StyledSpanDisabled>;
    }

    return eventsToExecute.includes(rowData.eventId) ? (
      <Check color="success" />
    ) : (
      <Close color="error" />
    );
  };
  useEffect(() => {
    if (isSuccessExecute) {
      toast.success(Strings.events.eventExecutedSuccessfully);
    }
  }, [isSuccessExecute]);
  useEffect(() => {
    if (isSuccessChangeStatus) {
      toast.success(Strings.events.eventStatusChangedSuccessfully);
      reloadTable();
    }
  }, [isSuccessChangeStatus]);
  const executeEvent = (eventId: number) => {
    postEventExecute(eventId);
  };
  const getActionsColumn = (rowData: IEvent) => {
    return (
      <div>
        <Tooltip title={Strings.events.executeThisEvent}>
          <PlayCircleFilled
            onClick={() => executeEvent(rowData.eventId)}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip
          title={
            rowData.isEnabled
              ? Strings.events.disableEvent
              : Strings.events.enableEvent
          }
        >
          <Switch
            checked={rowData.isEnabled}
            color="secondary"
            onChange={() =>
              changeEventStatus({
                eventId: rowData.eventId,
                status: !rowData.isEnabled
              })
            }
          />
        </Tooltip>
      </div>
    );
  };
  const getWeekDayColumn = (rowData: IEvent) => {
    return (
      <span>
        {rowData.weekDay === 'Every Day' ? 'ALL WEEK' : rowData.weekDay}
      </span>
    );
  };

  const getEventTimeColumn = (rowData: IEvent) => {
    return (
      <span>
        {rowData.localExecutionTime}{' '}
        {rowData.timeType === 1 ? '(Time)' : `(${rowData.timeTypeStr})`}
      </span>
    );
  };
  return (
    <>
      {isLoading && <LoadingRowsComponent />}
      {!isLoading && events && (
        <DataTable
          selection={selectedEvents}
          selectionMode="checkbox"
          onSelectionChange={(e) => setSelectedEvents(e.value)}
          className="mt-3"
          rowHover
          value={events}
          size="small"
          scrollable
          scrollHeight="100vh"
          paginator={events && events.length >= 50}
          selectionPageOnly
          rows={50}
          rowsPerPageOptions={[5, 10, 20, 50, events ? events.length : 50]}
          tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
        >
          <Column
            selectionMode="multiple"
            headerStyle={styledColumnHeaderCheckbox}
            header={getHeaderCheckbox}
            frozen
          />
          <Column field="isEnabled" header="Status" body={getEnabledColumn} />
          <Column field="name" header="Event Name" sortable />
          <Column field="dateTypeStr" header="Date Range" sortable />
          <Column
            field="localExecutionTime"
            header="Event Time - Current"
            sortable
            body={getEventTimeColumn}
          />
          <Column field="sortOrder" header="Order" sortable />
          <Column
            field="weekDay"
            header="Week Day"
            sortable
            body={getWeekDayColumn}
          />
          <Column field="" header="Actions" body={getActionsColumn} />
        </DataTable>
      )}
    </>
  );
};
