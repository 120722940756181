import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ButtonIconStyled = styled(IconButton)`
  && {
    margin-top: -5px;
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;

export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
    text-align: center;
    font-weight: 500;
    color: rgb(89, 89, 89);
    font-size: 1.2rem;
    line-height: 1;

    .tag {
      font-size: 12px;
    }
  }
`;
