import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import JsonView from '@uiw/react-json-view';
import { ArrowBackIos } from '@mui/icons-material';
import { useGetCredentialsMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { MarginComponent } from '../../../components/marginComponent/marginComponent';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';

export const GetCredentialsTemplate = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const [
    getCredentials,
    {
      isLoading: isLoadingGetCredentials,
      isSuccess: isSuccessGetCredentials,
      data: dataGetCredentials
    }
  ] = useGetCredentialsMutation();
  useEffect(() => {
    if (deviceId) {
      getCredentials(deviceId);
    }
  }, []);
  return (
    <>
      <Header>
        <MarginComponent ml={30} mr={30}>
          <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </ButtonIconStyled>
        </MarginComponent>
      </Header>
      <Grid container spacing={2} justifyContent="center" marginBottom={5}>
        <Grid item xs={10}>
          <Typography
            variant="h5"
            color="initial"
            textAlign="center"
            marginTop={3}
            marginBottom={1}
          >
            {Strings.serverAccess.devices.getCredentials}
          </Typography>
          <Typography variant="subtitle1" color="initial" textAlign="center">
            {Strings.serverAccess.devices.currentDevice} {deviceId}
          </Typography>
        </Grid>
        <Grid item xs={10} textAlign="left">
          {isLoadingGetCredentials && <LoadingRowsComponent />}
          {isSuccessGetCredentials && dataGetCredentials && (
            <JsonView
              value={dataGetCredentials}
              displayDataTypes={false}
              displayObjectSize={false}
              indentWidth={2}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
