import React from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';

const StyledClearButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconClear = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 30px;
  right: 10px;
`;

export const ClearButtonForFilter = ({
  filterName,
  clearFilter,
  filters,
  children
}: {
  filterName: string;
  children: React.ReactNode;
  clearFilter: (key: string) => void;
  filters: Record<string, any>;
}) => (
  <div className="position-relative">
    <StyledClearButton>
      <span />
      {filterName in filters && filters[filterName as keyof typeof filters] && (
        <StyledIconClear type="button" onClick={() => clearFilter(filterName)}>
          Clear <ClearIcon fontSize="small" />
        </StyledIconClear>
      )}
    </StyledClearButton>
    {children}
  </div>
);
