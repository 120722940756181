import { Button } from '@mui/material';
import React, { useState } from 'react';
import { StyledReadMoreButton } from './maxCharactersAddReadMoreButtonStyles';
import Strings from '../../../i18n';

interface MaxCharactersAndReadMoreButtonProps {
  value: string;
  maxCharacters: number;
}
export const MaxCharactersAndReadMoreButton = ({
  value,
  maxCharacters
}: MaxCharactersAndReadMoreButtonProps) => {
  const [isTruncated, setIsTruncated] = useState(value.length > maxCharacters);
  const resultString = isTruncated
    ? `${value.slice(0, maxCharacters)}...`
    : value;
  return (
    <div>
      {resultString}
      {value.length > maxCharacters && (
        <StyledReadMoreButton onClick={() => setIsTruncated(!isTruncated)}>
          {isTruncated ? Strings.common.readMore : Strings.common.readLess}
        </StyledReadMoreButton>
      )}
    </div>
  );
};
