import { LoadingButton } from '@mui/lab';
import { Grid, Typography, TextField, Divider } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Strings from '../../../i18n';
import {
  createUserValidator,
  forgotPasswordValidator,
  resetPasswordValidator
} from '../../../utils/validators';
import {
  ContainerForm,
  ContainerFormProfile,
  StyledButtonResendEmail,
  ErrorText
} from '../CreateUser/createUserStyles';
import { colors } from '../../../constants/colors';
import {
  useForgotPasswordMutation,
  useResetPasswordMutation
} from '../../../services/auth/authServices';
import { ContainerImage } from '../../../views/registration/registrationViewStyles';

const havenLogo = require('../../../assets/logo.png');

export const ResetPassword = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const queryParams = useQuery();
  const userParam = queryParams.get('user');
  const keyParam = queryParams.get('key');
  const [gotKey, setGotKey] = React.useState(false);

  const [
    postRequestResetPassword,
    { isSuccess: successRequest, isLoading: loadingRequestPassword }
  ] = useForgotPasswordMutation();

  const [
    postResetPassword,
    { isSuccess: successReset, isLoading: loadingResetPassword }
  ] = useResetPasswordMutation();
  useEffect(() => {
    if (userParam && keyParam) {
      setGotKey(true);
    }
  }, []);

  const sendInfoRequestReset = (payload: { email: string }) => {
    postRequestResetPassword(payload);
  };
  const sendInfoResetPassword = (payload: {
    email: string;
    password: string;
    guid: string;
  }) => {
    postResetPassword(payload);
  };
  useEffect(() => {
    if (successRequest) {
      toast.success(Strings.login.forgotSuccess);
    }
    if (successReset) {
      toast.success(Strings.login.resetPasswordSuccess);
    }
  }, [successRequest, successReset]);
  return (
    <ContainerImage>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item xs={0.5} md={2} />
        <Grid item xs={11} md={6} lg={3.5}>
          <ContainerForm container padding={3} justifyContent="center">
            <Grid item xs={12}>
              <Typography align="center" marginY={2} variant="h5">
                <img src={havenLogo} alt="Haven Logo" width={200} />
              </Typography>
              <Typography
                align="center"
                marginY={gotKey ? 3 : 1}
                variant="h5"
                fontWeight="bold"
                color={colors.strongGray}
                paddingTop={2}
              >
                {gotKey
                  ? Strings.login.resetPasswordTitle
                  : Strings.login.forgotPassword}
              </Typography>
            </Grid>
            {gotKey ? (
              <ContainerFormProfile item xs={10} md={8} lg={12}>
                <Formik
                  enableReinitialize
                  validationSchema={resetPasswordValidator}
                  initialValues={{
                    email: userParam ?? '',
                    password: '',
                    confirmPassword: ''
                  }}
                  onSubmit={(values) => {
                    sendInfoResetPassword({
                      email: values.email,
                      password: values.password,
                      guid: keyParam ?? ''
                    });
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    isValid,
                    setFieldTouched,
                    errors,
                    touched
                  }) => (
                    <Grid
                      direction="row"
                      container
                      spacing={2}
                      justifyContent="center"
                    >
                      <Grid item xs={12} md={10}>
                        <TextField
                          value={values.email}
                          id="email"
                          label={Strings.userProfile.emailLabel}
                          onBlur={() => setFieldTouched('email')}
                          onChange={handleChange('email')}
                          name="email"
                          disabled
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        {touched.email && errors.email && (
                          <ErrorText>{errors.email}</ErrorText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <TextField
                          value={values.password}
                          id="password"
                          label={Strings.login.password}
                          onBlur={() => setFieldTouched('password')}
                          onChange={handleChange('password')}
                          name="password"
                          type="password"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        {touched.password && errors.password && (
                          <ErrorText>{errors.password}</ErrorText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <TextField
                          value={values.confirmPassword}
                          id="confirmPassword"
                          label={Strings.login.confirmPassword}
                          onBlur={() => setFieldTouched('confirmPassword')}
                          onChange={handleChange('confirmPassword')}
                          name="confirmPassword"
                          type="password"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        {touched.confirmPassword && errors.confirmPassword && (
                          <ErrorText>{errors.confirmPassword}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={10} md={8} marginTop={1}>
                        <LoadingButton
                          color="info"
                          className="w-100"
                          disabled={!isValid}
                          onClick={() => handleSubmit()}
                          variant="contained"
                          size="large"
                          loading={loadingResetPassword}
                        >
                          {Strings.login.resetPasswordTitle}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </ContainerFormProfile>
            ) : (
              <ContainerFormProfile item xs={10} md={8} lg={12}>
                <Typography
                  align="center"
                  marginY={3}
                  marginTop={0}
                  variant="subtitle2"
                  color={colors.mediumGray}
                >
                  {Strings.login.forgotPasswordText}
                </Typography>
                <Formik
                  enableReinitialize
                  validationSchema={forgotPasswordValidator}
                  initialValues={{
                    email: ''
                  }}
                  onSubmit={(values) => {
                    sendInfoRequestReset({
                      email: values.email
                    });
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    isValid,
                    setFieldTouched,
                    errors,
                    touched
                  }) => (
                    <Grid
                      direction="row"
                      container
                      spacing={2}
                      justifyContent="center"
                    >
                      <Grid item xs={12} md={10}>
                        <TextField
                          value={values.email}
                          id="email"
                          label={Strings.userProfile.emailLabel}
                          onBlur={() => setFieldTouched('email')}
                          onChange={handleChange('email')}
                          name="email"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        {touched.email && errors.email && (
                          <ErrorText>{errors.email}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={10} md={8} marginTop={1}>
                        <LoadingButton
                          color="info"
                          className="w-100"
                          disabled={!isValid}
                          onClick={() => handleSubmit()}
                          variant="contained"
                          size="large"
                          loading={loadingRequestPassword}
                        >
                          {Strings.common.send}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </ContainerFormProfile>
            )}
          </ContainerForm>
        </Grid>
        <ToastContainer
          newestOnTop
          position="bottom-center"
          draggable
          theme="colored"
          autoClose={4000}
        />
        <Divider variant="fullWidth" />
      </Grid>
    </ContainerImage>
  );
};
