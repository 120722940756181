import React, { useEffect, useState } from 'react';
import { AddCircleOutline, ArrowBackIos } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { useGetSceneListByLocationMutation } from '../../../services/scenes/sceneService';
import { ScenesListTemplate } from '../../../templates/applications/scenes/scenesListTemplate';
import { ReOpenSideBarIcon } from '../../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { Header } from '../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../devices/devicesStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddSceneTemplate } from '../../../templates/applications/scenes/addScene/addScene';
import { SceneDetails } from '../../../templates/applications/scenes/sceneDetails/sceneDetails';
import { IScene } from '../../../types/Scenes/scenesTypes';

export const ScenesView = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [getScenesByLocId, { isSuccess, data, isLoading }] =
    useGetSceneListByLocationMutation();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [showSelectTypeAddScene, setShowSelectTypeAddScene] = useState(false);
  const [selectedScenes, setSelectedScenes] = useState<IScene[]>([]);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addScene
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const redirectToAddScene = (type: string) => {
    navigate(`/locations/${locationId}/applications/scenes/add?type=${type}`);
  };
  const reloadTable = () => {
    if (locationId) {
      getScenesByLocId(+locationId);
    }
  };
  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addScene:
        return <AddSceneTemplate />;
      case viewRightSideBarEnum.sceneDetails:
        return (
          <SceneDetails
            scene={selectedScenes[0]}
            setOpenSideBar={setOpenSideBar}
            openSideBar={openSideBar}
            setSelectedScenes={setSelectedScenes}
            selectedScenes={selectedScenes}
            reloadTable={reloadTable}
          />
        );
      default:
        return <p />;
    }
  };

  useEffect(() => {
    if (selectedScenes.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.sceneDetails);
    } else if (selectedScenes.length > 1) {
      openRightSideBarForMethod(
        viewRightSideBarEnum.multipleOrganizationsDetails
      );
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedScenes]);

  useEffect(() => {
    if (locationId) {
      reloadTable();
    }
  }, []);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.scenes.scenesTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  setShowSelectTypeAddScene(true);
                }}
              >
                <AddCircleOutline />
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openSideBar}
                selectedItems={selectedScenes}
                singleItemMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.organizationDetails
                  );
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.multipleOrganizationsDetails
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <ScenesListTemplate
        selectedScenes={selectedScenes}
        setSelectedScenes={setSelectedScenes}
        scenes={data ?? []}
        isLoading={isLoading}
      />
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
      <Dialog
        header={Strings.scenes.howToAddScene}
        visible={showSelectTypeAddScene}
        onHide={() => {
          setShowSelectTypeAddScene(false);
        }}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        <div className="row">
          <div className="col-6 text-center">
            <Button
              size="small"
              onClick={() => {
                redirectToAddScene(Strings.scenes.blind);
              }}
              severity="secondary"
              outlined
              label={Strings.scenes.blind}
            />
            <p>{Strings.scenes.blindDescription}</p>
          </div>
          <div className="col-6 text-center">
            <Button
              size="small"
              onClick={() => {
                redirectToAddScene(Strings.scenes.live);
              }}
              severity="secondary"
              outlined
              label={Strings.scenes.live}
            />
            <p>{Strings.scenes.liveDescription}</p>
          </div>
        </div>
      </Dialog>
    </>
  );
};
