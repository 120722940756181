import { Box, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { colors } from '../../../constants/colors';

export const TitleStyled = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 13px;
    margin-top: -25px;
    margin-left: 5px;
    margin-bottom: 20px;
  }
`;

export const InputStyled = styled(TextField)`
  && {
    height: 30px;
    justify-content: center;
    text-align: center;
    border: none;
    border-color: ${colors.white};
    background-color: ${colors.white};
  }
`;

export const noBorderStyle = {
  borderRadius: 2,
  '& fieldset': { border: 'none' }
};

export const ButtonsContainer = styled(Box)`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    text-transform: none;
    color: ${colors.white};
  }
`;

export const ButtonStyledCancel = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.lightGray};
    text-transform: none;
    color: ${colors.darkGray};
  }
`;
