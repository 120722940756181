/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Typography,
  Grid,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik } from 'formik';
import { createUserValidator } from '../../../utils/validators';
import { usePostAddEmailToVerifyMutation } from '../../../services/auth/authServices';
import {
  ContainerFormProfile,
  ErrorText,
  ContainerForm,
  StyledButtonResendEmail
} from './createUserStyles';
import Strings from '../../../i18n';
import { colors } from '../../../constants/colors';

const havenLogo = require('../../../assets/logo.png');

export const CreateUserTemplate = () => {
  const [postEmailToVerify, { isSuccess, isLoading, data, isError, error }] =
    usePostAddEmailToVerifyMutation();
  const sendInfo = (payload: { email: string }) => {
    const info: { email: string; urlToVerify: string } = {
      email: payload.email,
      urlToVerify: `${window.location.origin}/user-registration`
    };
    postEmailToVerify(info);
  };

  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (isSuccess) {
      toast.success('Email sent');
    }
    if (isError && error) {
      toast.error(error.toString());
    }
  }, [isSuccess, isError]);
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="left"
      alignItems="center"
    >
      <Grid item xs={0.5} md={2} />
      <Grid item xs={11} md={6} lg={3.5}>
        <ContainerForm container padding={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography align="center" marginY={2} variant="h5">
              <img src={havenLogo} alt="Haven Logo" width={200} />
            </Typography>
            <Typography
              align="center"
              marginY={3}
              variant="h5"
              fontWeight="bold"
              color={colors.strongGray}
              paddingTop={2}
            >
              {isSuccess
                ? Strings.userProfile.checkEmailForValidation
                : Strings.userProfile.createAccount}
            </Typography>
          </Grid>
          <ContainerFormProfile item xs={10} md={8} lg={12}>
            <Formik
              enableReinitialize
              validationSchema={createUserValidator}
              initialValues={{
                email: ''
              }}
              onSubmit={(values) => {
                sendInfo({
                  email: values.email
                });
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                isValid,
                setFieldTouched,
                errors,
                touched
              }) =>
                isSuccess ? (
                  <Grid
                    item
                    xs={10}
                    md={8}
                    marginTop={1}
                    textAlign="center"
                    display="inline"
                  >
                    <Typography>
                      {Strings.login.noGetEmail}
                      <StyledButtonResendEmail
                        type="button"
                        onClick={() => handleSubmit()}
                        className="ml-2"
                      >
                        {Strings.login.resendEmail}
                      </StyledButtonResendEmail>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    direction="row"
                    container
                    spacing={2}
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={10}>
                      <TextField
                        value={values.email}
                        id="email"
                        label={Strings.userProfile.emailLabel}
                        onBlur={() => setFieldTouched('email')}
                        onChange={handleChange('email')}
                        name="email"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      {touched.email && errors.email && (
                        <ErrorText>{errors.email}</ErrorText>
                      )}
                    </Grid>

                    <Grid item xs={10} md={8} marginTop={1}>
                      <LoadingButton
                        color="info"
                        className="w-100"
                        disabled={!isValid}
                        onClick={() => handleSubmit()}
                        variant="contained"
                        size="large"
                        loading={isLoading}
                      >
                        {Strings.login.createAccount}
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={8}
                      marginTop={1}
                      textAlign="center"
                      display="inline"
                    >
                      <Typography>
                        {Strings.login.alreadyHaveAccount}
                        <StyledButtonResendEmail
                          type="button"
                          onClick={() => loginWithRedirect()}
                          className="ml-2"
                        >
                          {Strings.login.signIn}
                        </StyledButtonResendEmail>
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }
            </Formik>
          </ContainerFormProfile>
        </ContainerForm>
      </Grid>
      <ToastContainer
        newestOnTop
        position="bottom-center"
        draggable
        theme="colored"
        autoClose={4000}
      />
      <Divider variant="fullWidth" />
    </Grid>
  );
};
