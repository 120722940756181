import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Location } from '../../../types/location/location';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { useUpdateLocationLatLongMutation } from '../../../services/locations/locationsService';

export const ChangeLatLongLocationBar = ({
  location,
  setOpenRightSideBar,
  reloadTable
}: {
  location: Location;
  setOpenRightSideBar: (open: boolean) => void;
  reloadTable: () => void;
}) => {
  const [updateLocationLatLong, { isSuccess }] =
    useUpdateLocationLatLongMutation();
  const [latLong, setLatLong] = useState({
    latitude: location?.lat || null,
    longitude: location?.lon || null
  });

  const handleSubmit = () => {
    updateLocationLatLong({
      locationId: location.locationId,
      lat: latLong.latitude || 0,
      lon: latLong.longitude || 0
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully updated location lat and long');
      reloadTable();
    }
  }, [isSuccess]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.locations.changeLatLongLocation}
        </StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: 'LAT',
            type: 'inputNumber',
            currentValue: latLong.latitude || '',
            nameAndId: 'latitude',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setLatLong({ ...latLong, latitude: +e.target.value })
          }}
        />
        <SideBarEditablePropertyItem
          item={{
            label: 'LONG',
            type: 'inputNumber',
            currentValue: latLong.longitude || '',
            nameAndId: 'longitude',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setLatLong({ ...latLong, longitude: +e.target.value })
          }}
        />
      </StyledContainerItems>

      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={!latLong.latitude || !latLong.longitude}
          onClick={() => handleSubmit()}
        >
          {Strings.common.send}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
