import { Divider, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const NameLocation = styled(Typography)`
  && {
    hyphens: auto;
    word-break: break-word;
    font-size: 13px;
    margin-left: 5px;
    margin-right: 5px;
    color: ${colors.strongGray};
  }
`;

export const DividerLocation = styled(Divider)`
  && {
    margin-top: 5px;
    background-color: ${colors.lightGray};
    margin-bottom: 5px;
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
    margin-top: -px;
  }
  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;
