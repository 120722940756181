import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { MainRoutes } from '../../routing/routes';
import { SideBar } from '../sideBar/sideBar';
import {
  selectIsSideBarOpen,
  setOpenSideBar
} from '../../../store/slices/app/app';
import { MainContainer, AppContent } from './mainStyles';
import { NavBar } from '../navBar/NavBar';
import Strings from '../../../i18n';
import { currentLocation } from '../../../store/slices/locations/location';

export const Main = () => {
  const dispatch = useDispatch();

  const isOpenSideBar = useSelector(selectIsSideBarOpen) as boolean;
  const selectedLocation = useSelector(currentLocation);
  const location = useLocation();
  if (
    location.pathname === Strings.login.callBack ||
    location.pathname === Strings.login.userRegistration ||
    location.pathname === Strings.login.createUser ||
    location.pathname === Strings.login.resetPassword ||
    location.pathname === Strings.login.loginWithPassword
  ) {
    return <MainRoutes />;
  }

  return (
    <MainContainer>
      <NavBar />
      <SideBar
        onMenuClose={() => {
          dispatch(setOpenSideBar(false));
        }}
      />
      <AppContent
        component="main"
        open={isOpenSideBar && Boolean(selectedLocation)}
      >
        <MainRoutes />
      </AppContent>
      <ToastContainer
        newestOnTop
        position="bottom-center"
        draggable
        theme="colored"
        autoClose={4000}
      />
    </MainContainer>
  );
};
