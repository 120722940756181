import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import parse from 'html-react-parser';
import { Editor } from 'primereact/editor';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { INoteItemList } from '../../../../types/notes/notesInterface';
import Strings from '../../../../i18n';
import { severityEnum } from '../../../../constants/commonEnums';
import { useEditNoteMutation } from '../../../../services/notes/notesService';
import { StyledContainerNote, StyledTooltip } from './viewAndEditStyles';

interface ViewAndEditNoteProps {
  note: INoteItemList | null;
  onDelete: (note: INoteItemList) => void;
  onCancel: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  reloadTable: () => void;
}
export const ViewAndEditNote = ({
  note,
  onDelete,
  onCancel,
  open,
  setOpen,
  reloadTable
}: ViewAndEditNoteProps) => {
  const [actualNote, setActualNote] = useState<INoteItemList | null>(note);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editNote, { isSuccess, isLoading }] = useEditNoteMutation();

  useEffect(() => {
    setActualNote(note);
  }, [note]);

  const handleCancel = () => {
    setActualNote(null);
    setOpen(false);
    setIsEditing(false);
    onCancel();
  };

  const handleEdit = (item: INoteItemList) => {
    editNote({
      id: item.id,
      text: item.text,
      type: item.type,
      severityLevel: item.severityLevel
    });
  };

  const increaseSeverityLevel = (severityLevel: severityEnum | undefined) => {
    setActualNote({
      ...actualNote,
      severityLevel
    } as INoteItemList);
    if (note) {
      editNote({
        id: note?.id,
        text: note?.text,
        type: note?.type,
        severityLevel: severityLevel as severityEnum
      });
    }
  };

  const getSeverityLevel = (severityLevel: severityEnum | undefined) => {
    switch (severityLevel) {
      case severityEnum.low:
        return (
          <LoadingButton
            className="btn-severity"
            data-pr-tooltip={Strings.notes.changeSeverityLevel}
            type="button"
            variant="contained"
            color="success"
            loading={isLoading}
            onClick={() => increaseSeverityLevel(severityEnum.medium)}
          >
            Low
          </LoadingButton>
        );
      case severityEnum.medium:
        return (
          <LoadingButton
            type="button"
            className="btn-severity"
            data-pr-tooltip={Strings.notes.changeSeverityLevel}
            variant="contained"
            color="warning"
            loading={isLoading}
            onClick={() => increaseSeverityLevel(severityEnum.high)}
          >
            Medium
          </LoadingButton>
        );
      case severityEnum.high:
        return (
          <LoadingButton
            type="button"
            className="btn-severity"
            data-pr-tooltip={Strings.notes.changeSeverityLevel}
            variant="contained"
            color="error"
            loading={isLoading}
            onClick={() => increaseSeverityLevel(severityEnum.low)}
          >
            Urgent
          </LoadingButton>
        );
      default:
        return (
          <LoadingButton
            type="button"
            className="btn-severity"
            data-pr-tooltip={Strings.notes.changeSeverityLevel}
            variant="contained"
            color="success"
            loading={isLoading}
            onClick={() => increaseSeverityLevel(severityEnum.medium)}
          >
            Low
          </LoadingButton>
        );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reloadTable();
      toast.success(Strings.notes.successEditNote);
    }
  }, [isSuccess]);

  return (
    <Dialog
      header={actualNote?.targetName}
      visible={open}
      onHide={handleCancel}
      style={{ width: '65vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
    >
      <div className="row justify-content-between align-items-center mb-3">
        <div className="col-6">
          <div>Note:</div>
        </div>
        <div className="col-6 text-right">
          <StyledTooltip target=".btn-severity" />
          {getSeverityLevel(actualNote?.severityLevel)}
        </div>
      </div>
      <StyledContainerNote>
        {isEditing ? (
          <Editor
            value={actualNote?.text || ''}
            onTextChange={(e) =>
              setActualNote({
                ...actualNote,
                text: e.htmlValue ?? ''
              } as INoteItemList)
            }
            style={{ height: '320px' }}
          />
        ) : (
          <div>{parse(actualNote?.text || '')}</div>
        )}
      </StyledContainerNote>
      <div className="row justify-content-between">
        <div className="col-12 text-center mt-3">
          <Button
            variant="contained"
            onClick={() => {
              if (isEditing) {
                handleEdit(actualNote as INoteItemList);
              }
              setIsEditing(!isEditing);
            }}
          >
            {isEditing ? Strings.common.save : Strings.notes.editNote}
          </Button>
        </div>
        <div className="col-12 text-right mt-3">
          <Button
            variant="contained"
            color="error"
            startIcon={<Delete />}
            onClick={() => onDelete(actualNote as INoteItemList)}
          >
            {Strings.common.delete}
          </Button>
          <Button
            variant="contained"
            className="ml-2"
            color="primary"
            onClick={() => handleCancel()}
          >
            {Strings.common.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
