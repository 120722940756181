import React, { useEffect, useState } from 'react';
import { AddCircleOutline, ArrowBackIos } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { ReOpenSideBarIcon } from '../../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { Header } from '../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../devices/devicesStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddSceneTemplate } from '../../../templates/applications/scenes/addScene/addScene';
import { EventsListTemplate } from '../../../templates/applications/events/eventsListTemplate';
import { EventDetails } from '../../../templates/applications/events/eventDetails/eventDetails';
import {
  useEventsToExecuteByDateAndLocationMutation,
  useGetEventListByLocationMutation
} from '../../../services/events/eventService';

export const EventsView = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [getEventsByLocId, { isSuccess, data, isLoading }] =
    useGetEventListByLocationMutation();
  const [
    getEventsToExecuteByDateAndLocation,
    {
      isSuccess: isSuccessToExecute,
      data: dataToExecute,
      isLoading: isLoadingToExecute
    }
  ] = useEventsToExecuteByDateAndLocationMutation();

  const reloadTable = () => {
    if (locationId) {
      getEventsByLocId(+locationId);
      getEventsToExecuteByDateAndLocation({
        date: new Date().toISOString(),
        locationId: +locationId
      });
    }
  };
  useEffect(() => {
    if (locationId) {
      reloadTable();
    }
  }, []);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<any[]>([]);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addScene
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const redirectToSelectTypeOfEvent = () => {
    navigate(`/locations/${locationId}/applications/schedules/typeOfEvent`);
  };

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addScene:
        return <AddSceneTemplate />;
      case viewRightSideBarEnum.eventDetails:
        return (
          <EventDetails
            event={selectedEvents[0]}
            openSideBar={openSideBar}
            selectedEvents={selectedEvents}
            setSelectedScenes={setSelectedEvents}
            setOpenSideBar={setOpenSideBar}
            reloadTable={reloadTable}
          />
        );
      default:
        return <p />;
    }
  };

  useEffect(() => {
    if (selectedEvents.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.eventDetails);
    } else if (selectedEvents.length > 1) {
      openRightSideBarForMethod(
        viewRightSideBarEnum.multipleOrganizationsDetails
      );
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedEvents]);

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.events.eventsTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  redirectToSelectTypeOfEvent();
                }}
              >
                <AddCircleOutline />
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openSideBar}
                selectedItems={selectedEvents}
                singleItemMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.organizationDetails
                  );
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.multipleOrganizationsDetails
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <EventsListTemplate
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        isLoading={isLoading}
        events={data ?? []}
        eventsToExecute={dataToExecute ?? []}
        reloadTable={reloadTable}
      />
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
