/* eslint-disable no-lonely-if */
import { MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ButtonStyled,
  CustomSelect,
  ButtonStyledCancel
} from './shareLocationBarStyles';
import Strings from '../../../i18n';
import { ShareLocationProps } from './shareLocationProps';
import { setOpenUserSideBar } from '../../../store/slices/app/app';
import {
  useGetRolesLocationMutation,
  useGetRolesOrganizationMutation,
  useShareLocationWithBusinessAccountMutation,
  useShareOrganizationUsingEmailMutation,
  useShareOrganizationWithBusinessAccountMutation
} from '../../../services/device/deviceService';
import { ConfirmModal } from '../../../components/commons/confirmModal/confirmModal';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledEditablePropertyItemContainer,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { useShareLocationWithTargetsMutation } from '../../../services/locations/locationsService';
import { shareLocationWithTargetsEnum } from '../../../constants/commonEnums';

export const ShareLocationBar = ({
  reloadTable,
  selectedLocations,
  selectedOrganizations,
  setOpenRightSideBar
}: ShareLocationProps) => {
  const dispatch = useDispatch();
  const [fieldValue, setFieldValue] = useState('');
  const [open, setOpen] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [locationIds, setLocationIds] = useState<number[] | undefined>([]);
  const [organizationIds, setOrganizationIds] = useState<number[] | undefined>(
    []
  );

  const [
    shareOrganization,
    { isLoading, isSuccess: isSuccessShareOrganization }
  ] = useShareOrganizationUsingEmailMutation();
  const [
    shareLocation,
    { isLoading: isLoadingShareLoc, isSuccess: isSuccessShareLoc }
  ] = useShareLocationWithTargetsMutation();
  const [
    shareLocationWithBusinessAccount,
    {
      isLoading: isLoadingShareLocWithBusinessAccount,
      isSuccess: isSuccessShareLocWithBusinessAccount
    }
  ] = useShareLocationWithBusinessAccountMutation();
  const [
    shareOrganizationWithBusinessAccount,
    {
      isLoading: isLoadingShareOrganizationWithBusinessAccount,
      isSuccess: isSuccessShareOrganizationWithBusinessAccount
    }
  ] = useShareOrganizationWithBusinessAccountMutation();
  const handleChangeRole = (event: any) => {
    setRoleId(+event.target.value);
  };
  const [
    getLocationRoles,
    { isSuccess: successGetLocationRoles, data: dataLocationRoles }
  ] = useGetRolesLocationMutation();
  const [
    getOrganizationRoles,
    { isSuccess: successGetOrganizationRoles, data: dataOrganizationRoles }
  ] = useGetRolesOrganizationMutation();

  useEffect(() => {
    setLocationIds(selectedLocations);
    setOrganizationIds(selectedOrganizations?.map((org) => org.organizationId));
    if (selectedLocations && locationIds) {
      getLocationRoles('');
    }
    if (selectedOrganizations && organizationIds) {
      getOrganizationRoles('');
    }
  }, []);

  const closeSideBarAndCleanFields = () => {
    setOpenRightSideBar(false);
    dispatch(setOpenUserSideBar(false));
    setFieldValue('');
    setRoleId(0);
  };

  useEffect(() => {
    if (
      isSuccessShareOrganization ||
      isSuccessShareOrganizationWithBusinessAccount
    ) {
      reloadTable();
      toast.success(Strings.organizations.successShareOrg);
      closeSideBarAndCleanFields();
    }
  }, [
    isSuccessShareOrganization,
    isSuccessShareOrganizationWithBusinessAccount
  ]);

  useEffect(() => {
    if (isSuccessShareLoc || isSuccessShareLocWithBusinessAccount) {
      reloadTable();
      toast.success(Strings.locations.successShareLoc);
      closeSideBarAndCleanFields();
    }
  }, [isSuccessShareLoc, isLoadingShareLocWithBusinessAccount]);
  useEffect(() => {
    setFieldValue('');
    setRoleId(0);
  }, []);

  const sendInfo = () => {
    if (fieldValue.includes('#')) {
      setOpen(true);
    } else {
      if (locationIds) {
        shareLocation({
          targets: [
            {
              value: fieldValue,
              type: shareLocationWithTargetsEnum.email
            }
          ],
          locationIds,
          locationRoleId: roleId,
          shareWithDomain: false,
          shareWithBusinessAccount: false
        });
      }
      if (organizationIds) {
        shareOrganization({
          email: fieldValue,
          organizationIds,
          organizationRoleId: roleId
        });
      }
    }
  };

  const shareWithBusinessAccount = () => {
    if (locationIds) {
      shareLocationWithBusinessAccount({
        businessAccountTag: fieldValue,
        locationIds,
        locationRoleId: roleId
      });
    }
    if (organizationIds) {
      shareOrganizationWithBusinessAccount({
        businessAccountTag: fieldValue,
        organizationIds,
        organizationRoleId: roleId
      });
    }
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {selectedLocations && locationIds
            ? Strings.locations.shareLocation
            : Strings.organizations.shareOrg}
        </StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: Strings.locations.shareLocationLabel,
            type: 'inputText',
            currentValue: fieldValue,
            placeholder: Strings.locations.shareLocationPlaceholder,
            onChange: (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setFieldValue(e.target.value.toString())
          }}
        />
        <StyledEditablePropertyItemContainer>
          <div className="label">{Strings.manageRoles.role}</div>
          <CustomSelect
            onChange={handleChangeRole}
            size="small"
            fullWidth
            value={roleId}
          >
            {successGetLocationRoles &&
              dataLocationRoles &&
              dataLocationRoles.map((element) => {
                return (
                  <MenuItem value={element.id} key={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            {successGetOrganizationRoles &&
              dataOrganizationRoles &&
              dataOrganizationRoles.map((element) => {
                return (
                  <MenuItem value={element.id} key={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            <MenuItem value={0} key={0}>
              {Strings.common.noSystemPermissions}
            </MenuItem>
          </CustomSelect>
        </StyledEditablePropertyItemContainer>
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <ButtonStyledCancel
          variant="contained"
          size="large"
          onClick={() => {
            setOpenRightSideBar(false);
            dispatch(setOpenUserSideBar(false));
          }}
        >
          {Strings.common.cancel}
        </ButtonStyledCancel>
        <ButtonStyled
          disabled={roleId === 0}
          variant="contained"
          loading={
            isLoading ||
            isLoadingShareLoc ||
            isLoadingShareLocWithBusinessAccount ||
            isLoadingShareOrganizationWithBusinessAccount
          }
          size="large"
          onClick={sendInfo}
        >
          {Strings.common.add}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
      <ConfirmModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        titleConfirmation={Strings.locations.areYouSureToShare}
        textConfirmation={Strings.locations.confirmShareLoc}
        onConfirm={() => shareWithBusinessAccount}
      />
    </>
  );
};
