import { Button } from 'primereact/button';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';

export const StyledEffectSelectorItem = styled.div`
  text-transform: capitalize;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  font-size: 0.8rem;
  line-height: 1rem;
  .name {
    font-weight: bold;
    font-size: 0.9rem;
    margin: 0;
  }
  .type {
    font-size: 0.6rem;
    margin: 0;
    color: ${colors.darkGray};
  }
  .execute-btn {
    background-color: transparent;
    border: none;
    font-size: 0.8rem;
    color: black;
  }
`;

export const StyledMenuItem = styled(Button)`
  background-color: transparent;
  border: none;
  color: black;
`;
