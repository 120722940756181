import React, { useState, useEffect } from 'react';
import { Search, Tune, ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../components/header/header';
import { ButtonIconStyled } from '../devices/devicesStyles';
import { OrganizationsTemplate } from '../../templates/organizations/organizationsTemplate/organizationsTemplate';
import Strings from '../../i18n';
import { setOpenOrganizationSideBar } from '../../store/slices/app/app';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { useGetLocationsByLoggedUserMutation } from '../../services/device/deviceService';
import { setLocationsByUser } from '../../store/slices/locations/location';
import {
  selectSystemOrganizationsReadWrite,
  setPermissionsReadWriteOrganizations
} from '../../store/slices/systemPermissions/systemPermissions';
import { selectCurrentUser } from '../../store/slices/login/login';
import {
  systemModuleEnum,
  systemModulePermissionsEnum
} from '../../constants/systemRolesEnums';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { Organization } from '../../types/organizations/organizations';

export const OrganizationsView = () => {
  const dispatch = useDispatch();
  const contextUser = useSelector(selectCurrentUser);
  const [openSideBar, setOpenSideBar] = useState(false);
  const permissionReadWrite = useSelector(selectSystemOrganizationsReadWrite);
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);
  const [getLocations, { isSuccess, data }] =
    useGetLocationsByLoggedUserMutation();
  const modulePermissions = contextUser.roleInfo.permissionsByModule.find(
    (module) => module.systemModuleName === systemModuleEnum.organizations
  );

  const isHavePermissionReadWrite = !!modulePermissions?.permissions.includes(
    systemModulePermissionsEnum.readWrite
  );

  dispatch(setPermissionsReadWriteOrganizations(isHavePermissionReadWrite));
  useEffect(() => {
    getLocations('');
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setLocationsByUser(data));
    }
  }, [isSuccess]);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addOrganization
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };
  const navigate = useNavigate();
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.organizations.organizations}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              {permissionReadWrite ? (
                <ButtonIconStyled
                  size="small"
                  onClick={() => {
                    openRightSideBarForMethod(
                      viewRightSideBarEnum.addOrganization
                    );
                  }}
                >
                  <AddCircleOutlineIcon />
                </ButtonIconStyled>
              ) : (
                <div />
              )}
              <ButtonIconStyled size="small">
                <Tune />
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openSideBar}
                selectedItems={selectedOrganizations}
                singleItemMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.organizationDetails
                  );
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.multipleOrganizationsDetails
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <OrganizationsTemplate
        sideBarOpenFor={sideBarOpenFor}
        openRightSideBarForMethod={openRightSideBarForMethod}
        setOpenSideBar={setOpenSideBar}
        openSideBar={openSideBar}
        selectedOrganizations={selectedOrganizations}
        setSelectedOrganizations={setSelectedOrganizations}
      />
    </>
  );
};
