import React, { useEffect } from 'react';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { useRemoveLocationsFromBusinessAccountMutation } from '../../../../services/businessAccount/businessAccountService';

interface LocationDetailsBusinessAccountSidebarProps {
  closeSideBar: () => void;
  reloadTable: () => void;
  selectedBusinessAccountLocationIds: number[];
  selectedIds: number[];
}
export const LocationDetailsBusinessAccount = ({
  closeSideBar,
  reloadTable,
  selectedBusinessAccountLocationIds,
  selectedIds
}: LocationDetailsBusinessAccountSidebarProps) => {
  const [removeFromBA, { isSuccess: isSuccessRemove }] =
    useRemoveLocationsFromBusinessAccountMutation();
  const removeFromBusinessAccount = () => {
    if (selectedBusinessAccountLocationIds.length > 0) {
      removeFromBA(selectedBusinessAccountLocationIds);
    }
  };
  useEffect(() => {
    if (isSuccessRemove) {
      closeSideBar();
      reloadTable();
    }
  }, [isSuccessRemove]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.locations.locations}</StyledTitleSideBar>
        <SideBarDeleteActionWithPopup
          onDelete={() => removeFromBusinessAccount()}
          titleConfirmation={Strings.businessAccount.removeFromBusinessTitle}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.businessAccount.removeFromBusiness}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
