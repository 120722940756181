import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { AvatarStyled, ErrorText } from './editProfileSidebarStyles';
import Strings from '../../../i18n';
import { setOpenEditUserSideBar } from '../../../store/slices/app/app';
import {
  selectCurrentUser,
  setCredentials,
  setUserInfo
} from '../../../store/slices/login/login';
import { colors } from '../../../constants/colors';
import {
  changePasswordValidator,
  updateUserInfo
} from '../../../utils/validators';
import {
  useDeleteMyAccountMutation,
  useGetUserInfoMutation,
  usePutChangePasswordMutation,
  useUpdateUserInfoMutation
} from '../../../services/users/usersService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';

export const EditProfileSidebar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { logout } = useAuth0();
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [changePasswordValues, setChangePasswordValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [currentFormValues, setCurrentFormValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    id: ''
  });
  const [putInfo, { isLoading: isLoadingPut, isSuccess: isSuccessPut }] =
    useUpdateUserInfoMutation();

  const [getInfo, { isSuccess, isLoading: isLoadingGetInfo, data }] =
    useGetUserInfoMutation();

  const [
    changePassword,
    { isSuccess: successChangePassword, isLoading: isLoadingChangePassword }
  ] = usePutChangePasswordMutation();

  const [
    deleteMyAccount,
    { isSuccess: successDeleteMyAccount, isLoading: isLoadingDeleteMyAccount }
  ] = useDeleteMyAccountMutation();

  useEffect(() => {
    if (currentUser) {
      getInfo();
    }
  }, [!currentUser]);
  useEffect(() => {
    if (isSuccessPut) {
      toast.success(Strings.userProfile.successEdited);
      dispatch(setUserInfo({ userInfo: currentFormValues }));
      dispatch(setOpenEditUserSideBar(false));
    }
  }, [isSuccessPut]);
  const sendInfo = (payload: any) => {
    putInfo(payload);
    setCurrentFormValues({
      firstName: payload.firstName,
      lastName: payload.lastName,
      phoneNumber: payload.phoneNumber,
      id: payload.userId
    });
  };

  const handleLogOut = () => {
    dispatch(
      setCredentials({
        user: null,
        email: '',
        token: '',
        picture: '',
        roleInfo: null,
        userInfo: null,
        refreshToken: '',
        authWithApi: false,
        userId: 0
      })
    );
    logout({
      logoutParams: { returnTo: `${window.location.origin}/callback` }
    });
  };
  useEffect(() => {
    if (successDeleteMyAccount) {
      toast.success(Strings.userProfile.successDelete);
      dispatch(setOpenEditUserSideBar(false));
      handleLogOut();
    }
  }, [successDeleteMyAccount]);

  const sendChangePassword = (payload: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    changePassword({
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword
    });
  };

  useEffect(() => {
    if (successChangePassword) {
      toast.success(Strings.userProfile.successPasswordChanged);
      setChangePasswordVisible(false);
    }
  }, [successChangePassword]);
  return (
    <Box height="100%">
      {!changePasswordVisible ? (
        <Formik
          enableReinitialize
          validationSchema={updateUserInfo}
          initialValues={{
            email: currentUser.email,
            firstName: data && data.firstName ? data.firstName : '',
            lastName: data && data.lastName ? data.lastName : '',
            phoneNumber: data && data.phoneNumber ? data.phoneNumber : ''
          }}
          onSubmit={(values) => {
            sendInfo({
              email: values.email,
              fullName: `${values.firstName} ${values.lastName}`,
              firstName: values.firstName,
              lastName: values.lastName,
              id: data ? data.id : 0,
              phoneNumber: values.phoneNumber
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isValid,
            setFieldTouched,
            errors,
            touched
          }) => (
            <>
              <StyledContainerItems>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <AvatarStyled src={currentUser.picture ?? ''} />
                  <Typography
                    fontSize={20}
                    color={colors.black}
                    marginTop={1}
                    fontWeight={500}
                  >
                    {Strings.userProfile.manageProfile}
                  </Typography>
                </Grid>
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.firstNameLabel,
                    type: 'inputText',
                    currentValue: values.firstName,
                    placeholder: '',
                    nameAndId: 'firstName',
                    onBlur: () => setFieldTouched('firstName'),
                    onChange: handleChange('firstName')
                  }}
                />
                {touched.firstName && errors.firstName && (
                  <ErrorText>{errors.firstName}</ErrorText>
                )}
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.lastNameLabel,
                    type: 'inputText',
                    currentValue: values.lastName,
                    placeholder: '',
                    nameAndId: 'lastName',
                    onBlur: () => setFieldTouched('lastName'),
                    onChange: handleChange('lastName')
                  }}
                />
                {touched.lastName && errors.lastName && (
                  <ErrorText>{errors.lastName}</ErrorText>
                )}
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.emailLabel,
                    type: 'inputText',
                    currentValue: values.email || '',
                    placeholder: '',
                    nameAndId: 'email',
                    disabled: true,
                    onChange: handleChange('email')
                  }}
                />
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.mobileNumberLabel,
                    type: 'inputText',
                    currentValue: values.phoneNumber || '',
                    placeholder: '',
                    nameAndId: 'phoneNumber',
                    onBlur: () => setFieldTouched('phoneNumber'),
                    onChange: handleChange('phoneNumber')
                  }}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <ErrorText>{errors.phoneNumber}</ErrorText>
                )}
                <SideBarSimpleMethodItem
                  name={Strings.userProfile.changePassword}
                  action={() => setChangePasswordVisible(true)}
                  successAction={false}
                  textSuccess=""
                />
                <SideBarDeleteActionWithPopup
                  textIcon={Strings.userProfile.deleteAccount}
                  onDelete={() => deleteMyAccount()}
                  textConfirmation={
                    Strings.userProfile.deleteAccountConfirmation
                  }
                  titleConfirmation={Strings.userProfile.deleteAccountTitle}
                />
              </StyledContainerItems>
              <StyledContainerFooterSideBar>
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => {
                    dispatch(setOpenEditUserSideBar(false));
                  }}
                >
                  {Strings.common.cancel}
                </button>
                <button
                  type="button"
                  className="btn-save"
                  disabled={!isValid || isLoadingGetInfo || isLoadingPut}
                  onClick={() => handleSubmit()}
                >
                  {Strings.common.save}
                </button>
              </StyledContainerFooterSideBar>
            </>
          )}
        </Formik>
      ) : (
        <Formik
          enableReinitialize
          validationSchema={changePasswordValidator}
          initialValues={{
            currentPassword: '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={(values) => {
            sendChangePassword({
              currentPassword: values.currentPassword,
              newPassword: values.password,
              confirmPassword: values.confirmPassword
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isValid,
            setFieldTouched,
            errors,
            touched
          }) => (
            <>
              <StyledContainerItems>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <AvatarStyled src={currentUser.picture ?? ''} />
                  <Typography
                    fontSize={20}
                    color={colors.black}
                    marginTop={1}
                    fontWeight={500}
                  >
                    {Strings.userProfile.changePassword}
                  </Typography>
                </Grid>
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.currentPassword,
                    type: 'inputText',
                    currentValue: values.currentPassword,
                    placeholder: '',
                    nameAndId: 'currentPassword',
                    onBlur: () => setFieldTouched('currentPassword'),
                    onChange: handleChange('currentPassword')
                  }}
                />
                {touched.currentPassword && errors.currentPassword && (
                  <ErrorText>{errors.currentPassword}</ErrorText>
                )}
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.newPassword,
                    type: 'inputText',
                    currentValue: values.password,
                    placeholder: '',
                    nameAndId: 'password',
                    onBlur: () => setFieldTouched('password'),
                    onChange: handleChange('password')
                  }}
                />
                {touched.password && errors.password && (
                  <ErrorText>{errors.password}</ErrorText>
                )}
                <SideBarEditablePropertyItem
                  item={{
                    label: Strings.userProfile.confirmPassword,
                    type: 'inputText',
                    currentValue: values.confirmPassword,
                    placeholder: '',
                    nameAndId: 'confirmPassword',
                    onBlur: () => setFieldTouched('confirmPassword'),
                    onChange: handleChange('confirmPassword')
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <ErrorText>{errors.confirmPassword}</ErrorText>
                )}
              </StyledContainerItems>
              <StyledContainerFooterSideBar>
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => {
                    setChangePasswordVisible(false);
                  }}
                >
                  {Strings.common.cancel}
                </button>
                <button
                  type="button"
                  className="btn-save"
                  disabled={!isValid || isLoadingChangePassword}
                  onClick={() => handleSubmit()}
                >
                  {Strings.common.save}
                </button>
              </StyledContainerFooterSideBar>
            </>
          )}
        </Formik>
      )}
    </Box>
  );
};
