import { Box, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddLocationProps } from './addFolderProps';
import {
  ButtonsContainer,
  TitleStyled,
  ButtonStyled
} from './addFolderBarStyles';
import Strings from '../../../i18n';
import { usePostFoldersMutation } from '../../../services/device/deviceService';
import { Folder } from '../../../types/folders/folder';
import { selectedFolder } from '../../../store/slices/folders/folders';

export const AddFolderBar = ({
  reloadTable,
  setOpenRightSideBar
}: AddLocationProps) => {
  const [name, setName] = useState('');
  const [addFolder, { isLoading, isSuccess, data }] = usePostFoldersMutation();
  const selectedFolders = useSelector(selectedFolder) as {
    folders: Folder[];
    idFolder: number;
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.serverAccess.folder.successAddFolder);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccess]);
  return (
    <Box height="100%">
      <TitleStyled variant="h5">{Strings.locations.addFolder}</TitleStyled>
      <TextField
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => setName(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        label={Strings.locations.folder}
      />
      <ButtonsContainer>
        <LoadingButton
          variant="contained"
          size="large"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </LoadingButton>
        <ButtonStyled
          variant="contained"
          loading={isLoading}
          size="large"
          onClick={() => {
            addFolder({ Name: name, ParentId: selectedFolders.idFolder });
          }}
        >
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
