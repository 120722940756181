/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';

interface DynamicImageProps {
  id: number;
  name: string;
  identifier: string;
}
export const DynamicImage = ({ id, name, identifier }: DynamicImageProps) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    const image = require(`../../../assets/location/icons-applications/${identifier}.jpg`);
    setSrc(image);
  }, [id]);

  return <img src={src} alt={name} width="80" key={identifier} />;
};
