import { ArrowBackIos, Cached } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingRowsComponent } from '../../../../components/commons/LoadingRows/LoadingRows';
import { Header } from '../../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  StyledBoxTable
} from '../../../../components/layout/main/mainStyles';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../locations/allLocationsList/allLocationListStyles';
import { useGetFileSyncDataRetrieverMutation } from '../../../../services/device/deviceService';

export const FileRetrievedDynamic = () => {
  const navigate = useNavigate();
  const path = localStorage.getItem('path');
  const [getDataFromUrl, { isSuccess, data, isLoading }] =
    useGetFileSyncDataRetrieverMutation();
  const loadTable = () => {
    if (path) {
      getDataFromUrl(path);
    }
  };
  useEffect(() => {
    if (!path) {
      navigate(-1);
    }
    loadTable();
  }, []);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.serverAccess.devices.customFiles}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <ButtonIconStyled size="small" onClick={() => loadTable()}>
                <Cached />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.file}: {path}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          {isLoading && <LoadingRowsComponent />}
          {isSuccess && data && (
            <StyledBoxTable>
              {data.map((dataItem: any) => (
                <DataTable
                  value={dataItem.rows}
                  size="small"
                  scrollable
                  selectionPageOnly
                >
                  {data &&
                    dataItem.columns?.map((item: any) => (
                      <Column
                        key={item.name}
                        field={item.name}
                        header={item.name}
                      />
                    ))}
                </DataTable>
              ))}
            </StyledBoxTable>
          )}
        </Grid>
      </Grid>
    </>
  );
};
