import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ButtonItem,
  Container,
  Name,
  SharedContainer,
  SharedText
} from './locationItemStyles';
import { LocationItemProps } from './locationItemProps';
import Strings from '../../i18n';

const user = require('../../assets/user.png');

export const LocationItem = ({ location }: LocationItemProps) => {
  return (
    <Grid item xs={6} md={4} textAlign="center">
      <Container>
        <SharedContainer>
          <SharedText>{Strings.locations.shared}</SharedText>
        </SharedContainer>
        <img src={user} alt="" width={40} />
        <Name>{location.locationName}</Name>
        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          marginTop={5}
        >
          <ButtonItem variant="contained">
            {Strings.locations.online}
          </ButtonItem>
          <ButtonItem variant="contained">
            {Strings.locations.upToDate}
          </ButtonItem>
        </Grid>
      </Container>
    </Grid>
  );
};
