import React, { useEffect, useState } from 'react';
import initSqlJs from 'sql.js';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, Search } from '@mui/icons-material';
import styled from 'styled-components';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';

import { DbDynamicTable } from './dbDynamicTable';
import { useFileSyncDesiredPropertiesMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { ButtonIconStyled } from '../../../components/layout/navBar/navBarStyles';
import { colors } from '../../../constants/colors';
import Strings from '../../../i18n';
import { FilesSyncByDeviceTable } from './filesSyncByDeviceTable';

const HiddenInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  background-color: ${colors.blue};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${colors.darkGray};
  }
`;

const SearchButton = styled.button`
  background-color: ${colors.blue};
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${colors.darkGray};
  }
`;

const StyledInputQuery = styled.input`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 4px 10px;
  width: 400px;
`;

export const DbUploader = () => {
  const navigate = useNavigate();
  const [dbContent, setDbContent] = useState<any>(null);
  const { deviceId } = useParams();
  const [query, setQuery] = useState<string>('');
  const [dbInstance, setDbInstance] = useState<any>(null);
  const [getDesiredProperties, { data: desiredProperties }] =
    useFileSyncDesiredPropertiesMutation();
  const [error, setError] = useState<string | null>(null);

  const executeQuery = (
    databaseInstance: any = dbInstance,
    currentQuery: string = query
  ) => {
    if (databaseInstance && currentQuery) {
      try {
        const results = databaseInstance.exec(currentQuery);
        if (results.length > 0) {
          setDbContent(results);
          setError(null);
        } else {
          setDbContent(null);
          setError('No data found in the table.');
        }
      } catch (e: any) {
        setError(`Query failed: ${e.message}`);
        setDbContent(null);
      }
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;

        const SQL = await initSqlJs({
          locateFile: (fileName) => `https://sql.js.org/dist/${fileName}`
        });

        const uInt8Array = new Uint8Array(arrayBuffer);
        const db = new SQL.Database(uInt8Array);
        setDbInstance(db);
        const parts = file.name.split('-');
        const initialQuery = `SELECT * FROM "${parts[0]}_table"`;
        setQuery(initialQuery);
        executeQuery(db, initialQuery);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      executeQuery();
    }
  };

  useEffect(() => {
    if (deviceId) {
      getDesiredProperties(deviceId);
    }
  }, [deviceId]);

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.devices.fileListLabel}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      {desiredProperties && (
        <FilesSyncByDeviceTable
          data={desiredProperties.filter((item) =>
            item.fileName.endsWith('.db')
          )}
        />
      )}
      <div className="row mt-4 d-flex justify-content-center">
        <div className="col-12 text-center">
          <div className="d-inline-block">
            <StyledLabel htmlFor="file-upload">
              {Strings.serverAccess.devices.clickToUpload}
            </StyledLabel>
            <HiddenInput
              id="file-upload"
              type="file"
              onChange={handleFileUpload}
            />
          </div>
          {dbInstance && (
            <div className="d-inline-block ml-4">
              <StyledInputQuery
                id="query"
                value={query}
                className="form-control d-inline-block"
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <SearchButton
                onClick={() => executeQuery()}
                className="d-inline-block m-0"
              >
                <Search />
              </SearchButton>
            </div>
          )}
        </div>
      </div>
      {error && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          <p>{error}</p>
        </div>
      )}
      {dbContent && dbContent.length > 0 && (
        <DbDynamicTable data={dbContent[0]} />
      )}
    </>
  );
};
