import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const ImageDevice = styled.img`
  height: 60px;
`;

export const Container = styled(Grid)`
  && {
    padding: 10px;
  }
`;

export const Tittle = styled(Typography)`
  && {
    font-size: 15px;
    margin-left: 10px;
    font-weight: bold;
  }
`;

export const Name = styled(Typography)`
  && {
    font-size: 12px;
    margin-left: 10px;
  }
`;

export const Ip = styled(Typography)`
  && {
    font-size: 13px;
    margin-left: 10px;
  }
`;
