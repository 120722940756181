import styled from 'styled-components';

export const StyledContainerType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  & .container-button {
    text-align: center;
    text-decoration: none;
    background: transparent;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 60px -19px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 60px -19px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 60px -19px rgba(0, 0, 0, 0.3);
  }
  & .container-info {
    background-color: white;
    border-radius: 25px;
    padding: 10px 0;
    margin: 20px 0;
    p {
      margin-top: 10px;
      margin-bottom: 0;
      color: #042654;
      font-weight: 400;
    }
  }
  & .image-display {
    height: 90px;
  }
`;
