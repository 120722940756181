/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { IDateType } from '../../../types/events/eventsInterface';
import { DATE_TYPE_ENUM } from '../../../constants/eventEnums';
import { EventUtils } from '../../../utils/eventUtils';
import './pickEventDateStyles.css';

interface IPickEventDateProps {
  showEventDatePicker: boolean;
  setShowEventDatePicker: (show: boolean) => void;
  dateType: IDateType;
  onOk: (data: IDateType) => void;
}

export const PickEventDate = ({
  showEventDatePicker,
  setShowEventDatePicker,
  dateType,
  onOk
}: IPickEventDateProps) => {
  const { months } = EventUtils;
  const [isTypeAllYear, setIsTypeAllYear] = useState(false);
  const [isTypeDate, setIsTypeDate] = useState(false);
  const [isTypeHoliday, setIsTypeHoliday] = useState(false);
  const [currentDateType, setCurrentDateType] = useState<IDateType>(dateType);
  const [submitted, setSubmitted] = useState(false);
  const [startMonthDays, setStartMonthDays] = useState(1);
  const [endMonthDays, setEndMonthDays] = useState(31);

  const dateTypeChanged = (type: number) => {
    setIsTypeAllYear(type === DATE_TYPE_ENUM.ALL_YEAR);
    setIsTypeDate(type === DATE_TYPE_ENUM.DATE_RANGE);
    setIsTypeHoliday(type === DATE_TYPE_ENUM.HOLIDAY);
    setCurrentDateType((prevState) => ({
      ...prevState,
      dateType: type
    }));
  };

  const callCancel = () => {
    setShowEventDatePicker(false);
  };

  const setStartMonth = (month: number) => {
    const days = EventUtils.getMonthDays(month);
    setStartMonthDays(days);
    setCurrentDateType((prevState) => ({
      ...prevState,
      startMonth: month,
      startDay: 1
    }));
  };

  const setStartDay = (day: number) => {
    setCurrentDateType((prevState) => ({
      ...prevState,
      startDay: day
    }));
  };

  const setEndMonth = (month: number) => {
    const days = EventUtils.getMonthDays(month);
    setEndMonthDays(days);
    setCurrentDateType((prevState) => ({
      ...prevState,
      endMonth: month,
      endDay: days
    }));
  };

  const setEndDay = (day: number) => {
    setCurrentDateType((prevState) => ({
      ...prevState,
      endDay: day
    }));
  };

  const setDateType = () => {
    setSubmitted(true);
    if (
      isTypeDate &&
      (!currentDateType.startMonth ||
        !currentDateType.startDay ||
        !currentDateType.endMonth ||
        !currentDateType.endDay)
    ) {
      return;
    }
    onOk(currentDateType);
    setShowEventDatePicker(false);
  };

  useEffect(() => {
    setCurrentDateType(dateType);
    dateTypeChanged(currentDateType.dateType);
  }, []);

  return (
    <Dialog
      header="Pick Event Time"
      visible={showEventDatePicker}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '80vw', '640px': '90vw' }}
      onHide={callCancel}
    >
      <div className="row">
        <div className="col-12">
          <div className="section-event-time-picker pt-2">
            <label htmlFor="dateAllYear">ALL YEAR</label>
            <RadioButton
              inputId="dateAllYear"
              name="customRadio"
              value="1"
              checked={isTypeAllYear}
              onChange={() => dateTypeChanged(DATE_TYPE_ENUM.ALL_YEAR)}
              onKeyDown={(e) => e.key === 'Escape' && callCancel()}
            />
          </div>
          <br />
          <div className="section-event-time-picker">
            <label htmlFor="dateRange">DATE RANGE</label>
            <RadioButton
              inputId="dateRange"
              name="customRadio"
              value="2"
              checked={isTypeDate}
              onChange={() => dateTypeChanged(DATE_TYPE_ENUM.DATE_RANGE)}
              onKeyDown={(e) => e.key === 'Escape' && callCancel()}
            />
          </div>
          <br />
          {isTypeDate && (
            <div className="card mb-2">
              <div className="row justify-content-center">
                <p className="w-100 text-center">Start:</p>
                <div className="col-12 date-range-container pb-4 justify-content-center">
                  <Dropdown
                    options={EventUtils.months}
                    value={currentDateType.startMonth}
                    onChange={(e: DropdownChangeEvent) =>
                      setStartMonth(e.value)
                    }
                    optionLabel="name"
                    placeholder="Select a Month"
                    className="p-mb-2"
                    optionValue="id"
                    style={{ width: '150px' }}
                  />
                  <InputNumber
                    value={currentDateType.startDay}
                    onValueChange={(e: any) => setStartDay(e.value ?? 0)}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-angle-up"
                    decrementButtonIcon="pi pi-angle-down"
                    min={1}
                    max={startMonthDays}
                  />
                </div>
              </div>
              <div className="row">
                <p className="w-100 text-center">End:</p>
                <div className="col-12 date-range-container pb-4 justify-content-center">
                  <br />
                  <Dropdown
                    options={months}
                    value={currentDateType.endMonth}
                    onChange={(e: DropdownChangeEvent) => setEndMonth(e.value)}
                    optionLabel="name"
                    placeholder="Select a Month"
                    optionValue="id"
                    className="p-mb-2"
                    style={{ width: '150px' }}
                  />
                  <InputNumber
                    value={currentDateType.endDay}
                    onValueChange={(e: any) => setEndDay(e.value ?? 0)}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-angle-up"
                    decrementButtonIcon="pi pi-angle-down"
                    min={1}
                    max={endMonthDays}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="section-event-time-picker">
            <label htmlFor="dateHolidayRange">HOLIDAY RANGE</label>
            <RadioButton
              inputId="dateHolidayRange"
              name="customRadio"
              value="3"
              checked={isTypeHoliday}
              onChange={() => dateTypeChanged(DATE_TYPE_ENUM.HOLIDAY)}
              onKeyDown={(e) => e.key === 'Escape' && callCancel()}
            />
          </div>
          <br />
          {isTypeHoliday && (
            <div className="card">
              <div className="row">
                <p className="w-100 text-center">Start:</p>
                <div className="col-12 date-range-container pb-4 justify-content-center">
                  <Dropdown
                    options={EventUtils.holidays}
                    value={currentDateType.holidayStartIndex}
                    onChange={(e: DropdownChangeEvent) =>
                      setCurrentDateType((prevState) => ({
                        ...prevState,
                        holidayStartIndex: e.value
                      }))
                    }
                    style={{ width: '150px' }}
                    optionLabel="name"
                    optionValue="index"
                    placeholder="Select a Holiday"
                    className={`p-mb-2 ${
                      submitted && !currentDateType.holidayStartIndex
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {submitted && !currentDateType.holidayStartIndex && (
                    <div className="text-danger">
                      <small>Please select Start Holidays</small>
                    </div>
                  )}
                  <InputNumber
                    value={currentDateType.holidayStartOffset}
                    onValueChange={(e) =>
                      setCurrentDateType((prevState) => ({
                        ...prevState,
                        holidayStartOffset: e.value ?? 0
                      }))
                    }
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-angle-up"
                    decrementButtonIcon="pi pi-angle-down"
                  />
                </div>
              </div>
              <div className="row">
                <p className="w-100 text-center">End:</p>
                <div className="col-12 date-range-container pb-4 justify-content-center">
                  <Dropdown
                    options={EventUtils.holidays}
                    value={currentDateType.holidayEndIndex}
                    onChange={(e: DropdownChangeEvent) =>
                      setCurrentDateType((prevState) => ({
                        ...prevState,
                        holidayEndIndex: e.value
                      }))
                    }
                    optionLabel="name"
                    placeholder="Select a Holiday"
                    optionValue="index"
                    style={{ width: '150px' }}
                    className={`p-mb-2 ${
                      submitted && !currentDateType.holidayEndIndex
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {submitted && !currentDateType.holidayEndIndex && (
                    <div className="text-danger">
                      <small>Please select End Holidays</small>
                    </div>
                  )}
                  <InputNumber
                    value={currentDateType.holidayEndOffset}
                    onValueChange={(e) =>
                      setCurrentDateType((prevState) => ({
                        ...prevState,
                        holidayEndOffset: e.value ?? 0
                      }))
                    }
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-angle-up"
                    decrementButtonIcon="pi pi-angle-down"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer w-100">
          <Button
            icon="pi pi-check"
            onClick={setDateType}
            label="Ok"
            className="me-2 dialog-btn-ok"
          />
          <Button
            icon="pi pi-times"
            onClick={callCancel}
            label="Cancel"
            className="p-button-danger"
          />
        </div>
      </div>
    </Dialog>
  );
};
