import React from 'react';
import {
  BackupTable,
  ManageAccounts,
  Adjust,
  AssignmentInd
} from '@mui/icons-material';
import Strings from '../i18n';

export const modules: {
  id: number;
  name: string;
  path: string;
  icon: React.ReactNode;
  key?: string;
}[] = [];

export const modulesWithLocation = [
  {
    id: 101,
    name: Strings.applications.pageTitle,
    path: '/applications',
    icon: <BackupTable />
  },
  {
    id: 102,
    name: Strings.users.users,
    path: '/users',
    icon: <ManageAccounts />
  },
  {
    id: 103,
    name: Strings.devices.devices,
    path: '/devices',
    icon: <Adjust />
  }
];
