import { Typography, Grid, Divider, TextField } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { updateUserInfo } from '../../../utils/validators';
import {
  useGetUserInfoMutation,
  useUpdateUserInfoMutation
} from '../../../services/users/usersService';
import {
  ContainerFormProfile,
  ErrorText,
  ContainerForm
} from './completeProfileStyles';
import Strings from '../../../i18n';
import { colors } from '../../../constants/colors';

const havenLogo = require('../../../assets/logo.png');

export const CompleteProfileAuth0 = () => {
  const navigate = useNavigate();
  const { isLoading, user } = useAuth0();

  const [putInfo, { isLoading: isLoadingPut, isSuccess: isSuccessPut }] =
    useUpdateUserInfoMutation();
  const [getInfo, { isLoading: isLoadingGetInfo, data }] =
    useGetUserInfoMutation();

  useEffect(() => {
    if (!isLoading) {
      getInfo();
    }
  }, [isLoading]);
  useEffect(() => {
    if (isSuccessPut) {
      toast.success(Strings.userProfile.successEdited);
      navigate('/locations');
    }
  }, [isSuccessPut]);
  const sendInfo = (payload: any) => {
    putInfo(payload);
  };
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11} md={6} lg={3.5}>
        <ContainerForm container padding={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography align="center" marginY={2} variant="h5">
              <img src={havenLogo} alt="Haven Logo" width={200} />
            </Typography>
            <Typography
              align="center"
              marginY={3}
              variant="h5"
              fontWeight="bold"
              color={colors.strongGray}
              paddingTop={2}
            >
              {Strings.userProfile.editAccount}
            </Typography>
          </Grid>
          <ContainerFormProfile item xs={10} md={8} lg={12}>
            <Formik
              enableReinitialize
              validationSchema={updateUserInfo}
              initialValues={{
                email: user?.email,
                firstName: data && data.firstName ? data.firstName : '',
                lastName: data && data.lastName ? data.lastName : '',
                phoneNumber: data && data.phoneNumber ? data.phoneNumber : ''
              }}
              onSubmit={(values) => {
                sendInfo({
                  email: values.email,
                  fullName: `${values.firstName} ${values.lastName}`,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  id: data ? data.id : 0,
                  phoneNumber: values.phoneNumber
                });
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                isValid,
                setFieldTouched,
                errors,
                touched
              }) => (
                <Grid
                  direction="row"
                  container
                  spacing={2}
                  justifyContent="center"
                >
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={values.firstName}
                      id="firstName"
                      label={Strings.userProfile.firstNameLabel}
                      onBlur={() => setFieldTouched('firstName')}
                      onChange={handleChange('firstName')}
                      name="firstName"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {touched.firstName && errors.firstName && (
                      <ErrorText>{errors.firstName}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={values.lastName}
                      id="lastName"
                      label={Strings.userProfile.lastNameLabel}
                      onBlur={() => setFieldTouched('lastName')}
                      onChange={handleChange('lastName')}
                      name="lastName"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {touched.lastName && errors.lastName && (
                      <ErrorText>{errors.lastName}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={values.email}
                      id="email"
                      label={Strings.userProfile.emailLabel}
                      onBlur={() => setFieldTouched('email')}
                      onChange={handleChange('email')}
                      name="email"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {touched.email && errors.email && (
                      <ErrorText>{errors.email}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={values.phoneNumber}
                      id="phoneNumber"
                      label={Strings.userProfile.mobileNumberLabel}
                      onBlur={() => setFieldTouched('phoneNumber')}
                      onChange={handleChange('phoneNumber')}
                      name="phoneNumber"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <ErrorText>{errors.phoneNumber}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={10} md={8} marginTop={1}>
                    <LoadingButton
                      color="info"
                      className="w-100"
                      disabled={!isValid}
                      onClick={() => handleSubmit()}
                      variant="contained"
                      size="large"
                      loading={isLoadingGetInfo || isLoadingPut}
                    >
                      Strings.userProfile.editAccountButton
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </ContainerFormProfile>
        </ContainerForm>
      </Grid>

      <Divider variant="fullWidth" />
    </Grid>
  );
};
