/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import './pickEventTimeStyle.css';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { TIME_TYPE_ENUM } from '../../../constants/eventEnums';
import { ITimeType } from '../../../types/events/eventsInterface';
import { EventUtils } from '../../../utils/eventUtils';

interface IPickEventTimeProps {
  showEventTimePicker: boolean;
  setShowEventTimePicker: (show: boolean) => void;
  timeType: ITimeType;
  onOk: (data: ITimeType) => void;
}
export const PickEventTime = ({
  showEventTimePicker,
  setShowEventTimePicker,
  timeType,
  onOk
}: IPickEventTimeProps) => {
  const marks = [
    { value: -240, label: '-240' },
    { value: -120, label: '-120' },
    { value: 0, label: '0' },
    { value: 120, label: '120' },
    { value: 240, label: '240' }
  ];
  const [currentTimeType, setCurrentTimeType] = useState<ITimeType>({
    ...timeType
  });
  const [valueForTime, setValueForTime] = useState<string>(
    `${currentTimeType.hour
      .toString()
      .padStart(2, '0')}:${currentTimeType.minute.toString().padStart(2, '0')}`
  );

  const handleTimeChange = (timeTypeNumber: number) => {
    setCurrentTimeType((prevState) => ({
      ...prevState,
      timeType: timeTypeNumber
    }));
  };
  const save = () => {
    const data: ITimeType = {
      ...currentTimeType
    };
    onOk(data);
    setShowEventTimePicker(false);
  };
  const callCancel = () => {
    setShowEventTimePicker(false);
  };
  const setValueTime = (value: string) => {
    const [hour, minute] = value.split(':').map(Number);
    setValueForTime(value);
    setCurrentTimeType((prevState) => ({
      ...prevState,
      hour: hour ?? 0,
      minute: minute ?? 0
    }));
  };
  return (
    <Dialog
      header="Pick Event Time"
      visible={showEventTimePicker}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '80vw', '640px': '90vw' }}
      onHide={() => setShowEventTimePicker(false)}
    >
      <div className="row time-picker-container">
        <div className="col-12">
          <div className="mt-3">
            <div className="section-event-time-picker">
              <label htmlFor="timeType1">TIME</label>
              <RadioButton
                inputId="timeType1"
                name="timeType"
                value={TIME_TYPE_ENUM.ABSOLUTE}
                onChange={() => handleTimeChange(TIME_TYPE_ENUM.ABSOLUTE)}
                checked={currentTimeType.timeType === TIME_TYPE_ENUM.ABSOLUTE}
              />
            </div>
            {currentTimeType.timeType === TIME_TYPE_ENUM.ABSOLUTE && (
              <div>
                <div className="row">
                  <div className="col-12">
                    <input
                      className="time-clock w-100"
                      name="time-clock"
                      value={valueForTime}
                      onChange={(e) => setValueTime(e.target.value)}
                      type="time"
                    />
                  </div>
                </div>
                <br />
              </div>
            )}

            <div className="section-event-time-picker mt-3">
              <label htmlFor="timeType2">SUNRISE</label>
              <RadioButton
                inputId="timeType2"
                name="timeType"
                value={TIME_TYPE_ENUM.SUNRISE}
                onChange={() => handleTimeChange(TIME_TYPE_ENUM.SUNRISE)}
                checked={currentTimeType.timeType === TIME_TYPE_ENUM.SUNRISE}
              />
            </div>
            {currentTimeType.timeType === TIME_TYPE_ENUM.SUNRISE && (
              <div className="row justify-content-center">
                <div className="col-11">
                  <p className="text-bold mb-1">
                    Selected: {currentTimeType.offsetMinutes} minutes
                  </p>
                  <Slider
                    defaultValue={currentTimeType.offsetMinutes}
                    valueLabelDisplay="auto"
                    step={10}
                    min={-240}
                    max={240}
                    marks={marks}
                    onChange={(e: any) =>
                      setCurrentTimeType({
                        ...currentTimeType,
                        offsetMinutes: e.target.value
                      })
                    }
                  />
                </div>
                <br />
              </div>
            )}

            <div className="section-event-time-picker mt-3">
              <label htmlFor="timeType3">SUNSET</label>
              <RadioButton
                inputId="timeType3"
                name="timeType"
                value={TIME_TYPE_ENUM.SUNSET}
                onChange={() => handleTimeChange(TIME_TYPE_ENUM.SUNSET)}
                checked={currentTimeType.timeType === TIME_TYPE_ENUM.SUNSET}
              />
            </div>
            {currentTimeType.timeType === TIME_TYPE_ENUM.SUNSET && (
              <div className="row justify-content-center">
                <div className="col-11">
                  <p className="text-bold mb-2 mt-1">
                    Selected: {currentTimeType.offsetMinutes} minutes
                  </p>
                  <Slider
                    defaultValue={currentTimeType.offsetMinutes}
                    valueLabelDisplay="auto"
                    step={10}
                    min={-240}
                    max={240}
                    marks={marks}
                    onChange={(e: any) =>
                      setCurrentTimeType({
                        ...currentTimeType,
                        offsetMinutes: e.target.value
                      })
                    }
                  />
                </div>
                <br />
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer mt-2 w-100">
          <Button
            icon="pi pi-check"
            onClick={save}
            label="Ok"
            className="me-2"
          />
          <Button
            icon="pi pi-times"
            onClick={callCancel}
            label="Cancel"
            className="p-button-danger"
          />
        </div>
      </div>
    </Dialog>
  );
};
