import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { IScene } from '../../../types/Scenes/scenesTypes';

interface IScenesListTemplateProps {
  selectedScenes: any[];
  setSelectedScenes: (selectedScenes: any[]) => void;
  scenes: IScene[];
  isLoading: boolean;
}
export const ScenesListTemplate = ({
  selectedScenes,
  setSelectedScenes,
  scenes,
  isLoading
}: IScenesListTemplateProps) => {
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedScenes.length > 0 ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={selectedScenes.length > 0 ?? false}
        onChange={() => {
          setSelectedScenes([]);
        }}
      />
    );
  };
  return (
    <>
      {isLoading && <LoadingRowsComponent />}
      {scenes && (
        <DataTable
          selection={selectedScenes}
          selectionMode="checkbox"
          onSelectionChange={(e) => setSelectedScenes(e.value)}
          className="mt-3"
          rowHover
          value={scenes}
          size="small"
          scrollable
          scrollHeight="100vh"
          paginator={scenes && scenes.length >= 50}
          selectionPageOnly
          rows={50}
          rowsPerPageOptions={[5, 10, 20, 50, scenes ? scenes.length : 50]}
          tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
        >
          <Column
            selectionMode="multiple"
            headerStyle={styledColumnHeaderCheckbox}
            header={getHeaderCheckbox}
            frozen
          />
          <Column field="id" header="Id" sortable />
          <Column field="name" header="Name" sortable />
          <Column field="sceneIndex" header="Scene Index" sortable />
          <Column field="actionCount" header="Action Count" sortable />
          <Column field="sceneTypeName" header="Type" sortable />
        </DataTable>
      )}
    </>
  );
};
