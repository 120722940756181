import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
}

// eslint-disable-next-line import/no-default-export
export default initFontAwesome;
