import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { decodedToken } from '../../../types/Auth/decodedTokenInterface';
import { useGetUserInfoMutation } from '../../../services/users/usersService';
import {
  selectCurrentUser,
  setTokenAndRefreshToken,
  setCredentials,
  setRoleLoggedUser,
  setUserId,
  setUserInfo
} from '../../../store/slices/login/login';
import { Loading } from '../../Loading';
import { useGetLoggedUserSystemRolePermissionsMutation } from '../../../services/systemPermissions/systemPermissionsService';
import {
  tokenCustomClaimsEnum,
  tokenUserIdEnum
} from '../../../constants/commonEnums';
import { useRefreshApiTokenMutation } from '../../../services/auth/authServices';

export const CallbackComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const expiredToken = queryParams.get('expiredToken');
  const navigate = useNavigate();
  let token: string = '';
  const { isLoading, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [
    getInfo,
    {
      isLoading: isLoadingGetInfo,
      data: userInfo,
      isSuccess: isSuccessGetInfo,
      isError: isErrorGetInfo
    }
  ] = useGetUserInfoMutation();

  const [
    refreshApiToken,
    { isSuccess: isSuccessRefresh, data: dataRefresh, isError }
  ] = useRefreshApiTokenMutation();

  const [
    getRoleByToken,
    { isLoading: isLoadingGetRole, data: roleInfo, isSuccess: isSuccessGetRole }
  ] = useGetLoggedUserSystemRolePermissionsMutation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [checkUser, setCheckUser] = useState(false);

  const handleAuth0Login = async () => {
    try {
      token = await getAccessTokenSilently();
      const decoded: decodedToken = jwtDecode(token);
      if (decoded[tokenCustomClaimsEnum.userId] > tokenUserIdEnum.notFound) {
        dispatch(
          setCredentials({
            user: user?.nickname ?? '',
            email: user?.email ?? '',
            token: token ?? '',
            picture: user?.picture ?? '',
            roleInfo: {},
            userInfo: null,
            refreshToken: '',
            authWithApi: false,
            userId: decoded[tokenCustomClaimsEnum.userId]
          })
        );
        getRoleByToken('');
        getInfo();
        setCheckUser(true);
      } else {
        navigate('/create-user');
      }
    } catch (error) {
      navigate('/login-with-password');
    }
  };

  const handleApiAuth = () => {
    if (expiredToken) {
      refreshApiToken({
        userId: currentUser.userId ?? 0,
        refreshToken: currentUser.refreshToken
      });
    } else {
      const redirectUrl = localStorage.getItem('redirectUrlAfterTokenRefresh');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrlAfterTokenRefresh');
        window.location.href = redirectUrl;
      } else {
        navigate('/');
      }
    }
  };

  const checkStoredTokens = () => {
    const storedToken = localStorage.getItem('authToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');

    if (storedToken) {
      try {
        const decoded: decodedToken = jwtDecode(storedToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp && decoded.exp > currentTime) {
          dispatch(
            setCredentials({
              user: currentUser.user ?? '',
              email: currentUser.email ?? '',
              token: storedToken,
              picture: currentUser.picture ?? '',
              roleInfo: currentUser.roleInfo ?? {},
              userInfo: currentUser.userInfo,
              refreshToken: storedRefreshToken || '',
              authWithApi: true,
              userId: decoded['unique_name']
            })
          );
          getRoleByToken('');
          getInfo();
          setCheckUser(true);
          return true;
        }
        if (storedRefreshToken) {
          refreshApiToken({
            userId: decoded[tokenCustomClaimsEnum.userId],
            refreshToken: storedRefreshToken
          });
          return true;
        }
      } catch (error) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
      }
    }
    return false;
  };

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && !currentUser.authWithApi) {
        handleAuth0Login();
      } else if (currentUser.authWithApi && currentUser.token !== '') {
        handleApiAuth();
      } else {
        const hasValidToken = checkStoredTokens();
        if (!hasValidToken) {
          navigate('/login-with-password');
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (checkUser && userInfo && isSuccessGetRole && isSuccessGetInfo) {
      const { firstName, lastName, phoneNumber, lastLogin } = userInfo;
      dispatch(setRoleLoggedUser({ roleInfo }));
      dispatch(setUserId({ userId: userInfo.userId }));
      dispatch(
        setUserInfo({
          userInfo: { firstName, lastName, phoneNumber, lastLogin }
        })
      );
      const redirectUrl = localStorage.getItem('redirectUrlAfterTokenRefresh');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrlAfterTokenRefresh');
        window.location.href = redirectUrl;
      } else {
        navigate('/');
      }
    } else if (isErrorGetInfo) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      navigate('/login-with-password');
    }
  }, [
    checkUser,
    isLoadingGetInfo,
    isSuccessGetRole,
    userInfo,
    isSuccessGetInfo,
    isErrorGetInfo
  ]);

  useEffect(() => {
    if (isSuccessRefresh && dataRefresh) {
      localStorage.setItem('authToken', dataRefresh.token);
      localStorage.setItem('refreshToken', dataRefresh.refreshToken);

      dispatch(
        setTokenAndRefreshToken({
          token: dataRefresh.token,
          refreshToken: dataRefresh.refreshToken
        })
      );

      const redirectUrl = localStorage.getItem('redirectUrlAfterTokenRefresh');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrlAfterTokenRefresh');
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/';
      }
    } else if (isError) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      navigate('/login-with-password');
    }
  }, [isSuccessRefresh, isError]);

  return <Loading />;
};
