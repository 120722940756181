/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React, { useEffect, useState } from 'react';
import { BrightnessSelectorProps } from './brightnessSelectorProps';
import {
  StyledBrightnessButton,
  StyledBrightnessDialog,
  StyledBrightnessItem
} from './brightnessSelectorStyles';
import Strings from '../../../i18n';
import { BRIGHTNESS_VALUES } from '../../../constants/commonEnums';

export const BrightnessSelectorBtn = ({
  lightBrightnessId,
  onChange,
  disabled
}: BrightnessSelectorProps) => {
  const [src, setSrc] = useState('');
  const [visible, setVisible] = useState(false);
  const [brightnessSelected, setBrightnessSelected] = useState<number | null>(
    lightBrightnessId === -1 ? null : lightBrightnessId
  );

  useEffect(() => {
    if (brightnessSelected) {
      const image = require(`../../../assets/images/brightness/${brightnessSelected}0percent.png`);
      setSrc(image);
    } else {
      const image = require(`../../../assets/images/brightness/select_brightness.png`);
      setSrc(image);
    }
  }, [brightnessSelected]);

  const handleChange = (brightness: number) => {
    onChange(brightness);
    setVisible(false);
    setBrightnessSelected(brightness);
  };
  return (
    <>
      <StyledBrightnessButton
        type="button"
        onClick={() => setVisible(true)}
        disabled={disabled}
      >
        <img src={src} height="40" alt="Brightness selector" />
      </StyledBrightnessButton>
      <StyledBrightnessDialog
        header={Strings.common.brightness}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      >
        {BRIGHTNESS_VALUES.map((brightnessValue) => (
          <StyledBrightnessItem
            type="button"
            key={brightnessValue}
            IsActive={brightnessSelected === brightnessValue}
            onClick={() => handleChange(brightnessValue)}
          >
            {brightnessValue}0%
          </StyledBrightnessItem>
        ))}
      </StyledBrightnessDialog>
    </>
  );
};

BrightnessSelectorBtn.defaultProps = {
  disabled: false
};
