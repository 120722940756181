import { Skeleton } from '@mui/material';
import React from 'react';

export const LoadingRowsComponent = () => {
  return (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
};
