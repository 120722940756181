import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { IGroup } from '../../types/Groups/groupsInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiGroups = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getGroupsByLocationId: builder.mutation<IGroup[], number>({
      query: (locationId) => ({
        url: `api/Group/AllGroupsByLocation/${locationId}`,
        method: 'GET'
      })
    }),
    deleteGroup: builder.mutation<null, number>({
      query: (groupId) => ({
        url: `api/Group/${groupId}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useGetGroupsByLocationIdMutation, useDeleteGroupMutation } =
  apiGroups;
