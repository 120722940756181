import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { createUserWithBAValidator } from '../../../utils/validators';
import { ErrorText } from '../../profile/editProfileSidebar/editProfileSidebarStyles';
import { useCreateUserWithBAMutation } from '../../../services/businessAccount/businessAccountService';

interface CreateUserBusinessAccountBarProps {
  id: number;
  reloadTable: () => void;
  closeSideBar: () => void;
}
export const CreateUserBusinessAccountBar = ({
  id,
  reloadTable,
  closeSideBar
}: CreateUserBusinessAccountBarProps) => {
  const [
    sendInfo,
    { isLoading: isLoadingPostInfo, isSuccess: isSuccessPostInfo }
  ] = useCreateUserWithBAMutation();

  useEffect(() => {
    if (isSuccessPostInfo) {
      reloadTable();
      closeSideBar();
      toast.success(Strings.businessAccount.anEmailWasSentToNewUser);
    }
  }, [isSuccessPostInfo]);
  return (
    <Formik
      enableReinitialize
      validationSchema={createUserWithBAValidator}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        businessAccountId: id
      }}
      onSubmit={(values) => {
        sendInfo({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          businessAccountId: values.businessAccountId
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        setFieldTouched,
        errors,
        touched
      }) => (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>
              {Strings.businessAccount.createUserAndAssign}
            </StyledTitleSideBar>
            <SideBarEditablePropertyItem
              item={{
                label: Strings.userProfile.firstNameLabel,
                type: 'inputText',
                currentValue: values.firstName,
                placeholder: Strings.userProfile.firstNameLabel,
                onChange: handleChange('firstName'),
                onBlur: () => setFieldTouched('firstName'),
                nameAndId: 'firstName'
              }}
            />
            {touched.firstName && errors.firstName && (
              <ErrorText>{errors.firstName}</ErrorText>
            )}
            <SideBarEditablePropertyItem
              item={{
                label: Strings.userProfile.lastNameLabel,
                type: 'inputText',
                currentValue: values.lastName,
                placeholder: Strings.userProfile.lastNameLabel,
                onChange: handleChange('lastName'),
                onBlur: () => setFieldTouched('lastName'),
                nameAndId: 'lastName'
              }}
            />
            {touched.lastName && errors.lastName && (
              <ErrorText>{errors.lastName}</ErrorText>
            )}
            <SideBarEditablePropertyItem
              item={{
                label: Strings.userProfile.emailLabel,
                type: 'inputText',
                currentValue: values.email,
                placeholder: Strings.userProfile.emailLabel,
                onChange: handleChange('email'),
                onBlur: () => setFieldTouched('email'),
                nameAndId: 'email'
              }}
            />
            {touched.email && errors.email && (
              <ErrorText>{errors.email}</ErrorText>
            )}
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                closeSideBar();
              }}
            >
              {Strings.common.cancel}
            </button>
            <LoadingButton
              color="info"
              onClick={() => handleSubmit()}
              variant="contained"
              size="small"
              loading={isLoadingPostInfo}
            >
              {Strings.users.addUser}
            </LoadingButton>
          </StyledContainerFooterSideBar>
        </>
      )}
    </Formik>
  );
};
