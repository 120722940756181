import { Box, Typography, Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  ButtonsContainer,
  TitleStyled,
  ButtonStyled,
  TextFieldStyled,
  ButtonStyledCancel
} from './moveLocationBarStyles';
import Strings from '../../../i18n';
import {
  useAssignLocationToOrganizationMutation,
  useGetOrganizationsMutation
} from '../../../services/device/deviceService';

import { colors } from '../../../constants/colors';
import { MoveLocationProps } from './moveLocationBarProps';

export const MoveLocationBar = ({
  reloadTable,
  idLocations,
  setOpenRightSideBar
}: MoveLocationProps) => {
  const [getOrganization, { data }] = useGetOrganizationsMutation();
  const [fieldValue, setFieldValue] = useState();
  const [assignLocation, { isLoading, isSuccess }] =
    useAssignLocationToOrganizationMutation();

  useEffect(() => {
    getOrganization('');
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.locations.successAssignedLocation);
      reloadTable();
    }
  }, [isSuccess]);
  return (
    <Box height="100%">
      <TitleStyled variant="h5">{Strings.locations.moveToOrg}</TitleStyled>
      <Box height="25%" bgcolor={colors.white} padding={2} marginTop={6}>
        <Typography
          fontSize={13}
          marginTop={1}
          marginLeft={0}
          color={colors.blue}
        >
          {Strings.organizations.organization}
        </Typography>
        <Autocomplete
          disablePortal
          id="user"
          options={data || []}
          getOptionLabel={(option) => option.organizationName}
          onChange={(event: any, newValue: any | null) => {
            setFieldValue(newValue?.organizationId);
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.organizationId}>
              {option.organizationName}
            </Box>
          )}
          renderInput={(params) => (
            <TextFieldStyled
              {...params}
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
      </Box>

      <ButtonsContainer>
        <ButtonStyledCancel
          variant="contained"
          size="large"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </ButtonStyledCancel>
        <ButtonStyled
          disabled={!fieldValue}
          variant="contained"
          loading={isLoading}
          size="large"
          onClick={() =>
            assignLocation({
              locationIds: idLocations,
              OrganizationId: fieldValue || 0
            })
          }
        >
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
