import Typography from '@mui/material/Typography';
import React from 'react';
import {
  StyledAnchor,
  StyledContainerButtonWithRedirect,
  StyledIcon
} from './ButtonWithRedirectStyles';
import { ButtonWithRedirectProps } from './ButtonWithRedirectProps';
import { colors } from '../../../constants/colors';

export const ButtonWithRedirect = ({
  icon,
  text,
  url,
  enabled = true
}: ButtonWithRedirectProps) => {
  return (
    <StyledContainerButtonWithRedirect>
      <StyledAnchor to={url} color={colors.black} isActive={enabled}>
        <StyledIcon>{icon}</StyledIcon>
        <Typography marginTop={1} fontWeight="bold">
          {text}
        </Typography>
      </StyledAnchor>
    </StyledContainerButtonWithRedirect>
  );
};
