import { Typography, Box, Tooltip, Button, Avatar } from '@mui/material';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';

import { colors } from '../../constants/colors';

export const StatusUser = styled(Typography)<{ isActive: boolean }>`
  && {
    color: ${({ isActive }) => (isActive ? colors.green : colors.orange)};
  }
`;

export const StyledTooltip = styled(Tooltip)`
  margin: 0 3px;
  &.css-i4bv87-MuiSvgIcon-root {
    width: 1rem;
  }
`;

export const StyledButtonName = styled(Button)<{ needsInformation: boolean }>`
  && {
    color: ${({ needsInformation }) =>
      needsInformation ? colors.strongGray : colors.black};
    text-transform: capitalize;
    font-weight: 400;
    font-style: ${({ needsInformation }) => (needsInformation ? 'Italic' : '')};
  }
`;

export const StyledInputSearch = styled(InputText)`
  padding-top: 2px;
  padding-bottom: 2px;
`;
