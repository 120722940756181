import { Button, Select } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ButtonStyledDetails = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.red};
    text-transform: none;
  }
`;

export const CustomSelect = styled(Select)`
  && {
    border: none;
    width: 100%;
    margin-bottom: 5px;
    background-color: ${colors.lightGray};
  }
`;
