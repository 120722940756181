import React, { useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { StyledTitleLabelAddEvent } from '../../templates/applications/events/addEvent/addEventStyles';
import Strings from '../../i18n';
import { useGetGroupsByLocationIdMutation } from '../../services/groups/groupsService';
import { IGroup } from '../../types/Groups/groupsInterfaces';

interface SingleGroupSelectorProps {
  locationId: number;
  selectedGroup: IGroup | undefined;
  setSelectedGroup: (selectedGroup: IGroup) => void;
  disabled: boolean;
}
export const SingleGroupSelector = ({
  locationId,
  selectedGroup,
  setSelectedGroup,
  disabled
}: SingleGroupSelectorProps) => {
  const [getGroups, { isSuccess, data }] = useGetGroupsByLocationIdMutation();

  useEffect(() => {
    if (locationId) {
      getGroups(locationId);
    }
  }, []);
  return (
    <>
      <StyledTitleLabelAddEvent>
        * {Strings.events.groupSelector}
      </StyledTitleLabelAddEvent>
      <Dropdown
        value={selectedGroup}
        onChange={(e) => setSelectedGroup(e.value)}
        options={data}
        optionLabel="groupName"
        placeholder="Select a group"
        className="w-100 md:w-20rem"
        showClear
        disabled={disabled}
      />
      {disabled && (
        <div className="text-warning">
          <span>{Strings.events.disableGroup}</span>
        </div>
      )}
    </>
  );
};
