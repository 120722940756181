import { Box, Grid, Checkbox, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddLocationProps } from './addLocationProps';
import Strings from '../../../i18n';
import {
  useAddLocationInOrgMutation,
  usePostLocationMutation,
  usePostOrganizationMutation
} from '../../../services/device/deviceService';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { colors } from '../../../constants/colors';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';

export const AddLocationBar = ({
  reloadTable,
  organizationId,
  setOpenRightSideBar
}: AddLocationProps) => {
  const [name, setName] = useState('');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isOrganization, setIsOrganization] = useState(false);
  const contextUser = useSelector(selectCurrentUser);
  const [addLocation, { isLoading, isSuccess }] = usePostLocationMutation();
  const [
    addLocationInOrg,
    { isLoading: isLoadingAddOrg, isSuccess: isSuccessAddOrg }
  ] = useAddLocationInOrgMutation();
  const [
    addOrganization,
    { isLoading: isLoadingCreateOrganization, isSuccess: isSuccessCreateOrg }
  ] = usePostOrganizationMutation();

  const clearForm = () => {
    setName('');
    setIsOrganization(false);
    setAddress('');
    setLat(0);
    setLng(0);
  };
  const handleClose = () => {
    setOpenRightSideBar(false);
    clearForm();
  };
  useEffect(() => {
    if (isSuccess || isSuccessAddOrg) {
      toast.success(Strings.locations.successAddLocation);
      setOpenRightSideBar(false);
      reloadTable();
      clearForm();
    }
  }, [isSuccess, isSuccessAddOrg]);
  useEffect(() => {
    if (isSuccessCreateOrg) {
      toast.success(Strings.organizations.addOrganization);
      setOpenRightSideBar(false);
      reloadTable();
      clearForm();
    }
  }, [isSuccessCreateOrg]);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOrganization(event.target.checked);
  };

  const [error, setError] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    if (!value.trim() || !/[a-zA-Z]/.test(value)) {
      setError(true);
    } else {
      setError(false);
    }
    setName(value);
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.locations.addLocation}</StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: isOrganization
              ? Strings.organizations.organizationName
              : Strings.locations.nameLocation,
            type: 'inputText',
            currentValue: name,
            nameAndId: 'name',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
          }}
        />
        <Grid container marginTop={1}>
          <Box marginTop={-1.5}>
            <Checkbox
              checked={isOrganization}
              onChange={handleChangeCheckbox}
            />
          </Box>
          <Typography fontSize={14} marginLeft={1} color={colors.darkGray}>
            {Strings.locations.addLocationAsOrg}
          </Typography>
        </Grid>
        {!isOrganization && (
          <>
            <SideBarEditablePropertyItem
              item={{
                label: `* ${Strings.common.enterAnAddress}`,
                type: 'inputText',
                currentValue: address,
                nameAndId: 'address',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress(e.target.value)
              }}
            />
            <SideBarEditablePropertyItem
              item={{
                label: `* ${Strings.common.enterAZipCode}`,
                type: 'inputText',
                currentValue: zipCode,
                nameAndId: 'zipCode',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setZipCode(e.target.value)
              }}
            />
            <SideBarEditablePropertyItem
              item={{
                label: Strings.common.latitude,
                type: 'inputNumber',
                currentValue: lat,
                nameAndId: 'lat',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setLat(+e.target.value)
              }}
            />
            <SideBarEditablePropertyItem
              item={{
                label: Strings.common.longitude,
                type: 'inputNumber',
                currentValue: lng,
                nameAndId: 'lng',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setLng(+e.target.value)
              }}
            />
          </>
        )}
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => handleClose()}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={isOrganization ? !name : !address || !zipCode}
          onClick={() => {
            if (!isOrganization) {
              addLocation({
                organizationId,
                Name: name.length > 0 ? name : address,
                OwnerId: contextUser.userId || 0,
                GeoInfo: {
                  Address: address,
                  Latitude: lat,
                  Longitude: lng,
                  zipCode
                }
              });
            } else {
              addOrganization({
                Name: name,
                ParentId: organizationId ?? null,
                OwnerId: contextUser.userId
              });
            }
          }}
        >
          {Strings.common.add}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
