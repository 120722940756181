import React from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import Strings from '../../../i18n';
import {
  ButtonStyled,
  ButtonsContainer,
  CancelButtonStyled,
  CheckBoxRole,
  CustomSelect,
  FormContainer,
  InputNameRole,
  LabelSelect,
  Title
} from './addAdminStyles';
import { setOpenUserSideBar } from '../../../store/slices/app/app';

export const AddAdmin = () => {
  const dispatch = useDispatch();
  return (
    <Box height="100%">
      <Title>{Strings.manageRoles.addAdmin}</Title>
      <Grid container>
        <FormContainer>
          <Grid>
            <Grid marginLeft={-1} marginBottom={4} marginTop={4}>
              <LabelSelect>{Strings.manageRoles.accountEmail}</LabelSelect>
              <InputNameRole size="small" />
            </Grid>
            <Grid marginLeft={-1} marginBottom={3} container>
              <Grid item>
                <CheckBoxRole color="default" defaultChecked />
              </Grid>
              <Grid item marginTop={1.5}>
                <LabelSelect>
                  {Strings.manageRoles.usePredefineRole}
                </LabelSelect>
              </Grid>
            </Grid>
            <Grid marginLeft={-1} marginBottom={3}>
              <LabelSelect>{Strings.manageRoles.role}</LabelSelect>
            </Grid>
            <LabelSelect>Option 1</LabelSelect>
            <CustomSelect size="small" fullWidth>
              <MenuItem value="">...</MenuItem>
              <MenuItem value={10}>...</MenuItem>
            </CustomSelect>
            <LabelSelect>Option 2</LabelSelect>
            <CustomSelect size="small" fullWidth>
              <MenuItem value="">...</MenuItem>
              <MenuItem value={10}>...</MenuItem>
            </CustomSelect>
            <LabelSelect>Option 3</LabelSelect>
            <CustomSelect size="small" fullWidth>
              <MenuItem value="">...</MenuItem>
              <MenuItem value={10}>...</MenuItem>
            </CustomSelect>
          </Grid>
        </FormContainer>
      </Grid>
      <ButtonsContainer>
        <CancelButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            dispatch(setOpenUserSideBar(false));
          }}
        >
          {Strings.common.cancel}
        </CancelButtonStyled>
        <ButtonStyled variant="contained" size="large">
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
