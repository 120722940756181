import { Grid } from '@mui/material';
import { Button } from 'primereact/button';

import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledGlobalSearchBtn = styled.button`
  && {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px;
    padding: 10px 0;
    color: ${colors.mediumGray};
  }
`;

export const StyledInputSearch = styled.input`
  && {
    width: 100%;
    color: ${colors.darkGray};
    background-color: ${colors.lightGray};
    border-radius: 10px;
    border: 2px solid #aaa;
    background-color: #f2f2f2;
    font-weight: 400;
    margin: 0;
    height: 100%;
    padding: 10px;
    border-color: transparent;
    &:focus {
      box-shadow: 0 0 0 0.2rem #e9e9e9;
      border-color: #f1f1f1;
    }
  }
`;

export const StyledContainerOptions = styled(Grid)`
  && {
    padding: 0 10px;
    margin: 0;
    border-bottom: 1px solid #aaa;
    background-color: transparent;
  }
`;

export const StyledContainerOptionsItem = styled.button`
  && {
    border-radius: 5px 5px 0 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 0;
    width: 100%;
    font-size: 12px;
    color: ${colors.darkGray};
    transition: background-color 0.3s;
    &:hover {
      background-color: ${colors.lightGray};
    }
    &:focus {
      background-color: ${colors.lightGray};
      outline: none;
    }
  }
`;

export const StyledContainerResults = styled(Grid)`
  && {
    padding: 0 10px;
    margin: 0;
    background-color: transparent;
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mediumLightGray}; /* Background color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Background color on hover */
  }

  ::-ms-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  ::-ms-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  ::-ms-scrollbar-thumb {
    background: #888; /* Background color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
  }

  ::-ms-scrollbar-thumb:hover {
    background: #555; /* Background color on hover */
  }
    .selected {
  background-color: ${colors.lightGray};
`;

export const StyledItemResult = styled.button<{ showOptions: boolean }>`
  && {
    border-radius: 0 0 5px 5px;
    background-color: transparent;
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
    font-size: 14px;
    color: ${colors.darkGray};
    transition: background-color 0.3s;
    border-bottom: 1px solid #eaeaea;
    position: relative;

    .showOnHover {
      opacity: ${(props) => (props.showOptions ? '1' : '0')};
      height: ${(props) => (props.showOptions ? 'auto' : '0')};
      overflow: hidden;
      transition: opacity 0.3s ease, height 0.3s ease;
      display: ${(props) => (props.showOptions ? 'flex' : 'none')};
    }

    &:hover {
      background-color: ${colors.lightGray};

      .showOnHover {
        display: flex !important;
        opacity: 1;
        height: auto;
      }
    }

    &:focus {
      background-color: ${colors.lightGray};
      outline: none;
    }

    h4 {
      margin: 0;
      font-size: 15px;
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      margin-bottom: 5px;
      font-size: 12px;
      margin-bottom: 5px;
    }

    @media (max-width: 768px) {
      .showOnHover {
        display: flex;
        opacity: 1;
        height: auto;
      }
    }
  }
`;

export const StyledResultRow = styled.p`
  font-size: 12px;
  color: ${colors.mediumGray};
  padding: 5px 0;
  margin: 0;
  text-align: right;
  width: 100%;
`;

export const StyledCancelBtn = styled.button`
  && {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    color: ${colors.darkGray};
    transition: background-color 0.3s;
    border-radius: 0 0 5px 5px;
    &:hover {
      background-color: ${colors.lightGray};
    }
  }
`;

export const StyledNoResults = styled.p`
  font-size: 14px;
  color: ${colors.mediumGray};
  padding: 5px 0;
  margin: 0;
  text-align: center;
  width: 100%;
`;

export const StyledButtonItemWithIcon = styled(Button)`
  && {
    margin: 0 2px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid ${colors.darkGray};
    cursor: pointer;
    padding: 3px;
    min-width: auto;
    font-size: 10px;
    color: ${colors.darkGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    &:hover {
      background-color: transparent;
      border-color: ${colors.mediumGray};
      font-color: ${colors.mediumGray};
      svg {
        color: ${colors.mediumGray};
      }
    }
    svg {
      font-size: 16px;
    }
  }
`;

export const styledTooltip: any = {
  position: 'bottom',
  style: { fontSize: '12px' }
};
