import React from 'react';
import {
  StyledAutoComplete,
  StyledEditablePropertyItemContainer
} from '../sideBarComponentsStyles';
import {
  StyledFileInput,
  StyledFileInputWrapper
} from '../MethodWithValueItem/sideBarMethodWithValueStyles';
import Strings from '../../../../i18n';

interface SideBarEditablePropertyItemProps {
  className?: string;
  item: {
    label: string;
    type: string;
    currentValue: string | number;
    placeholder?: string;
    disabled?: boolean;
    nameAndId?: string;
    onBlur?: any;
    onChange:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    fileName?: string;
    acceptedFiles?: string;
    selectedItems?: any[];
    filteredSuggestions?: any[];
    search?: (value: any) => void;
    setSelectedItems?: (value: any) => void;
  };
}

export const SideBarEditablePropertyItem = ({
  className,
  item
}: SideBarEditablePropertyItemProps) => {
  const switchInputToShow = (type: string, value: string | number) => {
    switch (type) {
      case 'inputText':
        return (
          <input
            type="text"
            className={`input-text ${className}`}
            placeholder={item.placeholder}
            value={value}
            onChange={item.onChange}
            id={item.nameAndId}
            onBlur={item.onBlur}
            name={item.nameAndId}
            disabled={item.disabled}
            key={item.nameAndId}
          />
        );
      case 'inputNumber':
        return (
          <input
            type="number"
            className={`input-text ${className}`}
            placeholder={item.placeholder}
            value={value}
            onChange={item.onChange}
            id={item.nameAndId}
            name={item.nameAndId}
            disabled={item.disabled}
          />
        );
      case 'inputPassword':
        return (
          <input
            type="password"
            className={`input-text ${className}`}
            placeholder={item.placeholder}
            defaultValue={value}
            id={item.nameAndId}
            name={item.nameAndId}
            onChange={item.onChange}
            disabled={item.disabled}
          />
        );
      case 'inputFile':
        return (
          <StyledFileInputWrapper>
            <StyledFileInput
              type="file"
              onChange={item.onChange}
              accept={item.acceptedFiles ?? '*'}
            />
            {item.fileName !== ''
              ? item.fileName
              : Strings.common.noFileSelected}
          </StyledFileInputWrapper>
        );
      case 'inputTags':
        return (
          <StyledAutoComplete
            field="name"
            multiple
            value={item.selectedItems}
            appendTo={document.body}
            suggestions={item.filteredSuggestions}
            completeMethod={(e) => item.search && item.search(e)}
            onChange={(e) =>
              item.setSelectedItems && item.setSelectedItems(e.value)
            }
          />
        );
      case 'inputTextArea':
        return (
          <textarea
            className={`input-text ${className}`}
            placeholder={item.placeholder}
            value={value}
            onChange={item.onChange}
            id={item.nameAndId}
            name={item.nameAndId}
            disabled={item.disabled}
            rows={10}
          />
        );
      default:
        return (
          <input
            type="text"
            className={`input-text ${className}`}
            placeholder={item.placeholder}
            defaultValue={value}
            id={item.nameAndId}
            name={item.nameAndId}
            onChange={item.onChange}
            disabled={item.disabled}
          />
        );
    }
  };
  return (
    <StyledEditablePropertyItemContainer>
      <div key={item.label}>
        <div className="label">{item.label}</div>
        {switchInputToShow(item.type, item.currentValue)}
      </div>
    </StyledEditablePropertyItemContainer>
  );
};

SideBarEditablePropertyItem.defaultProps = {
  className: ''
};
