import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { IDeleteActionWithPopupProps } from './sideBarDeleteActionWithPopupProps';
import Strings from '../../../../i18n';
import { StyledContainerSimpleMethod } from '../sideBarComponentsStyles';
import { colors } from '../../../../constants/colors';

export const SideBarDeleteActionWithPopup = ({
  onDelete,
  id,
  textConfirmation,
  titleConfirmation,
  textIcon
}: IDeleteActionWithPopupProps) => {
  const [open, setOpen] = React.useState(false);
  const [counter, setCounter] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setCounter(3);
    setIsCounting(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsCounting(false);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isCounting && counter > 0) {
      timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setIsCounting(false);
    }
    return () => clearTimeout(timer);
  }, [counter, isCounting]);

  return (
    <>
      <StyledContainerSimpleMethod
        onClick={() => handleClickOpen()}
        type="button"
        style={{ color: colors.red }}
      >
        {textIcon || Strings.common.delete}
      </StyledContainerSimpleMethod>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxHeight: 435
          }
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleConfirmation}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {textConfirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDelete()}
            color="success"
            variant="contained"
            disabled={isCounting}
          >
            {isCounting ? `Wait ${counter} sec...` : Strings.common.ok}
          </Button>
          <Button
            onClick={() => handleClose()}
            color="error"
            variant="contained"
          >
            {Strings.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
