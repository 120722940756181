/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { Grid } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { ArrowBackIos, Save } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from '../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  ButtonIconHeaderStyled
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { IAddEditBusinessAccount } from '../../../types/businessAccount/businessAccount';
import 'react-phone-number-input/style.css';
import './addEditBusinessAccountStyles.css';
import { selectCurrentUser } from '../../../store/slices/login/login';
import {
  useAddBusinessAccountMutation,
  useGetBusinessAccountDetailsMutation
} from '../../../services/businessAccount/businessAccountService';
import { useEditBusinessAccountMutation } from '../../../services/device/deviceService';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';

export const AddEditBusinessAccount = () => {
  const { businessAccountId } = useParams();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [businessAccountData, setBusinessAccountData] =
    useState<IAddEditBusinessAccount>({
      name: '',
      latitude: 0,
      longitude: 0,
      address: '',
      phoneNumber: '',
      zipCode: '',
      state: '',
      websiteAddress: '',
      accountingEmail: '',
      domainName: '',
      specialInstructions: ''
    });
  const contextUser = useSelector(selectCurrentUser);
  const [
    getInfoBA,
    { isLoading: isLoadingGetData, isSuccess: isSuccessGetData, data: dataBA }
  ] = useGetBusinessAccountDetailsMutation();

  const [addBusinessAccount, { isLoading, isSuccess }] =
    useAddBusinessAccountMutation();

  const [
    editBusinessAccount,
    {
      isLoading: isLoadingEditBusiness,
      isSuccess: isSuccessEditBusinessAccount
    }
  ] = useEditBusinessAccountMutation();

  const backTo = () => {
    navigate('/business-account');
  };

  const save = () => {
    if (editMode) {
      editBusinessAccount({
        ...businessAccountData,
        id: businessAccountId ? Number(businessAccountId) : 0,
        ownerId: businessAccountData.ownerId || 0
      });
    } else {
      addBusinessAccount({
        ...businessAccountData
      });
    }
  };
  useEffect(() => {
    setEditMode(!!businessAccountId);
    if (businessAccountId) {
      getInfoBA(+businessAccountId);
    }
  }, [businessAccountId]);
  useEffect(() => {
    if (dataBA) {
      setBusinessAccountData({ ...dataBA });
    }
  }, [isSuccessGetData]);

  useEffect(() => {
    if (isSuccessEditBusinessAccount || isSuccess) {
      backTo();
      toast.success(
        isSuccessEditBusinessAccount
          ? Strings.businessAccount.successEditBusiness
          : Strings.businessAccount.successBusiness
      );
    }
  }, [isSuccessEditBusinessAccount, isSuccess]);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between align-items-center">
            <div className="col-2 text-left">
              <ButtonIconHeaderStyled size="small" onClick={() => backTo()}>
                <ArrowBackIos />
              </ButtonIconHeaderStyled>
            </div>
            <div className="col-8 text-center">
              <StyledTitleHeader>
                {editMode
                  ? Strings.businessAccount.editBusinessAccount
                  : Strings.businessAccount.createBusinessAccount}
              </StyledTitleHeader>
            </div>
            <div className="col-2 text-right">
              <ButtonIconHeaderStyled
                size="small"
                onClick={() => save()}
                disabled={!businessAccountData.name}
              >
                <Save />
              </ButtonIconHeaderStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <div>
        {isLoadingGetData && <LoadingRowsComponent />}
        {!isLoadingGetData && (
          <Grid
            container
            spacing={2}
            className="mt-4 px-2"
            justifyContent="center"
          >
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={4}>
                  <p className="mb-0 label-text">
                    {Strings.businessAccount.businessAccountName}:
                  </p>
                  <InputText
                    id="username"
                    aria-describedby="username-help"
                    className="w-100"
                    value={businessAccountData.name}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        name: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterAName}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <p className="mb-0 label-text">
                    {Strings.common.phoneNumber}:
                  </p>
                  <PhoneInput
                    defaultCountry="US"
                    value={businessAccountData.phoneNumber}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        phoneNumber: e?.toString() || ''
                      })
                    }
                    placeholder={Strings.common.enterAPhoneNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p className="mb-0 label-text">
                    {Strings.businessAccount.address}:
                  </p>
                  <InputText
                    id="address"
                    aria-describedby="address-help"
                    className="w-100"
                    value={businessAccountData.address}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        address: e.target.value
                      })
                    }
                    placeholder={Strings.businessAccount.enterAnAddress}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <p className="mb-0 label-text">
                    {Strings.businessAccount.businessAccountZipCode}:
                  </p>
                  <InputText
                    id="zipCode"
                    aria-describedby="zipCode-help"
                    className="w-100"
                    value={businessAccountData.zipCode}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        zipCode: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterAZipCode}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <p className="mb-0 label-text">
                    {Strings.businessAccount.businessAccountState}:
                  </p>
                  <InputText
                    id="state"
                    aria-describedby="state-help"
                    className="w-100"
                    value={businessAccountData.state}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        state: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterAState}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <p className="mb-0 label-text">{Strings.common.latitude}:</p>
                  <InputNumber
                    id="latitude"
                    aria-describedby="latitude-help"
                    minFractionDigits={1}
                    maxFractionDigits={5}
                    className="w-100"
                    value={businessAccountData.latitude}
                    onValueChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        latitude: e.value as number
                      })
                    }
                    placeholder={Strings.common.enterALatitude}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <p className="mb-0 label-text">{Strings.common.longitude}:</p>
                  <InputNumber
                    id="longitude"
                    aria-describedby="longitude-help"
                    className="w-100"
                    minFractionDigits={1}
                    maxFractionDigits={5}
                    value={businessAccountData.longitude}
                    onValueChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        longitude: e.value as number
                      })
                    }
                    placeholder={Strings.common.enterALongitude}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={4}>
                  <p className="mb-0 label-text">
                    {Strings.businessAccount.websiteAddress}:
                  </p>
                  <InputText
                    id="websiteAddress"
                    aria-describedby="websiteAddress-help"
                    className="w-100"
                    value={businessAccountData.websiteAddress}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        websiteAddress: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterAWebsiteAddress}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <p className="mb-0 label-text">
                    {Strings.common.accountingEmail}:
                  </p>
                  <InputText
                    id="accountingEmail"
                    aria-describedby="accountingEmail-help"
                    className="w-100"
                    value={businessAccountData.accountingEmail}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        accountingEmail: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterAnAccountingEmail}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <p className="mb-0 label-text">
                    {Strings.common.domainName}:
                  </p>
                  <InputText
                    id="domainName"
                    aria-describedby="domainName-help"
                    className="w-100"
                    value={businessAccountData.domainName}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        domainName: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterADomainName}
                  />
                </Grid>
                {editMode && (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <p className="mb-0 label-text">
                        {Strings.common.primaryContactUserEmail}:
                      </p>
                      <InputText
                        id="domainName"
                        aria-describedby="domainName-help"
                        className="w-100"
                        value={businessAccountData.primaryContactUserEmail}
                        onChange={(e) =>
                          setBusinessAccountData({
                            ...businessAccountData,
                            primaryContactUserEmail: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <p className="mb-0 label-text">
                        {Strings.common.ownerEmail}:
                      </p>
                      <InputText
                        id="domainName"
                        aria-describedby="domainName-help"
                        disabled
                        className="w-100"
                        value={businessAccountData.ownerEmail}
                        onChange={(e) =>
                          setBusinessAccountData({
                            ...businessAccountData,
                            ownerEmail: e.target.value
                          })
                        }
                        placeholder={Strings.common.enterADomainName}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <p className="mb-0 label-text">
                    {Strings.common.specialInstructions}:
                  </p>
                  <InputTextarea
                    id="specialInstructions"
                    aria-describedby="specialInstructions-help"
                    rows={3}
                    className="w-100"
                    value={businessAccountData.specialInstructions}
                    onChange={(e) =>
                      setBusinessAccountData({
                        ...businessAccountData,
                        specialInstructions: e.target.value
                      })
                    }
                    placeholder={Strings.common.enterSpecialInstructions}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};
