import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledGroupTotalLightsButton = styled.button`
  background-color: #f8f9fa;
  border: none;
  border-radius: 5px;
  color: #495057;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e9ecef;
  }
`;

export const StyledIncludedLightSpan = styled.span`
  background-color: ${colors.lightGray};
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  margin: 5px;
  display: block;
  border-radius: 5px;
`;
