import { Avatar, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';

export const ContainerItem = styled.div`
  min-width: 270px;
  text-align: center;
  margin: 12px auto;
  .MuiAvatar-root {
    margin: 0 auto !important;
  }
  text-align: center;
`;

export const AvatarStyled = styled(Avatar)`
  width: 24px !important;
  height: 24px !important;
`;

export const ButtonStyled = styled(Button)`
  && {
    width: 80%;
    margin-bottom: 10px;
    font-weight: 400;
    background-color: ${colors.strongGray};
    color: ${colors.white};
    text-transform: none;
  }
`;

export const StyledVersionParagraph = styled.p`
  font-size: 12px;
  color: ${colors.mediumGray};
  margin: 0;
  text-align: center;
`;
