import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import Typography from '@mui/material/Typography';
import { colors } from '../../../constants/colors';

export const MobileMenu = styled(Drawer)`
  && {
    width: 250px;
    display: block;
    .MuiDrawer-paper {
      box-sizing: border-box;
      width: 250px !important;
    }
    @media (min-width: 900px) {
      display: none;
    }
  }
`;

export const DesktopMenu = styled(Drawer)`
  && {
    width: 280px;
    display: 'block';
    .MuiDrawer-paper {
      box-sizing: 'border-box';
      padding: 8px;
      margin-top: 50px;
      width: 280px;
      background-color: ${colors.white};
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
`;
