import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const sliceAuth = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    picture: null
  } as {
    user: null | any;
    email: null | string;
    token: null | string;
    picture: null | string;
    refreshToken: string;
    authWithApi: boolean;
    roleInfo: {
      systemRoleName: string;
      permissionsByModule: {
        systemModuleName: string;
        permissions: string[];
      }[];
    };
    userInfo: {
      firstName: string;
      lastName: string;
      lastLogin: string;
      phoneNumber: string;
    } | null;
    userId: null | number;
  },
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          user,
          email,
          token,
          picture,
          roleInfo,
          userInfo,
          refreshToken,
          authWithApi,
          userId
        }
      }: PayloadAction<{
        user: any;
        email: string;
        token: string;
        picture: string;
        roleInfo: any;
        refreshToken: string;
        authWithApi: boolean;
        userId: number;
        userInfo: {
          firstName: string;
          lastName: string;
          lastLogin: string;
          phoneNumber: string;
        } | null;
      }>
    ) => {
      state.user = user;
      state.email = email;
      state.token = token;
      state.picture = picture;
      state.roleInfo = roleInfo;
      state.userInfo = userInfo;
      state.authWithApi = authWithApi;
      state.refreshToken = refreshToken;
      state.userId = userId;
    },
    setRoleLoggedUser: (
      state,
      {
        payload: { roleInfo }
      }: PayloadAction<{
        roleInfo: any;
      }>
    ) => {
      state.roleInfo = roleInfo;
    },
    setUserInfo: (
      state,
      {
        payload: { userInfo }
      }: PayloadAction<{
        userInfo: any;
      }>
    ) => {
      state.userInfo = userInfo;
    },
    setUserId: (
      state,
      {
        payload: { userId }
      }: PayloadAction<{
        userId: number;
      }>
    ) => {
      state.userId = userId;
    },
    setTokenAndRefreshToken: (
      state,
      {
        payload: { token, refreshToken }
      }: PayloadAction<{
        token: string;
        refreshToken: string;
      }>
    ) => {
      state.token = token;
      state.refreshToken = refreshToken;
    }
  },
  extraReducers: (builder) => {}
});
export default sliceAuth.reducer;
export const selectCurrentUser = (state: RootState) => state.persistedReducer;
export const {
  setCredentials,
  setUserId,
  setRoleLoggedUser,
  setUserInfo,
  setTokenAndRefreshToken
} = sliceAuth.actions;
