/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Badge, Box } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import {
  ArrowBackIos,
  AddCircleOutline,
  Tune,
  Visibility,
  Map
} from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import {
  ButtonIconStyled,
  SharedWithItem,
  StatusProcess,
  StyledButtonName
} from './allLocationListStyles';
import { Location, LocationByUser } from '../../../types/location/location';
import { setAllLocations } from '../../../store/slices/locations/location';
import { useGetLocationsMutation } from '../../../services/device/deviceService';
import Strings from '../../../i18n';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddLocationBar } from '../addLocationBar/addLocationBar';
import { Header } from '../../../components/header/header';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { LocationDetailsBar } from '../locationDetailsBar/locationDetailsBar';
import { ShareLocationBar } from '../shareLocationBar/shareLocationBar';
import { TransferLocationBar } from '../transferLocationBar/transferLocationBar';
import { MoveLocationBar } from '../moveLocationBar/moveLocationBar';
import { MultipleLocationsDetailsSideBar } from '../multipleLocationsDetails/multipleLocationsDetailsBar';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { selectSystemLocationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import { AllLocationsFilters } from './allLocationsFilters/allLocationsFilters';
import { ShareLocationsWithTargets } from '../shareLocationsWithTargets/shareLocationsWithTargets';
import { ChangeLatLongLocationBar } from '../changeLatLongLocation/changeLatLongLocation';

export const AllLocationsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const filtersCount = Array.from(queryParams.keys()).length;
  const filterByLabel = queryParams.get('filterByLabel');
  const filterByOwner = queryParams.get('filterByOwner');
  const filterSharedWith = queryParams.get('filterSharedWith');
  const [allLocationsList, setAllLocationsList] = useState<Location[]>([]);
  const [openSharedWithDialog, setOpenSharedWithDialog] = useState(false);
  const [selectToShowSharedWith, setSelectToShowSharedWith] =
    useState<Location>();
  const [openSideBar, setOpenSideBar] = useState(false);
  const permissionLocationReadWrite = useSelector(
    selectSystemLocationsReadWrite
  );

  const [selectedLocations, setSelectedLocation] = useState<Location[]>([]);
  const [getLocations, { isLoading, isSuccess, data }] =
    useGetLocationsMutation();
  const [sideBarView, setSideBarView] = useState(
    viewRightSideBarEnum.addLocation
  );
  const SharedWithButton = (rowData: Location) => {
    return (
      <ButtonIconStyled
        size="small"
        onClick={() => {
          setSelectToShowSharedWith(rowData);
          setOpenSharedWithDialog(true);
        }}
      >
        <Visibility />
      </ButtonIconStyled>
    );
  };

  useEffect(() => {
    getLocations('');
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setAllLocations(data));
    }
  }, [isSuccess]);

  const openLocationSideBarFor = (view: number) => {
    setOpenSideBar(true);
    setSideBarView(view);
  };

  const selectLocation = (params: any) => {
    navigate(`/locations/${params.locationId}/applications`);
  };
  const getStatus = (item: any) => {
    return <StatusProcess IsActive={item.enabled} marginRight={2} />;
  };

  const getName = (item: any) => {
    return (
      <StyledButtonName onClick={() => selectLocation(item)}>
        {item.locationName}
      </StyledButtonName>
    );
  };
  const reloadTablesAnCloseSidebar = () => {
    getLocations('');
    setOpenSideBar(false);
  };
  const switchSideBar = () => {
    const locations: LocationByUser[] = selectedLocations.map((element) => ({
      locationId: element.locationId,
      locationName: element.locationName,
      locationAddress: '',
      locationRemoteAccessToken: '',
      isOwner: false,
      ownerEmail: '',
      organizationId: 0,
      organizationName: '',
      locationRoleId: 0
    }));
    switch (sideBarView) {
      case viewRightSideBarEnum.addLocation:
        return (
          <AddLocationBar
            reloadTable={() => {
              reloadTablesAnCloseSidebar();
            }}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.locationDetails:
        return (
          <LocationDetailsBar
            openRightSideBarForMethod={openLocationSideBarFor}
            reloadTable={() => {
              reloadTablesAnCloseSidebar();
            }}
            locationOrg={locations[0] ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocation:
        return (
          <ShareLocationBar
            reloadTable={() => reloadTablesAnCloseSidebar()}
            selectedLocations={locations.map((loc) => loc.locationId) ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocationsWithTargets:
        return (
          <ShareLocationsWithTargets
            reloadTable={() => reloadTablesAnCloseSidebar()}
            selectedLocations={locations.map((loc) => loc.locationId) ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.transferLocation:
        return (
          <TransferLocationBar
            reloadTable={() => reloadTablesAnCloseSidebar()}
            selectedLocations={locations.map((loc) => loc.locationId) ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.moveLocation:
        return (
          <MoveLocationBar
            reloadTable={() => reloadTablesAnCloseSidebar()}
            idLocations={locations.map((loc) => loc.locationId) ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.multipleLocationsDetails:
        return (
          <MultipleLocationsDetailsSideBar
            reloadTable={() => reloadTablesAnCloseSidebar()}
            openRightSideBarForMethod={openLocationSideBarFor}
            selectedLocations={locations.map((loc) => loc.locationId) ?? []}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.allLocationsFilters:
        return (
          <AllLocationsFilters
            reloadTable={() => reloadTablesAnCloseSidebar()}
            setOpenRightSideBar={setOpenSideBar}
            openRightSideBar={openSideBar}
          />
        );
      case viewRightSideBarEnum.changeLatLongLocation:
        return (
          <ChangeLatLongLocationBar
            location={selectedLocations[0]}
            setOpenRightSideBar={setOpenSideBar}
            reloadTable={() => reloadTablesAnCloseSidebar()}
          />
        );
      default:
        return (
          <AddLocationBar
            reloadTable={() => getLocations('')}
            setOpenRightSideBar={setOpenSideBar}
          />
        );
    }
  };
  useEffect(() => {
    if (isSuccess && data) {
      let filteredLocations = data;
      if (filterByLabel) {
        const lowerCaseFilter = filterByLabel.toLowerCase();
        filteredLocations = data.filter((location) => {
          return (
            location.locationName.toLowerCase().includes(lowerCaseFilter) ||
            location.ownerName.toLowerCase().includes(lowerCaseFilter) ||
            location.locationAddress.toLowerCase().includes(lowerCaseFilter) ||
            location.organizationName.toLowerCase().includes(lowerCaseFilter)
          );
        });
      }
      if (filterByOwner) {
        filteredLocations = filteredLocations.filter((location) => {
          return (
            location.ownerName
              .toLowerCase()
              .includes(filterByOwner.toLowerCase()) ||
            location.ownerEmail
              .toLowerCase()
              .includes(filterByOwner.toLowerCase())
          );
        });
      }
      if (filterSharedWith) {
        filteredLocations = filteredLocations.filter((location) => {
          return location.sharedWith.some((user) =>
            user.email.toLowerCase().includes(filterSharedWith.toLowerCase())
          );
        });
      }
      setAllLocationsList(filteredLocations);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (selectedLocations.length === 1) {
      openLocationSideBarFor(viewRightSideBarEnum.locationDetails);
    } else if (selectedLocations.length > 1) {
      openLocationSideBarFor(viewRightSideBarEnum.multipleLocationsDetails);
    } else {
      openLocationSideBarFor(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedLocations]);
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedLocations.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={
          (allLocationsList &&
            selectedLocations &&
            selectedLocations.length > 0) ??
          false
        }
        onChange={() => {
          setSelectedLocation([]);
        }}
      />
    );
  };
  const redirectToMap = (rowData: Location) => {
    if (rowData.lat && rowData.lon) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${rowData.lat},${rowData.lon}`,
        '_blank'
      );
    } else {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${rowData.locationAddress}`,
        '_blank'
      );
    }
  };
  const getAddressAndRedirectToMap = (rowData: Location) => {
    return (
      <span>
        {rowData.locationAddress && (
          <ButtonIconStyled size="small" onClick={() => redirectToMap(rowData)}>
            <Map />
          </ButtonIconStyled>
        )}
        {rowData.locationAddress}
      </span>
    );
  };
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-5 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.locations.allLocations}
              </StyledTitleHeader>
            </div>
            <div className="col-6 col-sm-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  setOpenSideBar(true);
                  setSideBarView(viewRightSideBarEnum.addLocation);
                }}
              >
                <AddCircleOutline />
              </ButtonIconStyled>
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  setOpenSideBar(true);
                  setSideBarView(viewRightSideBarEnum.allLocationsFilters);
                }}
              >
                <Badge badgeContent={filtersCount} color="warning">
                  <Tune />
                </Badge>
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>

      <Box>
        {isLoading && <LoadingRowsComponent />}
        {isSuccess && data && (
          <DataTable
            selection={selectedLocations}
            selectionMode="checkbox"
            onSelectionChange={(e) => setSelectedLocation(e.value)}
            className="mt-3"
            rowHover
            value={allLocationsList}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={allLocationsList && allLocationsList.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              20,
              50,
              allLocationsList ? allLocationsList.length : 100
            ]}
            tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column
              field="enabled"
              header=""
              sortable
              body={getStatus}
              headerStyle={{ width: '1.5rem', textAlign: 'center' }}
            />
            <Column
              field="locationName"
              header="Name"
              sortable
              body={getName}
            />
            <Column field="ownerName" header="Owner" sortable />
            <Column
              field="locationAddress"
              header="Address"
              sortable
              body={getAddressAndRedirectToMap}
            />
            <Column
              field="createdAt"
              body={(rowData) =>
                moment(rowData.createdAt).format('YY/MM/DD HH:mm')
              }
              header="Created At"
              sortable
              dataType="date"
            />
            <Column field="deviceCount" header="Devices" sortable />
            <Column
              field="sharedWith"
              header="Shared With"
              sortable
              body={SharedWithButton}
            />
          </DataTable>
        )}
      </Box>
      <Dialog
        header="Shared With"
        visible={openSharedWithDialog}
        onHide={() => {
          setOpenSharedWithDialog(false);
        }}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        {selectToShowSharedWith &&
          selectToShowSharedWith.sharedWith.map((user) => (
            <SharedWithItem key={user.email}>{user.email}</SharedWithItem>
          ))}
      </Dialog>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchSideBar()}
      </RightSideBar>
    </>
  );
};
