import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { FirmwareStatusEnum } from '../../constants/enums/firmwareEnums';

export const StyledScheduleStatus = styled.span<{ status: string }>`
  width: 100px;
  color: ${({ status }) => {
    switch (status) {
      case FirmwareStatusEnum.firmwareUpdateRequestFailed:
      case FirmwareStatusEnum.failed:
      case FirmwareStatusEnum.firmwareUpdateCancelledByUser:
      case FirmwareStatusEnum.firmwareUpdateCancelledBySystem:
        return colors.red;
      case FirmwareStatusEnum.deviceOfflineFirmwareUpdate:
      case FirmwareStatusEnum.staticMethodSent:
      case FirmwareStatusEnum.staticMethodAck:
      case FirmwareStatusEnum.firmwareUpdateRequested:
      case FirmwareStatusEnum.created:
        return colors.orange;
      case FirmwareStatusEnum.firmwareUpdateSuccess:
        return colors.green;
      case FirmwareStatusEnum.unknown:
        return colors.mediumGray;
      default:
        return colors.darkGray;
    }
  }};
`;
