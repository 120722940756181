import { Divider, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const NameRole = styled(Typography)`
  && {
    margin-left: 5px;
    margin-right: 5px;
    color: ${colors.strongGray};
  }
`;

export const UsersInRole = styled(Typography)`
  && {
    color: ${colors.mediumGray};
  }
`;

export const DividerRole = styled(Divider)`
  && {
    margin-top: 5px;
    background-color: ${colors.lightGray};
    margin-bottom: 5px;
  }
`;
