import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, Cached } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import JsonView from '@uiw/react-json-view';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Paginator } from 'primereact/paginator';
import { useGetRecordsByDbMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import {
  selectIsRightSideBarOpen,
  setOpenRightSideBar
} from '../../../store/slices/app/app';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import {
  StyledBoxTable,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { StyledButtonAction } from './recordsDeviceStyle';
import { TelemetryDevice } from '../../../types/Device/DeviceInterfaces';
import Strings from '../../../i18n';

export const DbDetailsRecords = () => {
  const defaultRows = 20;
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState(defaultRows);
  const { deviceId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const db = queryParams.get('db');

  const isOpenRightSideBar = useSelector(selectIsRightSideBarOpen) as boolean;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jsonToShow, setJsonToShow] = useState({});
  const [
    getTelemetry,
    {
      isLoading: isLoadingGetDebugDevice,
      isSuccess: isSuccessGetDebugDevice,
      data: dataDebugDevice
    }
  ] = useGetRecordsByDbMutation();

  const formatDateToISO = (date: string) => {
    return moment(date, 'MM/DD/YYYY, HH:mm').toISOString();
  };

  const getDataTable = () => {
    if (db) {
      getTelemetry({
        deviceId: deviceId as string,
        dbName: db,
        page: currentPage,
        pageSize: rows
      });
    }
  };

  const showJson = (element: {}) => {
    setJsonToShow(element);
    dispatch(setOpenRightSideBar(true));
  };

  const getRecordNumber = (item: TelemetryDevice) => {
    return [
      <StyledButtonAction
        onClick={() => {
          showJson(item);
        }}
        type="button"
      >
        <Typography>{item.recordNumber}</Typography>
      </StyledButtonAction>
    ];
  };

  const onChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  useEffect(() => {
    if (db) {
      getDataTable();
    }
  }, [currentPage, rows]);

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">{db}</StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <ButtonIconStyled size="small" onClick={() => getDataTable()}>
                <Cached />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.currentDevice} {deviceId}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          {isLoadingGetDebugDevice && <LoadingRowsComponent />}
          {isSuccessGetDebugDevice && dataDebugDevice && (
            <StyledBoxTable>
              <DataTable
                value={dataDebugDevice.records}
                size="small"
                scrollable
                selectionPageOnly
              >
                <Column
                  header="Time stamp"
                  sortable
                  field="receivedDate"
                  body={(rowData) => {
                    return rowData.receivedDate
                      ? moment(rowData.receivedDate).format('YYYY/MM/DD, h:mmA')
                      : '';
                  }}
                />

                <Column
                  field="recordNumber"
                  header="Record Number"
                  body={getRecordNumber}
                />
              </DataTable>
              {dataDebugDevice?.totalRecords > defaultRows && (
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={dataDebugDevice.totalRecords}
                  rowsPerPageOptions={[
                    10,
                    20,
                    30,
                    dataDebugDevice.totalRecords
                  ]}
                  onPageChange={onChangeHandler}
                />
              )}
            </StyledBoxTable>
          )}
        </Grid>
      </Grid>
      <RightSideBar
        open={isOpenRightSideBar}
        onMenuClose={() => {
          dispatch(setOpenRightSideBar(false));
        }}
      >
        <JsonView
          value={jsonToShow}
          displayDataTypes={false}
          displayObjectSize={false}
          indentWidth={2}
        />
      </RightSideBar>
    </>
  );
};
