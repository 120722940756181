import React from 'react';
import { DeviceMethodsSideBar } from '../deviceMethodsSideBar/deviceMethodsSideBar';
import { MultipleDevicesSelectedMethodsProps } from './multipleDevicesSelectedMethodsProps';
import {
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';

export const MultipleDevicesSelectedMethods = ({
  reloadTable,
  openRightSideBarForMethod,
  selectedDevice,
  openRightSideBar,
  setOpenRightSideBar,
  deviceLocationView,
  clearSingleDevice
}: MultipleDevicesSelectedMethodsProps) => {
  return (
    <div>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.serverAccess.devices.editMultipleDevices}
        </StyledTitleSideBar>
        <DeviceMethodsSideBar
          reloadTable={reloadTable}
          openRightSideBarForMethod={openRightSideBarForMethod}
          deviceIds={selectedDevice.map((item: any) => item.deviceId)}
          openRightSideBar={openRightSideBar}
          setOpenRightSideBar={setOpenRightSideBar}
          deviceLocationView={deviceLocationView}
          clearSingleDevice={() => clearSingleDevice()}
          idDevice={selectedDevice.map((item: any) => item.id)}
        />
      </StyledContainerItems>
    </div>
  );
};
