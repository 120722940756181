import { Avatar, Button, Divider, Typography } from '@mui/material';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import React, { useEffect, useState } from 'react';
import {
  CardSubtitle,
  MyActivityTargetName,
  ViewNoteButton
} from '../dashboardStyles';
import Strings from '../../../i18n';
import { severityEnum } from '../../../constants/commonEnums';
import { useGetNotesMutation } from '../../../services/notes/notesService';
import { INoteItemList } from '../../../types/notes/notesInterface';
import { ViewAndEditNote } from '../../commons/notes/viewAndEditNote/viewAndEditNote';

export const MyActivityComponent = () => {
  const [myActivityData, setMyActivityData] = useState<INoteItemList[]>([]);
  const [selectedNote, setSelectedNote] = useState<INoteItemList | null>(null);
  const [visible, setVisible] = React.useState(false);
  const [getNotes, { data: notesData, isSuccess: isSuccessNotes }] =
    useGetNotesMutation();
  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    if (isSuccessNotes && notesData) {
      setMyActivityData(notesData);
    }
  }, [isSuccessNotes, notesData]);

  const getGlobalSeverityIgnoringLow = () => {
    const highSeverityCount = myActivityData.filter(
      (item) => item.severityLevel === severityEnum.high
    ).length;
    const mediumSeverityCount = myActivityData.filter(
      (item) => item.severityLevel === severityEnum.medium
    ).length;
    if (highSeverityCount + mediumSeverityCount >= 2) {
      return severityEnum.high;
    }
    return severityEnum.low;
  };

  const myActivityTemplate = (item: INoteItemList) => {
    return (
      <div className="col-12 mt-3">
        <div className="d-flex justify-content-between align-items-center overflow-auto">
          <div className="d-flex align-items-center">
            <Avatar sx={{ width: 40, height: 40, marginRight: '1rem' }}>
              {item.targetName[0].toUpperCase()}
            </Avatar>
            <div className="flex-1">
              <h5 className="mb-1">
                {item.text.replace(/<\/?[^>]+(>|$)/g, '').length > 15
                  ? `${item.text
                      .replace(/<\/?[^>]+(>|$)/g, '')
                      .substring(0, 15)}...`
                  : item.text.replace(/<\/?[^>]+(>|$)/g, '')}
              </h5>
              <MyActivityTargetName className="mt-0 mb-1 text-sm text-gray-600">
                {item.targetName} ({item.type})
              </MyActivityTargetName>
            </div>
          </div>
          <div>
            <ViewNoteButton
              label={Strings.notes.viewNote}
              className="p-button-sm p-button-success"
              sev={item.severityLevel}
              onClick={() => {
                setSelectedNote(item);
                setVisible(true);
              }}
            />
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <Card
      title={
        <>
          <ViewAndEditNote
            note={selectedNote}
            onDelete={() => {}}
            onCancel={() => {
              setSelectedNote(null);
              setVisible(false);
            }}
            open={visible}
            setOpen={setVisible}
            reloadTable={() => {
              getNotes();
            }}
          />
          <Typography
            variant="h6"
            marginBottom={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {Strings.dashboard.myActivity}
            <CardSubtitle
              className="text-sm text-gray-500 font-bold ml-2"
              severity={getGlobalSeverityIgnoringLow()}
            >
              {Strings.dashboard.followUp}: {myActivityData.length}
            </CardSubtitle>
          </Typography>
          <Divider />
        </>
      }
    >
      <DataView value={myActivityData} itemTemplate={myActivityTemplate} />
    </Card>
  );
};
