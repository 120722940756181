import { configureStore } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { apiAuth } from '../services/auth/authServices';
import { apiLocations } from '../services/locations/locationsService';
import auth from './slices/login/login';
import app from './slices/app/app';
import location from './slices/locations/location';
import businessAccount from './slices/businessAccount/businessAccount';
import organization from './slices/organization/organization';
import systemPermissions from './slices/systemPermissions/systemPermissions';
import folders from './slices/folders/folders';

const persistConfig = {
  key: 'root',
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, auth);
export const store = configureStore({
  reducer: {
    [apiLocations.reducerPath]: apiLocations.reducer,
    [apiAuth.reducerPath]: apiAuth.reducer,
    persistedReducer,
    app,
    location,
    systemPermissions,
    folders,
    businessAccount,
    organization
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiLocations.middleware, apiAuth.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
