import React from 'react';
import { SaveAlt } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IFirmwareItemData } from '../../types/firmwareVersions/firmwareVersionsInterface';

interface VersionsTemplateProps {
  firmwareVersions: IFirmwareItemData[];
  selectedVersions: IFirmwareItemData[];
  setSelectedVersions: (selectedVersions: IFirmwareItemData[]) => void;
}
export const VersionsTemplate = ({
  firmwareVersions,
  selectedVersions,
  setSelectedVersions
}: VersionsTemplateProps) => {
  const getName = (rowData: IFirmwareItemData) => {
    return `${rowData.name} ${
      rowData.minWifiModuleVersionName
        ? `(${rowData.minWifiModuleVersionName})`
        : ''
    }`;
  };

  const getStatus = (rowData: IFirmwareItemData) => {
    return rowData.active ? 'Active' : 'Inactive';
  };

  const downloadCustomUrlIfExist = (rowData: IFirmwareItemData) => {
    return rowData.customUrl || rowData.fileUrl ? (
      <IconButton
        aria-label="save"
        href={
          rowData.customUrl ? rowData.customUrl ?? '' : rowData.fileUrl ?? ''
        }
        target="_blank"
        size="small"
      >
        <SaveAlt />
      </IconButton>
    ) : (
      <span className="font-italic fw-lighter">No information</span>
    );
  };

  const getCreatedAt = (rowData: IFirmwareItemData) => {
    const utcDate = moment.utc(rowData.createdAt);
    const browserTime = utcDate.local();
    return browserTime.format('YYYY/MM/DD, h:mmA');
  };

  return (
    <Box>
      <DataTable
        selection={selectedVersions}
        selectionMode="checkbox"
        onSelectionChange={(e) => {
          setSelectedVersions(e.value);
        }}
        value={firmwareVersions}
        size="small"
        scrollable
        paginator={firmwareVersions && firmwareVersions.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[
          5,
          10,
          25,
          firmwareVersions ? firmwareVersions.length : 50
        ]}
        tableStyle={{ fontSize: '14px' }}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="name" header="Name" sortable body={getName} />
        <Column
          field="createdAt"
          header="Created At"
          sortable
          body={getCreatedAt}
        />
        <Column field="createdByUser.email" header="Created by" sortable />
        <Column
          field="isProduction"
          header="Environment"
          sortable
          body={(rowData: IFirmwareItemData) =>
            rowData.isProduction ? 'Production' : 'Development'
          }
        />
        <Column field="active" header="Status" sortable body={getStatus} />
        <Column
          className="text-center"
          field="customUrl"
          header="WiFi Bin Url"
          sortable
          body={downloadCustomUrlIfExist}
        />
      </DataTable>
    </Box>
  );
};
