import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { IAddScene, IScene } from '../../types/Scenes/scenesTypes';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiScene = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getSceneListByLocation: builder.mutation<IScene[], number>({
      query: (locationId) => ({
        url: `/api/Scene/List/${locationId}`,
        method: 'GET'
      })
    }),
    getSceneDetails: builder.query<{}, number>({
      query: (sceneId) => ({
        url: `/api/Scene/${sceneId}`,
        method: 'GET'
      })
    }),
    addScene: builder.mutation<[], IAddScene>({
      query: (scene) => ({
        url: '/api/Scene',
        method: 'POST',
        body: scene
      })
    }),
    editScene: builder.mutation<[], {}>({
      query: (scene) => ({
        url: '/api/Scene',
        method: 'PUT',
        body: scene
      })
    }),
    deleteScene: builder.mutation<[], number>({
      query: (sceneId) => ({
        url: `/api/Scene/${sceneId}`,
        method: 'DELETE'
      })
    }),
    executeScene: builder.mutation<[], number>({
      query: (sceneId) => ({
        url: `/api/Commands/Scene/Execute`,
        method: 'POST',
        body: {
          sceneId,
          duration: null,
          brightness: null
        }
      })
    })
  })
});

export const {
  useGetSceneListByLocationMutation,
  useAddSceneMutation,
  useDeleteSceneMutation,
  useEditSceneMutation,
  useGetSceneDetailsQuery,
  useExecuteSceneMutation
} = apiScene;
