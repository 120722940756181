/* eslint-disable no-shadow */
export enum FirmwareStatusEnum {
  staticMethodSent = 'Static method sent',
  staticMethodAck = 'Static method ack',
  firmwareUpdateRequested = 'Firmware update requested',
  firmwareUpdateRequestFailed = 'Firmware Update request failed',
  failed = 'Failed',
  deviceOfflineFirmwareUpdate = 'Device offline firmware update',
  firmwareUpdateSuccess = 'Firmware update success',
  firmwareUpdateCancelledByUser = 'Firmware update cancelled by user',
  firmwareUpdateCancelledBySystem = 'Firmware update cancelled by system',
  unknown = 'Unknown',
  created = 'Created'
}
