import { Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const ButtonRow = styled(Button)`
  && {
    text-transform: none;
    color: ${colors.black};
    text-decoration: underline;
  }
`;

export const StyledInputLabel = styled.p`
  font-size: 15px;
  color: ${colors.black};
  margin: 10px 0 5px 0;
`;
