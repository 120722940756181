import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BusinessAccount } from '../../../types/businessAccount/businessAccount';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { SideBarSimpleMethodWithValue } from '../../../components/commons/SideBarComponents/MethodWithValueItem/sideBarMethodWithValueItem';
import { SideBarReadOnly } from '../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { setBusinessAccount } from '../../../store/slices/businessAccount/businessAccount';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import {
  useAddBusinessAccountUserMutation,
  useDeleteBusinessAccountMutation,
  useDeleteBusinessAccountUserMutation
} from '../../../services/device/deviceService';
import { useGetBusinessAccountDetailsMutation } from '../../../services/businessAccount/businessAccountService';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';

interface BusinessAccountDetailsProps {
  businessSelected: BusinessAccount;
  isOwner: boolean;
  closeSideBar: () => void;
  reloadTable: () => void;
  confirmCreateUser: () => void;
  closeWithoutClear: () => void;
}
export const BusinessAccountDetails = ({
  businessSelected,
  isOwner,
  closeSideBar,
  reloadTable,
  confirmCreateUser,
  closeWithoutClear
}: BusinessAccountDetailsProps) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [emailUser, setEmailUser] = React.useState<string>('');

  const [getBusinessAccountDetails, { data: detailsBA }] =
    useGetBusinessAccountDetailsMutation();

  const [
    addUserToBusinessAccount,
    { isSuccess: isSuccessAddUser, isError: isErrorAddUser }
  ] = useAddBusinessAccountUserMutation();

  const [deleteBusinessAccount, { isSuccess }] =
    useDeleteBusinessAccountMutation();

  const [deleteUser, { isSuccess: isSuccessDeleteUser }] =
    useDeleteBusinessAccountUserMutation();

  const goToUsersBusiness = () => {
    dispatch(setBusinessAccount(businessSelected.name));
    navigate(`/business-account/${businessSelected.id}/users`);
  };

  useEffect(() => {
    if (isSuccess || isSuccessDeleteUser) {
      toast.success(
        isSuccess
          ? Strings.businessAccount.successDeleteAccount
          : Strings.businessAccount.successLeavedAccount
      );
      reloadTable();
      closeSideBar();
    }
  }, [isSuccess || isSuccessDeleteUser]);

  useEffect(() => {
    if (isSuccessAddUser) {
      reloadTable();
      closeSideBar();
      toast.success(Strings.businessAccount.successAddedUser);
    }
  }, [isSuccessAddUser]);

  useEffect(() => {
    getBusinessAccountDetails(businessSelected.id);
  }, []);

  useEffect(() => {
    if (isErrorAddUser) {
      closeWithoutClear();
      confirmCreateUser();
    }
  }, [isErrorAddUser]);
  const goToEditBusinessAccount = () => {
    dispatch(setBusinessAccount(businessSelected.name));
    navigate(`/business-account/edit/${businessSelected.id}`);
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{businessSelected?.name}</StyledTitleSideBar>
        <SideBarReadOnly
          readOnlyItems={[
            {
              name: Strings.businessAccount.owner,
              value: detailsBA?.ownerEmail,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.address,
              value: detailsBA?.address,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.businessAccountState,
              value: detailsBA?.state,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.businessAccountZipCode,
              value: detailsBA?.zipCode,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.phone,
              value: detailsBA?.phoneNumber,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.primaryContactEmail,
              value: detailsBA?.primaryContactUserEmail,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            },
            {
              name: Strings.businessAccount.totalMembers,
              value: businessSelected?.numMembers.toString(),
              canEdit: isOwner,
              linkToEdit: `/business-account/${businessSelected?.id}/users`
            },
            {
              name: Strings.common.specialInstructions,
              value: detailsBA?.specialInstructions,
              canEdit: isOwner,
              linkToEdit: `/business-account/edit/${businessSelected?.id}`
            }
          ]}
        />
        {isOwner && (
          <SideBarSimpleMethodWithValue
            label={Strings.businessAccount.addMember}
            type="inputText"
            currentValue={emailUser}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setEmailUser(e.target.value.toString())}
            action={() => {
              addUserToBusinessAccount({
                businessAccountId: businessSelected.id,
                email: emailUser
              });
            }}
            successAction={false}
            textSuccess="textSuccess"
            buttonLabel="Invite member"
          />
        )}
        {isOwner && (
          <>
            <SideBarSimpleMethodItem
              name={Strings.businessAccount.viewMembers}
              action={() => goToUsersBusiness()}
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.businessAccount.editBusinessAccount}
              action={() => goToEditBusinessAccount()}
              successAction={false}
              textSuccess="success"
            />
          </>
        )}
        {isOwner ? (
          <SideBarDeleteActionWithPopup
            onDelete={() =>
              deleteBusinessAccount(businessSelected.id.toString())
            }
            titleConfirmation={Strings.businessAccount.deleteBusiness}
            textConfirmation={Strings.common.thisActionIsIrreversible}
            textIcon={Strings.businessAccount.deleteBusinessAccount}
          />
        ) : (
          <SideBarDeleteActionWithPopup
            onDelete={() =>
              deleteUser(
                businessSelected.businessAccountUserId?.toString() || ''
              )
            }
            titleConfirmation={Strings.businessAccount.leaveBusiness}
            textConfirmation={Strings.common.thisActionIsIrreversible}
            textIcon={Strings.businessAccount.leave}
          />
        )}
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            closeSideBar();
          }}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
