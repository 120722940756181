import { Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentCopy } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { StyledContainerReadOnly } from '../sideBarComponentsStyles';
import { MaxCharactersAndReadMoreButton } from '../../MaxCharactersAndAddMoreButton/maxCharactersAddReadMoreButton';
import Strings from '../../../../i18n';
import { colors } from '../../../../constants/colors';

interface SideBarReadOnlyProps {
  readOnlyItems: {
    name: string;
    value: string | null | undefined;
    element?: any;
    canEdit?: boolean;
    linkToEdit?: string;
  }[];
}
export const SideBarReadOnly = ({ readOnlyItems }: SideBarReadOnlyProps) => {
  const navigate = useNavigate();
  const redirectToEdit = (redirect: string | undefined) => {
    if (!redirect) return;
    navigate(redirect);
  };
  return (
    <StyledContainerReadOnly container>
      {readOnlyItems.map((item) => {
        return (
          <Grid
            item
            key={item.name}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            alignContent="center"
            marginY={1}
            lineHeight={1}
          >
            <button
              type="button"
              className="label"
              onClick={
                item.canEdit ? () => redirectToEdit(item.linkToEdit) : undefined
              }
              style={{
                cursor: item.canEdit ? 'pointer' : 'default',
                borderBottom: item.canEdit ? '1px solid #f0f0f0' : 'none'
              }}
            >
              {item.name}
            </button>
            <span className="value">
              {item.element}

              <MaxCharactersAndReadMoreButton
                value={(item.value as string) || ''}
                maxCharacters={30}
              />
              <Tooltip title={Strings.common.copyText} placement="top" arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(item.value || '');
                    toast.success(Strings.common.copiedText);
                  }}
                >
                  <ContentCopy fontSize="small" htmlColor={colors.mediumGray} />
                </IconButton>
              </Tooltip>
            </span>
          </Grid>
        );
      })}
    </StyledContainerReadOnly>
  );
};
