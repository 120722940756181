/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Strings from '../../../../i18n';
import { StyledPhoneNumberContainer } from '../sideBarComponentsStyles';

interface SideBarPhoneNumberProps {
  value: any;
  handleChange: (value: any) => void;
  handleBlur?: () => void;
  id?: string;
  defaultCountry?: string;
}

export const SideBarPhoneNumber = ({
  value,
  handleChange,
  handleBlur,
  id,
  defaultCountry
}: SideBarPhoneNumberProps) => {
  return (
    <StyledPhoneNumberContainer>
      <p className="label">{Strings.common.phoneNumber}</p>
      <PhoneInput
        defaultCountry="US"
        value={value}
        onChange={(e) => handleChange(e)}
        id={id}
        onBlur={handleBlur ? () => handleBlur() : undefined}
      />
    </StyledPhoneNumberContainer>
  );
};

SideBarPhoneNumber.defaultProps = {
  defaultCountry: 'US',
  id: 'phoneNumber',
  handleBlur: () => {}
};
