import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Strings from '../../i18n';
import { BusinessAccountTemplate } from '../../templates/businessAccount/businessAccountTemplate';
import { colors } from '../../constants/colors';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { ButtonIconStyled, TitleStyled } from './bussinessAccountStyles';
import { Header } from '../../components/header/header';
import { useGetBusinessAccountByUserMutation } from '../../services/device/deviceService';
import { selectCurrentUser } from '../../store/slices/login/login';
import {
  BusinessAccount,
  EditBusinessAccount
} from '../../types/businessAccount/businessAccount';
import { BusinessAccountDetails } from '../../templates/businessAccount/businessAccountDetails/businessAccountDetails';
import { CreateUserBusinessAccountBar } from '../../templates/businessAccount/CreateUserBusinessAccountBar/createUserBusinessAccountBar';

export const BusinessAccountView = () => {
  const navigate = useNavigate();
  const [getBusinessAccount, { data }] = useGetBusinessAccountByUserMutation();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [businessAccount, setBusinessAccount] = useState<EditBusinessAccount>({
    phoneNumber: '',
    name: '',
    latitude: 0,
    longitude: 0,
    address: '',
    id: 0,
    ownerId: 0
  });
  const [selectedBusinessAccount, setSelectedBusinessAccount] = useState<
    BusinessAccount[]
  >([]);
  const contextUser = useSelector(selectCurrentUser);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addBusinessAccount
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const reloadTable = () => {
    getBusinessAccount(contextUser.userId?.toString() || '');
    setSelectedBusinessAccount([]);
  };

  const closeSideBar = () => {
    setOpenSideBar(false);
    setSelectedBusinessAccount([]);
  };

  const closeWithoutClear = () => {
    setOpenSideBar(false);
  };

  useEffect(() => {
    if (!openSideBar) {
      setBusinessAccount({
        phoneNumber: '',
        name: '',
        latitude: 0,
        longitude: 0,
        address: '',
        id: 0,
        ownerId: 0
      });
    }
  }, [!openSideBar]);

  useEffect(() => {
    if (selectedBusinessAccount.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.businessAccountDetails);
    } else if (selectedBusinessAccount.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.multipleLocationsDetails);
      setOpenSideBar(false);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedBusinessAccount]);
  useEffect(() => {
    getBusinessAccount(contextUser.userId?.toString() || '');
  }, []);

  const redirectToAddBusiness = () => {
    navigate('/business-account/add');
  };

  const accept = () => {
    openRightSideBarForMethod(viewRightSideBarEnum.createUserBusinessAccount);
    setOpenSideBar(true);
  };

  const reject = () => {
    closeSideBar();
  };

  const confirmCreate = () => {
    confirmDialog({
      message: Strings.businessAccount.createUser,
      header: Strings.businessAccount.userNotExist,
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept,
      reject
    });
  };

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.businessAccountDetails:
        return (
          <BusinessAccountDetails
            businessSelected={selectedBusinessAccount[0]}
            closeSideBar={() => closeSideBar()}
            closeWithoutClear={() => closeWithoutClear()}
            isOwner={selectedBusinessAccount[0]?.owner === contextUser.email}
            reloadTable={() => reloadTable()}
            confirmCreateUser={confirmCreate}
          />
        );
      case viewRightSideBarEnum.createUserBusinessAccount:
        return (
          <CreateUserBusinessAccountBar
            reloadTable={() => reloadTable()}
            closeSideBar={() => closeSideBar()}
            id={selectedBusinessAccount[0]?.id || 0}
          />
        );
      default:
        return <p />;
    }
  };
  return (
    <>
      <ConfirmDialog />
      <div>
        <Header>
          <TitleStyled variant="subtitle1" color={colors.strongGray}>
            {Strings.businessAccount.businessAccount}
          </TitleStyled>
          <ButtonIconStyled
            size="small"
            onClick={() => {
              redirectToAddBusiness();
            }}
          >
            <AddCircleOutlineIcon />
          </ButtonIconStyled>
        </Header>
        <BusinessAccountTemplate
          selectedBusinessAccount={selectedBusinessAccount}
          setSelectedBusinessAccount={setSelectedBusinessAccount}
          reloadTable={() =>
            getBusinessAccount(contextUser.userId?.toString() || '')
          }
          openAddBusiness={() => redirectToAddBusiness()}
          data={data || []}
        />
      </div>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          closeSideBar();
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
