import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  Location,
  LocationByUser,
  LocationDetails
} from '../../../types/location/location';
import { viewTypeEnum } from '../../../constants/commonEnums';
import { OrganizationByUSer } from '../../../types/organizations/organizations';

const sliceLocation = createSlice({
  name: 'location',
  initialState: {
    viewItems: viewTypeEnum.listView,
    locationsByUser: [],
    locationsAndOrganizationByUser: [],
    allLocations: [],
    currentLocation: { locationId: null, locationName: null },
    userLastLocations: []
  } as {
    viewItems: string;
    locationsByUser: LocationByUser[];
    locationsAndOrganizationByUser: (LocationByUser | OrganizationByUSer)[];
    locationDetails?: LocationDetails;
    allLocations: Location[];
    currentLocation: {
      locationId: number | null;
      locationName: string | null;
    } | null;
    userLastLocations: { locationId: number; locationName: string }[] | null;
  },
  reducers: {
    setLocationsViewType: (
      state,
      {
        payload: { viewItems }
      }: PayloadAction<{
        viewItems: viewTypeEnum;
      }>
    ) => {
      state.viewItems = viewItems;
    },
    setLocationsByUser(state, action: PayloadAction<LocationByUser[]>) {
      state.locationsByUser = action.payload;
    },
    setLocationsDetails(state, action: PayloadAction<LocationDetails>) {
      state.locationDetails = action.payload;
    },
    setLocationsAndOrganizationsByUser(
      state,
      action: PayloadAction<(LocationByUser | OrganizationByUSer)[]>
    ) {
      state.locationsAndOrganizationByUser = action.payload;
    },
    setAllLocations(state, action: PayloadAction<Location[]>) {
      state.allLocations = action.payload;
    },
    setCurrentLocation(
      state,
      action: PayloadAction<{
        locationId: number;
        locationName: string;
      } | null>
    ) {
      state.currentLocation = action.payload;
    },
    setUserLastLocations(
      state,
      action: PayloadAction<
        | {
            locationId: number;
            locationName: string;
          }[]
        | null
      >
    ) {
      state.userLastLocations = action.payload;
    }
  }
});
export default sliceLocation.reducer;
export const selectedCurrentViewType = (state: RootState) =>
  state.location.viewItems;
export const selectedLocationsByUser = (state: RootState) =>
  state.location.locationsByUser;
export const selectedLocationsAndOrganizationsByUser = (state: RootState) =>
  state.location.locationsAndOrganizationByUser;
export const selectedAllLocations = (state: RootState) =>
  state.location.allLocations;
export const currentLocation = (state: RootState) =>
  state.location.currentLocation;
export const userLastLocations = (state: RootState) =>
  state.location.userLastLocations;
export const locationDetails = (state: RootState) =>
  state.location.locationDetails;
export const {
  setLocationsViewType,
  setLocationsByUser,
  setCurrentLocation,
  setAllLocations,
  setLocationsAndOrganizationsByUser,
  setUserLastLocations,
  setLocationsDetails
} = sliceLocation.actions;
