import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';

import { Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { ErrorText } from '../../../components/layout/main/mainStyles';
import { SendStaticOrSingletonProps } from './sendStaticOrSingleMethodProps';
import { sendStaticOrSingletonValidator } from '../../../utils/validators';
import Strings from '../../../i18n';
import {
  usePostStaticMethodMutation,
  usePostSingletonMethodMutation
} from '../../../services/device/deviceService';
import { IPostSingletonStaticMethod } from '../../../types/Device/DeviceInterfaces';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';

export const SendStaticOrSingletonMethod = ({
  method = viewRightSideBarEnum.singletonDevice,
  deviceIds,
  setOpenRightSideBar,
  clearSelectedDevices
}: SendStaticOrSingletonProps) => {
  const [checked, setChecked] = useState(true);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [
    staticMethod,
    { isSuccess: isSuccessStatic, data: dataStatic, isLoading: isLoadingStatic }
  ] = usePostStaticMethodMutation();

  const [
    singletonMethod,
    {
      isSuccess: isSuccessSingleton,
      data: dataSingleton,
      isLoading: isLoadingSingleton
    }
  ] = usePostSingletonMethodMutation();

  const sendInfo = (data: { methodName: string; methodValue: string }) => {
    if (method === viewRightSideBarEnum.singletonDevice) {
      const payload = deviceIds.map((id) => ({
        ...data,
        deviceId: id,
        ackRequired: checked
      }));
      singletonMethod(payload);
    }
    if (method === viewRightSideBarEnum.staticDevice) {
      const payload: IPostSingletonStaticMethod[] = deviceIds.map((id) => ({
        ...data,
        deviceId: id
      }));
      staticMethod(payload);
    }
  };

  const closeBar = () => {
    setOpenRightSideBar(false);
    clearSelectedDevices();
  };

  useEffect(() => {
    if (isSuccessSingleton) {
      toast.success(Strings.devices.singletonSentSuccess);
      closeBar();
    }
    if (isSuccessStatic) {
      toast.success(Strings.devices.staticSentSuccess);
      closeBar();
    }
  }, [isSuccessSingleton, isSuccessStatic]);

  return (
    <Formik
      enableReinitialize
      validationSchema={sendStaticOrSingletonValidator}
      initialValues={{
        methodName: '',
        methodValue: ''
      }}
      onSubmit={(values) => {
        sendInfo({
          methodName: '',
          methodValue: values.methodValue
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        setFieldTouched,
        errors,
        touched
      }) => (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>
              {method === viewRightSideBarEnum.singletonDevice
                ? Strings.serverAccess.devices.singletonMethodLabel
                : Strings.serverAccess.devices.staticMethodLabel}
            </StyledTitleSideBar>

            <SideBarEditablePropertyItem
              item={{
                label: Strings.serverAccess.devices.methodValueLabel,
                type: 'inputText',
                currentValue: values.methodValue,
                nameAndId: 'methodValue',
                onChange: handleChange('methodValue'),
                onBlur: () => setFieldTouched('methodValue')
              }}
            />
            {touched.methodValue && errors.methodValue && (
              <ErrorText>{errors.methodValue}</ErrorText>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    checked={checked}
                    onChange={handleChangeCheckbox}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={Strings.serverAccess.devices.ackRequired}
              />
            </Grid>
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                setOpenRightSideBar(false);
                clearSelectedDevices();
              }}
            >
              {Strings.common.cancel}
            </button>
            <LoadingButton
              color="info"
              disabled={!isValid}
              onClick={() => handleSubmit()}
              variant="contained"
              size="large"
              loading={isLoadingSingleton || isLoadingStatic}
            >
              {Strings.common.send}
            </LoadingButton>
          </StyledContainerFooterSideBar>
        </>
      )}
    </Formik>
  );
};
