import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import './onColorEventStyles.css';
import { BRIGHTNESS_VALUES } from '../../../../../constants/commonEnums';
import { ColorSelectionModal } from '../../../../../components/commons/ColorSelectorModal/colorSelectorModal';

const imgColor = require('../../../../../assets/images/eventsType/lightsZonesOn.png');
const imgBrightness = require('../../../../../assets/images/eventsType/brightness.png');

interface OnColorEventProps {
  emitSelectionColor: ({
    brightness,
    colorId
  }: {
    brightness: number;
    colorId: number;
  }) => void;
  status: {
    colorId: number;
    brightness: number;
  };
}
export const OnColorEvent = ({
  emitSelectionColor,
  status
}: OnColorEventProps) => {
  const [selectedColor, setSelectedColor] = useState<any>();
  const [selectedBrightness, setSelectedBrightness] = useState(
    status.brightness ?? 5
  );
  const [displayModalColorSelect, setDisplayModalColorSelect] = useState(false);
  const [showBrightnessSelectorDialog, setShowBrightnessSelectorDialog] =
    useState(false);

  const openDialogSelectColor = () => {
    setDisplayModalColorSelect(true);
  };

  const closeColorSelector = () => {
    setDisplayModalColorSelect(false);
  };

  const selectBrightness = () => {
    setShowBrightnessSelectorDialog(true);
  };

  const setNewBrightnessCommandDialog = (item: any) => {
    setSelectedBrightness(item);
    setShowBrightnessSelectorDialog(false);
  };

  useEffect(() => {
    if (selectedColor || selectedBrightness) {
      emitSelectionColor({
        brightness: selectedBrightness,
        colorId: selectedColor?.id ?? 1
      });
    }
  }, [selectedColor, selectedBrightness]);
  return (
    <div className="row pt-2">
      <div className="col-12">
        <div className="container-type">
          <div className="img-container">
            <img src={imgColor} alt="LZ-OFF" />
            <span style={{ padding: '0 11px' }}>Lights / Zones</span>
          </div>
          <div className="container-command">
            {selectedColor ? (
              <div className="color-container">
                <span>{selectedColor.name}</span>
                <div className="line-brightness">
                  <div
                    className="bright-select"
                    style={{
                      width: '100%',
                      backgroundColor: selectedColor.color,
                      backgroundImage: selectedColor.icon
                        ? `url(${selectedColor.icon})`
                        : ''
                    }}
                  />
                </div>
                <Button
                  icon="pi pi-ellipsis-h"
                  onClick={openDialogSelectColor}
                />
              </div>
            ) : (
              <div className="default-select">
                <span>Please, select a color</span>
                <Button
                  icon="pi pi-ellipsis-h"
                  onClick={openDialogSelectColor}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 pt-3">
        <div className="container-type">
          <div className="img-container">
            <img src={imgBrightness} alt="LZ-OFF" height="40" />
            <span>Brightness Control</span>
          </div>
          <div className="container-command">
            <div className="brightness-container">
              <span>{selectedBrightness}0%</span>
              <div className="line-brightness">
                <div
                  className="bright-select"
                  style={{ width: `${selectedBrightness}0%` }}
                />
              </div>
            </div>
            <Button icon="pi pi-ellipsis-h" onClick={selectBrightness} />
          </div>
        </div>
      </div>
      <Dialog
        header="BRIGHTNESS"
        visible={showBrightnessSelectorDialog}
        modal
        style={{ width: '50vw' }}
        draggable={false}
        resizable={false}
        onHide={() => setShowBrightnessSelectorDialog(false)}
      >
        {BRIGHTNESS_VALUES.map((item) => (
          <button
            type="button"
            key={item}
            className={`btn-select-brightness ${
              selectedBrightness === item ? 'current-bright' : ''
            }`}
            onClick={() => setNewBrightnessCommandDialog(item)}
          >
            {item}0%
          </button>
        ))}
      </Dialog>
      <ColorSelectionModal
        visible={displayModalColorSelect}
        onColorChange={setSelectedColor}
        capabilities={[{ name: 'EXTENDED_SOLID_COLORS' }]}
        setVisible={closeColorSelector}
        status={{ colorId: selectedColor?.id }}
        emitSelectionColor={() => {}}
      />
    </div>
  );
};
