import React from 'react';
import { Box, Typography } from '@mui/material';
import { ItemLocationSelectedProps } from './itemLocationSelectedProps';
import { Container } from './itemLocationSelectedStyles';
import { colors } from '../../constants/colors';

export const ItemLocationSelected = ({
  ownerName,
  locationName,
  action,
  selected
}: ItemLocationSelectedProps) => {
  return (
    <Container
      onClick={action}
      padding={1}
      bgcolor={selected ? colors.mediumGray : colors.tooltipBG}
      marginTop={1}
      marginBottom={1}
      borderRadius={2}
    >
      <Typography fontSize={13} color={colors.brown} fontWeight={500}>
        {locationName}
      </Typography>
      <Typography color={colors.brown} fontSize={13} fontWeight={500}>
        {ownerName}
      </Typography>
    </Container>
  );
};
