import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Rating } from 'primereact/rating';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Button } from 'primereact/button';
import Strings from '../../../../i18n';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { IEffectItemStore } from '../../../../types/effects/effectsStoreInterface';
import { SideBarSimpleMethodWithValue } from '../../../../components/commons/SideBarComponents/MethodWithValueItem/sideBarMethodWithValueItem';
import {
  useDeleteEffectMutation,
  useEffectRatingMutation,
  useGetEffectRatingsMutation,
  usePutChangeVideoEffectMutation,
  useUpdateEffectMutation
} from '../../../../services/effects/effectsStoreService';
import {
  StyledEffectVote,
  StyledRateContainer
} from '../effectStoreItem/effectStoreItemStyles';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';

interface SingleDetailsEffectStoreProps {
  effect: IEffectItemStore;
  closeSideBar: () => void;
  reloadTable: () => void;
  setOpenShareDialog: (value: boolean) => void;
  setOpenReportDialog: (value: boolean) => void;
  openSideBarFor: (value: viewRightSideBarEnum) => void;
}
export const SingleDetailsEffectStore = ({
  effect,
  closeSideBar,
  reloadTable,
  setOpenShareDialog,
  setOpenReportDialog,
  openSideBarFor
}: SingleDetailsEffectStoreProps) => {
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [isVoting, setIsVoting] = useState<boolean>(false);
  const [newRating, setNewRating] = useState<number>(
    effect?.ratingFromUser ?? 0
  );
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

  const [rateEffect, { isSuccess: successRateEffect }] =
    useEffectRatingMutation();

  const [deleteEffects, { isSuccess: successDelete }] =
    useDeleteEffectMutation();

  const [updateEffect, { isSuccess: successUpdate }] =
    useUpdateEffectMutation();

  const [updateVideoEffect, { isSuccess: successUpdateVideo }] =
    usePutChangeVideoEffectMutation();

  const deleteEffect = () => {
    deleteEffects([effect.id]);
  };

  const shareEffect = () => {
    setOpenShareDialog(true);
    closeSideBar();
  };
  const reportEffect = () => {
    setOpenReportDialog(true);
    closeSideBar();
  };

  const handleRatingChange = (e: { value: number }) => {
    setNewRating(e.value);
    setConfirmDialog(true);
  };

  const handleConfirmRating = () => {
    rateEffect({ effectId: effect.id, rating: newRating });
    setIsVoting(false);
    setConfirmDialog(false);
  };

  const handleCancelRating = () => {
    setIsVoting(false);
    setConfirmDialog(false);
  };

  const [name, setName] = React.useState<string>(effect?.name ?? '');

  const updateEffectName = () => {
    updateEffect({ effectId: effect.id, name, tags: effect.tags });
  };

  const handleVoteClick = () => {
    setIsVoting(true);
  };

  const changeFileHandler = (event: any) => {
    setFileUpload(event.target.files[0]);
  };

  const changeVideo = () => {
    const formData = new FormData();
    formData.append('file', fileUpload);
    updateVideoEffect({ effectId: effect.id, video: formData });
  };

  useEffect(() => {
    if (successDelete) {
      reloadTable();
      closeSideBar();
      toast.success(Strings.effects.deleteEffectSuccess);
    }
  }, [successDelete]);

  useEffect(() => {
    if (successUpdate) {
      reloadTable();
      closeSideBar();
      toast.success(Strings.effects.updateEffectSuccess);
    }
  }, [successUpdate]);

  useEffect(() => {
    if (successRateEffect) {
      toast.success(Strings.effects.rateEffectSuccess);
      reloadTable();
    }
  }, [successRateEffect]);

  useEffect(() => {
    if (successUpdateVideo) {
      toast.success(Strings.effects.changeVideoSuccess);
      reloadTable();
    }
  }, [successUpdateVideo]);

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{effect?.name}</StyledTitleSideBar>
        <SideBarSimpleMethodWithValue
          label={Strings.common.name}
          type="inputText"
          currentValue={name}
          action={() => {
            updateEffectName();
          }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          successAction={false}
          textSuccess="success"
          buttonLabel="Change"
        />
        <SideBarSimpleMethodWithValue
          label="Change video"
          type="inputFile"
          currentValue={fileUpload}
          fileName={fileUpload?.name ?? ''}
          onChange={changeFileHandler}
          acceptedFiles="video/*"
          action={() => {
            changeVideo();
          }}
          textSuccess="success"
          buttonLabel="Change"
          successAction={false}
        />
        {isVoting ? (
          <StyledRateContainer className="d-block text-center">
            <Rating
              value={newRating || 0}
              stars={5}
              cancel={false}
              onChange={(event: any) => handleRatingChange(event)}
              className="justify-content-center"
            />
            <StyledEffectVote
              className="p-button-sm"
              onClick={handleCancelRating}
              type="button"
            >
              {Strings.common.cancel}
            </StyledEffectVote>
          </StyledRateContainer>
        ) : (
          <StyledRateContainer className="d-block text-center">
            <Rating
              value={effect?.rating}
              stars={5}
              cancel={false}
              readOnly
              className="justify-content-center"
            />
            <StyledEffectVote
              className="p-button-sm"
              onClick={handleVoteClick}
              type="button"
            >
              {Strings.effects.rate}
            </StyledEffectVote>
          </StyledRateContainer>
        )}
        <SideBarSimpleMethodItem
          name={Strings.effects.viewRates}
          action={() => {
            openSideBarFor(viewRightSideBarEnum.viewRates);
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.effects.shareEffect}
          action={() => {
            shareEffect();
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.effects.reportEffect}
          action={() => {
            reportEffect();
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteEffect()}
          titleConfirmation={Strings.effects.deleteEffect}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.effects.deleteEffect}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
      <Dialog open={confirmDialog} onClose={handleCancelRating}>
        <DialogTitle>{Strings.effects.confirmRatingTitle}</DialogTitle>
        <DialogContent>
          <p>
            {Strings.effects.willGiveRating} {newRating} {Strings.effects.stars}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmRating} color="primary">
            {Strings.common.yes}
          </Button>
          <Button onClick={handleCancelRating} color="secondary">
            {Strings.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
