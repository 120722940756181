import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { ITrimLight } from '../../types/Lights/trimLightInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiTrimLights = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getTrimLightsByLocation: builder.mutation<ITrimLight[], number>({
      query: (locationId) => ({
        url: `api/Light/TrimLight/ByLocation/${locationId}`,
        method: 'GET'
      })
    }),
    setColorTrimLight: builder.mutation<
      void,
      { lightIds: number[]; colorId: number }
    >({
      query: (data) => ({
        url: `api/Light/Trim/SetColor`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useGetTrimLightsByLocationMutation,
  useSetColorTrimLightMutation
} = apiTrimLights;
