import React, { useEffect, useState } from 'react';
import { AddCircleOutline, Cached } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { Header } from '../../components/header/header';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import Strings from '../../i18n';
import { DeviceListByLocationTemplate } from '../../templates/locations/devicesByLocation/deviceListByLocation/deviceListByLocation';
import { ButtonIconStyled } from '../devices/devicesStyles';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { DeviceByLocation } from '../../types/Device/DeviceInterfaces';
import { useRefreshStatusDevicesMutation } from '../../services/device/deviceService';

export const DeviceByLocationView = () => {
  const { locationId } = useParams();
  const [refreshDevices, setRefreshDevices] = useState(false);
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const navigate = useNavigate();
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addDevice
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };

  const [refreshStatusDevices, { isSuccess: isSuccessRefreshStatus }] =
    useRefreshStatusDevicesMutation();
  const [selectedDevices, setSelectedDevices] = useState<DeviceByLocation[]>(
    []
  );

  const refreshStatusHandler = () => {
    if (locationId) {
      refreshStatusDevices(+locationId);
    }
  };

  useEffect(() => {
    if (isSuccessRefreshStatus) {
      toast.success(Strings.serverAccess.devices.successRefreshStatus);
      setRefreshDevices(true);
    }
  }, [isSuccessRefreshStatus]);
  const [searchValue, setSearchValue] = useState('');
  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left" />
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.devices.devices}</StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                disabled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.addDeviceToLocation
                  );
                }}
              >
                <AddCircleOutline />
              </ButtonIconStyled>
              <Tooltip title={Strings.serverAccess.devices.refreshStatus}>
                <ButtonIconStyled
                  size="small"
                  onClick={() => {
                    refreshStatusHandler();
                  }}
                >
                  <Cached />
                </ButtonIconStyled>
              </Tooltip>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openRightSideBar}
                selectedItems={selectedDevices}
                singleItemMethod={() => {
                  openRightSideBarForMethod(viewRightSideBarEnum.details);
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.editMultipleDevices
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <DeviceListByLocationTemplate
        searchValue={searchValue}
        openRightSideBar={openRightSideBar}
        setOpenRightSideBar={setOpenRightSideBar}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        sideBarOpenFor={sideBarOpenFor}
        setSideBarOpenFor={setSideBarOpenFor}
        refreshDevices={refreshDevices}
        setRefreshDevices={setRefreshDevices}
      />
    </div>
  );
};
