import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { MultipleScheduleMethodsProps } from './multipleScheduleMethodsProps';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import {
  StyledContainerItems,
  StyledContainerFooterSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { useDeleteMultipleDeviceMethodsMutation } from '../../../../services/device/deviceService';

export const MultipleScheduleMethodsDetails = ({
  openRightSideBarForMethod,
  setOpenSideBar,
  selectedMethods,
  reloadTable
}: MultipleScheduleMethodsProps) => {
  const [deleteMultipleDeviceMethods, { isSuccess: isSuccessDelete }] =
    useDeleteMultipleDeviceMethodsMutation();

  const deleteMethods = () => {
    const deviceMethodsSingletonIds = selectedMethods
      .filter((method) => method.methodType === 'Singleton')
      .map((method) => method.id);
    const deviceMethodsStaticIds = selectedMethods
      .filter((method) => method.methodType === 'Static')
      .map((method) => method.id);
    deleteMultipleDeviceMethods({
      singletonIds: deviceMethodsSingletonIds,
      staticIds: deviceMethodsStaticIds
    });
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success(Strings.devices.successDeleteMethods);
      reloadTable();
    }
  }, [isSuccessDelete]);

  return (
    <div>
      <StyledContainerItems>
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteMethods()}
          titleConfirmation={Strings.devices.deleteMethodsTitle}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.devices.deleteMethods}
        />
      </StyledContainerItems>

      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => setOpenSideBar(false)}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </div>
  );
};
