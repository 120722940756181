import { ArrowBackIos } from '@mui/icons-material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Header } from '../../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../../components/layout/main/mainStyles';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../../views/devices/devicesStyles';
import { StyledContainerType } from './selectTypeEventStyles';
import { eventTypes } from '../../../../constants/eventEnums';

const offImage = require('../../../../assets/images/eventsType/lightsZonesOff.png');
const onImage = require('../../../../assets/images/eventsType/lightsZonesOn.png');
const scenesImage = require('../../../../assets/images/eventsType/scenesImg.png');

export const SelectTypeEvent = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const redirectToAddEvent = (type: number) => {
    navigate(`/locations/${locationId}/applications/schedules/add/${type}`);
  };
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.events.eventType}</StyledTitleHeader>
            </div>
            <div className="col-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        <Grid item xs={10} md={8}>
          <StyledContainerType>
            <button
              type="button"
              className="container-button w-100"
              onClick={() => redirectToAddEvent(eventTypes.OFF_EVENT)}
            >
              <div className="container-info">
                <img
                  src={offImage}
                  alt="Lights / Zones - OFF"
                  className="image-display"
                />
                <p>Lights / Zones - OFF</p>
              </div>
            </button>
          </StyledContainerType>
        </Grid>
        <Grid item xs={10} md={8}>
          <StyledContainerType>
            <button
              type="button"
              className="container-button w-100"
              onClick={() => redirectToAddEvent(eventTypes.COLOR_EVENT)}
            >
              <div className="container-info">
                <img
                  src={onImage}
                  alt={Strings.events.lightsZonesOn}
                  className="image-display"
                />
                <p>{Strings.events.lightsZonesOn}</p>
              </div>
            </button>
          </StyledContainerType>
        </Grid>
        <Grid item xs={10} md={8}>
          <StyledContainerType>
            <button
              type="button"
              className="container-button w-100"
              onClick={() => redirectToAddEvent(eventTypes.SCENE_EVENT)}
            >
              <div className="container-info">
                <img
                  src={scenesImage}
                  alt={Strings.events.scenes}
                  className="image-display"
                />
                <p>{Strings.events.scenes}</p>
              </div>
            </button>
          </StyledContainerType>
        </Grid>
      </Grid>
    </>
  );
};
