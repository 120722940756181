import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Grid } from '@mui/material';
import { colors } from '../../../../constants/colors';

export const StyledTitleLabelAddEvent = styled.p`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.blueTheme};
  text-transform: uppercase;
`;

export const ItemGridAddEvent = styled(Grid)`
  padding: 20px;
`;

export const WhenButtonSelector = styled(Button)`
  background-color: ${colors.lightGray};
  border: none;
  width: 300px;
  color: ${colors.blueTheme};
  font-weight: 400;
  text-align: left;
  padding: 10px;
  margin: 2px 0;
  text-transform: uppercase;
`;
