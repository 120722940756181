import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { StyledContainerSimpleMethod } from '../sideBarComponentsStyles';

interface SideBarSimpleMethodItemProps {
  name: string;
  action: () => void;
  successAction: boolean;
  textSuccess: string;
  loading?: boolean;
}

export const SideBarSimpleMethodItem = ({
  name,
  action,
  successAction,
  textSuccess,
  loading
}: SideBarSimpleMethodItemProps) => {
  useEffect(() => {
    if (successAction) {
      toast.success(textSuccess);
    }
  }, [successAction]);

  return (
    <StyledContainerSimpleMethod
      onClick={action}
      type="button"
      disabled={loading}
    >
      {name}
    </StyledContainerSimpleMethod>
  );
};

SideBarSimpleMethodItem.defaultProps = {
  loading: false
};
