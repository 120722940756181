import { Button, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const ButtonRow = styled(Button)`
  && {
    text-transform: none;
    color: ${colors.black};
    text-decoration: underline;
  }
`;

export const StatusProcess = styled(Box)<{ IsActive: boolean }>`
  && {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background-color: ${({ IsActive }) =>
      IsActive ? colors.green : colors.red};
  }
`;
