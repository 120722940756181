import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { StyledSwitchWithLabel } from '../sideBarComponentsStyles';

interface SideBarSwitchWithLabelProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  className?: string;
}
export const SideBarSwitchWithLabel = ({
  label,
  checked,
  onChange,
  className
}: SideBarSwitchWithLabelProps) => {
  return (
    <StyledSwitchWithLabel
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={className}
    >
      <span className="label">{label}</span>
      <InputSwitch checked={checked} onChange={(e) => onChange()} />
    </StyledSwitchWithLabel>
  );
};

SideBarSwitchWithLabel.defaultProps = {
  className: ''
};
