import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import Strings from '../../../i18n';
import { useDeleteMultipleOrganizationsMutation } from '../../../services/organizations/organizationsService';

interface MultipleOrganizationsDetailsProps {
  idOrganizations: number[];
  reloadTable: () => void;
}

export const MultipleOrganizationsDetails = ({
  idOrganizations,
  reloadTable
}: MultipleOrganizationsDetailsProps) => {
  const [deleteOrgsWithId, { isSuccess: isSuccessDelete }] =
    useDeleteMultipleOrganizationsMutation();

  useEffect(() => {
    if (isSuccessDelete) {
      reloadTable();
      toast.success(`${Strings.organizations.successDeletedOrganization}s`);
    }
  }, [isSuccessDelete]);

  const deleteOrganizations = () => {
    deleteOrgsWithId(idOrganizations);
  };
  return (
    <StyledContainerItems>
      <StyledTitleSideBar>{Strings.common.details}</StyledTitleSideBar>
      <SideBarDeleteActionWithPopup
        onDelete={() => deleteOrganizations()}
        titleConfirmation={Strings.organizations.deleteOrganizationTitle}
        textConfirmation={Strings.common.thisActionIsIrreversible}
        textIcon={`${Strings.organizations.deleteOrganization}s`}
      />
    </StyledContainerItems>
  );
};
