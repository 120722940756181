export const configAuth0 = {
  audience: 'https://development.havenlighting.com',
  domain: 'dev-f5fum6mu.us.auth0.com',
  clientId: process.env.REACT_APP_AUTH0CLIENT_ID ?? '',
  clientSecret:
    'hPC-ns9DFo2sY-Am5sCthkKRlA8wGiXFgE7COE75HdyK466nhxIB2HDsSAaEqcio',
  auth0Url: 'https://dev-f5fum6mu.us.auth0.com/',
  bdd: 'dev-users-db',
  grantType: 'http://auth0.com/oauth/grant-type/passwordless/otp',
  scope: 'email profile openid offline_access'
};

export const config = {
  baseUrlDealers:
    process.env.REACT_APP_API_URL ?? 'https://dev-api.havenlighting.com/',
  // baseUrlDealers: 'https://cf49-2806-108e-12-b134-78d8-cdc9-bfd3-717b.ngrok-free.app/',
  baseUrlResidential:
    'https://ase-hvnlght-residential-api-dev.azurewebsites.net/',
  baseUrlBlackBox: 'https://ase-cardinal-api-blackbox-dev.azurewebsites.net'
};

export const clientIdPHueDev = `TBGGn0fk6pWjkNtFaBRDsnFf8ePgt8BG`; // Dev
export const clientIdPHueProd = 'yC0CupioeWECMYa6ZCZwKPNSsgZSowQk'; // Prod
export const googleApiKey = 'AIzaSyCW0wvM-DRN7xxmRPHKjvlIU8r0HOG-MxM';

export const configPHue = {
  authorizePHue: `https://api.meethue.com/v2/oauth2/authorize?client_id=${'TBGGn0fk6pWjkNtFaBRDsnFf8ePgt8BG'}&response_type=code`
};
