import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete, Chip, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Strings from '../../../i18n';
import { useGetRolesLocationMutation } from '../../../services/device/deviceService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledEditablePropertyItemContainer,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { ConfirmModal } from '../../../components/commons/confirmModal/confirmModal';
import { IShareLocationWithTargets } from '../../../types/location/location';
import {
  ButtonStyled,
  CustomSelect,
  ButtonStyledCancel
} from '../shareLocationBar/shareLocationBarStyles';
import {
  locationRoleEnum,
  shareLocationWithTargetsEnum
} from '../../../constants/commonEnums';
import { useShareLocationWithTargetsMutation } from '../../../services/locations/locationsService';

interface ShareLocationsWithTargetsProps {
  selectedLocations: number[];
  setOpenRightSideBar: (open: boolean) => void;
  reloadTable?: () => void;
}
export const ShareLocationsWithTargets = ({
  selectedLocations,
  setOpenRightSideBar,
  reloadTable
}: ShareLocationsWithTargetsProps) => {
  const [targets, setTargets] = useState<string[]>([]);
  const [roleId, setRoleId] = useState(2);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [payload, setPayload] = useState<IShareLocationWithTargets>({
    targets: [],
    locationIds: selectedLocations,
    locationRoleId: locationRoleEnum.admin,
    shareWithDomain: false,
    shareWithBusinessAccount: false
  });
  const [openConfirmBusinessAccounts, setOpenConfirmBusinessAccounts] =
    useState(false);

  const [
    shareLocation,
    {
      isLoading: isLoadingShareLoc,
      isSuccess: isSuccessShareLoc,
      data: dataShareLocation
    }
  ] = useShareLocationWithTargetsMutation();

  const [
    getLocationRoles,
    { isSuccess: successGetLocationRoles, data: dataLocationRoles }
  ] = useGetRolesLocationMutation();

  const handleChangeRole = (event: any) => {
    setRoleId(+event.target.value);
    setPayload((prevPayload) => ({
      ...prevPayload,
      locationRoleId: +event.target.value
    }));
  };

  useEffect(() => {
    getLocationRoles('');
  }, []);

  const confirmShareDomain = () => {
    setOpenConfirm(true);
  };

  const confirmShareBusinessAccounts = () => {
    setOpenConfirmBusinessAccounts(true);
  };

  const getTargetType = (target: string) => {
    if (target.startsWith('@')) {
      return shareLocationWithTargetsEnum.domain;
    }
    if (target.startsWith('#')) {
      return shareLocationWithTargetsEnum.businessAccount;
    }
    return shareLocationWithTargetsEnum.email;
  };

  const setPayloadWithTargets = useCallback(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      targets: targets.map((target) => ({
        value: target,
        type: getTargetType(target)
      }))
    }));
  }, [targets]);

  const sendInfo = () => {
    setPayload((prevPayload) => {
      const updatedPayload = {
        ...prevPayload,
        targets: targets.map((target) => ({
          value: target,
          type: getTargetType(target)
        }))
      };
      shareLocation(updatedPayload);
      return updatedPayload;
    });
  };

  const checkIfHasBusinessAccounts = () => {
    const hasBusinessAccounts = targets.some((target) =>
      target.startsWith('#')
    );
    if (hasBusinessAccounts) {
      confirmShareBusinessAccounts();
    } else {
      sendInfo();
    }
  };

  const checkIfHasDomain = () => {
    setPayloadWithTargets();
    const hasDomain = targets.some((target) => target.startsWith('@'));
    if (hasDomain) {
      confirmShareDomain();
    } else {
      checkIfHasBusinessAccounts();
    }
  };

  useEffect(() => {
    if (isSuccessShareLoc && dataShareLocation) {
      if (!dataShareLocation.some((location) => !location.success)) {
        setOpenRightSideBar(false);
        toast.success(Strings.locations.shareLocationSuccess);
        if (reloadTable) {
          reloadTable();
        }
      } else {
        dataShareLocation.forEach((location) => {
          if (location.success) {
            toast.success(
              `${location.message} with: ${
                location.user ??
                location.businessAccount ??
                location.domain ??
                ''
              }`
            );
          } else {
            toast.error(
              `${location.message} with: ${
                location.user ??
                location.businessAccount ??
                location.domain ??
                ''
              }`
            );
          }
        });
      }
    }
  }, [isSuccessShareLoc, dataShareLocation]);

  const handleBlur = () => {
    if (inputValue.trim() !== '' && !targets.includes(inputValue)) {
      setTargets([...targets, inputValue]);
      setInputValue('');
      setPayloadWithTargets();
    }
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.locations.shareLocation}
        </StyledTitleSideBar>
        <StyledEditablePropertyItemContainer>
          <div className="label">{Strings.locations.targets}</div>
          <p className="hints">{Strings.locations.shareLocationHint}</p>
          <Autocomplete
            clearIcon={false}
            options={[]}
            freeSolo
            multiple
            value={targets}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              setTargets(newValue);
              setPayloadWithTargets();
            }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip label={option} {...props({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Press enter to add target"
                onBlur={handleBlur}
              />
            )}
          />
        </StyledEditablePropertyItemContainer>
        <StyledEditablePropertyItemContainer>
          <div className="label">{Strings.manageRoles.role}</div>
          <CustomSelect
            onChange={handleChangeRole}
            size="small"
            fullWidth
            value={roleId}
          >
            {successGetLocationRoles &&
              dataLocationRoles &&
              dataLocationRoles.map((element) => {
                return (
                  <MenuItem value={element.id} key={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            <MenuItem value={0} key={0}>
              {Strings.common.noSystemPermissions}
            </MenuItem>
          </CustomSelect>
        </StyledEditablePropertyItemContainer>
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={roleId === 0 || isLoadingShareLoc}
          onClick={checkIfHasDomain}
        >
          {Strings.common.add}
        </button>
      </StyledContainerFooterSideBar>
      <ConfirmModal
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        titleConfirmation={Strings.locations.areYouSureToShareWithDomain}
        textConfirmation={Strings.locations.confirmShareDomain}
        onConfirm={() => {
          const updatedPayload = {
            ...payload,
            shareWithDomain: true
          };
          setPayload(updatedPayload);
          setOpenConfirm(false);
          checkIfHasBusinessAccounts();
        }}
        showNoButton
        onNo={() => {
          setOpenConfirm(false);
          const updatedPayload = {
            ...payload,
            shareWithDomain: false
          };
          setPayload(updatedPayload);
          checkIfHasBusinessAccounts();
        }}
      />
      <ConfirmModal
        open={openConfirmBusinessAccounts}
        onClose={() => {
          setOpenConfirmBusinessAccounts(false);
        }}
        titleConfirmation={
          Strings.locations.areYouSureToShareWithBusinessAccount
        }
        textConfirmation={Strings.locations.confirmShareBusinessAccounts}
        onConfirm={() => {
          const updatedPayload = {
            ...payload,
            shareWithBusinessAccount: true
          };
          setPayload(updatedPayload);
          setOpenConfirmBusinessAccounts(false);
          sendInfo();
        }}
        showNoButton
        onNo={() => {
          setOpenConfirmBusinessAccounts(false);
          const updatedPayload = {
            ...payload,
            shareWithBusinessAccount: false
          };
          setPayload(updatedPayload);
          sendInfo();
        }}
      />
    </>
  );
};

ShareLocationsWithTargets.defaultProps = {
  reloadTable: () => {}
};
