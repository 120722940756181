import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavigate } from 'react-router-dom';
import { MyFilesTemplate } from '../../templates/myFilesTemplate/myFilesTemplate';
import { Header } from '../../components/header/header';
import { MarginComponent } from '../../components/marginComponent/marginComponent';
import { ButtonIconStyled, TitleStyled } from './myFilesStyles';
import Strings from '../../i18n';

export const MyFilesView = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header>
        <MarginComponent ml={30} mr={30}>
          <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </ButtonIconStyled>
        </MarginComponent>
        <TitleStyled>{Strings.myFiles.myFiles}</TitleStyled>
        <ButtonIconStyled size="small">
          <TuneIcon />
        </ButtonIconStyled>
      </Header>
      <MyFilesTemplate />
    </div>
  );
};
