import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  IDeviceAndLocationStatusCount,
  IGlobalSearchResponse
} from '../../types/Dashboard/globalSearchInterfaces';
import { ICustomActivityFeed } from '../../types/Dashboard/dashboardInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiDashboard = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getGlobalSearchByType: builder.mutation<
      IGlobalSearchResponse,
      { type: string; search: string }
    >({
      query: ({ type, search }) => ({
        url: `api/Dashboard/Search?searchValue=${search}&type=${type}`,
        method: 'GET'
      })
    }),
    getDeviceAndLocationStatusCount: builder.mutation<
      IDeviceAndLocationStatusCount,
      void
    >({
      query: () => ({
        url: `api/Dashboard/UserDeviceAndLocationStatusCount/`,
        method: 'GET'
      })
    }),
    getCustomActivityFeed: builder.mutation<ICustomActivityFeed[], void>({
      query: () => ({
        url: `api/Dashboard/CustomActivityFeed`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetGlobalSearchByTypeMutation,
  useGetDeviceAndLocationStatusCountMutation,
  useGetCustomActivityFeedMutation
} = apiDashboard;
