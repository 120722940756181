import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LocationDetailsBarProps } from './locationDetailsBarProps';
import { ButtonStyled, ButtonStyledCancel } from './locationDetailsBarStyles';
import Strings from '../../../i18n';
import {
  useDeleteOrganizationMutation,
  usePutLocationMutation
} from '../../../services/device/deviceService';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { useDeleteLocationMutation } from '../../../services/locations/locationsService';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarReadOnly } from '../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';

export const LocationDetailsBar = ({
  reloadTable,
  locationOrg,
  openRightSideBarForMethod,
  setOpenRightSideBar
}: LocationDetailsBarProps) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState<string | undefined>('');
  const isLocation = 'locationId' in locationOrg;
  const contextUser = useSelector(selectCurrentUser);
  const [editLocation, { isLoading, isSuccess }] = usePutLocationMutation();

  const [deleteLocation, { isSuccess: isSuccessDeleteLocation }] =
    useDeleteLocationMutation();

  const [deleteOrganization, { isSuccess: isSuccessDeleteOrganization }] =
    useDeleteOrganizationMutation();

  const testMessage = [1, 10, 100, 300];

  useEffect(() => {
    if (locationOrg) {
      setName(
        isLocation ? locationOrg.locationName : locationOrg.organizationName
      );

      setAddress(
        isLocation ? locationOrg.locationAddress : locationOrg.address
      );
    }
  }, [locationOrg]);
  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.locations.successEditLocation);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccess]);

  const deleteLocationOrganization = () => {
    if (isLocation) {
      deleteLocation([locationOrg.locationId]);
    } else {
      deleteOrganization([locationOrg.organizationId] ?? [locationOrg.id]);
    }
  };

  useEffect(() => {
    if (isSuccessDeleteLocation) {
      toast.success(Strings.locations.successDeleteLocation);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteLocation]);
  useEffect(() => {
    if (isSuccessDeleteOrganization) {
      toast.success(Strings.organizations.deleteOrganization);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteOrganization]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {isLocation ? locationOrg.locationName : locationOrg.organizationName}
        </StyledTitleSideBar>
        <SideBarSimpleMethodItem
          name={Strings.locations.transferOwnership}
          action={() => {
            openRightSideBarForMethod(viewRightSideBarEnum.transferLocation);
          }}
          successAction={false}
          textSuccess="success"
        />
        {!isLocation && (
          <SideBarSimpleMethodItem
            name={
              isLocation
                ? Strings.locations.shareLocation
                : Strings.organizations.shareOrg
            }
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.shareLocation);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        <SideBarSimpleMethodItem
          name={Strings.locations.shareLocationsWithTargets}
          action={() => {
            openRightSideBarForMethod(
              viewRightSideBarEnum.shareLocationsWithTargets
            );
          }}
          successAction={false}
          textSuccess="success"
        />
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.locations.moveToOrg}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.moveLocation);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.notes.addNote}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.addNote);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.locations.changeLatLongLocation}
            action={() => {
              openRightSideBarForMethod(
                viewRightSideBarEnum.changeLatLongLocation
              );
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        <SideBarEditablePropertyItem
          item={{
            label: Strings.locations.locationName,
            type: 'inputText',
            currentValue: name,
            placeholder: '',
            disabled: locationOrg.isShared,
            onChange: (e) => setName(e.target.value)
          }}
        />
        <SideBarReadOnly
          readOnlyItems={[
            {
              name: Strings.locations.owner,
              value: locationOrg.isShared
                ? locationOrg.ownerEmail
                : contextUser.email
            }
          ]}
        />
        <SideBarEditablePropertyItem
          item={{
            label: Strings.businessAccount.address,
            type: 'inputText',
            currentValue: address || '',
            placeholder: '',
            disabled: locationOrg.isShared,
            onChange: (e) => setAddress(e.target.value)
          }}
        />
        {isLocation && (
          <SideBarReadOnly
            readOnlyItems={[
              {
                name: Strings.locations.apiKey,
                value: locationOrg.locationRemoteAccessToken
              }
            ]}
          />
        )}
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteLocationOrganization()}
          titleConfirmation={
            isLocation
              ? Strings.locations.deleteLocationTitle
              : Strings.organizations.deleteOrganizationTitle
          }
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={
            isLocation
              ? Strings.locations.deleteLocation
              : Strings.organizations.deleteOrganization
          }
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={!name.length || locationOrg.isShared || isLoading}
          onClick={() => {
            editLocation({
              id: isLocation
                ? locationOrg.locationId
                : locationOrg.organizationId,
              Name: name,
              OwnerId: contextUser.userId || 0,
              GeoInfo: {
                Address: address || '',
                Latitude: 20.6763989,
                Longitude: -103.3479102
              }
            });
          }}
        >
          {Strings.common.save}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
