import React from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
  NameUserTitle,
  AvatarUser,
  NameUser,
  EmailUser,
  ActiveUser,
  FormContainer,
  LabelSelect,
  CustomSelect,
  ButtonsContainer,
  ButtonStyled,
  CancelButtonStyled
} from './userSidebarStyles';
import Strings from '../../../i18n';

export interface UserSideBarProps {
  setOpenSideBar: (open: boolean) => void;
}
export const UserSideBar = ({ setOpenSideBar }: UserSideBarProps) => {
  const { user } = useAuth0();
  return (
    <Box height="100%">
      <NameUserTitle>{user?.nickname}</NameUserTitle>
      <Grid container marginTop={2}>
        <Grid item container justifyContent="center">
          <AvatarUser alt="user" src={user?.picture} />
        </Grid>
        <Grid item container justifyContent="center">
          <NameUser>{user?.nickname}</NameUser>
        </Grid>
        <Grid item container justifyContent="center">
          <EmailUser>{user?.email}</EmailUser>
        </Grid>
        <Grid item container justifyContent="center">
          <ActiveUser>
            {user?.email_verified
              ? Strings.users.active
              : Strings.users.pending}
          </ActiveUser>
        </Grid>
      </Grid>
      <FormContainer>
        <Grid>
          <LabelSelect>Option 1</LabelSelect>
          <CustomSelect size="small" fullWidth>
            <MenuItem value="">...</MenuItem>
            <MenuItem value={10}>...</MenuItem>
          </CustomSelect>
          <LabelSelect>Option 2</LabelSelect>
          <CustomSelect size="small" fullWidth>
            <MenuItem value="">...</MenuItem>
            <MenuItem value={10}>...</MenuItem>
          </CustomSelect>
          <LabelSelect>Option 3</LabelSelect>
          <CustomSelect size="small" fullWidth>
            <MenuItem value="">...</MenuItem>
            <MenuItem value={10}>...</MenuItem>
          </CustomSelect>
        </Grid>
      </FormContainer>
      <ButtonsContainer>
        <CancelButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            setOpenSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </CancelButtonStyled>
        <ButtonStyled variant="contained" size="large">
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
