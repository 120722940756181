import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const Title = styled(Typography)`
  && {
    margin-top: -30px;
    font-weight: bold;
  }
`;

export const ButtonNewRole = styled(Button)`
  && {
    margin-top: 5px;
    color: ${colors.blue};
    text-transform: none;
  }
`;
