import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Editor } from 'primereact/editor';
import Strings from '../../../../i18n';
import { SideBarEditablePropertyItem } from '../../SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar,
  ButtonStyled
} from '../../SideBarComponents/sideBarComponentsStyles';
import { usePostNoteMutation } from '../../../../services/notes/notesService';
import { SelectItemMethod } from '../../SideBarComponents/SelectItemMethod/selectItemMethod';
import { severityEnum } from '../../../../constants/commonEnums';

interface AddNoteSideBarProps {
  closeSideBar: () => void;
  targetId: number | string;
  type: 'device' | 'location' | 'user';
}
export const AddNoteSideBar = ({
  closeSideBar,
  targetId,
  type
}: AddNoteSideBarProps) => {
  const [text, setText] = React.useState<any>('');
  const [severityLevel, setSeverityLevel] = React.useState<severityEnum>(
    severityEnum.low
  );
  const [postNote, { isSuccess, isError, error }] = usePostNoteMutation();

  const createNote = () => {
    switch (type) {
      case 'device':
        postNote({ deviceId: targetId, text, type, severityLevel });
        break;
      case 'location':
        postNote({ locationId: +targetId, text, type, severityLevel });
        break;
      case 'user':
        postNote({ userId: +targetId, text, type, severityLevel });
        break;
      default:
        return '';
    }
    return '';
  };

  useEffect(() => {
    if (isSuccess) {
      closeSideBar();
      toast.success(Strings.notes.successNote);
    }
  }, [isSuccess]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.notes.createNote}</StyledTitleSideBar>
        <Editor
          value={text || ''}
          onTextChange={(e) => setText(e.htmlValue)}
          style={{ height: '320px' }}
        />
        <SelectItemMethod
          item={{
            label: Strings.common.severity,
            currentValue: severityLevel,
            options: [
              { id: severityEnum.low, name: 'Low' },
              { id: severityEnum.medium, name: 'Medium' },
              { id: severityEnum.high, name: 'High' }
            ],
            onChange: (e) => setSeverityLevel(+e.target.value),
            placeholder: Strings.common.severity
          }}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          onClick={() => {
            createNote();
          }}
        >
          {Strings.common.add}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
