import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, Cached, Send } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Typography, Grid } from '@mui/material';
import { Checkbox } from 'primereact/checkbox';
import { Header } from '../../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  styledColumnHeaderCheckbox
} from '../../../../components/layout/main/mainStyles';
import { useGetListOfFilesCommandByDeviceIdMutation } from '../../../../services/device/deviceService';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../locations/allLocationsList/allLocationListStyles';
import { LoadingRowsComponent } from '../../../../components/commons/LoadingRows/LoadingRows';
import { RightSideBar } from '../../../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import { SendFileThroughEmail } from './sendFileThroughEmail/sendFileThroughEmail';
import { IFileDevice } from '../../../../types/Device/DeviceInterfaces';

export const ListOfFilesCommandComponent = () => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<IFileDevice[]>([]);
  const { deviceId } = useParams();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [sideBarOpenFor, setSideBarOpenFor] = useState<number>(
    viewRightSideBarEnum.empty
  );

  const [getFileList, { isSuccess, isLoading, data }] =
    useGetListOfFilesCommandByDeviceIdMutation();

  const getDataTable = () => {
    if (deviceId) {
      getFileList(deviceId);
    }
  };

  const handleOpenSideBar = (method: number): void => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  useEffect(() => {
    if (deviceId) {
      getDataTable();
    }
  }, []);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.sendFileThroughEmail:
        return (
          <SendFileThroughEmail
            deviceId={deviceId ?? ''}
            fileNames={selectedItems.map((item) => item.name)}
            closeSideBar={() => setOpenSideBar(false)}
          />
        );
      default:
        return <p />;
    }
  };

  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedItems.length ? 'visible' : 'hidden'
        }}
        checked={(selectedItems && selectedItems.length > 0) ?? false}
        onChange={() => {
          setSelectedItems([]);
        }}
      />
    );
  };

  useEffect(() => {
    if (selectedItems.length >= 1) {
      handleOpenSideBar(viewRightSideBarEnum.sendFileThroughEmail);
    } else {
      handleOpenSideBar(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedItems]);

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.serverAccess.devices.fileListLabel}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <ButtonIconStyled size="small" onClick={() => getDataTable()}>
                <Cached />
              </ButtonIconStyled>
              <ButtonIconStyled
                size="small"
                onClick={() =>
                  handleOpenSideBar(viewRightSideBarEnum.sendFileThroughEmail)
                }
              >
                <Send />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.currentDevice} {deviceId}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          {isLoading && <LoadingRowsComponent />}
          {isSuccess && data && (
            <DataTable
              selection={selectedItems}
              selectionMode="checkbox"
              onSelectionChange={(e) => setSelectedItems(e.value)}
              className="mt-3"
              rowHover
              value={data as any}
              size="small"
              scrollable
              selectionPageOnly
              rows={50}
              rowsPerPageOptions={[5, 10, 20, 50]}
              tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={styledColumnHeaderCheckbox}
                header={getHeaderCheckbox}
                frozen
              />
              <Column field="name" header="Name" sortable />
              <Column field="type" header="Type" />
              <Column field="size" header="Size" sortable />
            </DataTable>
          )}
        </Grid>
      </Grid>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
