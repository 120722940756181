import React from 'react';
import { Divider } from '@mui/material';
import Strings from '../../i18n';
import { colors } from '../../constants/colors';
import { ServerAccessMenuComponent } from '../../templates/ServerAccess/ServerAccessMenu/ServerAccessMenu.component';
import { StyledTitleHeader } from '../../components/layout/main/mainStyles';

export const ServerAccessView = () => {
  return (
    <div>
      <StyledTitleHeader
        variant="subtitle1"
        align="center"
        color={colors.strongGray}
        marginBottom={2}
      >
        {Strings.serverAccess.pageTitle}
      </StyledTitleHeader>
      <Divider variant="fullWidth" />
      <ServerAccessMenuComponent />
    </div>
  );
};
