import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { useDeleteMultipleUsersBAMutation } from '../../../../services/businessAccount/businessAccountService';

interface UsersInBusinessDetailsProps {
  closeSideBar: () => void;
  ids: number[];
  reloadTable: () => void;
}
export const UsersInBusinessDetails = ({
  closeSideBar,
  ids,
  reloadTable
}: UsersInBusinessDetailsProps) => {
  const [deleteUsersFromBA, { isSuccess: isSuccessDeleteUser }] =
    useDeleteMultipleUsersBAMutation();
  useEffect(() => {
    if (isSuccessDeleteUser) {
      toast.success(Strings.businessAccount.successDeleteUser);
      reloadTable();
    }
  }, [isSuccessDeleteUser]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.users.userDetails}</StyledTitleSideBar>
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteUsersFromBA(ids)}
          titleConfirmation={Strings.businessAccount.deleteUserTitle}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={`${Strings.businessAccount.removeUser}${
            ids.length > 1 ? 's' : ''
          }`}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
