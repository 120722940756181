import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { colors } from '../../../constants/colors';

export const StyledColorButton = styled.button<{
  bgImage?: string;
  bgColor: string;
  colorName: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0 10px;
  outline: none;
  width: 40px;
  height: 40px;
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:before {
    content: '${({ colorName }) =>
      colorName === 'PICK COLOR' ? '' : colorName}';
    display: block;
    font-size: 9px;
    text-align: center;
    color: black;
    text-transform: capitalize;
    font-weight: bold;
  }
`;

export const StyledColorDialog = styled(Dialog)`
  &.p-dialog .p-dialog-header {
    display: none !important;
  }
`;

export const StyledGrid = styled(Grid)<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
export const StyledColorListSelectorBtn = styled.button<{
  isActive?: boolean;
  visible: boolean;
}>`
  display: ${({ visible }) => (visible ? 'block' : 'none !important')};
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  width: 100% !important;
  font-weight: bold;
  color: ${colors.blueTheme}
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background-color: #f5f5f5;
  }
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${colors.blueTheme}` : 'none'};
`;

export const StyledTitleColorList = styled.h3`
  text-align: left;
  color: #000;
  font-weight: bold;
  margin: 20px 0 12px 0;
  font-size: 1rem;
  text-transform: uppercase;
`;

export const ColorItem = styled.button<{
  bgImage?: string;
  bgColor: string;
  colorName: string;
  active?: boolean;
}>`
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0 10px;
  outline: none;
  width: 65px;
  height: 65px;
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:before {
    content: '${({ colorName }) => colorName}';
    display: block;
    font-size: 9px;
    text-align: center;
    color: black;
    text-transform: capitalize;
    font-weight: bold;
  }
  box-shadow: ${({ active }) => (active ? '1px 6px 9px 0 #545454' : 'none')};
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 1px 6px 9px 0 #545454;
  }
  &:focus {
    box-shadow: 1px 6px 9px 0 #545454;
    outline: none;
  }
`;

export const StyledEffectSelectorBtn = styled.button`
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  font-weight: bold;
  color: ${colors.blueTheme}
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s;
  border-radius: 15px;
  &:hover {
    box-shadow: 1px 6px 9px 0 #545454;
  }
  p {
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
    }
`;
