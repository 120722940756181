import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Editor } from 'primereact/editor';
import Strings from '../../../../i18n';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar,
  ButtonStyled
} from '../../SideBarComponents/sideBarComponentsStyles';
import { useEditNoteMutation } from '../../../../services/notes/notesService';
import { INoteItemList } from '../../../../types/notes/notesInterface';

interface EditNoteSideBarProps {
  closeSideBar: () => void;
  selectedNote: INoteItemList;
  reloadTable: () => void;
}

export const EditNoteSideBar = ({
  closeSideBar,
  selectedNote,
  reloadTable
}: EditNoteSideBarProps) => {
  const [text, setText] = useState<string>(selectedNote?.text || '');
  const [editNote, { isSuccess, isError, error }] = useEditNoteMutation();

  const save = () => {
    editNote({
      id: selectedNote.id,
      text,
      type: selectedNote.type,
      severityLevel: selectedNote.severityLevel
    });
  };

  useEffect(() => {
    setText(selectedNote?.text || '');
  }, [selectedNote]);

  useEffect(() => {
    if (isSuccess) {
      closeSideBar();
      toast.success(Strings.notes.successEditNote);
      reloadTable();
    }
  }, [isSuccess]);

  return (
    <>
      {selectedNote && (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>
              {Strings.notes.editNote}: {selectedNote.targetName}
            </StyledTitleSideBar>
            <Editor
              value={selectedNote?.text}
              onTextChange={(e) => setText(e.htmlValue ?? '')}
              style={{ height: '320px' }}
            />
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => closeSideBar()}
            >
              {Strings.common.cancel}
            </button>
            <button type="button" className="btn-save" onClick={save}>
              {Strings.common.save}
            </button>
          </StyledContainerFooterSideBar>
        </>
      )}
      <div />
    </>
  );
};
