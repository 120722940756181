import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { Dialog } from 'primereact/dialog';
import JsonView from '@uiw/react-json-view';
import { toast } from 'react-toastify';
import {
  useGetGlobalDeviceAnnounceMutation,
  useGetLastDeviceAnnounceByDeviceIdMutation
} from '../../services/device/deviceService';
import { LoadingRowsComponent } from '../../components/commons/LoadingRows/LoadingRows';
import {
  StyledBoxTable,
  StyledButtonActionUnderlined
} from '../../components/layout/main/mainStyles';
import {
  IGlobalDeviceAnnounceItem,
  IPayloadInfoDeviceAnnounce,
  IResponseInfoDeviceAnnounce
} from '../../types/Device/DeviceInterfaces';
import Strings from '../../i18n';
import { StyledButtonAction } from './globalDeviceAnnounceTemplateStyles';
import { JsonViewItem } from '../../components/commons/JsonViewItem/jsonViewItem';

const payloadIcon = require('../../assets/images/deviceAnnounce/payloadAnnounce.png');
const responseIcon = require('../../assets/images/deviceAnnounce/responseAnnounce.png');

export const GlobalDeviceAnnounceListTemplate = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const payloadValues = [
    'deviceId',
    'uniqueProductName',
    'firmwareVersions',
    'hardwareVersions',
    'utcDate'
  ];
  const responseValues = ['sessionId', 'methods'];
  const [getGlobalDeviceAnnounce, { isSuccess, data, isLoading }] =
    useGetGlobalDeviceAnnounceMutation();

  const [
    getLastDeviceAnnounceByDeviceId,
    {
      isSuccess: isSuccessGetDeviceAnnounceById,
      data: dataLastDeviceAnnounce,
      isError: isErrorGetDeviceAnnounceById,
      error: errorGetDeviceAnnounceById
    }
  ] = useGetLastDeviceAnnounceByDeviceIdMutation();

  const getDataTable = () => {
    getGlobalDeviceAnnounce();
    setShowDialog(false);
  };
  useEffect(() => {
    getDataTable();
  }, []);

  const getTime = (rowData: IGlobalDeviceAnnounceItem) => {
    const utcDate = moment.utc(rowData.announceDate);
    const browserTime = utcDate.local();
    return rowData.announceDate ? browserTime.format('YYYY/MM/DD, h:mmA') : '';
  };
  const openToSee = (rowData: IGlobalDeviceAnnounceItem, type: string) => {
    setDialogType(type);
    getLastDeviceAnnounceByDeviceId(rowData.deviceId);
  };
  useEffect(() => {
    if (isSuccessGetDeviceAnnounceById && dataLastDeviceAnnounce) {
      setShowDialog(true);
    }
  }, [isSuccessGetDeviceAnnounceById]);

  useEffect(() => {
    if (isErrorGetDeviceAnnounceById) {
      setShowDialog(false);
      toast.error(Strings.common.notFound);
    }
  }, [isErrorGetDeviceAnnounceById]);

  const getValuesForPayload = (
    info: IPayloadInfoDeviceAnnounce,
    key: string
  ) => {
    return JSON.stringify(info[key]);
  };
  const getValuesForResponse = (
    info: IResponseInfoDeviceAnnounce | null,
    key: string
  ) => {
    if (info) {
      return JSON.stringify(info[key]);
    }
    return '';
  };

  const getRedirectByLocation = (rowData: IGlobalDeviceAnnounceItem) => {
    return (
      <Tooltip title={Strings.locations.goToLocation}>
        <StyledButtonActionUnderlined
          type="button"
          onClick={() => {
            window.location.href = `/Locations/${rowData.locationId}/devices`;
          }}
        >
          {rowData.deviceId}
        </StyledButtonActionUnderlined>
      </Tooltip>
    );
  };
  const goToLocation = (rowData: IGlobalDeviceAnnounceItem) => {
    return (
      <Tooltip title={Strings.locations.goToLocation}>
        <a
          href={`/locations/${rowData.locationId}/applications`}
          target="_blank"
          rel="noreferrer"
        >
          {rowData.locationName}
        </a>
      </Tooltip>
    );
  };

  const getActionButtons = (rowData: IGlobalDeviceAnnounceItem) => {
    return (
      <div>
        <Tooltip title={Strings.common.payload}>
          <StyledButtonAction
            type="button"
            onClick={() => {
              openToSee(rowData, Strings.common.payload);
            }}
          >
            <img src={payloadIcon} alt="Payload" width={30} />
          </StyledButtonAction>
        </Tooltip>
        <Tooltip title={Strings.common.response}>
          <StyledButtonAction
            type="button"
            onClick={() => {
              openToSee(rowData, Strings.common.response);
            }}
          >
            <img src={responseIcon} alt="Payload" width={30} />
          </StyledButtonAction>
        </Tooltip>
      </div>
    );
  };
  return (
    <div>
      {isLoading && <LoadingRowsComponent />}
      {isSuccess && data && (
        <StyledBoxTable>
          <DataTable
            value={data}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={data && data.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column
              field="announceDate"
              header="Time stamp"
              sortable
              body={getTime}
            />
            <Column field="" header="Payload" body={getActionButtons} />
            <Column
              field="firmwareVersion"
              header="Firmware Version"
              sortable
            />
            <Column
              field="ackReceived"
              header="Method ACK"
              sortable
              body={(item) => {
                return item.ackReceived
                  ? Strings.common.yes
                  : Strings.common.no;
              }}
            />
            <Column
              field="deviceId"
              header="Device ID"
              sortable
              body={getRedirectByLocation}
            />
            <Column
              field="ownerInfo"
              header="Owner Info"
              sortable
              style={{ textTransform: 'capitalize' }}
            />
            <Column
              field="locationName"
              header="Location"
              body={goToLocation}
              sortable
              style={{ textTransform: 'capitalize' }}
            />
          </DataTable>
        </StyledBoxTable>
      )}
      <Dialog
        header={dialogType}
        visible={showDialog}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
        onHide={() => setShowDialog(false)}
      >
        {dialogType === Strings.common.payload && dataLastDeviceAnnounce
          ? payloadValues.map((value) => {
              return (
                <div key={value}>
                  <JsonViewItem
                    title={value}
                    values={getValuesForPayload(
                      dataLastDeviceAnnounce['payload'],
                      value
                    )}
                  />
                </div>
              );
            })
          : responseValues.map((value) => {
              return (
                <div key={value}>
                  <JsonViewItem
                    title={value}
                    values={getValuesForResponse(
                      dataLastDeviceAnnounce
                        ? dataLastDeviceAnnounce['response']
                        : null,
                      value
                    )}
                  />
                </div>
              );
            })}
      </Dialog>
    </div>
  );
};
