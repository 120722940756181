import React from 'react';
import './offEventStyles.css';
import Strings from '../../../../../i18n';

const img = require('../../../../../assets/images/eventsType/lightsZonesOn.png');

export const LightsZonesOff = () => {
  return (
    <div className="row pt-2">
      <div className="col-12">
        <div className="container-type">
          <div className="img-container">
            <img src={img} alt="LZ-OFF" />
            <span>{Strings.events.lightsZoneSelector}</span>
          </div>
          <p>{Strings.events.lightsZonesOff}</p>
        </div>
      </div>
    </div>
  );
};
