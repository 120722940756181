import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { IScene } from '../../../../types/Scenes/scenesTypes';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import {
  StyledContainerItems,
  StyledContainerFooterSideBar,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import {
  useDeleteSceneMutation,
  useExecuteSceneMutation
} from '../../../../services/scenes/sceneService';

interface ISceneDetailsProps {
  scene: IScene;
  selectedScenes: IScene[];
  setSelectedScenes: (scenes: IScene[]) => void;
  openSideBar: boolean;
  setOpenSideBar: (openSideBar: boolean) => void;
  reloadTable: () => void;
}
export const SceneDetails = ({
  scene,
  openSideBar,
  selectedScenes,
  setSelectedScenes,
  setOpenSideBar,
  reloadTable
}: ISceneDetailsProps) => {
  const [execute, { isSuccess: isSuccessExecute }] = useExecuteSceneMutation();
  const [deleteSc, { isSuccess: isSuccessDelete }] = useDeleteSceneMutation();
  const deleteScene = () => {
    deleteSc(scene.id);
  };
  const executeScene = () => {
    execute(scene.id);
  };
  useEffect(() => {
    if (isSuccessExecute) {
      setOpenSideBar(false);
      setSelectedScenes([]);
      toast.success(Strings.scenes.successExecuteScene);
    }
  }, [isSuccessExecute]);
  useEffect(() => {
    if (isSuccessDelete) {
      setOpenSideBar(false);
      setSelectedScenes([]);
      toast.success(Strings.scenes.successDeleteScene);
      reloadTable();
    }
  }, [isSuccessDelete]);
  return (
    <>
      {scene && (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>{scene?.name}</StyledTitleSideBar>
            <SideBarSimpleMethodItem
              name={Strings.scenes.executeScene}
              action={() => executeScene()}
              successAction={false}
              textSuccess="success"
            />
            <SideBarDeleteActionWithPopup
              onDelete={() => deleteScene()}
              titleConfirmation={Strings.scenes.deleteSceneTitle}
              textConfirmation={Strings.common.thisActionIsIrreversible}
              textIcon={Strings.scenes.deleteScene}
            />
          </StyledContainerItems>

          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                setOpenSideBar(false);
                setSelectedScenes([]);
              }}
            >
              {Strings.common.cancel}
            </button>
          </StyledContainerFooterSideBar>
        </>
      )}
      <div />
    </>
  );
};
