import React from 'react';
import {
  Assignment,
  Devices,
  FilterNone,
  Groups,
  LocationOn,
  MonitorHeart,
  Person,
  PhoneAndroid
} from '@mui/icons-material';
import Strings from '../../../i18n';
import { systemModuleEnum } from '../../../constants/systemRolesEnums';

export const itemsServerAccessMenu: {
  icon: React.ReactNode;
  text: string;
  url: string;
  key: string;
}[] = [
  {
    icon: <MonitorHeart />,
    text: Strings.serverAccess.menu.serviceHealth,
    url: '/health-monitor',
    key: systemModuleEnum.serviceHealth
  },
  {
    icon: <Groups />,
    text: Strings.serverAccess.menu.organizations,
    url: '/organizations',
    key: systemModuleEnum.organizations
  },
  {
    icon: <Devices />,
    text: Strings.serverAccess.menu.versions,
    url: '/firmwareVersions',
    key: systemModuleEnum.versions
  },
  {
    icon: <FilterNone />,
    text: Strings.serverAccess.menu.productsModels,
    url: '/',
    key: systemModuleEnum.products
  },
  {
    icon: <Person />,
    text: Strings.serverAccess.menu.users,
    url: '/users',
    key: systemModuleEnum.users
  },
  {
    icon: <LocationOn />,
    text: Strings.serverAccess.menu.locations,
    url: '/all-locations',
    key: systemModuleEnum.locations
  },
  {
    icon: <PhoneAndroid />,
    text: Strings.serverAccess.menu.devices,
    url: '/server-access/devices',
    key: systemModuleEnum.devices
  },
  {
    icon: <PhoneAndroid />,
    text: Strings.serverAccess.menu.deviceAnnounce,
    url: '/server-access/device-announce',
    key: systemModuleEnum.deviceAnnounce
  },
  {
    icon: <Assignment />,
    text: Strings.serverAccess.menu.firmwareSchedule,
    url: '/server-access/firmware-schedule',
    key: systemModuleEnum.firmwareSchedule
  }
];
