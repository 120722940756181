import { LoadingButton } from '@mui/lab';
import { Box, Select, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const TitleStyled = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 13px;
    margin-top: -25px;
    margin-left: 5px;
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled(Box)`
  && {
    height: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    text-transform: none;
    background-color: ${colors.strongGray};
    color: ${colors.white};
  }
`;

export const ButtonStyledCancel = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.lightGray};
    text-transform: none;
    color: ${colors.darkGray};
  }
`;

export const TextFieldStyled = styled(TextField)`
  && {
    background-color: ${colors.lightGray};
  }
`;

export const CustomSelect = styled(Select)`
  && {
    margin: 0;
    width: 100%;
    background-color: ${colors.lightGray};}
    border: 1px solid #aaa;
  }
`;
