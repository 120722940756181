import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Grid, Collapse, MenuItem, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import Strings from '../../../i18n';
import { ItemDetail } from '../../../components/itemDetail/itemDetail';
import { ApplicationDetailsProps } from './applicationDetailsProps';
import { ButtonStyledDetails, CustomSelect } from './applicationDetailsStyles';
import { colors } from '../../../constants/colors';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { locationDetails } from '../../../store/slices/locations/location';

export const ApplicationsDetails = ({
  collapse,
  openRightSideBarForMethod
}: ApplicationDetailsProps) => {
  const locationSelected = useSelector(locationDetails);
  return (
    <Collapse in={collapse}>
      <Grid container paddingLeft="17%">
        <ItemDetail
          nameProperty={Strings.applications.enableAuditLog}
          component={
            <>
              <Typography
                fontSize={13}
                marginTop={1}
                fontWeight={500}
                marginLeft={0}
                color={colors.mediumGray}
              >
                {Strings.applications.auditLog}
              </Typography>
              <CustomSelect size="small">
                <MenuItem value={1}>enabled</MenuItem>
                <MenuItem value={0}>disabled</MenuItem>
              </CustomSelect>
            </>
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.automaticUpdates}
          component={
            <Grid container justifyContent="space-between">
              <Grid item xs={5}>
                <Typography
                  fontSize={13}
                  marginTop={1}
                  fontWeight={500}
                  marginLeft={0}
                  color={colors.mediumGray}
                >
                  {Strings.applications.occurs}
                </Typography>
                <CustomSelect size="small">
                  <MenuItem value={1}>Daily</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  fontSize={13}
                  marginTop={1}
                  fontWeight={500}
                  marginLeft={0}
                  color={colors.mediumGray}
                >
                  {Strings.applications.at}
                </Typography>
                <CustomSelect size="small">
                  <MenuItem value={1}>3 AM</MenuItem>
                </CustomSelect>
              </Grid>
            </Grid>
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.bitField}
          component={
            <>
              <Typography
                fontSize={13}
                marginTop={1}
                fontWeight={500}
                marginLeft={0}
                color={colors.mediumGray}
              >
                {Strings.applications.bitField}
              </Typography>
              <CustomSelect size="small">
                <MenuItem value={1}>enabled</MenuItem>
                <MenuItem value={0}>disabled</MenuItem>
              </CustomSelect>
            </>
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.longitude}
          value={
            locationSelected?.geoInfo
              ? locationSelected?.geoInfo.longitude.toString()
              : ''
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.latitude}
          value={
            locationSelected?.geoInfo
              ? locationSelected?.geoInfo.latitude.toString()
              : ''
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.sunriseTime}
          value="07:04:49"
        />
        <ItemDetail
          nameProperty={Strings.applications.sunsetTime}
          value="20:19:21"
        />
        <ItemDetail
          nameProperty={Strings.applications.localTime}
          value="20:19:21"
        />
        <ItemDetail
          nameProperty={Strings.applications.consoleControls}
          component={
            <Grid container marginLeft={-1}>
              <Grid item xs={12} marginTop={-1}>
                <ButtonStyledDetails
                  onClick={() =>
                    openRightSideBarForMethod(
                      viewRightSideBarEnum.transferLocation
                    )
                  }
                  variant="text"
                  size="small"
                  endIcon={
                    <Tooltip
                      arrow
                      title={Strings.applications.transferLoc}
                      placement="top"
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  }
                >
                  {Strings.locations.transferOwnership}
                </ButtonStyledDetails>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Collapse>
  );
};
