import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { StyledContainerMethodWithValue } from '../sideBarComponentsStyles';
import Strings from '../../../../i18n';
import {
  StyledFileInput,
  StyledFileInputWrapper
} from './sideBarMethodWithValueStyles';

interface SideBarSimpleMethodItemProps {
  label: string;
  type: string;
  currentValue: any;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  action: () => void;
  successAction: boolean;
  textSuccess: string;
  fileName?: string;
  buttonLabel?: string;
  acceptedFiles?: string;
}
export const SideBarSimpleMethodWithValue = ({
  label,
  type,
  currentValue,
  onChange,
  action,
  successAction,
  textSuccess,
  buttonLabel,
  fileName,
  acceptedFiles
}: SideBarSimpleMethodItemProps) => {
  const [value, setValue] = useState(currentValue);

  const switchInputToShow = () => {
    switch (type) {
      case 'inputText':
        return (
          <input
            type="text"
            className="input-text"
            value={currentValue}
            onChange={onChange}
          />
        );
      case 'inputNumber':
        return (
          <input
            type="number"
            className="input-text"
            value={currentValue}
            onChange={onChange}
          />
        );
      case 'inputPassword':
        return (
          <input
            type="password"
            className="input-text"
            value={currentValue}
            onChange={onChange}
          />
        );
      case 'inputFile':
        return (
          <StyledFileInputWrapper>
            <StyledFileInput
              type="file"
              onChange={onChange}
              accept={acceptedFiles ?? '*'}
            />
            {fileName !== '' ? fileName : Strings.common.noFileSelected}
          </StyledFileInputWrapper>
        );
      default:
        return <input type="text" className="input-text" value={value} />;
    }
  };
  return (
    <StyledContainerMethodWithValue container>
      <Grid item xs={12}>
        <div className="label">{label}</div>
        <div className="value">{switchInputToShow()}</div>
        <button type="button" className="btn-send" onClick={action}>
          {buttonLabel}
        </button>
      </Grid>
    </StyledContainerMethodWithValue>
  );
};
SideBarSimpleMethodWithValue.defaultProps = {
  fileName: undefined,
  buttonLabel: Strings.common.send,
  acceptedFiles: '*'
};
