import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  ICommandBrightness,
  ICommandOnOff,
  ICommandPattern,
  ICommandSetColor
} from '../../types/commandsInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiCommands = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    sendOnOffCommand: builder.mutation<'', ICommandOnOff>({
      query: (payload) => ({
        url: `api/Commands/${payload.method}`,
        method: 'POST',
        body: {
          id: payload.id,
          type: payload.type
        }
      })
    }),
    sendBrightnessCommand: builder.mutation<'', ICommandBrightness>({
      query: (payload) => ({
        url: `api/Commands/Brightness`,
        method: 'POST',
        body: payload
      })
    }),
    sendColorCommand: builder.mutation<'', ICommandSetColor>({
      query: (payload) => ({
        url: `api/Commands/SetColor`,
        method: 'POST',
        body: payload
      })
    }),
    sendTurnOnPatternCommand: builder.mutation<'', ICommandPattern>({
      query: (payload) => ({
        url: `api/Commands/On/Pattern`,
        method: 'POST',
        body: payload
      })
    })
  })
});

export const {
  useSendOnOffCommandMutation,
  useSendColorCommandMutation,
  useSendBrightnessCommandMutation,
  useSendTurnOnPatternCommandMutation
} = apiCommands;
