import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import {
  StyledContainerItems,
  StyledContainerFooterSideBar,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { useDeleteEventMutation } from '../../../../services/events/eventService';
import { IEvent } from '../../../../types/events/eventsInterface';

interface IEventDetailsProps {
  event: IEvent;
  selectedEvents: IEvent[];
  setSelectedScenes: (scenes: IEvent[]) => void;
  openSideBar: boolean;
  setOpenSideBar: (openSideBar: boolean) => void;
  reloadTable: () => void;
}
export const EventDetails = ({
  event,
  openSideBar,
  selectedEvents,
  setSelectedScenes,
  setOpenSideBar,
  reloadTable
}: IEventDetailsProps) => {
  const [deleteEv, { isSuccess: isSuccessDelete }] = useDeleteEventMutation();
  const deleteScene = () => {
    deleteEv(event.eventId);
  };

  useEffect(() => {
    if (isSuccessDelete) {
      setOpenSideBar(false);
      setSelectedScenes([]);
      toast.success(Strings.scenes.successDeleteScene);
      reloadTable();
    }
  }, [isSuccessDelete]);
  return (
    <>
      {event && (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>{event.name}</StyledTitleSideBar>
            <SideBarDeleteActionWithPopup
              onDelete={() => deleteScene()}
              titleConfirmation={Strings.events.deleteEventTitle}
              textConfirmation={Strings.common.thisActionIsIrreversible}
              textIcon={Strings.events.deleteEvent}
            />
          </StyledContainerItems>

          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                setOpenSideBar(false);
                setSelectedScenes([]);
              }}
            >
              {Strings.common.cancel}
            </button>
          </StyledContainerFooterSideBar>
        </>
      )}
      <div />
    </>
  );
};
