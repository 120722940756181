import React, { useEffect } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Strings from '../../../i18n';
import { addULocationToFolderValidator } from '../../../utils/validators';
import { selectedLocationsByUser } from '../../../store/slices/locations/location';
import { ErrorText } from '../../../components/auth/Profile/completeProfileStyles';
import { AssignLocationProps } from './assignLocationProps';
import { useAssignLocationToFolderMutation } from '../../../services/device/deviceService';
import { folderIdToAssignLocation } from '../../../store/slices/folders/folders';

export const AssignLocation = ({
  reloadTable,
  setOpenRightSideBar
}: AssignLocationProps) => {
  const locations = useSelector(selectedLocationsByUser);
  const folderId = useSelector(folderIdToAssignLocation);

  const [addLocationToFolder, { isSuccess, isLoading }] =
    useAssignLocationToFolderMutation();

  const sendInfo = (payload: any) => {
    addLocationToFolder(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('added user to location successfully');
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccess]);
  return (
    <Grid container justifyContent="center" textAlign="center">
      <Grid item xs={10}>
        {Strings.locations.assignLocationTitle}
      </Grid>
      <Grid item xs={10} marginTop={4}>
        <Formik
          enableReinitialize
          validationSchema={addULocationToFolderValidator}
          initialValues={{
            locationId: null
          }}
          onSubmit={(values) => {
            sendInfo({
              FolderId: folderId,
              LocationId: values.locationId
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isValid,
            setFieldTouched,
            errors,
            touched,
            setFieldValue
          }) => (
            <Grid direction="row" container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="user"
                  options={locations}
                  getOptionLabel={(option) => option.locationName}
                  onChange={(event: any, newValue: any | null) => {
                    setFieldValue('locationId', newValue?.locationId);
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.locationId}>
                      {option.locationName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Locations"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
                {touched.locationId && errors.locationId && (
                  <ErrorText>{errors.locationId}</ErrorText>
                )}
              </Grid>

              <Grid item xs={10} md={8}>
                <LoadingButton
                  color="info"
                  className="w-100"
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                  variant="contained"
                  size="large"
                  loading={isLoading}
                >
                  {Strings.common.send}
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
