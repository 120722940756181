import styled from 'styled-components';

export const StyledFileInputWrapper = styled.label`
  display: inline-block;
  border: 2px solid #ccc;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
`;

export const StyledFileInput = styled.input`
  display: none;
`;
