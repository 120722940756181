import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  IEffectInLocation,
  IPostRippleCommand
} from '../../types/effects/effectsInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiEffects = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    postRippleCommand: builder.mutation<null, IPostRippleCommand>({
      query: (data) => ({
        url: `api/Commands/Effects/Ripple`,
        method: 'POST',
        body: data
      })
    }),
    addEffectRipple: builder.mutation<null, FormData>({
      query: (data) => ({
        url: `api/Effect/Wave`,
        method: 'POST',
        body: data
      })
    }),
    getEffectValidTags: builder.mutation<
      { id: number; name: string }[],
      string
    >({
      query: (filter) => ({
        url: `api/Effect/ValidTags?filter=${filter}`,
        method: 'GET'
      })
    }),
    getEffectsByLocation: builder.mutation<IEffectInLocation[], number>({
      query: (location) => ({
        url: `api/Effect/ByLocation/${location}`,
        method: 'GET'
      })
    }),
    executeEffect: builder.mutation<
      null,
      { id: number; type: string; effectId: number }
    >({
      query: (data) => ({
        url: `api/Commands/Effects/Execute`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  usePostRippleCommandMutation,
  useAddEffectRippleMutation,
  useGetEffectValidTagsMutation,
  useGetEffectsByLocationMutation,
  useExecuteEffectMutation
} = apiEffects;
