import {
  Button,
  Typography,
  IconButton,
  Box,
  Select,
  TextField
} from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { colors } from '../../../constants/colors';

export const Title = styled(Typography)`
  && {
    margin-top: -30px;
    font-weight: bold;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    margin-top: 5px;
    font-weight: bold;
    text-align: center;
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }
`;

export const FormContainer = styled(Box)`
  && {
    width: 100%;
    background-color: #fdfdfd;
    margin-top: 5px;
    border-radius: 8px;
    padding: 20px;
  }
`;

export const LabelSelect = styled(Typography)`
  && {
    color: ${colors.mediumGray};
    font-size: 12px;
  }
`;

export const CustomSelect = styled(Select)`
  && {
    margin-bottom: 5px;
    background-color: ${colors.lightGray};
  }
`;

export const ButtonsContainer = styled(Box)`
  && {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    text-transform: none;
    background-color: ${colors.strongGray};
    color: ${colors.white};
  }
`;

export const CancelButtonStyled = styled(LoadingButton)`
  && {
    color: ${colors.strongGray};
    text-transform: none;
  }
`;

export const InputNameRole = styled(TextField)`
  && {
    width: 100%;
    margin-bottom: 10px;
    background-color: ${colors.lightGray};
  }
`;
