/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Typography,
  Grid,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { updateUserInfo } from '../../../../utils/validators';
import {
  ContainerFormProfile,
  ErrorText,
  ContainerForm
} from '../createUserStyles';
import Strings from '../../../../i18n';
import { colors } from '../../../../constants/colors';
import { ContainerImage } from '../../../../views/registration/registrationViewStyles';
import { usePostUserInfoWithKeyMutation } from '../../../../services/auth/authServices';

const logoImg = require('../../../../assets/logo.png');

export const CompleteProfileAndValidate = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const queryParams = useQuery();
  const emailParam = queryParams.get('email') ?? '';
  const email = emailParam.replace(/ /g, '+');
  const key = queryParams.get('key');
  const [editMode, setEditMode] = useState(false);
  const [checked, setChecked] = useState(true);
  const [countdown, setCountdown] = useState(5);
  const { logout, loginWithRedirect } = useAuth0();
  const [
    postUserInfo,
    { isSuccess: isSuccessUserInfo, isLoading, data, error, isError }
  ] = usePostUserInfoWithKeyMutation();

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (isSuccessUserInfo) {
      toast.success('Added info successfully');
      const intervalId = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(intervalId);
            logout();
            loginWithRedirect();
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [isSuccessUserInfo]);

  const sendInfo = (payload: any) => {
    postUserInfo(payload);
  };

  useEffect(() => {
    if (isError && error) {
      toast.error(error.toString());
    }
  }, [isSuccessUserInfo, isError, error]);
  return (
    <ContainerImage>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item xs={0.5} md={2} />
        <Grid item xs={11} md={6} lg={3.5}>
          <ContainerForm container padding={3} justifyContent="center">
            {isSuccessUserInfo ? (
              <Grid item xs={11}>
                <Typography align="center" marginY={2} variant="h5">
                  <img src={logoImg} alt="Haven Logo" width={200} />
                </Typography>
                <Typography
                  align="center"
                  marginY={3}
                  variant="h5"
                  fontWeight="bold"
                  color={colors.strongGray}
                  paddingTop={2}
                >
                  {Strings.login.successPostData}
                </Typography>
                <Typography
                  align="center"
                  marginBottom={3}
                  variant="h6"
                  color={colors.strongGray}
                >
                  {Strings.login.redirectToLoginPage} {countdown}s
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={11}>
                  <Typography align="center" marginY={2} variant="h5">
                    <img src={logoImg} alt="Haven Logo" width={200} />
                  </Typography>
                  <Typography
                    align="center"
                    marginY={3}
                    variant="h6"
                    fontWeight="bold"
                    color={colors.strongGray}
                    paddingTop={2}
                  >
                    {Strings.userProfile.fillAllTheInformation}
                  </Typography>
                </Grid>
                <ContainerFormProfile item xs={10} md={8} lg={12}>
                  <Formik
                    enableReinitialize
                    validationSchema={updateUserInfo}
                    initialValues={{
                      email,
                      firstName: '',
                      lastName: '',
                      password: '',
                      key
                    }}
                    onSubmit={(values) => {
                      sendInfo({
                        email,
                        fullName: `${values.firstName} ${values.lastName}`,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                        key
                      });
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      values,
                      isValid,
                      setFieldTouched,
                      errors,
                      touched
                    }) => (
                      <Grid
                        direction="row"
                        container
                        spacing={2}
                        justifyContent="center"
                      >
                        <Grid item xs={12} md={10}>
                          <TextField
                            value={values.firstName}
                            id="firstName"
                            label={Strings.userProfile.firstNameLabel}
                            onBlur={() => setFieldTouched('firstName')}
                            onChange={handleChange('firstName')}
                            name="firstName"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                          {touched.firstName && errors.firstName && (
                            <ErrorText>{errors.firstName}</ErrorText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <TextField
                            value={values.lastName}
                            id="lastName"
                            label={Strings.userProfile.lastNameLabel}
                            onBlur={() => setFieldTouched('lastName')}
                            onChange={handleChange('lastName')}
                            name="lastName"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                          {touched.lastName && errors.lastName && (
                            <ErrorText>{errors.lastName}</ErrorText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <TextField
                            value={values.password}
                            id="password"
                            label={Strings.userProfile.passwordLabel}
                            onBlur={() => setFieldTouched('password')}
                            onChange={handleChange('password')}
                            name="password"
                            fullWidth
                            type="password"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                          {touched.password && errors.password && (
                            <ErrorText>{errors.password}</ErrorText>
                          )}
                        </Grid>
                        {!editMode && (
                          <Grid item xs={12} md={9} marginTop={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="info"
                                  checked={checked}
                                  onChange={handleChangeCheckbox}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label={
                                <Typography color={colors.strongGray}>
                                  {Strings.login.agreeTermsLabel}
                                  <Link href="#" color={colors.blue}>
                                    {Strings.login.termsOfServiceLabel}
                                  </Link>
                                  {Strings.common.and}
                                  <Link href="#" color={colors.blue}>
                                    {Strings.login.privacyPolicyLabel}
                                  </Link>
                                </Typography>
                              }
                            />
                          </Grid>
                        )}
                        <Grid item xs={10} md={8} marginTop={1}>
                          <LoadingButton
                            color="info"
                            className="w-100"
                            disabled={!isValid || !checked}
                            onClick={() => handleSubmit()}
                            variant="contained"
                            size="large"
                            loading={isLoading}
                          >
                            {editMode
                              ? Strings.userProfile.editAccountButton
                              : Strings.login.createAccount}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    )}
                  </Formik>
                </ContainerFormProfile>
              </>
            )}
          </ContainerForm>
        </Grid>
        <ToastContainer
          newestOnTop
          position="bottom-center"
          draggable
          theme="colored"
          autoClose={4000}
        />
        <Divider variant="fullWidth" />
      </Grid>
    </ContainerImage>
  );
};
