import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled(Box)`
  && {
    flex-grow: 1;
    border: 1px solid ${colors.lightGray};
    padding: 40px;
  }
`;

export const ButtonItem = styled(Button)`
  && {
    height: 70px;
    width: 70px;
    text-transform: none;
    color: ${colors.white};
    background-color: ${colors.green};
  }
`;

export const Name = styled.p`
  margin-top: 20px;
  color: ${colors.mediumGray};
`;

export const SharedContainer = styled(Box)`
  && {
    padding: 5px;
    border-radius: 30px;
    margin-left: 85%;
    width: 35%;
    margin-top: -35px;
    background-color: ${colors.lightGray};
  }
`;

export const SharedText = styled(Typography)`
  && {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 10px;
  }
`;
