import React, { useState, useEffect } from 'react';
import {
  FolderOpen,
  GridView,
  FormatListBulleted,
  Public,
  Tune
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Badge, Tooltip } from '@mui/material';
import { Header } from '../../components/header/header';
import Strings from '../../i18n';
import { ButtonIconStyled, TitleStyled } from '../devices/devicesStyles';
import {
  selectedCurrentViewType,
  setLocationsViewType
} from '../../store/slices/locations/location';
import { homeListTypes, viewTypeEnum } from '../../constants/commonEnums';
import { TooltipStyled } from './locationStyles';
import { LocationsList } from '../../templates/locations/locationsList/locationsList';
import { LocationsTemplate } from '../../templates/locations/locationsTemplate/locationsTemplate';
import { LocationsFolder } from '../../templates/locations/locationFolder/locationFolder';
import { LocationMaps } from '../../templates/locations/locationMap/locationMap';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { AddLocationBar } from '../../templates/locations/addLocationBar/addLocationBar';
import { AssignLocation } from '../../templates/locations/assignLocation/assignLocation';
import { AddFolderBar } from '../../templates/locations/addFolderBar/addFolderBar';
import { MultipleItemsDetails } from '../../templates/locations/locationsList/multipleItemsDetails/multipleItemsDetails';
import { ShareLocationBar } from '../../templates/locations/shareLocationBar/shareLocationBar';
import { TransferLocationBar } from '../../templates/locations/transferLocationBar/transferLocationBar';
import { MoveLocationBar } from '../../templates/locations/moveLocationBar/moveLocationBar';
import { useGetFoldersTreeMutation } from '../../services/device/deviceService';
import {
  selectedFolder,
  setSelectedFolder
} from '../../store/slices/folders/folders';
import { Folder } from '../../types/folders/folder';
import { searchFolderById } from '../../utils/folder';
import { selectCurrentUser } from '../../store/slices/login/login';
import {
  systemModuleEnum,
  systemModulePermissionsEnum
} from '../../constants/systemRolesEnums';
import { setPermissionsReadWriteLocations } from '../../store/slices/systemPermissions/systemPermissions';
import { ILocOrgItem, LocationByUser } from '../../types/location/location';
import {
  useGetLocationsByLoggedUserMutation,
  useGetLoggedUserLocationsAndOrganizationsMutation
} from '../../services/locations/locationsService';
import { OrganizationByUSer } from '../../types/organizations/organizations';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { LocationListByUserDetails } from '../../templates/locations/locationsList/locationsListByUserDetails/locationListByUserDetails';
import { AddNoteSideBar } from '../../components/commons/notes/addNoteSideBar/addNoteSideBar';
import { ContainerStyled } from '../../components/layout/main/mainStyles';
import { ShareLocationsWithTargets } from '../../templates/locations/shareLocationsWithTargets/shareLocationsWithTargets';
import { LocationListFilters } from '../../templates/locations/locationsList/locationListFilters/locationListFilters';

export const LocationsView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filtersCount = Array.from(queryParams.keys()).length;
  const [filters, setFilters] = useState({
    filterByLabel: queryParams.get('filterByLabel') || ''
  });
  const [filteredLocOrgsAndBA, setFilteredLocOrgsAndBA] = useState<
    ILocOrgItem[]
  >([]);
  const contextUser = useSelector(selectCurrentUser);
  const [searchValue, setSearchValue] = useState('');
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<ILocOrgItem[]>([]);
  const [getFolders, { isSuccess, data }] = useGetFoldersTreeMutation();

  const selectedFolders = useSelector(selectedFolder) as {
    folders: Folder[];
    idFolder: number;
  };
  const modulePermissions = contextUser.roleInfo.permissionsByModule?.find(
    (module) => module.systemModuleName === systemModuleEnum.locations
  );

  const permissionReadWrite = !!modulePermissions?.permissions.includes(
    systemModulePermissionsEnum.readWrite
  );

  dispatch(setPermissionsReadWriteLocations(permissionReadWrite));
  const [getLocationsByLoggedUser, { data: dataLocations }] =
    useGetLocationsByLoggedUserMutation();
  const [
    getLoggedUserLocAndOrg,
    {
      data: dataByUser,
      error: locationsByUserError,
      isLoading: locationsByUserLoading,
      isSuccess: locationsByUserSuccess
    }
  ] = useGetLoggedUserLocationsAndOrganizationsMutation();
  const currentView = useSelector(selectedCurrentViewType);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    +viewRightSideBarEnum.addLocation
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };
  const reloadTableLocationsAndCloseSideBar = () => {
    getLocationsByLoggedUser('');
    getLoggedUserLocAndOrg(null);
    setOpenRightSideBar(false);
  };

  const selectView = (view: viewTypeEnum) => {
    dispatch(
      setLocationsViewType({
        viewItems: view
      })
    );
  };

  useEffect(() => {
    getLocationsByLoggedUser('');
    getLoggedUserLocAndOrg(null);
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      if (selectedFolders.idFolder) {
        const findedFolder = searchFolderById(data, selectedFolders.idFolder);
        dispatch(
          setSelectedFolder({
            folders: findedFolder[0].folder.folders,
            idFolder: selectedFolders.idFolder
          })
        );
      } else {
        dispatch(setSelectedFolder({ folders: data, idFolder: null }));
      }
    }
  }, [isSuccess]);
  useEffect(() => {
    const filterByLabel = queryParams.get('filterByLabel') || '';
    setFilters({
      filterByLabel
    });
  }, [location.search]);

  useEffect(() => {
    if (dataByUser) {
      let filteredData = dataByUser;
      if (filters.filterByLabel) {
        const lowerCaseFilter = filters.filterByLabel.toLowerCase();
        filteredData = dataByUser.filter((locOrg) => {
          return (
            locOrg.name.toLowerCase().includes(lowerCaseFilter) ||
            locOrg.address.toLowerCase().includes(lowerCaseFilter)
          );
        });
      }
      setFilteredLocOrgsAndBA(filteredData);
    }
  }, [locationsByUserSuccess, filters]);

  const renderViewLocations = () => {
    switch (currentView) {
      case viewTypeEnum.mapView:
        return <LocationMaps locations={dataLocations} />;
      case viewTypeEnum.tileView:
        return <LocationsTemplate />;
      case viewTypeEnum.listView:
        return (
          <LocationsList
            searchValue={searchValue}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            locationsByUser={dataLocations}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBarForMethod={openRightSideBarForMethod}
            locOrgByUser={filteredLocOrgsAndBA ?? []}
          />
        );
      case viewTypeEnum.foldersView:
        return (
          <LocationsFolder
            sideBarOpenFor={sideBarOpenFor}
            openRightSideBarForMethod={openRightSideBarForMethod}
          />
        );
      default:
        return <LocationsTemplate />;
    }
  };

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addFolders:
        return (
          <AddFolderBar
            reloadTable={() => getFolders('')}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.addLocation:
        return (
          <AddLocationBar
            reloadTable={reloadTableLocationsAndCloseSideBar}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.assignLocation:
        return (
          <AssignLocation
            reloadTable={() => getFolders('')}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.transferLocation:
        return (
          <TransferLocationBar
            reloadTable={reloadTableLocationsAndCloseSideBar}
            selectedLocations={selectedLocations.map((loc) => loc.id)}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocation:
        return (
          <ShareLocationBar
            reloadTable={reloadTableLocationsAndCloseSideBar}
            selectedLocations={selectedLocations.map((loc) => loc.id)}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocationsWithTargets:
        return (
          <ShareLocationsWithTargets
            selectedLocations={selectedLocations.map((loc) => loc.id)}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.locationDetails:
        return (
          <LocationListByUserDetails
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={() => getLocationsByLoggedUser('')}
            locationOrg={selectedLocations[0] ?? []}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.moveLocation:
        return (
          <MoveLocationBar
            reloadTable={() => getLocationsByLoggedUser('')}
            idLocations={selectedLocations.map((loc) => loc.id)}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.multipleLocationsDetails:
        return (
          <MultipleItemsDetails
            reloadTable={() => getLocationsByLoggedUser('')}
            openRightSideBarForMethod={openRightSideBarForMethod}
            selectedItems={selectedLocations.map((loc) => ({
              id: loc.id,
              type: loc.type as homeListTypes
            }))}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.addNote:
        return (
          <AddNoteSideBar
            closeSideBar={() => setOpenRightSideBar(false)}
            targetId={selectedLocations[0].id}
            type="location"
          />
        );
      case viewRightSideBarEnum.userLocationsAndOrgFilters:
        return (
          <LocationListFilters
            reloadTable={() => getLocationsByLoggedUser('')}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
          />
        );
      default:
        return (
          <AddLocationBar
            reloadTable={() => getLocationsByLoggedUser('')}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
    }
  };

  return (
    <>
      <Header>
        <ContainerStyled>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left" />
            <div className="col-3 text-center">
              <TitleStyled align="center">
                {Strings.locations.title}
              </TitleStyled>
            </div>
            <div className="col-8 col-sm-4 text-right">
              {currentView !== viewTypeEnum.foldersView &&
                permissionReadWrite && (
                  <Tooltip
                    title={Strings.locations.addLocation}
                    placement="top"
                    arrow
                    componentsProps={TooltipStyled}
                  >
                    <ButtonIconStyled
                      size="small"
                      onClick={() => {
                        openRightSideBarForMethod(
                          viewRightSideBarEnum.addLocation
                        );
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </ButtonIconStyled>
                  </Tooltip>
                )}
              <ButtonIconStyled
                disabled
                className={
                  viewTypeEnum.foldersView === currentView ? 'selected' : ''
                }
                size="small"
                onClick={() => {
                  selectView(viewTypeEnum.foldersView);
                }}
              >
                <FolderOpen />
              </ButtonIconStyled>

              <ButtonIconStyled
                disabled
                size="small"
                className={
                  viewTypeEnum.tileView === currentView ? 'selected' : ''
                }
                onClick={() => {
                  selectView(viewTypeEnum.tileView);
                }}
              >
                <GridView />
              </ButtonIconStyled>
              <ButtonIconStyled
                size="small"
                className={
                  viewTypeEnum.listView === currentView ? 'selected' : ''
                }
                onClick={() => {
                  selectView(viewTypeEnum.listView);
                }}
              >
                <FormatListBulleted />
              </ButtonIconStyled>
              <ButtonIconStyled
                size="small"
                className={
                  viewTypeEnum.mapView === currentView ? 'selected' : ''
                }
                onClick={() => {
                  selectView(viewTypeEnum.mapView);
                }}
              >
                <Public />
              </ButtonIconStyled>
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.userLocationsAndOrgFilters
                  );
                }}
              >
                <Badge
                  badgeContent={filtersCount}
                  color="warning"
                  invisible={filtersCount === 0}
                >
                  <Tune />
                </Badge>
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openRightSideBar}
                selectedItems={selectedLocations}
                singleItemMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.locationDetails
                  );
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.multipleLocationsDetails
                  );
                }}
              />
            </div>
          </div>
        </ContainerStyled>
      </Header>
      {renderViewLocations()}
      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
