/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
import { TIME_TYPE_ENUM, dayOfWeeksInitial } from '../constants/eventEnums';
import {
  IAddEvent,
  IDateType,
  IHoliday,
  ITimeType
} from '../types/events/eventsInterface';

export class EventUtils {
  static binaryToWeekdays(binary: string) {
    if (binary === '1111111') return 'All Week';
    const daysOfWeek = dayOfWeeksInitial;
    let result = '';
    for (let i = 0; i < binary.length; i++) {
      if (binary[i] === '1') {
        result += daysOfWeek[i] + ' ';
      } else {
        result += '- ';
      }
    }

    return result.trim();
  }

  static weekDaysBinToDaysOfWeekNumber(weekDays: number) {
    return weekDays.toString(2).padStart(7, '0');
  }

  static binaryToDecimal(weekDays: number): number {
    return parseInt(weekDays.toString(), 2);
  }

  static updateDaysOfWeek(weekDays: number): string {
    return weekDays.toString(2);
  }

  static formatOffset(offsetMinutes: number): string {
    return `${offsetMinutes > 0 ? '+' : ''}${
      offsetMinutes !== 0 ? offsetMinutes.toString() : ''
    }`;
  }

  static getEventTime(timeType: ITimeType): string {
    switch (timeType.timeType) {
      case TIME_TYPE_ENUM.ABSOLUTE:
        return `${timeType.hour!.toString().padStart(2, '0')}:${timeType
          .minute!.toString()
          .padStart(2, '0')}`;
      case TIME_TYPE_ENUM.SUNRISE:
        return `SUNRISE ${this.formatOffset(timeType.offsetMinutes!)}`;
      case TIME_TYPE_ENUM.SUNSET:
        return `SUNSET ${this.formatOffset(timeType.offsetMinutes!)}`;
      default:
        return '00:00';
    }
  }

  static holidays: IHoliday[] = [
    { index: 1, name: `New Year's Day` },
    { index: 2, name: 'Martin Luther King Jr. Day' },
    { index: 3, name: `President's Day` },
    { index: 4, name: `Saint Patrick's Day` },
    { index: 5, name: 'Easter Sunday' },
    { index: 6, name: `Mother's Day` },
    { index: 7, name: 'Memorial Day' },
    { index: 8, name: `Father's Day` },
    { index: 9, name: 'Independence Day' },
    { index: 10, name: 'Labor Day' },
    { index: 11, name: 'Columbus Day' },
    { index: 12, name: 'Pink Ribbon Day' },
    { index: 13, name: `Veteran's Day` },
    { index: 14, name: 'Thanksgiving Day' },
    { index: 15, name: 'Christmas Day' }
  ];

  static months: { id: number; name: string }[] = [
    { id: 1, name: 'JANUARY' },
    { id: 2, name: 'FEBRUARY' },
    { id: 3, name: 'MARCH' },
    { id: 4, name: 'APRIL' },
    { id: 5, name: 'MAY' },
    { id: 6, name: 'JUNE' },
    { id: 7, name: 'JULY' },
    { id: 8, name: 'AUGUST' },
    { id: 9, name: 'SEPTEMBER' },
    { id: 10, name: 'OCTOBER' },
    { id: 11, name: 'NOVEMBER' },
    { id: 12, name: 'DECEMBER' }
  ];

  static getMonthDays(month: number) {
    let maxDays = 28;
    switch (month) {
      case 1:
        maxDays = 31;
        break;
      case 2:
        maxDays = 28;
        break;
      case 3:
        maxDays = 31;
        break;
      case 4:
        maxDays = 30;
        break;
      case 5:
        maxDays = 31;
        break;
      case 6:
        maxDays = 30;
        break;
      case 7:
        maxDays = 31;
        break;
      case 8:
        maxDays = 31;
        break;
      case 9:
        maxDays = 30;
        break;
      case 10:
        maxDays = 31;
        break;
      case 11:
        maxDays = 30;
        break;
      case 12:
        maxDays = 31;
        break;
      default:
        maxDays = 28;
        break;
    }

    return maxDays;
  }

  static getEventDate(schedule: IDateType) {
    switch (schedule.dateType) {
      case 1:
        return 'ALL YEAR';
      case 2:
        return `${this.months[schedule.startMonth! - 1].name} ${
          schedule.startDay
        } - ${this.months[schedule.endMonth! - 1].name} ${schedule.endDay}`;
      case 3:
        return `${
          this.holidays[schedule.holidayStartIndex! - 1].name
        } ${this.formatOffset(schedule.holidayStartOffset!)} TO ${
          this.holidays[schedule.holidayEndIndex! - 1].name
        } ${this.formatOffset(schedule.holidayEndOffset!)}`;
      default:
        return 'ALL YEAR';
    }
  }

  static daysOfWeekStringRepresentationArray = [
    { value: 'MON', position: 64 },
    { value: 'TUE', position: 32 },
    { value: 'WED', position: 16 },
    { value: 'THU', position: 8 },
    { value: 'FRI', position: 4 },
    { value: 'SAT', position: 2 },
    { value: 'SUN', position: 1 }
  ];
}

export const eventDefaultSettings: IAddEvent = {
  name: '',
  dateType: 1,
  weekDays: 127,
  timeType: 1,
  hour: 0,
  minute: 0,
  offsetMinutes: 0,
  sceneId: 0,
  startMonth: 0,
  startDay: 0,
  endMonth: 0,
  endDay: 0,
  holidayStartIndex: 0,
  holidayStartOffset: 0,
  holidayEndIndex: 0,
  holidayEndOffset: 0,
  locationId: 0,
  daysOfWeek: EventUtils.updateDaysOfWeek(127),
  expiring: false,
  eventId: 0,
  eventType: 0,
  isHighPrecedence: 0,
  lightBrightnessId: 0,
  lightColorId: 0,
  targets: [],
  lightingStatusId: 0,
  patternSpeedId: 0,
  sequenceId: 0,
  sparkleDuration: 0,
  status: 0,
  whiteId: 0
};
