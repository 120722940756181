import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const sliceOrganization = createSlice({
  name: 'organization',
  initialState: {
    nameOrganization: ''
  } as {
    nameOrganization: string;
  },
  reducers: {
    setNameOrganization(state, action: PayloadAction<string>) {
      state.nameOrganization = action.payload;
    }
  }
});
export default sliceOrganization.reducer;
export const selectedNameOrganization = (state: RootState) =>
  state.organization.nameOrganization;

export const { setNameOrganization } = sliceOrganization.actions;
