import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { PrimeReactProvider } from 'primereact/api';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { theme } from './theme/theme';
import { Main } from './components/layout/main/main';
import './App.css';
import initFontAwesome from './utils/initFontAwesome';
import { store } from './store/store';
import { Loading } from './components/Loading';

initFontAwesome();

export const App = () => {
  const persistor = persistStore(store);

  return (
    <ThemeProvider theme={theme}>
      <PrimeReactProvider value={{ inputStyle: 'filled', ripple: true }}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <CssBaseline />
          <Main />
        </PersistGate>
      </PrimeReactProvider>
    </ThemeProvider>
  );
};
