import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { ButtonWithRedirect } from '../../components/commons/ButtonWithRedirect/ButtonWithRedirect';
import { colors } from '../../constants/colors';
import { useGetApplicationsByLocationMutation } from '../../services/application/applicationService';
import { DynamicImage } from './applicationDetails/dynamicImage';
import Strings from '../../i18n';

export const ApplicationsTemplate = () => {
  const { locationId } = useParams();
  const [getApplicationsByLocation, { isSuccess, data }] =
    useGetApplicationsByLocationMutation();

  useEffect(() => {
    if (locationId) {
      getApplicationsByLocation(+locationId);
    }
  }, [locationId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        <Grid item xs={10} md={8}>
          <Box
            sx={{ flexGrow: 1, border: `1px solid ${colors.lightGray}` }}
            marginTop={3}
          >
            {data && (
              <Grid container spacing={2} marginTop={2} alignItems="end">
                {data.map((element) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2.3}
                    key={element.name}
                    textAlign="center"
                  >
                    <ButtonWithRedirect
                      icon={
                        <DynamicImage
                          key={element.id}
                          id={element.id}
                          name={element.name}
                          identifier={element.identifier}
                        />
                      }
                      text={element.name}
                      url={`/locations/${locationId}/applications/${element.identifier}`}
                      enabled={element.enabled}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <Outlet />
    </Box>
  );
};
