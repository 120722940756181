import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FolderItem from '../../../components/folderItem/folderItem';
import {
  selectedFolder,
  setSelectedFolder
} from '../../../store/slices/folders/folders';
import { Folder } from '../../../types/folders/folder';
import { LocationFolderProps } from './locationFolderProps';
import { searchFolderById } from '../../../utils/folder';
import { useGetFoldersTreeMutation } from '../../../services/device/deviceService';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { selectSystemLocationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';

const addIcon = require('../../../assets/add.png');

export const LocationsFolder = ({
  openRightSideBarForMethod
}: LocationFolderProps) => {
  const dispatch = useDispatch();
  const permissionReadWrite = useSelector(selectSystemLocationsReadWrite);
  const selectedFolders = useSelector(selectedFolder) as {
    folders: Folder[];
    idFolder: number;
  };
  const [getFolders, { isLoading, isSuccess, data }] =
    useGetFoldersTreeMutation();
  const addFolder = {
    name: 'addFolder',
    image: addIcon,
    action: () => {
      if (permissionReadWrite) {
        openRightSideBarForMethod(viewRightSideBarEnum.addFolders);
      }
    }
  };

  useEffect(() => {
    getFolders('');
    return () => {
      dispatch(setSelectedFolder({ folders: null, idFolder: null }));
    };
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      if (selectedFolders.idFolder) {
        const findedFolder = searchFolderById(data, selectedFolders.idFolder);
        dispatch(
          setSelectedFolder({
            folders: findedFolder[0].folder.folders,
            idFolder: selectedFolders.idFolder
          })
        );
      } else {
        dispatch(setSelectedFolder({ folders: data, idFolder: null }));
      }
    }
  }, [isSuccess]);
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} md={8}>
          <Grid container spacing={2} marginTop={2}>
            {selectedFolders.folders &&
              selectedFolders.folders.map((item: any) => {
                return (
                  <Grid item xs={6} md={4} textAlign="center" key={item.id}>
                    <FolderItem
                      item={item}
                      openRightSideBarForMethod={openRightSideBarForMethod}
                    />
                  </Grid>
                );
              })}
            <Grid item xs={6} md={4} textAlign="center">
              <FolderItem
                item={addFolder}
                openRightSideBarForMethod={openRightSideBarForMethod}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
