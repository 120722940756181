import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useAddDeviceMutation,
  useGetAllProductNamesMutation
} from '../../../services/device/deviceService';
import { ButtonStyled } from './addDeviceStyles';
import Strings from '../../../i18n';
import { AddDeviceBarProps } from './addDeviceBarProps';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';

export const AddDeviceBar = ({
  reloadTable,
  setOpenRightSideBar,
  openRightSideBar,
  clearSelectedDevices
}: AddDeviceBarProps) => {
  const [macAddress, setMacAddress] = useState('');
  const [productType, setProductType] = useState<number>(1);
  const [addDevice, { isLoading, isSuccess }] = useAddDeviceMutation();
  const [getProducts, { data, isSuccess: isSuccessGetProduct }] =
    useGetAllProductNamesMutation();
  const handleChangeRole = (event: any) => {
    setProductType(event.target.value.toString());
  };

  useEffect(() => {
    getProducts('');
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.serverAccess.devices.successAddDevice);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccess]);

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.serverAccess.devices.addDevice}
        </StyledTitleSideBar>
        <SelectItemMethod
          item={{
            label: Strings.devices.productType,
            currentValue: productType,
            options: data || [],
            onChange: handleChangeRole,
            placeholder: Strings.devices.productType
          }}
        />
        <SideBarEditablePropertyItem
          item={{
            label: Strings.devices.deviceId,
            type: 'inputText',
            currentValue: macAddress,
            placeholder: Strings.devices.enterDeviceId,
            onChange: (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setMacAddress(e.target.value.toString())
          }}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
            clearSelectedDevices();
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={productType === undefined || macAddress === ''}
          onClick={() => {
            if (productType !== undefined) {
              addDevice({ macAddress, productType });
            }
          }}
        >
          {Strings.common.add}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
