import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { StyledBoxTable } from '../../components/layout/main/mainStyles';
import { CursorStyleDataGrid } from '../../constants/globalStyles';

export const MyFilesTemplate = () => {
  const data = [
    {
      id: 0,
      name: 'SB600 Templates',
      lastUpdate: new Date()
    },
    {
      id: 1,
      name: '195 Templates',
      lastUpdate: new Date()
    },
    { id: 2, name: '225 Templates', lastUpdate: new Date() }
  ];
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'lastUpdate',
      headerName: 'Last Update',
      width: 300
    }
  ];

  return (
    <StyledBoxTable>
      <DataGrid
        sx={CursorStyleDataGrid}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[5, 10]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 }
          }
        }}
      />
    </StyledBoxTable>
  );
};
