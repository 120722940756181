import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  FlaggedLocation,
  ILocOrgItem,
  IShareLocationWithTargets,
  IShareLocationWithTargetsResponse,
  Location,
  LocationByUser,
  LocationDetails
} from '../../types/location/location';
import { PropsDataResponse } from '../../types/apiCommonResponse/apiCommonResponse';
import customFetchBase from '../expiredToken/expiredToken';
import { UsersByLocation } from '../../types/users';
import { OrganizationByUSer } from '../../types/organizations/organizations';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiLocations = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getLocations: builder.mutation<Location[], {}>({
      query: () => ({
        url: 'api/Location/AllLocations',
        method: 'GET'
      })
    }),
    getFolders: builder.mutation<PropsDataResponse<any>, {}>({
      query: () => ({
        url: 'api/Location/UserFolderTrees',
        method: 'GET'
      })
    }),
    addFolders: builder.mutation<PropsDataResponse<any>, { name: string }>({
      query: (data) => ({
        url: '/api/Location/Folder',
        method: 'POST',
        body: {
          id: 0,
          name: data.name,
          parentId: 0,
          enabled: true,
          lastUpdate: '2023-11-01T21:02:19.957Z',
          folders: ['string'],
          locations: [
            {
              id: 0,
              folderId: 0,
              locationId: 0,
              enabled: true,
              lastUpdate: '2023-11-01T21:02:19.957Z',
              locationDetails: {
                id: 0,
                description: 'string',
                customerId: 0,
                timeZoneId: 0,
                trimNonProductionTime: true,
                enabled: true,
                subscriptionId: 0
              }
            }
          ]
        }
      })
    }),
    getLocationsUsersByLocationId: builder.mutation<UsersByLocation[], {}>({
      query: (locationId: number) => ({
        url: `api/Location/GetLocationUsersByLocationId/${locationId}`,
        method: 'GET'
      })
    }),
    addUserToLocation: builder.mutation<
      {},
      {
        UserId: number;
        LocationId: number;
        LocationAlias: string;
        LocationRoleId: number;
      }
    >({
      query: (data) => ({
        url: '/api/Location/ShareLocation',
        method: 'POST',
        body: data
      })
    }),
    deleteUserFromLocation: builder.mutation<{}, {}>({
      query: (userLocationId: number) => ({
        url: `api/Location/DeleteLocationUser/${userLocationId}`,
        method: 'DELETE'
      })
    }),
    getLocationsByLoggedUser: builder.mutation<LocationByUser[], {}>({
      query: () => ({
        url: `api/Location/GetLoggedUserLocations`,
        method: 'GET'
      })
    }),
    getLocationDetailsByLocationId: builder.mutation<LocationDetails, number>({
      query: (id) => ({
        url: `/api/Location/${id}`,
        method: 'GET'
      })
    }),
    getUserLastLocations: builder.mutation<
      {
        id: number;
        name: string;
      }[],
      { userId: number | null; numLocations: number }
    >({
      query: (payload) => ({
        url: `/api/Location/UserLastSelectedLocation/${payload.userId}/${payload.numLocations}`,
        method: 'GET'
      })
    }),
    postUserSelectLocation: builder.mutation<
      null,
      { userId: number | null; locationId: number | null }
    >({
      query: (payload) => ({
        url: `/api/Location/UserSelectedLocation`,
        method: 'POST',
        body: payload
      })
    }),
    deleteLocation: builder.mutation<null, number[]>({
      query: (locationIds) => ({
        url: `api/Location?ids=${locationIds.join(',')}`,
        method: `DELETE`
      })
    }),
    shareLocationWithTargets: builder.mutation<
      IShareLocationWithTargetsResponse[],
      IShareLocationWithTargets
    >({
      query: (data) => ({
        url: `api/Location/ShareLocationUsingTargets`,
        method: 'POST',
        body: data
      })
    }),
    getFlaggedLocations: builder.mutation<FlaggedLocation[], void>({
      query: () => ({
        url: `/api/Location/FlaggedLocations`,
        method: 'GET'
      })
    }),
    getLoggedUserLocationsAndOrganizations: builder.mutation<
      ILocOrgItem[],
      null
    >({
      query: () => ({
        url: `api/Location/GetLoggedUserLocationsAndOrganizations`,
        method: 'GET'
      })
    }),
    getLocationsAndOrganizationsByUser: builder.mutation<ILocOrgItem[], number>(
      {
        query: (userId) => ({
          url: `api/Location/GetLocationsAndOrganizationsByUser`,
          params: {
            userId
          },
          method: 'GET'
        })
      }
    ),
    updateLocationLatLong: builder.mutation<
      null,
      { locationId: number; lat: number; lon: number }
    >({
      query: (data) => ({
        url: `api/Location/${data.locationId}/UpdateGPS`,
        method: 'PUT',
        body: {
          latitude: data.lat,
          longitude: data.lon
        }
      })
    })
  })
});

export const {
  useGetLocationsMutation,
  useGetFoldersMutation,
  useAddFoldersMutation,
  useGetLocationsUsersByLocationIdMutation,
  useAddUserToLocationMutation,
  useDeleteUserFromLocationMutation,
  useGetLocationsByLoggedUserMutation,
  useGetLocationDetailsByLocationIdMutation,
  useGetUserLastLocationsMutation,
  usePostUserSelectLocationMutation,
  useDeleteLocationMutation,
  useGetFlaggedLocationsMutation,
  useGetLoggedUserLocationsAndOrganizationsMutation,
  useShareLocationWithTargetsMutation,
  useUpdateLocationLatLongMutation,
  useGetLocationsAndOrganizationsByUserMutation
} = apiLocations;
