import React from 'react';
import { Search, Tune, ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header/header';
import { MarginComponent } from '../../components/marginComponent/marginComponent';
import { ButtonIconStyled, TitleStyled } from '../devices/devicesStyles';
import { HealthTemplate } from '../../templates/healthTemplate/healthTemplate';
import Strings from '../../i18n';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';

export const HealthMonitorView = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.serviceHealth}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled size="small">
                <Tune />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <HealthTemplate />
    </>
  );
};
