import React, { useEffect } from 'react';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import { Badge } from 'primereact/badge';
import { Avatar, Divider, Typography } from '@mui/material';
import {
  CardSubtitle,
  FlaggedLocationsDetails,
  FlaggedLocationsOwnerName
} from '../dashboardStyles';
import Strings from '../../../i18n';
import { severityEnum } from '../../../constants/commonEnums';
import { useGetFlaggedLocationsMutation } from '../../../services/locations/locationsService';
import { FlaggedLocation } from '../../../types/location/location';

export const FlaggedLocationsComponent = () => {
  const [
    getFlaggedLocations,
    { data: flaggedLocationsData, isSuccess: isSuccessFlaggedLocations }
  ] = useGetFlaggedLocationsMutation();
  useEffect(() => {
    getFlaggedLocations();
  }, []);

  const flaggedLocationsTemplate = (item: FlaggedLocation) => {
    return (
      <div className="col-12 mt-3">
        <div className="d-flex align-items-center justify-content-between overflow-auto">
          <div className="d-flex align-items-center">
            <Avatar sx={{ width: 40, height: 40, marginRight: '1rem' }}>
              {item.locationName[0].toUpperCase()}
            </Avatar>
            <div className="flex-1">
              <h5 className="mb-0">{item.locationName}</h5>
              <FlaggedLocationsOwnerName>
                {item.ownerName}
              </FlaggedLocationsOwnerName>
            </div>
          </div>
          <FlaggedLocationsDetails>
            <Badge
              value={item.isOnline ? 'Online' : 'Offline'}
              severity={item.isOnline ? 'success' : 'danger'}
              className="mr-2 mb-2 md:mb-0"
            />
            <span className="text-sm">
              Total Controllers: {item.numDevices}
            </span>
          </FlaggedLocationsDetails>
        </div>
        <Divider />
      </div>
    );
  };
  return (
    <Card
      title={
        <>
          <Typography
            variant="h6"
            marginBottom={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {Strings.dashboard.flaggedLocations}
            <CardSubtitle
              className="text-sm text-gray-500 font-bold ml-2"
              severity={
                flaggedLocationsData?.length && flaggedLocationsData.length >= 5
                  ? severityEnum.high
                  : severityEnum.medium
              }
            >
              {Strings.dashboard.watchList}: {flaggedLocationsData?.length}
            </CardSubtitle>
          </Typography>
          <Divider />
        </>
      }
    >
      <DataView
        value={flaggedLocationsData}
        itemTemplate={flaggedLocationsTemplate}
      />
    </Card>
  );
};
