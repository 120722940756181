import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { ErrorText } from '../../../profile/editProfileSidebar/editProfileSidebarStyles';
import { sendStaticOrSingletonValidator } from '../../../../utils/validators';
import {
  usePostSingletonMethodMutation,
  usePostStaticMethodMutation
} from '../../../../services/device/deviceService';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import { IPostSingletonStaticMethod } from '../../../../types/Device/DeviceInterfaces';
import { SelectItemMethod } from '../../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';

export const AddDeviceMethodFromTemplate = ({
  closeBar,
  deviceId,
  reloadTable
}: {
  closeBar: () => void;
  deviceId: string;
  reloadTable: () => void;
}) => {
  const [checked, setChecked] = useState(true);

  const [selectedMethodType, setSelectedMethodType] = useState<number>(
    viewRightSideBarEnum.singletonDevice
  );

  const methodsOptions = [
    {
      name: Strings.serverAccess.devices.singletonLabel,
      id: viewRightSideBarEnum.singletonDevice
    },
    {
      name: Strings.serverAccess.devices.staticLabel,
      id: viewRightSideBarEnum.staticDevice
    }
  ];

  const [
    staticMethod,
    { isSuccess: isSuccessStatic, data: dataStatic, isLoading: isLoadingStatic }
  ] = usePostStaticMethodMutation();

  const [
    singletonMethod,
    {
      isSuccess: isSuccessSingleton,
      data: dataSingleton,
      isLoading: isLoadingSingleton
    }
  ] = usePostSingletonMethodMutation();

  useEffect(() => {
    if (isSuccessSingleton) {
      toast.success(Strings.devices.singletonSentSuccess);
      closeBar();
      reloadTable();
    }
    if (isSuccessStatic) {
      toast.success(Strings.devices.staticSentSuccess);
      closeBar();
      reloadTable();
    }
  }, [isSuccessSingleton, isSuccessStatic]);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const sendInfo = (data: { methodName: string; methodValue: string }) => {
    if (selectedMethodType === viewRightSideBarEnum.singletonDevice) {
      const payload = {
        methodName: data.methodName,
        methodValue: data.methodValue,
        deviceId,
        ackRequired: checked
      };
      singletonMethod([payload]);
    }
    if (selectedMethodType === viewRightSideBarEnum.staticDevice) {
      const payload: IPostSingletonStaticMethod = {
        methodName: data.methodName,
        methodValue: data.methodValue,
        deviceId,
        ackRequired: checked
      };
      staticMethod([payload]);
    }
  };

  const handleChangeRole = (event: any) => {
    setSelectedMethodType(event.target.value);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={sendStaticOrSingletonValidator}
        initialValues={{
          methodName: '',
          methodValue: ''
        }}
        onSubmit={(values) => {
          sendInfo({
            methodName: '',
            methodValue: values.methodValue
          });
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          isValid,
          setFieldTouched,
          errors,
          touched
        }) => (
          <>
            <StyledContainerItems>
              <StyledTitleSideBar>
                {Strings.serverAccess.devices.addMethodLabel}
              </StyledTitleSideBar>
              <SelectItemMethod
                item={{
                  label: Strings.devices.methodType,
                  currentValue: selectedMethodType,
                  options: methodsOptions || [],
                  onChange: handleChangeRole,
                  placeholder: Strings.devices.productType
                }}
              />
              <SideBarEditablePropertyItem
                item={{
                  label: Strings.serverAccess.devices.methodValueLabel,
                  type: 'inputText',
                  currentValue: values.methodValue,
                  nameAndId: 'methodValue',
                  onChange: handleChange('methodValue'),
                  onBlur: () => setFieldTouched('methodValue')
                }}
              />
              {touched.methodValue && errors.methodValue && (
                <ErrorText>{errors.methodValue}</ErrorText>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      checked={checked}
                      onChange={handleChangeCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={Strings.serverAccess.devices.ackRequired}
                />
              </Grid>
            </StyledContainerItems>
            <StyledContainerFooterSideBar>
              <button
                type="button"
                className="btn-cancel"
                onClick={() => {
                  closeBar();
                }}
              >
                {Strings.common.cancel}
              </button>
              <LoadingButton
                color="info"
                disabled={!isValid}
                onClick={() => handleSubmit()}
                variant="contained"
                size="large"
                loading={isLoadingSingleton || isLoadingStatic}
              >
                {Strings.common.send}
              </LoadingButton>
            </StyledContainerFooterSideBar>
          </>
        )}
      </Formik>
    </div>
  );
};
