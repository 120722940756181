import React from 'react';
import { Divider, Grid, MenuItem, Menu } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
  ButtonIconStyled,
  Container,
  Name,
  paperMenuStyle
} from './folderItemStyles';
import { FolderItemProps } from './folderItemProps';
import {
  setIdFolder,
  setSelectedFolder
} from '../../store/slices/folders/folders';
import { Folder } from '../../types/folders/folder';
import { colors } from '../../constants/colors';
import Strings from '../../i18n';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { selectSystemLocationsReadWrite } from '../../store/slices/systemPermissions/systemPermissions';

const folder = require('../../assets/folders.png');

const FolderItem = ({ item, openRightSideBarForMethod }: FolderItemProps) => {
  const permissionReadWrite = useSelector(selectSystemLocationsReadWrite);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container
        onClick={
          'action' in item
            ? item.action
            : (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                dispatch(
                  setSelectedFolder(
                    'folders' in item
                      ? { folders: item.folders, idFolder: item.id }
                      : { folders: null, idFolder: null }
                  )
                );
              }
        }
      >
        {'folders' in item && (
          <Grid
            container
            justifyContent="flex-end"
            marginLeft={6}
            marginTop={-6}
          >
            <ButtonIconStyled
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (permissionReadWrite) {
                  e.stopPropagation();
                  dispatch(setIdFolder({ idFolderToAssignLocation: item.id }));
                  handleClick(e);
                }
              }}
            >
              <SettingsApplicationsIcon htmlColor={colors.mediumGray} />
            </ButtonIconStyled>
          </Grid>
        )}
        <Grid>
          <img
            src={'image' in item ? item.image : folder}
            alt=""
            width={40}
            height={40}
          />
        </Grid>
        <Name>{item.name}</Name>
        <Divider />
      </Container>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperMenuStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() =>
            openRightSideBarForMethod(viewRightSideBarEnum.assignLocation)
          }
        >
          {Strings.locations.assignLocation}
        </MenuItem>
      </Menu>
    </>
  );
};

export default FolderItem;
