import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../../constants/colors';

export const StyledContainerButtonWithRedirect = styled.div`
  margin: 10px 0;
`;

export const StyledAnchor = styled(Link)<{ isActive?: boolean }>`
  color: black;
  width: 100%;
  text-decoration: none;
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};

  &&:hover {
    color: ${colors.black};
  }
`;

export const StyledIcon = styled.span`
  svg {
    font-size: 2rem;
    color: ${colors.strongGray};
    &:hover {
      svg {
        transition: all 0.3s;
        font-size: 2rem;
        color: ${colors.black};
      }
    }
  }
`;
