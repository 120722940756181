import { ArrowBackIos, Save } from '@mui/icons-material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Header } from '../../../components/header/header';
import {
  ErrorText,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../locations/allLocationsList/allLocationListStyles';
import { TooltipStyled } from '../../organizations/organizationsLocs/organizationsLocStyles';
import {
  useAddFirmwareVersionMutation,
  useEditFirmwareVersionMutation,
  useGetDetailsFirmwareFileMutation,
  useGetFirmwareVersionDetailsMutation,
  useGetUpgradeTypesMutation,
  usePostEditFileMutation
} from '../../../services/firmwareVersions/firmwareVersionsService';
import {
  addEditFirmwareFileValidator,
  addEditFirmwareVersionValidator
} from '../../../utils/validators';
import { StyledInputLabel } from '../versionTemplateStyles';
import { convertToFormData } from '../../../utils/locations';
import { useGetAllProductNamesMutation } from '../../../services/device/deviceService';

export const AddEditUploadedFile = () => {
  const navigate = useNavigate();
  const { versionId, fileId } = useParams();
  const [useCustomUrl, setUseCustomUrl] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const items = [
    { name: 'Development', value: false },
    { name: 'Production', value: true }
  ];
  const [fileUpload, setFileUpload] = useState<{
    firmwareVersionId: number;
    controllerTypeId: number | null;
    firmwareUpgradeTypeId: number | null;
    otaFile: File | string | null;
    customUrl: string;
    fileUrl?: string | null;
  }>({
    firmwareVersionId: 0,
    controllerTypeId: null,
    firmwareUpgradeTypeId: null,
    otaFile: null,
    customUrl: ''
  });

  const [getFIleDetails, { isSuccess: successGetData, data: dataFile }] =
    useGetDetailsFirmwareFileMutation();

  const [
    getUpgradeTypes,
    { isSuccess: successGetUpgradeTypes, data: dataUpgrade }
  ] = useGetUpgradeTypesMutation();

  const [
    getControllerTypes,
    { isSuccess: successGetControllerTypes, data: dataControllerTypes }
  ] = useGetAllProductNamesMutation();
  const [postFile, { isSuccess: successPost, data: successPostData }] =
    usePostEditFileMutation();

  const [editFirmwareVersion, { isSuccess: successEdit, data: dataEdit }] =
    useEditFirmwareVersionMutation();

  useEffect(() => {
    getUpgradeTypes();
    getControllerTypes('');
    if (fileId) {
      getFIleDetails(fileId);
      setIsEditing(true);
    }
  }, [fileId, getFIleDetails]);

  useEffect(() => {
    if (successGetData && dataFile) {
      setFileUpload({
        firmwareVersionId: dataFile.data.firmwareVersionId,
        controllerTypeId: dataFile.data.controllerTypeId,
        firmwareUpgradeTypeId: dataFile.data.firmwareUpgradeTypeId,
        otaFile: dataFile.data.otafile,
        customUrl: dataFile.data.customUrl,
        fileUrl: dataFile.data.fileUrl
      });
    }
  }, [successGetData, dataFile]);

  const save = () => {
    document.getElementById('submitForm')?.click();
  };

  const onUpload = (event: any) => {
    const file = event.target.files[0];
    setFileUpload({ ...fileUpload, otaFile: file });
  };

  const sendInfo = (values: any) => {
    if (!isEditing) {
      const formData = convertToFormData(values);
      formData.append('otaFile', fileUpload.otaFile as File);
      postFile(formData);
    } else {
      const payload = {
        ...values,
        id: versionId
      };
      const formData = convertToFormData(payload);
      if (fileId) {
        postFile(formData);
      }
    }
  };

  useEffect(() => {
    if (successPost) {
      toast.success(Strings.versions.successAddFirmwareVersion);
      navigate(`/firmwareVersions/${versionId}/files`);
    }
  }, [successPost]);

  useEffect(() => {
    if (successEdit && dataEdit) {
      toast.success(Strings.versions.successEditFirmwareVersion);
      navigate(`/firmwareVersions/${versionId}/files`);
    }
  }, [successEdit]);

  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.versions}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip
                title={Strings.common.save}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={save}>
                  <Save />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <div className="row justify-content-center">
        <div className="col-11 col-10">
          <Formik
            enableReinitialize
            validationSchema={addEditFirmwareFileValidator}
            initialValues={{
              controllerTypeId: fileUpload.controllerTypeId,
              firmwareUpgradeTypeId: fileUpload.firmwareUpgradeTypeId,
              customUrl: fileUpload.customUrl,
              firmwareVersionId: versionId
            }}
            onSubmit={(values) => {
              sendInfo(values);
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isValid,
              setFieldTouched,
              setFieldValue,
              errors,
              touched
            }) => (
              <div>
                <div>
                  <StyledInputLabel>
                    {Strings.versions.upgradeType}
                  </StyledInputLabel>
                  <Dropdown
                    value={values.firmwareUpgradeTypeId}
                    onChange={(e) => {
                      setFieldValue('firmwareUpgradeTypeId', e.value);
                    }}
                    options={dataUpgrade?.data ?? []}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a value"
                    className="w-100"
                    id="firmwareUpgradeTypeId"
                    onBlur={() => setFieldTouched('firmwareUpgradeTypeId')}
                  />
                  {errors.firmwareUpgradeTypeId &&
                    touched.firmwareUpgradeTypeId && (
                      <ErrorText>{errors.firmwareUpgradeTypeId}</ErrorText>
                    )}
                </div>
                <div>
                  <StyledInputLabel>
                    {Strings.versions.controllerType}
                  </StyledInputLabel>
                  <Dropdown
                    value={values.controllerTypeId}
                    onChange={(e) => setFieldValue('controllerTypeId', e.value)}
                    options={dataControllerTypes ?? []}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a value"
                    className="w-100"
                    id="controllerTypeId"
                    onBlur={() => setFieldTouched('controllerTypeId')}
                  />
                  {errors.controllerTypeId && touched.controllerTypeId && (
                    <ErrorText>{errors.controllerTypeId}</ErrorText>
                  )}
                </div>
                {fileUpload.fileUrl && isEditing && (
                  <div>
                    <StyledInputLabel>
                      {Strings.versions.fileUrl}
                    </StyledInputLabel>
                    <InputText
                      type="text"
                      disabled
                      name="fileUrl"
                      value={fileUpload.fileUrl ?? ''}
                      className="w-100"
                    />
                  </div>
                )}
                <div className="mt-3">
                  <Checkbox
                    name="useCustom"
                    value={useCustomUrl}
                    onChange={(e) => {
                      setUseCustomUrl(e.checked as boolean);
                    }}
                    checked={useCustomUrl}
                  />
                  <span className="ml-2">Use custom URL File</span>
                </div>
                {useCustomUrl ? (
                  <div>
                    <StyledInputLabel>
                      {Strings.versions.customUrl}
                    </StyledInputLabel>
                    <InputText
                      type="text"
                      id="customUrl"
                      name="customUrl"
                      value={values.customUrl ?? ''}
                      onChange={handleChange('customUrl')}
                      onBlur={() => setFieldTouched('customUrl')}
                      className="w-100"
                    />
                    {errors.customUrl && touched.customUrl && (
                      <ErrorText>{errors.customUrl}</ErrorText>
                    )}
                  </div>
                ) : (
                  <div>
                    <StyledInputLabel>
                      {Strings.versions.otaFile}
                    </StyledInputLabel>
                    <input
                      type="file"
                      name="demo[]"
                      onChange={onUpload}
                      accept=".bin"
                    />
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    id="submitForm"
                    className="d-none"
                  >
                    {Strings.common.save}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
