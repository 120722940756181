import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import Strings from '../../../i18n';
import { EventUtils } from '../../../utils/eventUtils';

interface IPickEventDays {
  showEventDaysPicker: boolean;
  setShowEventDaysPicker: (show: boolean) => void;
  daysSelected: {
    weekDays: number;
    daysOfWeek: number;
  };
  onOk: (data: { daysOfWeek: number; weekDays: number }) => void;
}

export const PickEventDays = ({
  setShowEventDaysPicker,
  showEventDaysPicker,
  daysSelected,
  onOk
}: IPickEventDays) => {
  const callCancel = () => {
    setShowEventDaysPicker(false);
  };

  const [week, setWeek] = useState<{ [key: string]: boolean }>({
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false
  });

  const [currentSelectedDays, setCurrentSelectedDays] = useState<{
    weekDays: number;
    daysOfWeek: number;
  }>({
    ...daysSelected
  });

  const setValueDayWeek = (day: string, checked: boolean) => {
    setWeek((prevWeek) => ({
      ...prevWeek,
      [day]: checked
    }));
  };

  const updateWeekDays = (day: string, position: number, checked: boolean) => {
    setCurrentSelectedDays((prevDays) => ({
      ...prevDays,
      weekDays: checked
        ? prevDays.weekDays + position
        : prevDays.weekDays - position
    }));
    setCurrentSelectedDays((prevDays) => ({
      ...prevDays,
      daysOfWeek: +EventUtils.weekDaysBinToDaysOfWeekNumber(prevDays.weekDays)
    }));
    setValueDayWeek(day, checked);
  };

  const handleOk = () => {
    onOk(currentSelectedDays);
    setShowEventDaysPicker(false);
    onOk({
      daysOfWeek: currentSelectedDays.daysOfWeek,
      weekDays: currentSelectedDays.weekDays
    });
  };

  const setInitialWeekState = (daysOfWeek: string) => {
    const weekTemp = {
      MON: daysOfWeek[0] === '1',
      TUE: daysOfWeek[1] === '1',
      WED: daysOfWeek[2] === '1',
      THU: daysOfWeek[3] === '1',
      FRI: daysOfWeek[4] === '1',
      SAT: daysOfWeek[5] === '1',
      SUN: daysOfWeek[6] === '1'
    };
    setWeek(weekTemp);
  };

  useEffect(() => {
    setInitialWeekState(
      EventUtils.weekDaysBinToDaysOfWeekNumber(
        EventUtils.binaryToDecimal(daysSelected.daysOfWeek)
      )
    );
  }, [daysSelected.daysOfWeek]);

  return (
    <Dialog
      header={Strings.events.selectDays}
      visible={showEventDaysPicker}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '80vw', '640px': '90vw' }}
      onHide={callCancel}
    >
      <div className="row">
        {EventUtils.daysOfWeekStringRepresentationArray.map((item) => (
          <div
            key={item.value}
            className="togglebutton select-days-btns col my-2"
          >
            <ToggleButton
              onLabel={item.value.toUpperCase()}
              offLabel={item.value.toUpperCase()}
              checked={week[item.value.toUpperCase()]}
              onChange={(e) =>
                updateWeekDays(item.value.toUpperCase(), item.position, e.value)
              }
              name={item.value}
            />
          </div>
        ))}
        {!Object.values(week).some((day) => day) && (
          <div className="text-danger">
            <small>Please provide Week Days</small>
          </div>
        )}
        <div className="col-12 text-right w-100">
          <Button
            type="button"
            label="OK"
            className="btn btn-close-days"
            onClick={handleOk}
          />
        </div>
      </div>
    </Dialog>
  );
};
