import { Box, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ButtonsContainer,
  TitleStyled,
  ButtonStyled,
  ButtonStyledCancel
} from './addOrganizationBarStyles';
import Strings from '../../../i18n';
import { setOpenOrganizationSideBar } from '../../../store/slices/app/app';
import { usePostOrganizationMutation } from '../../../services/device/deviceService';
import { AddOrganizationProps } from './addOrganizationProps';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { colors } from '../../../constants/colors';

export const AddOrganizationBar = ({
  reloadTable,
  setNewOrgId
}: AddOrganizationProps) => {
  const dispatch = useDispatch();
  const contextUser = useSelector(selectCurrentUser);
  const [name, setName] = useState('');
  const [addOrganization, { isLoading, isSuccess, data }] =
    usePostOrganizationMutation();
  const clearForm = () => {
    setName('');
  };
  useEffect(() => {
    if (isSuccess && data) {
      toast.success(Strings.organizations.addOrganization);
      dispatch(setOpenOrganizationSideBar(false));
      setNewOrgId(data);
      reloadTable();
      clearForm();
    }
  }, [isSuccess]);
  return (
    <Box height="100%">
      <TitleStyled variant="h5">
        {Strings.organizations.addOrganization}
      </TitleStyled>
      <Box height="25%" bgcolor={colors.white} padding={2} marginTop={6}>
        <TextField
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setName(e.target.value)}
          fullWidth
          variant="outlined"
          value={name}
          size="small"
          label={Strings.organizations.enterNameOrganization}
        />
      </Box>
      <ButtonsContainer>
        <ButtonStyledCancel
          variant="contained"
          size="large"
          onClick={() => {
            dispatch(setOpenOrganizationSideBar(false));
          }}
        >
          {Strings.common.cancel}
        </ButtonStyledCancel>
        <ButtonStyled
          variant="contained"
          loading={isLoading}
          size="large"
          onClick={() => {
            addOrganization({
              Name: name,
              ParentId: null,
              OwnerId: contextUser.userId
            });
          }}
        >
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
