import { Grid } from '@mui/material';
import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  DividerLocation,
  NameLocation,
  ButtonIconStyled
} from './locationToAssignItemStyles';
import { LocationToAssignItemProps } from './locationToAssignItemProps';

export const LocationToAssignItem = ({
  location,
  isToAssign,
  action
}: LocationToAssignItemProps) => {
  return (
    <>
      <Grid container>
        <Grid item xs={1}>
          {isToAssign ? (
            <ButtonIconStyled size="small" onClick={action}>
              <AddCircleOutlineIcon />
            </ButtonIconStyled>
          ) : (
            <ButtonIconStyled size="small" onClick={action}>
              <HighlightOffIcon />
            </ButtonIconStyled>
          )}
        </Grid>
        <Grid item xs={9}>
          <NameLocation>{location.locationName}</NameLocation>
        </Grid>
      </Grid>
      <DividerLocation />
    </>
  );
};
