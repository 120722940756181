import { ArrowBackIos, Group } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledBackButtonNavigator,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { GroupsListTemplate } from '../../../templates/applications/groups/groupsListTemplate';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';

export const GroupsView = () => {
  const navigate = useNavigate();
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addGroup
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <StyledBackButtonNavigator
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos />
              </StyledBackButtonNavigator>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.groups.title}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      <GroupsListTemplate
        setOpenSideBar={setOpenSideBar}
        openRightSideBarForMethod={openRightSideBarForMethod}
        openSideBar={openSideBar}
        sideBarOpenFor={sideBarOpenFor}
      />
    </>
  );
};
