import React from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import { ListItemProps } from './listItemProps';
import { ListItemLink, ListLink, ListItemSvg } from './listItemStyles';
import { colors } from '../../constants/colors';

export const ListItem: React.FC<ListItemProps> = ({
  selectedId,

  children,
  name,
  path
}) => (
  <ListLink to={path}>
    <ListItemLink selected={selectedId === path}>
      <ListItemSvg selected={selectedId === path}>{children}</ListItemSvg>
      <ListItemText primary={name} />
    </ListItemLink>
  </ListLink>
);
