import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { IApplicationItem } from '../../types/applications/ApplicationsInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);
const customFetchResidential = customFetchBase(config.baseUrlResidential);

export const apiApplications = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getApplicationsByLocation: builder.mutation<IApplicationItem[], number>({
      query: (locationId) => ({
        url: `api/Application/ByLocation/${locationId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetApplicationsByLocationMutation } = apiApplications;

export const apiApplicationsResidential = createApi({
  baseQuery: customFetchResidential,
  endpoints: (builder) => ({
    getGroupsByLocation: builder.mutation<IApplicationItem[], number>({
      query: (locationId) => ({
        url: `api/Group/AllGroupsByLocation/${locationId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetGroupsByLocationMutation } = apiApplicationsResidential;
