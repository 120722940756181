import { Avatar, Box, Select, Typography } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { colors } from '../../../constants/colors';

export const NameUserTitle = styled(Typography)`
  && {
    margin-top: -30px;
    font-weight: bold;
  }
`;
export const AvatarUser = styled(Avatar)`
  && {
    height: 25px;
    width: 25px;
    font-size: 10px;
    margin-right: 10px;
  }
`;
export const NameUser = styled(Typography)`
  && {
    margin-top: 10px;
    color: ${colors.strongGray};
    font-weight: 500;
  }
`;
export const EmailUser = styled(Typography)`
  && {
    margin-top: 10px;
    color: ${colors.mediumGray};
    font-size: 14px;
  }
`;
export const ActiveUser = styled(Typography)<{ isActive: boolean | undefined }>`
  && {
    margin-top: 10px;
    color: ${({ isActive }) => (isActive ? colors.green : colors.orange)};
    font-size: 13px;
  }
`;
export const FormContainer = styled(Box)`
  && {
    width: 100%;
    background-color: #fdfdfd;
    margin-top: 5px;
    border-radius: 8px;
    padding: 20px;
  }
`;
export const LabelSelect = styled(Typography)`
  && {
    color: ${colors.mediumGray};
    font-size: 12px;
  }
`;
export const CustomSelect = styled(Select)`
  && {
    margin-bottom: 5px;
    background-color: ${colors.lightGray};
  }
`;
export const ButtonsContainer = styled(Box)`
  && {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    color: ${colors.white};
    text-transform: none;
  }
`;
export const CancelButtonStyled = styled(LoadingButton)`
  && {
    color: ${colors.strongGray};
    text-transform: none;
    margin-right: 20px;
  }
`;
