import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  IFirmwareFile,
  IFirmwareItemData
} from '../../types/firmwareVersions/firmwareVersionsInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiFirmwareVersions = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getListFirmwareVersions: builder.mutation<
      { data: IFirmwareItemData[] },
      void
    >({
      query: () => ({
        url: `api/Firmware/Version`,
        method: 'GET'
      })
    }),
    getFirmwareVersionDetails: builder.mutation<
      { data: IFirmwareItemData },
      string
    >({
      query: (id) => ({
        url: `api/Firmware/Version/${id}`,
        method: 'GET'
      })
    }),
    addFirmwareVersion: builder.mutation<number, FormData>({
      query: (body) => ({
        url: `api/Firmware/Version`,
        method: 'POST',
        body
      })
    }),
    editFirmwareVersion: builder.mutation<
      number,
      { body: FormData; versionId: number }
    >({
      query: (body) => ({
        url: `api/Firmware/Version/${body.versionId}`,
        method: 'PUT',
        body: body.body
      })
    }),
    deleteFirmwareVersions: builder.mutation<number, number[]>({
      query: (id) => ({
        url: `api/Firmware/Version?ids=${id.join(',')}`,
        method: 'DELETE'
      })
    }),
    deleteUploadedFilesFirmwareVersion: builder.mutation<number, number[]>({
      query: (id) => ({
        url: `api/Firmware/File?ids=${id.join(',')}`,
        method: 'DELETE'
      })
    }),
    getDetailsFirmwareFile: builder.mutation<{ data: IFirmwareFile }, string>({
      query: (id) => ({
        url: `api/Firmware/File/${id}`,
        method: 'GET'
      })
    }),
    getUpgradeTypes: builder.mutation<
      { data: { id: number; name: string }[] },
      void
    >({
      query: () => ({
        url: `api/Firmware/Catalog/UpgradeType`,
        method: 'GET'
      })
    }),
    postEditFile: builder.mutation<number, FormData>({
      query: (body) => ({
        url: `api/firmware/file`,
        method: 'POST',
        body
      })
    }),
    getFirmwareVersionsDetailsForFiles: builder.mutation<
      { data: IFirmwareItemData },
      string
    >({
      query: (id) => ({
        url: `api/firmware/version/details/${id}`,
        method: 'GET'
      })
    }),
    deleteScheduleFirmwareUpdate: builder.mutation<{}, number[]>({
      query: (data) => ({
        url: `api/Firmware/Schedule?ids=${data.join(',')}`,
        method: 'DELETE',
        body: data
      })
    }),
    deleteCompletedFirmwareUpdate: builder.mutation<{}, void>({
      query: (data) => ({
        url: `api/Firmware/Schedule/Completed`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetListFirmwareVersionsMutation,
  useGetFirmwareVersionDetailsMutation,
  useAddFirmwareVersionMutation,
  useEditFirmwareVersionMutation,
  useDeleteFirmwareVersionsMutation,
  useGetDetailsFirmwareFileMutation,
  useGetUpgradeTypesMutation,
  usePostEditFileMutation,
  useDeleteUploadedFilesFirmwareVersionMutation,
  useGetFirmwareVersionsDetailsForFilesMutation,
  useDeleteScheduleFirmwareUpdateMutation,
  useDeleteCompletedFirmwareUpdateMutation
} = apiFirmwareVersions;
