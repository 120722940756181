import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { IOrganizationDetails } from '../../types/organizations/organizations';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiOrganizations = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getOrganizationDetails: builder.mutation<IOrganizationDetails, number>({
      query: (organizationId) => ({
        url: `/api/Organization/${organizationId}`,
        method: 'GET'
      })
    }),
    deleteOrganization: builder.mutation<null, number>({
      query: (organizationId) => ({
        url: `api/Organization/?organizationId=${organizationId}`,
        method: 'DELETE'
      })
    }),
    deleteMultipleOrganizations: builder.mutation<null, number[]>({
      query: (organizationIds) => ({
        url: `api/Organization/?ids=${organizationIds.join(',')}`,
        method: 'DELETE',
        body: organizationIds
      })
    })
  })
});

export const {
  useGetOrganizationDetailsMutation,
  useDeleteOrganizationMutation,
  useDeleteMultipleOrganizationsMutation
} = apiOrganizations;
