import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Strings from '../../i18n';
import { ButtonStyledText, ContainerArrow } from './applicationsViewStyles,';
import { ApplicationsDetails } from '../../templates/applications/applicationDetails/applicationDetails';
import { ApplicationsTemplate } from '../../templates/applications/applicationsTemplate';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { TransferLocationBar } from '../../templates/locations/transferLocationBar/transferLocationBar';
import { ShareLocationBar } from '../../templates/locations/shareLocationBar/shareLocationBar';
import { LocationByUser } from '../../types/location/location';
import { ItemDetail } from '../../components/itemDetail/itemDetail';
import { locationDetails } from '../../store/slices/locations/location';
import { StyledTitleHeader } from '../../components/layout/main/mainStyles';

export const ApplicationsView = () => {
  const { locationId } = useParams();
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const locationSelected = useSelector(locationDetails);

  const [location] = useState<LocationByUser>({
    locationId: locationId ? parseInt(locationId, 10) : 0,
    locationName: '',
    isOwner: false,
    locationRoleId: 0,
    organizationName: ''
  });
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.shareLocation
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };
  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.transferLocation:
        return (
          <TransferLocationBar
            reloadTable={() => {}}
            selectedLocations={[location.locationId]}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocation:
        return (
          <ShareLocationBar
            reloadTable={() => {}}
            selectedLocations={[location.locationId]}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );

      default:
        return (
          <ShareLocationBar
            reloadTable={() => {}}
            selectedLocations={[location.locationId]}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
    }
  };
  return (
    <div>
      <StyledTitleHeader>{Strings.applications.pageTitle}</StyledTitleHeader>
      <Divider variant="fullWidth" />
      <ApplicationsTemplate />
      <Grid container justifyContent="center" paddingLeft="17%" marginTop={2}>
        <ItemDetail
          nameProperty={Strings.applications.streetAddress}
          value={
            locationSelected?.geoInfo ? locationSelected?.geoInfo.address : ''
          }
        />
        <ItemDetail
          nameProperty={Strings.applications.currentOwner}
          value={locationSelected?.ownerEmail}
        />
      </Grid>
      <Grid container justifyContent="center" marginTop={1} marginBottom={2}>
        <ButtonStyledText
          onClick={() => setShowDetails(!showDetails)}
          variant="text"
          endIcon={
            showDetails ? (
              <ContainerArrow>
                <KeyboardArrowUpIcon />
              </ContainerArrow>
            ) : (
              <ContainerArrow>
                <KeyboardArrowDownIcon />
              </ContainerArrow>
            )
          }
        >
          {Strings.applications.showMoreDetails}
        </ButtonStyledText>
      </Grid>
      <ApplicationsDetails
        collapse={showDetails}
        openRightSideBarForMethod={openRightSideBarForMethod}
      />
      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </div>
  );
};
