/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import {
  selectCurrentUser,
  setCredentials,
  setTokenAndRefreshToken
} from '../../../store/slices/login/login';
import { PrivateRouteProps } from './privateRoute.props';
import {
  setCurrentLocation,
  setLocationsDetails,
  setUserLastLocations
} from '../../../store/slices/locations/location';
import {
  useGetLocationDetailsByLocationIdMutation,
  useGetUserLastLocationsMutation,
  usePostUserSelectLocationMutation
} from '../../../services/locations/locationsService';
import { useGetUserInfoMutation } from '../../../services/users/usersService';
import { useGetLoggedUserSystemRolePermissionsMutation } from '../../../services/systemPermissions/systemPermissionsService';

export const PrivateRouteWithLocation = ({ children }: PrivateRouteProps) => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [getInfo, { data: userInfo, isSuccess: isSuccessGetInfo }] =
    useGetUserInfoMutation();

  const [getRoleByToken, { data: roleInfo, isSuccess: isSuccessGetRole }] =
    useGetLoggedUserSystemRolePermissionsMutation();
  const [currentLocationId, setCurrentLocationId] = useState(0);
  const [
    getLocationDetails,
    { isSuccess: isSuccessGetLocationDetails, data: dataLocationDetails }
  ] = useGetLocationDetailsByLocationIdMutation();
  const [
    getUserLastLocation,
    { isSuccess: isSuccessLastLocation, data: dataLastLocation }
  ] = useGetUserLastLocationsMutation();
  const [postSelectLocation] = usePostUserSelectLocationMutation();
  useEffect(() => {
    if (locationId) {
      getLocationDetails(+locationId);
      setCurrentLocationId(+locationId);
    } else {
      dispatch(setCurrentLocation(null));
    }
    getUserLastLocation({ userId: user.userId, numLocations: 8 });
  }, [locationId, currentLocationId]);

  useEffect(() => {
    if (isSuccessGetLocationDetails && dataLocationDetails) {
      postSelectLocation({
        userId: user.userId,
        locationId: locationId ? +locationId : null
      });
      dispatch(setLocationsDetails(dataLocationDetails));
      dispatch(
        setCurrentLocation({
          locationId: dataLocationDetails.id,
          locationName: dataLocationDetails?.name
        })
      );
    }
  }, [isSuccessGetLocationDetails]);

  useEffect(() => {
    if (isSuccessLastLocation && dataLastLocation && locationId) {
      dispatch(
        setUserLastLocations(
          dataLastLocation
            .filter((e) => e.id !== +locationId)
            .map((e) => {
              return { locationId: e.id, locationName: e.name };
            })
        )
      );
    }
  }, [isSuccessLastLocation]);

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('authToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (token) {
        dispatch(
          setTokenAndRefreshToken({ token, refreshToken: refreshToken || '' })
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!user.email) {
      const currentUrl = window.location.href;
      if (!currentUrl.includes('callback')) {
        localStorage.setItem('redirectUrlAfterTokenRefresh', currentUrl);
      }
      if (localStorage.getItem('authToken')) {
        getRoleByToken('');
        getInfo();
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetInfo && isSuccessGetRole) {
      dispatch(
        setCredentials({
          user: '',
          email: userInfo?.email ?? '',
          token: localStorage.getItem('authToken') || '',
          picture: '',
          roleInfo: roleInfo ?? {},
          userInfo: {
            firstName: userInfo?.firstName ?? '',
            lastName: userInfo?.lastName ?? '',
            lastLogin: userInfo?.lastLogin ?? '',
            phoneNumber: String(userInfo?.phoneNumber)
          },
          refreshToken: localStorage.getItem('refreshToken') || '',
          authWithApi: true,
          userId: userInfo?.id ?? 0
        })
      );
    }
  }, [isSuccessGetInfo, isSuccessGetRole]);

  return user && user.user !== null ? <>{children}</> : <div />;
};
