import { colors } from '../../constants/colors';

export const TooltipStyled = {
  tooltip: {
    sx: {
      bgcolor: colors.tooltipBG,
      color: colors.tooltipText
    }
  }
};
