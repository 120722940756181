import styled from 'styled-components';
import { AppBar, Toolbar, IconButton, Link } from '@mui/material';
import { colors } from '../../../constants/colors';

export const DesktopNavBar = styled(AppBar)`
  && {
    background-color: ${colors.navBarBackground};
    box-shadow: none;
    @media (max-width: 900px) {
      display: none;
    }
  }
`;
export const MobileNavBar = styled(AppBar)`
  && {
    background-color: ${colors.navBarBackground};
    box-shadow: none;

    @media (min-width: 900px) {
      display: none;
    }
  }
`;

export const ToolBarStyled = styled(Toolbar)`
  && {
    padding: 0px;
    min-height: 40px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const ImageStyled = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

export const TitleStyled = styled(Link)`
  && {
    flex-grow: 1;
    font-weight: 400;
    color: ${colors.strongGray};
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
    color: ${colors.mediumGray};
  }
`;
