import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { CreateRoleUserProps } from './createRoleUserProps';
import Strings from '../../../i18n';
import {
  ButtonIconStyled,
  ButtonStyled,
  ButtonsContainer,
  CancelButtonStyled,
  CustomSelect,
  FormContainer,
  InputNameRole,
  LabelSelect,
  SubTitle,
  Title
} from './createRoleUserStyles';
import {
  useAddSystemRoleWithInfoMutation,
  useGetSystemModulesMutation
} from '../../../services/systemPermissions/systemPermissionsService';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';

export const CreateRoleUser = ({
  openRightSideBarForMethod,
  setOpenSideBar
}: CreateRoleUserProps) => {
  const [payload, setPayload] = useState<{
    roleName: string;
    modulesInfo: { id: number; name: string; value: string }[];
  }>({
    roleName: '',
    modulesInfo: []
  });
  const [getModules, { isSuccess: successModules, data: dataModules }] =
    useGetSystemModulesMutation();

  const handleRoleNameChange = (event: any) => {
    setPayload({ ...payload, roleName: event.target.value });
  };

  const handleModuleValueChange = (moduleId: number, value: string) => {
    const updatedModulesInfo = payload.modulesInfo.map((element) =>
      element.id === moduleId ? { ...element, value } : element
    );
    setPayload({ ...payload, modulesInfo: updatedModulesInfo });
  };

  const [
    addSystemRole,
    { isSuccess: isSuccessAddSystemRole, data: dataAddSystemRole }
  ] = useAddSystemRoleWithInfoMutation();

  const sendInfo = () => {
    const transformedPayload = {
      roleName: payload.roleName,
      modulesInfo: payload.modulesInfo.map((module) => ({
        moduleId: module.id,
        roleModulePermissions:
          module.value === 'No permissions' ? '' : module.value
      }))
    };
    addSystemRole(transformedPayload);
  };
  useEffect(() => {
    getModules('');
  }, []);
  useEffect(() => {
    if (isSuccessAddSystemRole) {
      toast.success(Strings.manageRoles.successAddNewRole);
      setOpenSideBar(false);
    }
  }, [isSuccessAddSystemRole]);

  useEffect(() => {
    if (successModules && dataModules) {
      payload.modulesInfo = dataModules.map((element) => ({
        id: element.id,
        name: element.name,
        value: ''
      }));
    }
  }, [successModules]);

  return (
    <Box height="100%">
      <Title>{Strings.manageRoles.manageRoles}</Title>
      <Grid container>
        <Grid container marginTop={3}>
          <Grid item xs={1}>
            <ButtonIconStyled
              size="small"
              onClick={() =>
                openRightSideBarForMethod(viewRightSideBarEnum.manageUserRoles)
              }
            >
              <ArrowBackIosIcon />
            </ButtonIconStyled>
          </Grid>
          <Grid item xs={10}>
            <SubTitle>{Strings.manageRoles.addRoleTitle}</SubTitle>
          </Grid>
        </Grid>
        <FormContainer>
          <Grid>
            {successModules && dataModules && (
              <Grid>
                <LabelSelect>{Strings.manageRoles.name}</LabelSelect>
                <InputNameRole
                  size="small"
                  value={payload.roleName}
                  onChange={handleRoleNameChange}
                />
              </Grid>
            )}
            {successModules &&
              dataModules &&
              payload.modulesInfo.map((element) => {
                return (
                  <div key={element.id}>
                    <LabelSelect>{element.name}</LabelSelect>
                    <CustomSelect
                      size="small"
                      fullWidth
                      value={element.value}
                      onChange={(event) =>
                        handleModuleValueChange(
                          element.id,
                          event.target.value as string
                        )
                      }
                    >
                      <MenuItem value="Read">Read</MenuItem>
                      <MenuItem value="Read/Write">Read / Write</MenuItem>
                      <MenuItem value="No permissions">No permissions</MenuItem>
                    </CustomSelect>
                  </div>
                );
              })}
          </Grid>
        </FormContainer>
      </Grid>
      <ButtonsContainer>
        <CancelButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            setOpenSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </CancelButtonStyled>
        <ButtonStyled
          variant="contained"
          size="large"
          color="info"
          onClick={sendInfo}
        >
          {Strings.common.add}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
