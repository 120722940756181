import React, { useEffect } from 'react';
import { Divider } from '@mui/material';
import { MultiSelect } from 'primereact/multiselect';
import { ILightZone } from '../../types/Lights/lightZoneInterfaces';
import { StyledTitleLabelAddEvent } from '../../templates/applications/events/addEvent/addEventStyles';
import Strings from '../../i18n';
import { useGetLightsZonesByLocationMutation } from '../../services/lightsZones/lightsZonesService';

interface LightZoneSelectorProps {
  locationId: number;
  selectedLightZones: ILightZone[];
  setSelectedLightZones: (selectedLightZones: ILightZone[]) => void;
  disabled: boolean;
}
export const LightZoneSelector = ({
  locationId,
  selectedLightZones,
  setSelectedLightZones,
  disabled
}: LightZoneSelectorProps) => {
  const [getLightsZones, { isSuccess, data }] =
    useGetLightsZonesByLocationMutation();

  useEffect(() => {
    if (locationId) {
      getLightsZones(locationId);
    }
  }, []);
  return (
    <>
      <StyledTitleLabelAddEvent>
        * {Strings.events.lightsZoneSelector}
      </StyledTitleLabelAddEvent>

      <MultiSelect
        value={selectedLightZones}
        onChange={(e) => setSelectedLightZones(e.value)}
        options={data}
        optionLabel="name"
        placeholder="Select lights / zones"
        className="w-100 md:w-20rem"
        showSelectAll={false}
        display="chip"
        selectedItemsLabel="ellipsis"
        disabled={disabled}
      />
      {disabled && (
        <div className="text-warning">
          <span>{Strings.events.disableLightZones}</span>
        </div>
      )}
    </>
  );
};
