import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    text-transform: none;
    color: ${colors.white};
  }
`;
