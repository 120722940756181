import React from 'react';
import { useSelector } from 'react-redux';
import { MobileMenu, DesktopMenu } from './sideBarStyles';
import { MainDrawerProps } from './sideBarProps';
import { selectIsSideBarOpen } from '../../../store/slices/app/app';
import { SidebarList } from '../sidebarList/sidebarList';
import { currentLocation } from '../../../store/slices/locations/location';

export const SideBar = ({ onMenuClose }: MainDrawerProps) => {
  const isOpenSideBar = useSelector(selectIsSideBarOpen) as boolean;
  const location = useSelector(currentLocation);
  return (
    <>
      <MobileMenu
        variant="temporary"
        open={isOpenSideBar && Boolean(location)}
        onClose={onMenuClose}
        ModalProps={{
          keepMounted: true
        }}
      >
        <SidebarList />
      </MobileMenu>
      <DesktopMenu
        variant="persistent"
        open={isOpenSideBar && Boolean(location)}
        onClose={onMenuClose}
        anchor="left"
      >
        <SidebarList />
      </DesktopMenu>
    </>
  );
};
