import { Avatar } from '@mui/material';
import React, { useEffect } from 'react';
import { stringAvatar } from '../../../utils/users';

interface AvatarUserProps {
  fullName: string;
  size: number;
}
export const AvatarUserComponent = ({ fullName, size }: AvatarUserProps) => {
  return (
    <Avatar
      {...stringAvatar(fullName)}
      style={{ width: size, height: size, fontSize: size / 2.2 }}
      className="mr-2"
    />
  );
};
