import React, { useState } from 'react';
import { AddCircleOutline, ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { TrimLightsList } from '../../../templates/applications/trimLights/trimLightsList/trimLightsList';
import { Header } from '../../../components/header/header';
import {
  StyledBackButtonNavigator,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../devices/devicesStyles';
import { TooltipStyled } from '../../locations/locationStyles';

export const TrimLightsView = () => {
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <StyledBackButtonNavigator
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos />
              </StyledBackButtonNavigator>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.trimLights.title}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip
                title={Strings.locations.addLocation}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={() => {}} disabled>
                  <AddCircleOutline />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <TrimLightsList
        searchValue={searchValue}
        setSelectionModel={setSelectionModel}
        selectionModel={selectionModel}
      />
    </>
  );
};
