import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIos, Tune } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header/header';
import Strings from '../../i18n';
import { UsersTemplate } from '../../templates/usersTemplate/usersTemplate';
import { ButtonIconStyled } from './usersViewStyles';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { selectCurrentUser } from '../../store/slices/login/login';
import {
  systemModuleEnum,
  systemModulePermissionsEnum
} from '../../constants/systemRolesEnums';
import { setPermissionsReadWriteSystemUsers } from '../../store/slices/systemPermissions/systemPermissions';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { IUserInfo } from '../../types/Auth/authInterface';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';

export const UsersView = () => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);
  const [openSideBar, setOpenSideBar] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const filtersCount = Array.from(queryParams.keys()).length;
  const navigate = useNavigate();

  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.editUserPermissions
  );

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const contextUser = useSelector(selectCurrentUser);

  const modulePermissions = contextUser.roleInfo.permissionsByModule.find(
    (module) => module.systemModuleName === systemModuleEnum.users
  );

  const permissionReadWrite = !!modulePermissions?.permissions.includes(
    systemModulePermissionsEnum.readWrite
  );

  dispatch(setPermissionsReadWriteSystemUsers(permissionReadWrite));

  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.users.users}</StyledTitleHeader>
            </div>
            <div className="col-7 col-sm-4 col-md-4 text-right">
              {permissionReadWrite ? (
                <>
                  <ButtonIconStyled
                    size="small"
                    onClick={() => {
                      openRightSideBarForMethod(
                        viewRightSideBarEnum.addSystemUser
                      );
                    }}
                  >
                    <PersonAddAltIcon />
                  </ButtonIconStyled>
                  <ButtonIconStyled
                    disabled
                    size="small"
                    onClick={() => {
                      openRightSideBarForMethod(viewRightSideBarEnum.addAdmin);
                    }}
                  >
                    <AdminPanelSettingsIcon />
                  </ButtonIconStyled>
                  <ButtonIconStyled
                    size="small"
                    onClick={() => {
                      openRightSideBarForMethod(
                        viewRightSideBarEnum.manageUserRoles
                      );
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </ButtonIconStyled>
                  <ButtonIconStyled
                    disabled
                    size="small"
                    onClick={() => {
                      openRightSideBarForMethod(
                        viewRightSideBarEnum.editUserPermissions
                      );
                    }}
                  >
                    <AccountCircleIcon />
                  </ButtonIconStyled>
                </>
              ) : null}
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(viewRightSideBarEnum.filters);
                }}
              >
                <Badge badgeContent={filtersCount} color="warning">
                  <Tune />
                </Badge>
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openSideBar}
                selectedItems={selectedUsers}
                singleItemMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.editUserAndRole
                  );
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.editMultipleUsers
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <UsersTemplate
        sideBarOpenFor={sideBarOpenFor}
        openRightSideBarForMethod={openRightSideBarForMethod}
        setOpenSideBar={setOpenSideBar}
        openedSideBar={openSideBar}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    </div>
  );
};
