import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const sliceBusinessAccount = createSlice({
  name: 'auth',
  initialState: { name: '' } as {
    name: string;
  },
  reducers: {
    setBusinessAccount: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    }
  },
  extraReducers: (builder) => {}
});
export default sliceBusinessAccount.reducer;
export const selectNameBusinessAccount = (state: RootState) =>
  state.businessAccount.name;
export const { setBusinessAccount } = sliceBusinessAccount.actions;
