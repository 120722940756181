import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Strings from '../../../../i18n';
import { useGetLatestSharedEffectsUserMutation } from '../../../../services/effects/effectsStoreService';
import { colors } from '../../../../constants/colors';

interface ShareEffectWithButtonProps {
  onShare: (email: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ShareEffectDialog: React.FC<ShareEffectWithButtonProps> = ({
  onShare,
  open,
  setOpen
}) => {
  const [email, setEmail] = useState<string>('');
  const [filteredEmails, setFilteredEmails] = useState<string[]>([]);

  const [
    getLatestEmails,
    { isSuccess: successLatestEmails, data: dataLatestEmails }
  ] = useGetLatestSharedEffectsUserMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const onShareHandle = () => {
    onShare(email);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      setEmail('');
      getLatestEmails();
    }
  }, [open, getLatestEmails]);

  useEffect(() => {
    if (successLatestEmails && dataLatestEmails) {
      setFilteredEmails(dataLatestEmails.data || []);
    }
  }, [successLatestEmails, dataLatestEmails]);

  const handleSearch = (event: any) => {
    const { query } = event;
    if (dataLatestEmails && dataLatestEmails.data) {
      const results = dataLatestEmails.data.filter((em: string) =>
        em.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredEmails(results);
    }
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435
        }
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        {Strings.effects.shareEffect}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="row" id="drop">
            <div className="col-12">{Strings.effects.shareEffectText}</div>
            <div className="col-12 mt-3 mb-5 scroll-auto">
              <AutoComplete
                value={email}
                suggestions={filteredEmails}
                completeMethod={handleSearch}
                onChange={(e) => setEmail(e.value)}
                dropdown
                forceSelection={false}
                placeholder="Enter an email or select one previously shared"
                className="w-100"
              />
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MUIButton onClick={onShareHandle} color="success" variant="contained">
          {Strings.common.ok}
        </MUIButton>
        <MUIButton onClick={handleClose} color="error" variant="contained">
          {Strings.common.cancel}
        </MUIButton>
      </DialogActions>
    </Dialog>
  );
};
