import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { colors } from '../../../constants/colors';

export const CustomList = styled(List)`
  && {
    width: 100%;
    max-width: 360;
    background-color: ${colors.white};
    .mui-selected {
      background-color: ${colors.white};
    }
  }
`;
