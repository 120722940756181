import { Grid } from '@mui/material';
import React from 'react';
import { DividerRole, NameRole, UsersInRole } from './roleItemStyles';
import { RoleItemProps } from './roleItemProps';

const addSquare = require('../../assets/addSquare.png');

export const RoleItem = ({ role }: RoleItemProps) => {
  return (
    <>
      <Grid container width="40vh">
        <img alt="add" src={addSquare} />
        <NameRole>{role.name}</NameRole>
        <UsersInRole>{`(${role.userCount ?? 0} users)`}</UsersInRole>
      </Grid>
      <DividerRole />
    </>
  );
};
