import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiEvent = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getEventListByLocation: builder.mutation<[], number>({
      query: (locationId) => ({
        url: `/api/Event/EventLightZones?locationId=${locationId}`,
        method: 'GET'
      })
    }),
    postEventScene: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/Scene',
        method: 'POST',
        body: event
      })
    }),
    postEventOn: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/On',
        method: 'POST',
        body: event
      })
    }),
    postEventOff: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/Off',
        method: 'POST',
        body: { ...event }
      })
    }),
    postEventColor: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/Color',
        method: 'POST',
        body: event
      })
    }),
    postEventEffect: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/Effect',
        method: 'POST',
        body: event
      })
    }),
    postEventSequence: builder.mutation<[], {}>({
      query: (event) => ({
        url: '/api/Event/Sequence',
        method: 'POST',
        body: event
      })
    }),
    deleteEvent: builder.mutation<[], number>({
      query: (eventId) => ({
        url: `/api/Event/Delete/${eventId}`,
        method: 'DELETE'
      })
    }),
    eventsToExecuteByDateAndLocation: builder.mutation<
      number[],
      { date: string; locationId: number }
    >({
      query: (event) => ({
        url: '/api/Event/EventsToExecuteByDate',
        method: 'GET',
        params: {
          executionDate: event.date,
          locationId: event.locationId
        }
      })
    }),
    postEventExecute: builder.mutation<[], number>({
      query: (eventId) => ({
        url: `/api/Event/${eventId}/Execute`,
        method: 'POST'
      })
    }),
    changeEventStatus: builder.mutation<
      [],
      { eventId: number; status: boolean }
    >({
      query: (status) => ({
        url: `/api/Event/SetStatus/${status.eventId}/${status.status}`,
        method: 'PUT'
      })
    })
  })
});

export const {
  useGetEventListByLocationMutation,
  usePostEventSceneMutation,
  usePostEventOnMutation,
  usePostEventOffMutation,
  usePostEventColorMutation,
  usePostEventEffectMutation,
  usePostEventSequenceMutation,
  useDeleteEventMutation,
  useEventsToExecuteByDateAndLocationMutation,
  usePostEventExecuteMutation,
  useChangeEventStatusMutation
} = apiEvent;
