import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { addSystemUserValidator } from '../../../utils/validators';
import { AddSystemUserProps } from './addSystemUserProps';
import Strings from '../../../i18n';
import { ErrorText } from '../../../components/auth/Profile/completeProfileStyles';
import { usePostUserInfoMutation } from '../../../services/users/usersService';
import {
  useAddUpdateSystemUserRoleMutation,
  useGetSystemRolesMutation
} from '../../../services/systemPermissions/systemPermissionsService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';
import { systemModulePermissionsEnum } from '../../../constants/systemRolesEnums';

export const AddNewSystemUser = ({
  reloadTable,
  setOpenSideBar
}: AddSystemUserProps) => {
  const [roleId, setRoleId] = useState(0);
  const [dataRolesWithAssignLater, setDataRolesWithAssignLater] = useState<
    { id: any; name: any }[]
  >([]);
  const handleChangeRole = (event: any) => {
    setRoleId(+event.target.value);
  };

  const [
    getSystemRoles,
    { isSuccess: successGetSystemRoles, data: dataSystemRoles }
  ] = useGetSystemRolesMutation();

  const [
    postUserInfo,
    {
      isLoading: isLoadingPostInfo,
      isSuccess: isSuccessPostInfo,
      data: dataPostInfo
    }
  ] = usePostUserInfoMutation();

  const [
    updateRoleByUserId,
    { isSuccess: isSuccessUpdateRole, isLoading: isLoadingUpdateRole }
  ] = useAddUpdateSystemUserRoleMutation();

  const updateUserRole = () => {
    if (dataPostInfo && roleId !== systemModulePermissionsEnum.assignLater) {
      const payload = {
        userId: dataPostInfo?.userId,
        systemRoleId: roleId
      };
      updateRoleByUserId(payload);
    }
  };
  const sendInfo = async (payload: any) => {
    postUserInfo(payload);
  };

  useEffect(() => {
    if (successGetSystemRoles && dataSystemRoles) {
      const roles = [
        { id: 0, name: Strings.common.assignLater },
        ...dataSystemRoles
      ];
      setDataRolesWithAssignLater(roles);
    }
  }, [successGetSystemRoles]);

  useEffect(() => {
    getSystemRoles('');
  }, []);
  useEffect(() => {
    if (isSuccessPostInfo) {
      if (roleId !== systemModulePermissionsEnum.assignLater) {
        updateUserRole();
      } else {
        toast.success(Strings.users.successCreateUser);
        reloadTable();
        setOpenSideBar(false);
      }
    }
  }, [isSuccessPostInfo]);

  useEffect(() => {
    if (isSuccessUpdateRole && isSuccessUpdateRole) {
      toast.success(Strings.users.successCreateUser);
      reloadTable();
      setOpenSideBar(false);
    }
  }, [isSuccessUpdateRole, isSuccessUpdateRole]);

  return (
    <Formik
      enableReinitialize
      validationSchema={addSystemUserValidator}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: null
      }}
      onSubmit={(values) => {
        sendInfo({
          email: values.email,
          fullName: `${values.firstName} ${values.lastName}`,
          firstName: values.firstName,
          lastName: values.lastName,
          auth0Id: '',
          phoneNumber: values.phoneNumber ?? 0
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        setFieldTouched,
        errors,
        touched
      }) => (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>
              {Strings.users.createNewUser}
            </StyledTitleSideBar>
            <>
              <SideBarEditablePropertyItem
                item={{
                  label: Strings.userProfile.firstNameLabel,
                  type: 'inputText',
                  currentValue: values.firstName,
                  nameAndId: 'firstName',
                  onChange: handleChange('firstName'),
                  onBlur: () => setFieldTouched('firstName')
                }}
              />
              {touched.firstName && errors.firstName && (
                <ErrorText>{errors.firstName}</ErrorText>
              )}
              <SideBarEditablePropertyItem
                item={{
                  label: Strings.userProfile.lastNameLabel,
                  type: 'inputText',
                  currentValue: values.lastName,
                  nameAndId: 'lastName',
                  onChange: handleChange('lastName'),
                  onBlur: () => setFieldTouched('lastName')
                }}
              />
              {touched.lastName && errors.lastName && (
                <ErrorText>{errors.lastName}</ErrorText>
              )}
              <SideBarEditablePropertyItem
                item={{
                  label: Strings.userProfile.emailLabel,
                  type: 'inputText',
                  currentValue: values.email,
                  nameAndId: 'email',
                  onChange: handleChange('email'),
                  onBlur: () => setFieldTouched('email')
                }}
              />
              {touched.email && errors.email && (
                <ErrorText>{errors.email}</ErrorText>
              )}
              <SideBarEditablePropertyItem
                item={{
                  label: Strings.userProfile.mobileNumberLabel,
                  type: 'inputText',
                  currentValue: values.phoneNumber ?? '',
                  nameAndId: 'phoneNumber',
                  onChange: handleChange('phoneNumber'),
                  onBlur: () => setFieldTouched('phoneNumber')
                }}
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <ErrorText>{errors.phoneNumber}</ErrorText>
              )}
              {dataSystemRoles && (
                <SelectItemMethod
                  item={{
                    label: Strings.users.systemRole,
                    currentValue: roleId,
                    options: dataRolesWithAssignLater || [],
                    onChange: handleChangeRole,
                    placeholder: Strings.devices.productType
                  }}
                />
              )}
            </>
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                setOpenSideBar(false);
              }}
            >
              {Strings.common.cancel}
            </button>
            <LoadingButton
              variant="contained"
              disabled={!isValid}
              size="large"
              className="btn-save"
              onClick={() => handleSubmit()}
              loading={isLoadingPostInfo || isLoadingUpdateRole}
            >
              {Strings.common.save}
            </LoadingButton>
          </StyledContainerFooterSideBar>
        </>
      )}
    </Formik>
  );
};
