import { Select } from '@mui/material';
import styled from 'styled-components';

export const CustomSelect = styled(Select)`
  && {
    height: 48px;
    border: none;
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const StyledButtonAction = styled.button`
  background-color: transparent;
  border: none;
  color: #487ff6;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
`;
