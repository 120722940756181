export function checkCapability(
  capabilityName: string | string[],
  capabilitiesArray: { name: string }[]
) {
  if (typeof capabilityName === 'string') {
    capabilityName = [capabilityName];
  }
  return capabilityName.some((name) =>
    capabilitiesArray?.some((capability) => capability.name === name)
  );
}
