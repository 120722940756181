import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const sliceApp = createSlice({
  name: 'app',
  initialState: {
    isSideBarOpen: false,
    isRightSideBarOpen: false,
    isLocationSideBarOpen: false,
    isOrganizationSideBarOpen: false,
    isUserSidebarOpen: false,
    isDeviceByLocationSidebarOpen: false,
    isEditUserSideBarOpen: false,
    isLoadingCall: false,
    isBusinessAccountSideBarOpen: false
  } as {
    isBusinessAccountSideBarOpen: boolean;
    isEditUserSideBarOpen: boolean;
    isOrganizationSideBarOpen: boolean;
    isSideBarOpen: boolean;
    isRightSideBarOpen: boolean;
    isLocationSideBarOpen: boolean;
    isUserSidebarOpen: boolean;
    isDeviceByLocationSidebarOpen: boolean;
    isLoadingCall: boolean;
  },
  reducers: {
    setOpenSideBar: (
      state,
      { payload: isSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isSideBarOpen = isSideBarOpen;
    },
    setOpenRightSideBar: (
      state,
      { payload: isRightSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isRightSideBarOpen = isRightSideBarOpen;
    },
    setOpenLocationSideBar: (
      state,
      { payload: isLocationSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isLocationSideBarOpen = isLocationSideBarOpen;
    },
    setOpenUserSideBar: (
      state,
      { payload: isUserSidebarOpen }: PayloadAction<boolean>
    ) => {
      state.isUserSidebarOpen = isUserSidebarOpen;
    },
    setOpenDeviceByLocationSideBar: (
      state,
      { payload: isDeviceByLocationSidebarOpen }: PayloadAction<boolean>
    ) => {
      state.isDeviceByLocationSidebarOpen = isDeviceByLocationSidebarOpen;
    },
    setLoadingCall: (
      state,
      { payload: isLoadingCall }: PayloadAction<boolean>
    ) => {
      state.isLoadingCall = isLoadingCall;
    },
    setOpenOrganizationSideBar: (
      state,
      { payload: isOrganizationSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isOrganizationSideBarOpen = isOrganizationSideBarOpen;
    },
    setOpenEditUserSideBar: (
      state,
      { payload: isEditUserSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isEditUserSideBarOpen = isEditUserSideBarOpen;
    },
    setOpenBusinessAccountSideBar: (
      state,
      { payload: isBusinessAccountSideBarOpen }: PayloadAction<boolean>
    ) => {
      state.isBusinessAccountSideBarOpen = isBusinessAccountSideBarOpen;
    }
  },
  extraReducers: (builder) => {}
});
export default sliceApp.reducer;
export const selectIsSideBarOpen = (state: RootState) =>
  state.app.isSideBarOpen;
export const selectIsRightSideBarOpen = (state: RootState) =>
  state.app.isRightSideBarOpen;
export const selectIsLocationSideBarOpen = (state: RootState) =>
  state.app.isLocationSideBarOpen;
export const selectIsUserSideBarOpen = (state: RootState) =>
  state.app.isUserSidebarOpen;
export const selectIsBusinessAccountSideBarOpen = (state: RootState) =>
  state.app.isBusinessAccountSideBarOpen;
export const selectIsDeviceByLocationSideBarOpen = (state: RootState) =>
  state.app.isDeviceByLocationSidebarOpen;
export const selectIsLoadingCall = (state: RootState) =>
  state.app.isLoadingCall;
export const selectIsOrganizationSideBarOpen = (state: RootState) =>
  state.app.isOrganizationSideBarOpen;
export const selectIsEditUserSideBarOpen = (state: RootState) =>
  state.app.isEditUserSideBarOpen;
export const {
  setOpenSideBar,
  setOpenRightSideBar,
  setOpenLocationSideBar,
  setOpenUserSideBar,
  setLoadingCall,
  setOpenOrganizationSideBar,
  setOpenDeviceByLocationSideBar,
  setOpenBusinessAccountSideBar,
  setOpenEditUserSideBar
} = sliceApp.actions;
