import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  IAddRoleWithInfo,
  IAddUpdateSystemRoleByUser
} from '../../types/rolesModulesAndPermissions/rolesModulesAndPermissionsInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);
export const SystemRolesAndPermissionsService = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getSystemRoles: builder.mutation<{ id: number; name: string }[], {}>({
      query: () => ({
        url: '/api/Permission/GetSystemRolesWithUserCount',
        method: 'GET'
      })
    }),
    getSystemModules: builder.mutation<{ id: number; name: string }[], {}>({
      query: () => ({
        url: '/api/Permission/GetSystemModules',
        method: 'GET'
      })
    }),
    getSystemRolePermissionsByRoleAndModule: builder.mutation<
      string[],
      { systemRoleId: number; systemModuleId: number }
    >({
      query: (payload) => ({
        url: `/api/Permission/GetSystemRolePermissionsByRoleAndModule/${payload.systemRoleId}/${payload.systemModuleId}`,
        method: 'GET'
      })
    }),
    getLoggedUserSystemRolePermissions: builder.mutation<{}, {}>({
      query: () => ({
        url: '/api/Permission/GetLoggedUserSystemRolePermissions',
        method: 'GET'
      })
    }),
    addUpdateSystemUserRole: builder.mutation<{}, IAddUpdateSystemRoleByUser>({
      query: (payload) => ({
        url: '/api/Permission/AddUpdateSystemUserRole',
        method: 'POST',
        body: payload
      })
    }),
    addSystemRoleWithInfo: builder.mutation<{}, IAddRoleWithInfo>({
      query: (payload) => ({
        url: '/api/Permission/AddSystemRoleWithInfo',
        method: 'POST',
        body: payload
      })
    }),
    addSystemModule: builder.mutation<{}, null>({
      query: (payload) => ({
        url: '/api/Permission/AddSystemModule',
        method: 'POST',
        body: { name: 'Locations' }
      })
    })
  })
});

export const {
  useGetSystemRolesMutation,
  useGetSystemModulesMutation,
  useGetSystemRolePermissionsByRoleAndModuleMutation,
  useGetLoggedUserSystemRolePermissionsMutation,
  useAddUpdateSystemUserRoleMutation,
  useAddSystemRoleWithInfoMutation,
  useAddSystemModuleMutation
} = SystemRolesAndPermissionsService;
