import { LoadingButton } from '@mui/lab';
import { Avatar, Box, TextField } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ButtonsContainer = styled(Box)`
  && {
    height: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    text-transform: none;
    color: ${colors.white};
  }
`;

export const AvatarStyled = styled(Avatar)`
  && {
    height: 60px;
    width: 60px;
  }
`;

export const TextFieldStyled = styled(TextField)({
  '& label': {
    color: colors.black
  },
  width: '90%',

  input: {
    color: colors.darkGray
  },
  '& label.Mui-focused': {
    color: colors.black
  },
  '& .MuiInput-underline': {
    marginBottom: 15
  }
});
export const ButtonStyledCancel = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.lightGray};
    text-transform: none;
    color: ${colors.darkGray};
  }
`;
export const ErrorText = styled.p`
  font-size: 10px;
  color: ${colors.red};
  text-align: center;
`;
