import { Tooltip } from '@mui/material';
import React from 'react';
import { ViewSidebar } from '@mui/icons-material';
import {
  ReOpenTooltipStyled,
  ReOpenButtonIconStyled
} from './reOpenSideBarIconStyles';
import Strings from '../../../i18n';
import { colors } from '../../../constants/colors';

interface ReOpenSideBarIconProps {
  selectedItems: any[];
  singleItemMethod: () => void;
  multipleItemsMethod: () => void;
  isOpenRightSideBar: boolean;
}

export const ReOpenSideBarIcon = ({
  selectedItems,
  singleItemMethod,
  multipleItemsMethod,
  isOpenRightSideBar
}: ReOpenSideBarIconProps) => {
  if (isOpenRightSideBar || selectedItems.length === 0) {
    return null;
  }

  const handleClick = () => {
    if (selectedItems.length === 1) {
      singleItemMethod();
    } else {
      multipleItemsMethod();
    }
  };

  return (
    <ReOpenButtonIconStyled size="small" onClick={handleClick}>
      <Tooltip
        title={Strings.common.openDetails}
        placement="top"
        arrow
        componentsProps={ReOpenTooltipStyled}
      >
        <ViewSidebar htmlColor={colors.strongGray} />
      </Tooltip>
    </ReOpenButtonIconStyled>
  );
};
