import { GridRowId } from '@mui/x-data-grid';
import {
  LocationByUser,
  LocationInOrganization
} from '../types/location/location';

export const filterLocations = (
  locations: LocationByUser[],
  userLocations: LocationInOrganization[]
) => {
  return locations.filter((location) => {
    return !userLocations.some(
      (userLocation) => userLocation.locationId === location.locationId
    );
  });
};

export const extractNumberAndType = (
  string: string
): { number: number; type: string } => {
  const result: { number: number; type: string } = { number: 0, type: '' };

  const match = string.match(/^(\d+)(org|loc)$/);

  if (match) {
    const number = parseInt(match[1], 10);
    const type = match[2];
    result.number = number;
    result.type = type;
  }

  return result;
};

export function convertToFormData(data: any) {
  const formData = new FormData();

  function appendFormData(info: any, parentKey = '') {
    if (info && typeof info === 'object' && !Array.isArray(info)) {
      Object.keys(info).forEach((key) => {
        appendFormData(info[key], parentKey ? `${parentKey}.${key}` : key);
      });
    } else {
      formData.append(parentKey, info);
    }
  }

  appendFormData(data);
  return formData;
}
