import React, { useEffect } from 'react';
import {
  Search,
  Tune,
  ArrowBackIos,
  Add,
  AddCircleOutline,
  Edit,
  UploadFile,
  CheckCircle,
  HighlightOff,
  EmojiEvents,
  Delete
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { Header } from '../../components/header/header';
import { ButtonIconStyled } from '../devices/devicesStyles';
import { VersionsTemplate } from '../../templates/versions/versionsTemplate';
import Strings from '../../i18n';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  TooltipStyled
} from '../../components/layout/main/mainStyles';
import {
  useDeleteFirmwareVersionsMutation,
  useEditFirmwareVersionMutation,
  useGetListFirmwareVersionsMutation
} from '../../services/firmwareVersions/firmwareVersionsService';
import { LoadingRowsComponent } from '../../components/commons/LoadingRows/LoadingRows';
import { IFirmwareItemData } from '../../types/firmwareVersions/firmwareVersionsInterface';
import { DeleteButtonWithoutText } from '../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { convertToFormData } from '../../utils/locations';

export const VersionsView = () => {
  const navigate = useNavigate();
  const [selectedVersions, setSelectedVersions] = React.useState<
    IFirmwareItemData[]
  >([]);
  const [
    getFirmwareVersions,
    {
      data: firmwareVersions,
      isLoading: isLoadingFirmwareVersions,
      isSuccess: isSuccessFirmwareVersions
    }
  ] = useGetListFirmwareVersionsMutation();

  const [
    deleteFirmwareVersions,
    {
      isLoading: isLoadingDeleteFirmwareVersions,
      isSuccess: isSuccessDeleteFirmwareVersions
    }
  ] = useDeleteFirmwareVersionsMutation();

  const [editFirmwareVersion, { isSuccess: successEdit, data: dataEdit }] =
    useEditFirmwareVersionMutation();

  useEffect(() => {
    getFirmwareVersions();
  }, []);

  const reloadTable = () => {
    getFirmwareVersions();
    setSelectedVersions([]);
  };

  const redirectToAddVersion = () => {
    navigate('/firmwareVersions/add');
  };

  const redirectToEditVersion = () => {
    navigate(`/firmwareVersions/edit/${selectedVersions[0].id}`);
  };

  const deleteMultipleVersions = () => {
    const ids = selectedVersions.map((version) => version.id);
    deleteFirmwareVersions(ids);
  };

  const activateDeactivateVersion = (method: string) => {
    const selectedVersion = selectedVersions[0];
    const payload = {
      id: selectedVersion.id,
      name: selectedVersion.name,
      isProduction: selectedVersion.isProduction,
      active: method === Strings.versions.activate,
      minWifiModuleVersionId: selectedVersion.minWifiModuleVersionId,
      wifiFileId: selectedVersion.wifiFileId,
      minWifiModuleVersionName: selectedVersion.minWifiModuleVersionName,
      otaFile: null,
      customUrl: selectedVersion.customUrl
    };
    const formData = convertToFormData(payload);
    editFirmwareVersion({ body: formData, versionId: selectedVersion.id });
  };

  const promoteToProduction = () => {
    const selectedVersion = selectedVersions[0];
    const payload = {
      id: selectedVersion.id,
      name: selectedVersion.name,
      isProduction: true,
      active: selectedVersion.active,
      minWifiModuleVersionId: selectedVersion.minWifiModuleVersionId,
      wifiFileId: selectedVersion.wifiFileId,
      minWifiModuleVersionName: selectedVersion.minWifiModuleVersionName,
      otaFile: null,
      customUrl: selectedVersion.customUrl
    };
    const formData = convertToFormData(payload);
    editFirmwareVersion({ body: formData, versionId: selectedVersion.id });
  };

  const redirectToUploadedFiles = () => {
    navigate(`/firmwareVersions/${selectedVersions[0].id}/files`);
  };

  useEffect(() => {
    if (isSuccessDeleteFirmwareVersions) {
      reloadTable();
      toast.success(Strings.versions.successDeleteVersion);
    }
  }, [isSuccessDeleteFirmwareVersions]);

  useEffect(() => {
    if (successEdit) {
      reloadTable();
      toast.success(Strings.versions.statusChanged);
    }
  }, [successEdit]);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.versions}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip
                title={Strings.versions.addVersion}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={redirectToAddVersion}>
                  <AddCircleOutline />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.common.edit}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled
                  size="small"
                  onClick={redirectToEditVersion}
                  disabled={selectedVersions.length !== 1}
                >
                  <Edit />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.versions.uploadedFiles}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled
                  size="small"
                  onClick={redirectToUploadedFiles}
                  disabled={selectedVersions.length !== 1}
                >
                  <UploadFile />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.versions.activate}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled
                  size="small"
                  onClick={() =>
                    activateDeactivateVersion(Strings.versions.activate)
                  }
                  disabled={selectedVersions.length !== 1}
                >
                  <CheckCircle />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.versions.deactivate}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled
                  size="small"
                  onClick={() =>
                    activateDeactivateVersion(Strings.versions.deactivate)
                  }
                  disabled={selectedVersions.length !== 1}
                >
                  <HighlightOff />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.versions.promoteToProduction}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled
                  size="small"
                  onClick={promoteToProduction}
                  disabled={selectedVersions.length !== 1}
                >
                  <EmojiEvents />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.common.delete}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <DeleteButtonWithoutText
                  onDelete={deleteMultipleVersions}
                  id={selectedVersions[0]?.id}
                  textConfirmation={Strings.versions.confirmDeleteVersion}
                  titleConfirmation={Strings.versions.deleteVersion}
                  disabled={selectedVersions.length === 0}
                />
              </Tooltip>

              <ButtonIconStyled size="small">
                <Tune />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      {isLoadingFirmwareVersions && <LoadingRowsComponent />}
      {isSuccessFirmwareVersions && (
        <VersionsTemplate
          firmwareVersions={firmwareVersions?.data ?? []}
          selectedVersions={selectedVersions}
          setSelectedVersions={setSelectedVersions}
        />
      )}
    </>
  );
};
