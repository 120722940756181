import { Box, Button, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StatusProcess = styled(Box)<{ IsActive?: boolean }>`
  && {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin: 0 auto;
    margin: 15px 0;
    background-color: ${({ IsActive }) =>
      IsActive ? colors.green : colors.red};
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;
export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
    text-align: center;
  }
`;

export const StyledButtonName = styled(Button)`
  && {
    cursor: pointer;
    color: ${colors.black};
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 0;
    justify-content: flex-start;
  }
`;

export const SharedWithItem = styled.span`
  && {
    padding: 10px;
    border: 1px solid ${colors.lightGray};
    border-radius: 5px;
    margin: 5px;
    display: inline-block;
  }
`;
