import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { ContentCopy } from '@mui/icons-material';
import { Column } from 'primereact/column';
import moment from 'moment';
import { toast } from 'react-toastify';
import { RightSideBar } from '../../../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import { LoadingRowsComponent } from '../../../../components/commons/LoadingRows/LoadingRows';
import { useGetDeviceByLocationMutation } from '../../../../services/deviceByLocation/deviceByLocationService';
import { SendStaticOrSingletonMethod } from '../../../device/sendStaticOrSingleMethod/sendStaticOrSingleMethod';
import { AddDeviceBar } from '../../../device/addDeviceBar/addDeviceBar';
import { DetailsDevice } from '../../../device/detailsDevice/detailsDevice';
import { DeviceByLocation } from '../../../../types/Device/DeviceInterfaces';
import { DeviceListByLocationProps } from './deviceListByLocationProps';
import { MultipleDevicesSelectedMethods } from '../../../device/multipleDeviceSelectedMethods/multipleDevicesSelectedMethods';
import { SetWifiCredentialsComponent } from '../setWifiCredentials/setWifiCredentials';
import { SendDirectFOTA } from '../../../device/directFOTA/sendDirectFOTA';
import { AddNoteSideBar } from '../../../../components/commons/notes/addNoteSideBar/addNoteSideBar';
import Strings from '../../../../i18n';
import { DeleteButtonWithoutText } from '../../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { useDeleteScheduleFirmwareUpdateMutation } from '../../../../services/firmwareVersions/firmwareVersionsService';
import { StyledButtonName } from '../../allLocationsList/allLocationListStyles';
import { StyledStatusDevice } from '../../../device/devicesTemplate/devicesTemplateStyle';
import { MoveDeviceToLocation } from '../../../device/moveDeviceToLocation/moveDeviceToLocation';
import { SendDirectMethod } from '../../../device/sendDirectMethod/sendDirectMethod';

export const DeviceListByLocationTemplate = ({
  searchValue,
  openRightSideBar,
  setOpenRightSideBar,
  selectedDevices,
  setSelectedDevices,
  sideBarOpenFor,
  setSideBarOpenFor,
  refreshDevices,
  setRefreshDevices
}: DeviceListByLocationProps) => {
  const { locationId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchQuery = queryParams.get('search');
  const [reorderedData, setReorderedData] = useState<DeviceByLocation[]>([]);

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };

  const [getDevices, { isSuccess, data, isLoading }] =
    useGetDeviceByLocationMutation();
  const clearSelectedDevices = () => {
    setSelectedDevices([]);
  };

  const [
    deleteScheduledFirmware,
    { isSuccess: isSuccessDeleteScheduledFirmware }
  ] = useDeleteScheduleFirmwareUpdateMutation();

  const reloadTable = () => {
    if (locationId) {
      getDevices(+locationId);
      clearSelectedDevices();
    }
  };

  useEffect(() => {
    reloadTable();
  }, []);

  useEffect(() => {
    if (refreshDevices) {
      reloadTable();
      setRefreshDevices(false);
    }
  }, [refreshDevices]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.singletonDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.singletonDevice}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.staticDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.staticDevice}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.addDevice:
        return (
          <AddDeviceBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.details:
        return (
          <DetailsDevice
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            deviceLocationView
          />
        );
      case viewRightSideBarEnum.editMultipleDevices:
        return (
          <MultipleDevicesSelectedMethods
            reloadTable={() => reloadTable}
            openRightSideBarForMethod={openRightSideBarForMethod}
            selectedDevice={selectedDevices}
            openRightSideBar={openRightSideBar}
            setOpenRightSideBar={setOpenRightSideBar}
            deviceLocationView
            clearSingleDevice={() => {}}
          />
        );
      case viewRightSideBarEnum.SetWifiCredentials:
        return (
          <SetWifiCredentialsComponent
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={() => reloadTable()}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
            openRightSideBarForMethod={openRightSideBarForMethod}
          />
        );
      case viewRightSideBarEnum.sendDirectMethodText:
        return (
          <SendDirectMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.directFOTA:
        return (
          <SendDirectFOTA
            deviceIds={selectedDevices.map((item) => ({
              deviceId: item.deviceId,
              productTypeId: item.productTypeId
            }))}
            reloadTable={async () => reloadTable()}
            clearSelectedDevices={clearSelectedDevices}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.moveLocation:
        return (
          <MoveDeviceToLocation
            reloadTable={reloadTable}
            ids={selectedDevices.map((item) => item.deviceId)}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.addNote:
        return (
          <AddNoteSideBar
            closeSideBar={() => setOpenRightSideBar(false)}
            targetId={selectedDevices[0].deviceId}
            type="device"
          />
        );
      default:
        return (
          <AddDeviceBar
            reloadTable={async () => reloadTable()}
            openRightSideBarForMethod={openRightSideBarForMethod}
            openRightSideBar={openRightSideBar}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
    }
  };

  const filterData = () => {
    setReorderedData(
      data?.filter((item) => {
        const searchValueLowerCase = searchValue.toLowerCase();

        let includesSearchValue = false;

        includesSearchValue = item.deviceId
          .toLowerCase()
          .includes(searchValueLowerCase);

        return includesSearchValue;
      }) ?? []
    );
  };

  useEffect(() => {
    if (selectedDevices.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.details);
    } else if (selectedDevices.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.editMultipleDevices);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenRightSideBar(false);
    }
  }, [selectedDevices]);

  const reorderDevices = (deviceList: DeviceByLocation[], idToMove: string) => {
    const usersArrayCopy = [...deviceList];
    return usersArrayCopy.sort((a, b) => {
      if (a.deviceId === idToMove) return -1;
      if (b.deviceId === idToMove) return 1;
      return 0;
    });
  };
  useEffect(() => {
    if (searchQuery) {
      setOpenRightSideBar(false);
      setSelectedDevices(
        data?.filter((item) => item.deviceId === searchQuery) ?? []
      );
      setReorderedData(reorderDevices(data ?? [], searchQuery));
    } else {
      setReorderedData(data ?? []);
    }
  }, [isSuccess, searchQuery]);

  const getDatabaseLocal = (rowData: DeviceByLocation) => {
    if (rowData.dataBaseStatus) {
      const utcDate = moment.utc(rowData.lastUpdate);
      const browserTime = utcDate.local();
      return (
        <StyledStatusDevice status={rowData.connectionStatus}>
          {rowData.statusLabel}
        </StyledStatusDevice>
      );
    }
    return '';
  };

  useEffect(() => {
    if (isSuccessDeleteScheduledFirmware) {
      reloadTable();
      toast.success(Strings.serverAccess.scheduleFirmwareUpdate.deleteSuccess);
    }
  }, [isSuccessDeleteScheduledFirmware]);

  const getTargetWithDeleteButton = (rowData: DeviceByLocation) => {
    return rowData.firmwareUpdate ? (
      <>
        <DeleteButtonWithoutText
          onDelete={() => {
            deleteScheduledFirmware([
              rowData.firmwareUpdate.firmwareScheduleId
            ]);
          }}
          id={rowData.id.toString()}
          titleConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmwareTitle
          }
          textConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmware
          }
        />
        {rowData.firmwareUpdate.firmwareTarget}
      </>
    ) : (
      <span />
    );
  };
  const getDeviceId = (item: DeviceByLocation) => {
    return (
      <Grid container>
        <Tooltip title={Strings.common.copyText} placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(item.deviceId);
              toast.success(Strings.common.copiedText);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
        <StyledButtonName>{item.deviceId}</StyledButtonName>
      </Grid>
    );
  };
  return (
    <>
      {isLoading && <LoadingRowsComponent />}
      {isSuccess && reorderedData && (
        <Box>
          <DataTable
            selection={selectedDevices}
            onSelectionChange={(e) => {
              setSelectedDevices(e.value);
            }}
            selectionMode="checkbox"
            value={reorderedData}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={reorderedData && reorderedData.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              25,
              reorderedData ? reorderedData.length : 50
            ]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column selectionMode="multiple" frozen />
            <Column field="product" header="Product" sortable />
            <Column
              field="statusLabel"
              header="Online / Offline"
              sortable
              body={getDatabaseLocal}
            />
            <Column field="productType" header="Model" sortable />
            <Column
              field="deviceId"
              header="Device ID"
              sortable
              body={getDeviceId}
            />
            <Column
              field="firmwareVersion"
              header="Firmware Version "
              sortable
            />
            <Column
              field="firmwareUpdate.firmwareTarget"
              header="Pending FOTA"
              sortable
              body={getTargetWithDeleteButton}
            />
          </DataTable>
        </Box>
      )}

      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
          setSideBarOpenFor(viewRightSideBarEnum.addDevice);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
