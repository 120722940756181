import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Strings from '../../../i18n';
import {
  ButtonStyled,
  ButtonStyledCancel,
  ButtonsContainer,
  InputStyled,
  TitleStyled,
  noBorderStyle
} from './addDeviceToLocationStyles';
import { AddDeviceToLocationProps } from './addDeviceToLocationProps';
import { usePutDeviceToLocationMutation } from '../../../services/deviceByLocation/deviceByLocationService';
import { colors } from '../../../constants/colors';
import { useGetUserSelectedLocationMutation } from '../../../services/device/deviceService';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { LocationUserSelected } from '../../../types/location/location';
import { ItemLocationSelected } from '../../../components/itemLocationSelected/itemLocationSelected';

export const AddDeviceToLocation = ({
  reloadTable,
  ids = [],
  setOpenRightSideBar,
  clearSelectedDevices
}: AddDeviceToLocationProps) => {
  const contextUser = useSelector(selectCurrentUser);
  const [filterText, setFilterText] = useState('');
  const [preLimit, setPreLimit] = useState(0);
  const [beforeLimit, setBeforeLimit] = useState(5);
  const [locationSelected, setLocationSelected] = useState(0);
  const [locationsPreferred, setLocationsPreferred] = useState<
    LocationUserSelected[]
  >([]);
  const [getLocations, { isSuccess: isSuccessLocations, data: dataLocations }] =
    useGetUserSelectedLocationMutation();
  const [
    postDeviceToLocations,
    { isSuccess: isSuccessPostDevice, data: dataPost, isLoading }
  ] = usePutDeviceToLocationMutation();

  useEffect(() => {
    if (isSuccessLocations && dataLocations) {
      setLocationsPreferred(dataLocations.filter((loc) => loc.order !== 0));
    }
  }, [isSuccessLocations]);

  useEffect(() => {
    if (isSuccessPostDevice) {
      toast.success('Added device(s) to location successfully');
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessPostDevice]);

  useEffect(() => {
    getLocations(contextUser.userId?.toString() || '');
  }, []);

  const filteredItems = dataLocations?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const items = filterText.length ? filteredItems : locationsPreferred;
  return (
    <>
      {isSuccessLocations && dataLocations && (
        <Box height="100%">
          <TitleStyled variant="h5">
            {Strings.locations.selectExistingLocation}
          </TitleStyled>
          <Box
            height="100%"
            bgcolor={colors.white}
            padding={2}
            marginTop={3}
            borderRadius={2}
          >
            <Grid direction="row" container>
              <Typography
                fontSize={13}
                color={colors.darkGray}
                marginTop={1}
                marginLeft={2}
              >
                {Strings.locations.searchByNameOwnerAddress}
              </Typography>
              <Grid
                item
                xs={12}
                bgcolor={colors.lightGray}
                padding={1}
                borderRadius={2}
              >
                <InputStyled
                  sx={noBorderStyle}
                  fullWidth
                  onChange={(e) => setFilterText(e.target.value)}
                />

                {items &&
                  items.slice(preLimit, beforeLimit).map((loc) => (
                    <ItemLocationSelected
                      selected={loc.id === locationSelected}
                      action={() => {
                        setLocationSelected(loc.id);
                      }}
                      locationName={loc.name}
                      ownerName={loc.owner}
                    />
                  ))}
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                marginTop={1}
                marginBottom={1}
              >
                <ButtonStyledCancel
                  disabled={preLimit === 0 && beforeLimit === 5}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setPreLimit((prev) => prev - 5);
                    setBeforeLimit((prev) => prev - 5);
                  }}
                >
                  {Strings.common.previous}
                </ButtonStyledCancel>
                <ButtonStyled
                  disabled={items && items?.length <= beforeLimit}
                  onClick={() => {
                    setPreLimit((prev) => prev + 5);
                    setBeforeLimit((prev) => prev + 5);
                  }}
                  variant="contained"
                  loading={isLoading}
                  size="small"
                >
                  {Strings.common.next}
                </ButtonStyled>
              </Grid>
            </Grid>
            <ButtonsContainer height="10%">
              <ButtonStyledCancel
                variant="contained"
                size="large"
                onClick={() => {
                  setOpenRightSideBar(false);
                  clearSelectedDevices();
                }}
              >
                {Strings.common.cancel}
              </ButtonStyledCancel>
              <ButtonStyled
                onClick={() =>
                  postDeviceToLocations({
                    ids,
                    locationId: locationSelected
                  })
                }
                disabled={!locationSelected}
                variant="contained"
                loading={isLoading}
                size="large"
              >
                {Strings.common.select}
              </ButtonStyled>
            </ButtonsContainer>
          </Box>
        </Box>
      )}
      <div> </div>
    </>
  );
};
