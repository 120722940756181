import { Link } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import styled from 'styled-components';
import ListItemButton from '@mui/material/ListItemButton';
import { colors } from '../../constants/colors';

export const ListLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${colors.black};
    background-color: ${colors.white};
  }
`;

export const ListItemLink = styled(ListItemButton)`
  && {
    border-radius: 5px;
    span: {
      font-weight: 400;
      margin-left: 10px;
    }
    &.Mui-selected {
      background-color: ${colors.lightBlue};
      color: ${colors.blue};
    }
  }
`;

export const ListItemSvg = styled(ListItemIcon)<{
  selected: boolean;
}>`
  && {
    color: ${({ selected }) => (selected ? colors.blue : colors.black)};
  }
`;
