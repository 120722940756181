import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { ErrorText } from '../../../components/layout/main/mainStyles';
import { sendDirectMethodValidator } from '../../../utils/validators';
import Strings from '../../../i18n';
import { useSendDirectMethodMutation } from '../../../services/device/deviceService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';

interface SendDirectMethodProps {
  deviceIds: string[];
  setOpenRightSideBar: (value: boolean) => void;
  clearSelectedDevices: () => void;
}
export const SendDirectMethod = ({
  deviceIds,
  setOpenRightSideBar,
  clearSelectedDevices
}: SendDirectMethodProps) => {
  const [
    sendDirect,
    { isSuccess: isSuccessStatic, data: dataStatic, isLoading: isLoadingStatic }
  ] = useSendDirectMethodMutation();

  const sendInfo = (data: { methodValue: string }) => {
    const info = JSON.parse(data.methodValue);
    const payload = {
      plainStringCommand: JSON.stringify(info),
      deviceIds
    };
    sendDirect(payload);
  };

  const closeBar = () => {
    setOpenRightSideBar(false);
    clearSelectedDevices();
  };

  useEffect(() => {
    if (isSuccessStatic) {
      toast.success(Strings.devices.directMethodSentSuccess);
      closeBar();
    }
  }, [isSuccessStatic]);

  return (
    <Formik
      enableReinitialize
      validationSchema={sendDirectMethodValidator}
      initialValues={{
        methodValue: ''
      }}
      onSubmit={(values) => {
        sendInfo({
          methodValue: values.methodValue
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        setFieldTouched,
        errors,
        touched
      }) => (
        <>
          <StyledContainerItems>
            <StyledTitleSideBar>
              {Strings.serverAccess.devices.sendDirectMethodLabel}
            </StyledTitleSideBar>

            <SideBarEditablePropertyItem
              item={{
                label: Strings.serverAccess.devices.methodValueLabel,
                type: 'inputTextArea',
                currentValue: values.methodValue,
                nameAndId: 'methodValue',
                onChange: handleChange('methodValue'),
                onBlur: () => setFieldTouched('methodValue')
              }}
            />
            {touched.methodValue && errors.methodValue && (
              <ErrorText>{errors.methodValue}</ErrorText>
            )}
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <button
              type="button"
              className="btn-cancel"
              onClick={() => {
                setOpenRightSideBar(false);
                clearSelectedDevices();
              }}
            >
              {Strings.common.cancel}
            </button>
            <button
              type="button"
              className="btn-save"
              disabled={!isValid}
              onClick={() => handleSubmit()}
            >
              {Strings.common.send}
            </button>
          </StyledContainerFooterSideBar>
        </>
      )}
    </Formik>
  );
};
