import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { INoteItemList } from '../../../types/notes/notesInterface';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { useDeleteNoteMutation } from '../../../services/notes/notesService';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';

interface SingleDetailsNoteProps {
  closeSideBar: () => void;
  selectedNote: INoteItemList;
  reloadTable: () => void;
  openToViewNote: (selected: INoteItemList) => void;
  openSideBarForMethod: (method: number) => void;
}
export const SingleDetailsNote = ({
  closeSideBar,
  selectedNote,
  reloadTable,
  openToViewNote,
  openSideBarForMethod
}: SingleDetailsNoteProps) => {
  const [deleteNote, { isSuccess }] = useDeleteNoteMutation();

  const deleteSelectedNote = () => {
    deleteNote({ id: selectedNote.id, type: selectedNote.type });
  };

  const viewNote = () => {
    openToViewNote(selectedNote);
  };
  const editNote = () => {
    openSideBarForMethod(viewRightSideBarEnum.editNote);
  };

  useEffect(() => {
    if (isSuccess) {
      reloadTable();
      toast.success(Strings.notes.successDeleteNote);
    }
  }, [isSuccess]);

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.details}</StyledTitleSideBar>
        <SideBarSimpleMethodItem
          name={Strings.notes.viewNote}
          action={() => {
            viewNote();
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.notes.editNote}
          action={() => {
            editNote();
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteSelectedNote()}
          titleConfirmation={Strings.notes.deleteNote}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.notes.deleteNoteTitle}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
