import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledContainerFooterSideBar,
  StyledTitleSideBar
} from '../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarDeleteActionWithPopup } from '../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import Strings from '../../i18n';
import { IScheduleFirmwareData } from '../../types/Device/DeviceInterfaces';
import { useDeleteScheduleFirmwareUpdateMutation } from '../../services/firmwareVersions/firmwareVersionsService';

interface ScheduleFirmwareSingleDetailsProps {
  closeSideBar: () => void;
  selectedSchedules: IScheduleFirmwareData[];
  reloadTable: () => void;
}
export const ScheduleFirmwareSingleDetails = ({
  closeSideBar,
  selectedSchedules,
  reloadTable
}: ScheduleFirmwareSingleDetailsProps) => {
  const [deleteSchedule, { isSuccess: successDeleteSchedule }] =
    useDeleteScheduleFirmwareUpdateMutation();
  const deleteFirmwareSchedule = () => {
    deleteSchedule(selectedSchedules.map((item) => item.firmwareScheduleId));
  };

  useEffect(() => {
    if (successDeleteSchedule) {
      closeSideBar();
      reloadTable();
      toast.success(Strings.serverAccess.scheduleFirmwareUpdate.deleteSuccess);
    }
  }, [successDeleteSchedule]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.details}</StyledTitleSideBar>
        <SideBarDeleteActionWithPopup
          onDelete={() => deleteFirmwareSchedule()}
          titleConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmwareTitle
          }
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmware}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
