import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import {
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../../i18n';
import { SideBarSimpleMethodItem } from '../../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { useSendFileThroughEmailMutation } from '../../../../../services/device/deviceService';

interface SendFileThroughEmailProps {
  deviceId: string;
  fileNames: string[];
  closeSideBar: () => void;
}
export const SendFileThroughEmail = ({
  deviceId,
  fileNames,
  closeSideBar
}: SendFileThroughEmailProps) => {
  const [sendFiles, { isSuccess }] = useSendFileThroughEmailMutation();

  const sendFilesThroughEmail = () => {
    const payload = {
      deviceId,
      fileNames
    };
    sendFiles(payload);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.devices.emailSentSuccess);
      closeSideBar();
    }
  }, [isSuccess]);
  return (
    <StyledContainerItems>
      <StyledTitleSideBar>
        {Strings.devices.sendFileThroughEmailTitle}
      </StyledTitleSideBar>
      <Grid container>
        <SideBarSimpleMethodItem
          name={Strings.devices.emailToMe}
          action={() => sendFilesThroughEmail()}
          successAction={false}
          textSuccess="success"
        />
      </Grid>
    </StyledContainerItems>
  );
};
