import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { MultipleLocationDetailsBarProps } from '../../multipleLocationsDetails/multipleLocationsDetailsBarProps';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { StyledContainerItems } from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import Strings from '../../../../i18n';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { useDeleteLocationMutation } from '../../../../services/locations/locationsService';
import { useDeleteOrganizationMutation } from '../../../../services/device/deviceService';
import { homeListTypes } from '../../../../constants/commonEnums';

interface MultipleItemsDetailsProps {
  openRightSideBarForMethod: (method: viewRightSideBarEnum) => void;
  reloadTable: () => void;
  selectedItems: {
    id: number;
    type: homeListTypes;
  }[];
  setOpenRightSideBar: (open: boolean) => void;
}
export const MultipleItemsDetails = ({
  openRightSideBarForMethod,
  reloadTable,
  selectedItems,
  setOpenRightSideBar
}: MultipleItemsDetailsProps) => {
  const isAllLocations = selectedItems.every(
    (item) => item.type === homeListTypes.location
  );
  const [deleteLocation, { isSuccess: isSuccessDeleteLocation }] =
    useDeleteLocationMutation();

  const [deleteOrganization, { isSuccess: isSuccessDeleteOrganization }] =
    useDeleteOrganizationMutation();

  useEffect(() => {
    if (isSuccessDeleteLocation) {
      toast.success(Strings.locations.successDeleteLocation);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteLocation]);

  useEffect(() => {
    if (isSuccessDeleteOrganization) {
      toast.success(Strings.organizations.deleteOrganization);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteOrganization]);

  const deleteLocationOrganization = () => {
    const locationIds = selectedItems
      .filter((item) => item.type === homeListTypes.location)
      .map((loc) => loc.id);
    const organizationIds = selectedItems
      .filter((item) => item.type === homeListTypes.organization)
      .map((org) => org.id);
    if (locationIds && locationIds.length) {
      deleteLocation(locationIds);
    }
    if (organizationIds && organizationIds.length) {
      deleteOrganization(organizationIds);
    }
  };

  return (
    <StyledContainerItems>
      <SideBarSimpleMethodItem
        name={Strings.locations.transferOwnership}
        action={() => {
          openRightSideBarForMethod(viewRightSideBarEnum.transferLocation);
        }}
        successAction={false}
        textSuccess="success"
      />
      {isAllLocations && (
        <SideBarSimpleMethodItem
          name={Strings.locations.shareLocationsWithTargets}
          action={() => {
            openRightSideBarForMethod(
              viewRightSideBarEnum.shareLocationsWithTargets
            );
          }}
          successAction={false}
          textSuccess="success"
        />
      )}
      <SideBarSimpleMethodItem
        name={Strings.locations.moveToOrg}
        action={() => {
          openRightSideBarForMethod(viewRightSideBarEnum.moveLocation);
        }}
        successAction={false}
        textSuccess="success"
      />
      <SideBarDeleteActionWithPopup
        onDelete={() => deleteLocationOrganization()}
        titleConfirmation={Strings.locations.deleteLocationTitle}
        textConfirmation={Strings.common.thisActionIsIrreversible}
        textIcon={Strings.common.delete}
      />
    </StyledContainerItems>
  );
};
