import { Box, Button, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StatusProcess = styled(Box)<{ IsActive?: boolean }>`
  && {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background-color: ${({ IsActive }) =>
      IsActive ? colors.green : colors.red};
  }
`;

export const QuitCheckboxHeader = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none'
    }
};

export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
    text-align: center;
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;

export const TooltipStyled = {
  tooltip: {
    sx: {
      bgcolor: colors.tooltipBG,
      color: colors.tooltipText
    }
  }
};

export const StyledButtonName = styled(Button)`
  && {
    color: ${colors.black};
    text-transform: capitalize;
    font-weight: 400;
    font-style: 'Italic';
  }
`;
export const TriangleOrg = styled.img`
  && {
    height: 10px;
    width: 10px;
    margin-bottom: 5px;
  }
`;
