import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ButtonRow, StatusProcess } from './healthTemplateStyles';
import { useGetHealthMonitorMutation } from '../../services/health/healthService';
import { LoadingRowsComponent } from '../../components/commons/LoadingRows/LoadingRows';
import { StyledBoxTable } from '../../components/layout/main/mainStyles';
import { CursorStyleDataGrid } from '../../constants/globalStyles';

export const HealthTemplate = () => {
  const [getHealthMonitor, { isLoading, isSuccess, data }] =
    useGetHealthMonitorMutation();

  useEffect(() => {
    getHealthMonitor('');
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: ({ row }) => {
        return [<StatusProcess IsActive={row.status} />];
      }
    },
    { field: 'processName', headerName: 'Name', flex: 1, width: 200 },
    {
      field: 'createdAtFormatted',
      headerName: 'Last Updated',
      flex: 1,
      width: 200
    },
    { field: 'statusMessage', headerName: 'Message', flex: 1, width: 500 },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      cellClassName: 'actions',
      flex: 1,
      width: 200,
      getActions: ({ row }) => {
        return [
          <Box>
            <ButtonRow variant="text">{row.action.actionText}</ButtonRow>
          </Box>
        ];
      }
    }
  ];

  const getRowId = (row: { processName: string }) => row.processName;
  return (
    <StyledBoxTable>
      {isLoading && <LoadingRowsComponent />}
      {isSuccess && data && (
        <DataGrid
          sx={CursorStyleDataGrid}
          getRowId={getRowId}
          rows={data.data}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 }
            }
          }}
        />
      )}
    </StyledBoxTable>
  );
};
