import { SelectButton } from 'primereact/selectbutton';
import styled from 'styled-components';

export const StyledSelectSwitchAllLightsZones = styled(SelectButton)`
  padding: 0;
  && .p-button {
    padding: 8px !important;
    text-transform: uppercase;
  }
`;
