import React from 'react';
import { Grid } from '@mui/material';
import { InfoToolTipMapProps } from './infoToolTipMapProps';
import { ImageDevice, Container, Tittle, Name } from './infoToolTipMapStyles';

const user = require('../../assets/user.png');

const InfoToolTipMap = ({ makerInfo }: InfoToolTipMapProps) => {
  return (
    <Container container direction="row">
      <Grid item>
        <ImageDevice src={user} />
      </Grid>
      <Grid item>
        <Tittle>{makerInfo.address}</Tittle>
        <Name>{makerInfo.name}</Name>
      </Grid>
    </Container>
  );
};

export default InfoToolTipMap;
