import React from 'react';
import { IGroup } from '../../../../types/Groups/groupsInterfaces';
import { StyledIncludedLightSpan } from '../groupsStyle';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { SideBarReadOnly } from '../../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import {
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import Strings from '../../../../i18n';
import { useDeleteGroupMutation } from '../../../../services/groups/groupsService';

interface detailsGroupProps {
  reloadTable: () => void;
  group: IGroup;
}

export const DetailsGroupBar = ({ reloadTable, group }: detailsGroupProps) => {
  const [deleteGroupById, { isSuccess: isSuccessDelete }] =
    useDeleteGroupMutation();

  const deleteGroup = () => {
    deleteGroupById(group.groupId);
  };
  return (
    <div>
      <StyledContainerItems>
        <StyledTitleSideBar>{group.shortName}</StyledTitleSideBar>
        <SideBarReadOnly
          readOnlyItems={[
            {
              name: Strings.common.name,
              value: group.groupName
            },
            {
              name: 'Lights',
              value: group.lights.map((light: any) => light.name).join(', ')
            },
            {
              name: 'Is On',
              value: group.isOn ? 'Yes' : 'No'
            }
          ]}
        />

        <SideBarDeleteActionWithPopup
          onDelete={() => deleteGroup()}
          titleConfirmation={Strings.groups.deleteGroup}
          textConfirmation={Strings.common.thisActionIsIrreversible}
          textIcon={Strings.groups.confirmDeleteGroup}
        />
      </StyledContainerItems>
    </div>
  );
};
