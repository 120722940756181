import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import { Rating } from 'primereact/rating';

export const EffectMenuDots = styled.button`
  background-color: rgba(137, 137, 137, 0.5);
  border: none;
  color: white;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  border-top-right-radius: 5px;
  &:hover {
    background-color: transparent;
  }
`;

export const StyledEffectMenuItem = styled(MenuItem)`
  && {
    border: 1px solid;
    margin: 0 15px;
    border-radius: 5px;
    margin-top: 6px;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  &:hover div {
    opacity: 1;
  }
`;

export const PlayIconOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

export const StyledEffectVote = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  background-color: transparent;
  font-size: 10px;
  text-transform: uppercase;
`;

export const StyledIconPlay = styled(PlayCircleOutline)`
  && {
    color: white;
    font-size: 50px;
  }
`;

export const StyledRateContainer = styled.div`
  padding: 0.7rem 0px;
  font-size: 0.875rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 0.5rem 0px;
  text-transform: capitalize;
  font-weight: 600;
  color: rgb(46, 108, 246);
  border: none;
`;

export const StyledRatingSmall = styled(Rating)`
  && {
    font-size: 14px;

    .p-rating-item {
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;
