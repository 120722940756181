import { ArrowBackIos, Tune } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import Strings from '../../i18n';
import { ButtonIconStyled } from '../devices/devicesStyles';
import { GlobalDeviceAnnounceListTemplate } from '../../templates/deviceAnnounce/globalDeviceAnnounceTemplate';

export const GlobalDeviceAnnounceView = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.globalDeviceAnnounce}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      <GlobalDeviceAnnounceListTemplate />
    </>
  );
};
