import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { LocationItem } from '../../../components/locationItem/locationItem';
import { selectedLocationsByUser } from '../../../store/slices/locations/location';

export const LocationsTemplate = () => {
  const locations = useSelector(selectedLocationsByUser);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} md={8}>
          <Box>
            <Grid container spacing={2} marginTop={2}>
              {locations.map((element) => (
                <LocationItem location={element} />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
