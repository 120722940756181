import { createApi } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { DeviceByLocation } from '../../types/Device/DeviceInterfaces';
import { PieChartStatus } from '../../constants/enums/dashboardEnums';

const customFetch = customFetchBase(config.baseUrlDealers);
export const DeviceByLocationService = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getDeviceToAssign: builder.mutation<{ id: number; deviceId: string }[], {}>(
      {
        query: () => ({
          url: '/api/Device/Location',
          method: 'GET'
        })
      }
    ),
    getDeviceByLocation: builder.mutation<DeviceByLocation[], number>({
      query: (locationId) => ({
        url: `/api/Device/Location/${locationId}`,
        method: 'GET',
        responseHandler: async (response) => {
          const data = await response.json();
          return data.map((device: DeviceByLocation) => ({
            ...device,
            statusLabel:
              device.connectionStatus === PieChartStatus.onlineMoreThan15 ||
              device.connectionStatus === PieChartStatus.onlineLessThan15
                ? `On since ${
                    device.lastUpdate !== '1/1/0001 12:00:00 AM'
                      ? moment
                          .utc(device.lastUpdate)
                          .local()
                          .format('YYMMDD HH:mm')
                      : '-'
                  }`
                : `Off since ${
                    device.lastUpdate !== '1/1/0001 12:00:00 AM'
                      ? moment
                          .utc(device.lastUpdate)
                          .local()
                          .format('YYMMDD HH:mm')
                      : '-'
                  }`
          }));
        }
      })
    }),
    deleteDeviceFromLocation: builder.mutation<{}, number[]>({
      query: (deviceId: number[]) => ({
        url: `api/Device/Location?ids=${deviceId.map((id) => id).join(',')}`,
        method: 'DELETE'
      })
    }),
    putDeviceToLocation: builder.mutation<
      {}[],
      { ids: number[]; locationId: number }
    >({
      query: (payload) => ({
        url: `/api/Device/Location`,
        method: 'PUT',
        body: payload
      })
    }),
    moveDeviceToLocation: builder.mutation<
      {},
      { ids: string[]; locationId: number }
    >({
      query: (payload) => ({
        url: `/api/Devices/MoveDevices`,
        method: 'POST',
        body: {
          macAddresses: payload.ids,
          targetLocationId: payload.locationId
        }
      })
    })
  })
});

export const {
  useGetDeviceToAssignMutation,
  useGetDeviceByLocationMutation,
  useDeleteDeviceFromLocationMutation,
  usePutDeviceToLocationMutation,
  useMoveDeviceToLocationMutation
} = DeviceByLocationService;
