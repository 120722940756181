/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import {
  defaultColor,
  extendColorsList,
  standardColorList,
  whiteColors
} from '../../../constants/ColorList';
import { ColorV2 } from '../../../types/colorsModel';
import {
  LightCapabilitiesEnum,
  TAB_COLOR_INDEX
} from '../../../constants/commonEnums';
import { checkCapability } from '../../../utils/lights';
import Strings from '../../../i18n';
import { AllColorList } from '../../../types/allColorListModel';
import {
  ColorItem,
  StyledColorButton,
  StyledColorDialog,
  StyledColorListSelectorBtn,
  StyledGrid,
  StyledTitleColorList
} from '../ColorSelector/ColorSelectorStyles';

interface ColorSelectorProps {
  status: {
    colorId: number | null;
  };
  capabilities: { name: string }[];
  onColorChange: (color: ColorV2) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  emitSelectionColor: ({
    brightness,
    colorId
  }: {
    brightness: number;
    colorId: number;
  }) => void;
}

export const ColorSelectionModal = ({
  status,
  capabilities,
  onColorChange,
  visible,
  setVisible,
  emitSelectionColor
}: ColorSelectorProps) => {
  const [colorSelected, setColorSelected] = useState<ColorV2>(defaultColor);

  const [colorsByCapability, setColorsByCapability] = useState<AllColorList>();

  const [currentView, setCurrentView] = useState(TAB_COLOR_INDEX.colors);

  const [tabStatus, setTabStatus] = useState({
    colors: true,
    patterns: false,
    sparkles: false,
    effects: false,
    tapeMeasure: false
  });

  const findColorById = (colors: ColorV2[]) => {
    setColorSelected(
      colors.find((color) => color.id === status.colorId) || defaultColor
    );
  };

  const setNewColor = (color: ColorV2) => {
    setColorSelected(color);
    onColorChange(color);
    setVisible(false);
  };

  useEffect(() => {
    if (status.colorId) {
      findColorById(extendColorsList.allColors);
    }
  }, []);

  useEffect(() => {
    tabStatus.colors = checkCapability(
      [
        LightCapabilitiesEnum.SOLID_COLORS,
        LightCapabilitiesEnum.EXTENDED_SOLID_COLORS,
        LightCapabilitiesEnum.HALLOWEEN_AS_A_SOLID_COLOR,
        LightCapabilitiesEnum.TEMPERATURE,
        LightCapabilitiesEnum.LEGACY_SOLID_COLORS
      ],
      capabilities
    );
    tabStatus.patterns = checkCapability(
      [
        LightCapabilitiesEnum.EXTENDED_PATTERNS,
        LightCapabilitiesEnum.LEGACY_PATTERNS
      ],
      capabilities
    );
    tabStatus.sparkles = checkCapability(
      LightCapabilitiesEnum.SPARKLE,
      capabilities
    );
    tabStatus.effects = checkCapability(
      LightCapabilitiesEnum.TRIM_LIGHT,
      capabilities
    );
    tabStatus.tapeMeasure = checkCapability(
      LightCapabilitiesEnum.TRIM_LIGHT,
      capabilities
    );
    setColorsByCapability(
      checkCapability(LightCapabilitiesEnum.EXTENDED_SOLID_COLORS, capabilities)
        ? extendColorsList
        : standardColorList
    );
  }, []);

  const openDialogSelector = () => {
    setVisible(true);
  };

  const importImage = (icon: string) => {
    const image = require(`../../../assets/images/colors/${icon}`);
    return image;
  };

  return (
    <StyledColorDialog
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: '70vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
    >
      <Grid container textAlign="center" marginTop={4} alignItems="center">
        <StyledGrid item xs={4} show={tabStatus.colors}>
          <StyledColorListSelectorBtn
            visible={tabStatus.colors}
            isActive={currentView === TAB_COLOR_INDEX.colors}
            onClick={() => setCurrentView(TAB_COLOR_INDEX.colors)}
          >
            {Strings.colorSelector.color}
          </StyledColorListSelectorBtn>
        </StyledGrid>
        <StyledGrid item xs={4} show={tabStatus.patterns}>
          <StyledColorListSelectorBtn
            visible={tabStatus.patterns}
            isActive={currentView === TAB_COLOR_INDEX.patterns}
            onClick={() => setCurrentView(TAB_COLOR_INDEX.patterns)}
          >
            {Strings.colorSelector.patterns}
          </StyledColorListSelectorBtn>
        </StyledGrid>
        <StyledGrid item xs={4} show={tabStatus.sparkles}>
          <StyledColorListSelectorBtn
            visible={tabStatus.sparkles}
            isActive={currentView === TAB_COLOR_INDEX.sparkles}
            onClick={() => setCurrentView(TAB_COLOR_INDEX.sparkles)}
          >
            {Strings.colorSelector.sparkles}
          </StyledColorListSelectorBtn>
        </StyledGrid>
        <StyledGrid item xs={4} show={tabStatus.effects}>
          <StyledColorListSelectorBtn
            visible={tabStatus.effects}
            isActive={currentView === TAB_COLOR_INDEX.effects}
            onClick={() => setCurrentView(TAB_COLOR_INDEX.effects)}
          >
            {Strings.colorSelector.effects}
          </StyledColorListSelectorBtn>
        </StyledGrid>
        <StyledGrid item xs={4} show={tabStatus.tapeMeasure}>
          <StyledColorListSelectorBtn
            visible={tabStatus.tapeMeasure}
            isActive={currentView === TAB_COLOR_INDEX.tapeMeasure}
            onClick={() => setCurrentView(TAB_COLOR_INDEX.tapeMeasure)}
          >
            {Strings.colorSelector.tapeMeasure}
          </StyledColorListSelectorBtn>
        </StyledGrid>
      </Grid>
      <Divider />

      {/* START OF COLORS MAPPING */}
      {currentView === TAB_COLOR_INDEX.colors && (
        <StyledGrid
          container
          textAlign="center"
          show={currentView === TAB_COLOR_INDEX.colors}
        >
          <StyledGrid item xs={12} show>
            <StyledTitleColorList>
              {Strings.colorSelector.whiteTemperature}
            </StyledTitleColorList>
            <Grid container>
              {whiteColors.map((color) => (
                <Grid item key={color.id} xs={3} md={2.25} marginBottom={2}>
                  <ColorItem
                    key={color.id}
                    bgColor={color.color}
                    colorName={color.name}
                    bgImage={color.icon ? importImage(color.icon) : ''}
                    active={colorSelected.id === color.id}
                    onClick={() => setNewColor(color)}
                  />
                </Grid>
              ))}
            </Grid>
            <Divider />
          </StyledGrid>
          <StyledGrid item xs={12} show>
            <StyledTitleColorList>
              {Strings.colorSelector.color}
            </StyledTitleColorList>
            <Grid container>
              {colorsByCapability?.solidColors.map((color) => (
                <Grid item key={color.id} xs={3} md={2.25} marginBottom={2}>
                  <ColorItem
                    key={color.id}
                    bgColor={color.color}
                    colorName={color.name}
                    bgImage={color.icon ? importImage(color.icon) : ''}
                    active={colorSelected.id === color.id}
                    onClick={() => setNewColor(color)}
                  />
                </Grid>
              ))}
            </Grid>
          </StyledGrid>
        </StyledGrid>
      )}
      {currentView === TAB_COLOR_INDEX.patterns && (
        <StyledGrid show={currentView === TAB_COLOR_INDEX.patterns}>
          <StyledTitleColorList>
            {Strings.colorSelector.patterns}
          </StyledTitleColorList>
        </StyledGrid>
      )}
      {currentView === TAB_COLOR_INDEX.sparkles && (
        <StyledGrid show={currentView === TAB_COLOR_INDEX.sparkles}>
          <StyledTitleColorList>
            {Strings.colorSelector.sparkles}
          </StyledTitleColorList>
        </StyledGrid>
      )}
      {currentView === TAB_COLOR_INDEX.effects && (
        <StyledGrid show={currentView === TAB_COLOR_INDEX.effects}>
          <StyledTitleColorList>
            {Strings.colorSelector.effects}
          </StyledTitleColorList>
        </StyledGrid>
      )}
      {currentView === TAB_COLOR_INDEX.tapeMeasure && (
        <StyledGrid show={currentView === TAB_COLOR_INDEX.tapeMeasure}>
          <StyledTitleColorList>
            {Strings.colorSelector.tapeMeasure}
          </StyledTitleColorList>
          <Grid container>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Moving"
            />
          </Grid>
        </StyledGrid>
      )}
    </StyledColorDialog>
  );
};
