/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow
} from '@react-google-maps/api';
import { MarkerInfo } from '../../../types/markerCoords';
import { containerStyle } from './locationMapStyles';

import { googleApiKey } from '../../../config/config';
import Strings from '../../../i18n';
import InfoToolTipMap from '../../../components/infoToolTipMap/infoToolTipMap';
import { LocationByUser } from '../../../types/location/location';

const MarkerIcon = require('../../../assets/user.png');

interface LocationMapsProps {
  locations: LocationByUser[] | undefined;
}

export const LocationMaps = ({ locations }: LocationMapsProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedElement, setSelectedElement] = useState<MarkerInfo | null>(
    null
  );
  const [markers, setMarkers] = useState<MarkerInfo[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey
  });

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((x) => {
        bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
      });
      mapInstance.fitBounds(bounds);
      setMap(mapInstance);
    },
    [markers]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (locations) {
      const newMarkers = locations.map((element) => {
        return {
          lat: element.latitude ? +element.latitude : 0,
          lng: element.longitude ? +element.longitude : 0,
          address: element.locationAddress ?? '',
          name: element.locationName
        };
      });
      setMarkers(newMarkers);
    }
  }, [locations]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers.length > 0 &&
        markers.map((element, index) => (
          <Marker
            key={index}
            onMouseOver={() => {
              setSelectedElement(element);
            }}
            onMouseOut={() => {
              setSelectedElement(null);
            }}
            position={element}
            icon={{
              url: MarkerIcon,
              scaledSize: {
                width: 50,
                height: 50,
                equals: () => true
              }
            }}
          >
            {selectedElement?.lat === element.lat &&
              selectedElement.lng === element.lng && (
                <InfoWindow
                  onCloseClick={() => {
                    setSelectedElement(null);
                  }}
                >
                  <InfoToolTipMap makerInfo={selectedElement} />
                </InfoWindow>
              )}
          </Marker>
        ))}
    </GoogleMap>
  ) : (
    <div>{Strings.common.loading}</div>
  );
};
