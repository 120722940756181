import React, { useState } from 'react';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Strings from '../../i18n';
import { Header } from '../../components/header/header';
import { UsersByLocationTemplate } from '../../templates/locations/usersByLocation/usersByLocationTemplate';
import { ButtonIconStyled } from '../users/usersViewStyles';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { IUserInfo } from '../../types/Auth/authInterface';

export const UserByLocationView = () => {
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.editUserPermissions
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };
  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left" />
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.users.users}</StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.addUserToLocation
                  );
                }}
              >
                <PersonAddAltIcon />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <UsersByLocationTemplate
        sideBarOpenFor={sideBarOpenFor}
        openRightSideBarForMethod={openRightSideBarForMethod}
        openedSideBar={openSideBar}
        setOpenSideBar={setOpenSideBar}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    </div>
  );
};
