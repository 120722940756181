import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Start, Place, Launch, Memory } from '@mui/icons-material';
import { IDeviceResultItem } from '../../../../types/Dashboard/globalSearchInterfaces';
import {
  StyledButtonItemWithIcon,
  StyledItemResult,
  styledTooltip
} from '../globalSearchStyles';
import Strings from '../../../../i18n';

interface DeviceResultItemProps {
  data: IDeviceResultItem;
  clearSearchAndClose: () => void;
  showOptions: boolean;
}
export const DeviceResultItemComponent = ({
  data,
  clearSearchAndClose,
  showOptions
}: DeviceResultItemProps) => {
  const navigate = useNavigate();

  const redirectToDevice = (event: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/server-access/devices?search=${data.deviceId}`);
    clearSearchAndClose();
  };
  const redirectToDeviceLocation = (event: React.MouseEvent) => {
    event?.stopPropagation();
    if (data.locationId !== 0 && data.locationId !== undefined) {
      navigate(`/locations/${data.locationId}/devices?search=${data.deviceId}`);
      clearSearchAndClose();
    } else {
      toast.error(Strings.locations.noLocationAssigned);
    }
  };

  const redirectToLocation = (event: React.MouseEvent) => {
    event?.stopPropagation();
    if (data.locationId !== 0 && data.locationId !== undefined) {
      navigate(`/locations/${data.locationId}/applications`);
      clearSearchAndClose();
    } else {
      toast.error(Strings.locations.noLocationAssigned);
    }
  };

  const goToDeviceDetails = (event: React.MouseEvent) => {
    event?.stopPropagation();
    window.open(`/server-access/devices?search=${data.deviceId}`, '_blank');
  };
  return (
    <StyledItemResult onClick={redirectToDevice} showOptions={showOptions}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <h4>{data.deviceId}</h4>
          <span className="d-flex">{`${data.product}: ${data.model}`}</span>
          {data.locationId !== 0 && (
            <span>
              <strong>{Strings.globalSearch.assignedLocation}:</strong>{' '}
              {data.locationName}
            </span>
          )}
        </Grid>
        <Grid
          item
          className="showOnHover"
          xs={12}
          md="auto"
          justifyContent="flex-end"
        >
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToDevice}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToDevice}
          >
            <Start />
          </StyledButtonItemWithIcon>
          {data.locationId !== 0 && (
            <>
              <StyledButtonItemWithIcon
                type="button"
                tooltip={Strings.globalSearch.goToLocation}
                tooltipOptions={{ ...styledTooltip }}
                onClick={redirectToLocation}
              >
                <Place />
              </StyledButtonItemWithIcon>
              <StyledButtonItemWithIcon
                type="button"
                tooltip={Strings.globalSearch.goToDeviceLocation}
                tooltipOptions={{ ...styledTooltip }}
                onClick={redirectToDeviceLocation}
              >
                <Memory />
              </StyledButtonItemWithIcon>
            </>
          )}

          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.openInExternalTab}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToDeviceDetails}
          >
            <Launch />
          </StyledButtonItemWithIcon>
        </Grid>
      </Grid>
    </StyledItemResult>
  );
};
