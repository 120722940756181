import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { ArrowBackIos, Visibility } from '@mui/icons-material';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import parse from 'html-react-parser';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Header } from '../../components/header/header';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox,
  ContainerStyled
} from '../../components/layout/main/mainStyles';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { ButtonIconStyled, TitleStyled } from '../devices/devicesStyles';
import Strings from '../../i18n';
import { useGetNotesMutation } from '../../services/notes/notesService';
import { INoteItemList } from '../../types/notes/notesInterface';
import { StyledButtonName } from '../../templates/locations/locationsList/locationListStyles';
import { SingleDetailsNote } from '../../templates/notes/singleDetailsNote/singleDetailsNote';
import { EditNoteSideBar } from '../../components/commons/notes/editNoteSideBar/editNoteSideBar';

export const MyNotesList = () => {
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = React.useState(false);
  const [textToShow, setTextToShow] = React.useState('');
  const [titleToShow, setTitleToShow] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [openSideFarFor, setOpenSidebarFor] = React.useState(
    viewRightSideBarEnum.empty
  );
  const [selectedNotes, setSelectedNotes] = React.useState<INoteItemList[]>([]);

  const [getNotes, { data, isSuccess }] = useGetNotesMutation();

  useEffect(() => {
    getNotes();
  }, []);
  const reloadTable = () => {
    getNotes();
    setOpenSideBar(false);
    setSelectedNotes([]);
  };
  const redirectToLocation = (locationId: number) => {
    navigate(`/locations/${locationId}/applications`);
  };
  const redirectToUser = (userId: number) => {
    navigate(`/users?searchUser=${userId}`);
  };
  const redirectToDevice = (deviceId: number) => {
    navigate(`/server-access/devices?search=${deviceId}`);
  };

  const closeSideBar = () => {
    setOpenSideBar(false);
  };
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedNotes.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={(selectedNotes && selectedNotes.length > 0) ?? false}
        onChange={() => {
          setSelectedNotes([]);
        }}
      />
    );
  };

  const viewText = (rowData: INoteItemList) => {
    return (
      <ButtonIconStyled
        size="small"
        onClick={() => {
          setTextToShow(rowData.text);
          setTitleToShow(rowData.targetName);
          setVisible(true);
        }}
      >
        <Visibility />
      </ButtonIconStyled>
    );
  };

  const switchComponentsToShowRightSideBar = () => {
    switch (openSideFarFor) {
      case viewRightSideBarEnum.locationDetailsBusinessAccount:
        return '';
      case viewRightSideBarEnum.noteDetails:
        return (
          <SingleDetailsNote
            closeSideBar={closeSideBar}
            selectedNote={selectedNotes[0]}
            reloadTable={reloadTable}
            openSideBarForMethod={setOpenSidebarFor}
            openToViewNote={(selected: INoteItemList) => {
              setTextToShow(selected.text);
              setTitleToShow(selected.targetName);
              setVisible(true);
              closeSideBar();
            }}
          />
        );
      case viewRightSideBarEnum.editNote:
        return (
          <EditNoteSideBar
            selectedNote={selectedNotes[0]}
            closeSideBar={closeSideBar}
            reloadTable={reloadTable}
          />
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (selectedNotes.length === 1) {
      setOpenSidebarFor(viewRightSideBarEnum.noteDetails);

      setOpenSideBar(true);
    } else if (selectedNotes.length > 1) {
      setOpenSideBar(false);
    } else {
      setOpenSidebarFor(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedNotes]);

  const getTargetName = (rowData: INoteItemList) => {
    if (rowData.type === 'Location') {
      return (
        <StyledButtonName
          size="small"
          onClick={() => {
            redirectToLocation(rowData.targetId);
          }}
        >
          {rowData.targetName}
        </StyledButtonName>
      );
    }
    if (rowData.type === 'User') {
      return (
        <StyledButtonName
          size="small"
          onClick={() => {
            redirectToUser(rowData.targetId);
          }}
        >
          {rowData.targetName}
        </StyledButtonName>
      );
    }
    if (rowData.type === 'Device') {
      return (
        <StyledButtonName
          size="small"
          onClick={() => {
            redirectToDevice(rowData.targetId);
          }}
        >
          {rowData.targetName}
        </StyledButtonName>
      );
    }
    return rowData.targetName;
  };

  return (
    <>
      <Header>
        <ContainerStyled>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <TitleStyled>{Strings.notes.myNotes}</TitleStyled>
            </div>
            <div className="col-8 col-sm-4 text-right" />
          </div>
        </ContainerStyled>
      </Header>
      <Box>
        {data && (
          <DataTable
            selection={selectedNotes}
            selectionMode="checkbox"
            onSelectionChange={(e) => {
              setSelectedNotes(e.value);
            }}
            value={data}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={data && data.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column field="date" header="Date" sortable />
            <Column
              field="targetName"
              header="Target"
              sortable
              body={getTargetName}
            />
            <Column field="type" header="Type" sortable />
            <Column field="" header="Note" body={viewText} />
          </DataTable>
        )}
      </Box>
      <RightSideBar open={openSideBar} onMenuClose={() => closeSideBar()}>
        {switchComponentsToShowRightSideBar()}
      </RightSideBar>
      <Dialog
        header={titleToShow}
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        {parse(textToShow)}
      </Dialog>
    </>
  );
};
