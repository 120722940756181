import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PlayCircleOutline } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Rating } from 'primereact/rating';
import { IEffectItemStore } from '../../../../types/effects/effectsStoreInterface';
import Strings from '../../../../i18n';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';

interface EffectStoreListTableProps {
  selectedEffects: IEffectItemStore[];
  data: IEffectItemStore[];
  setSelectedEffects: (value: IEffectItemStore[]) => void;
  reloadTable: () => void;
  openSideBarFor: (value: viewRightSideBarEnum) => void;
}
export const EffectStoreListTable = ({
  selectedEffects,
  data,
  setSelectedEffects,
  reloadTable,
  openSideBarFor
}: EffectStoreListTableProps) => {
  const [openDialogVideo, setOpenDialogVideo] = useState<boolean>(false);

  const handleCloseDialogVideo = () => {
    setOpenDialogVideo(false);
  };

  const [effect, setEffect] = useState<IEffectItemStore>(
    {} as IEffectItemStore
  );

  const renderVideoButton = (rowData: IEffectItemStore) => {
    return (
      <Button
        onClick={() => {
          setEffect(rowData);
          setOpenDialogVideo(true);
        }}
        color="info"
      >
        <PlayCircleOutline />
      </Button>
    );
  };
  const getRating = (rowData: IEffectItemStore) => {
    return (
      <Rating
        value={rowData.rating}
        cancel={false}
        readOnly
        style={{ fontSize: '14px' }}
      />
    );
  };
  return (
    <div>
      <DataTable
        selection={selectedEffects}
        selectionMode="checkbox"
        onSelectionChange={(e) => {
          setSelectedEffects(e.value);
        }}
        value={data}
        size="small"
        scrollable
        paginator={data && data.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
        tableStyle={{ fontSize: '14px' }}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="name" header="Name" sortable />
        <Column field="effectType" header="Type" sortable />
        <Column field="user" header="Owner" sortable />
        <Column field="rating" header="Rating" sortable body={getRating} />
        <Column field="" header="Video" body={renderVideoButton} />
      </DataTable>
      <Dialog
        open={openDialogVideo}
        onClose={handleCloseDialogVideo}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{effect?.name}</DialogTitle>
        <DialogContent>
          <video width="100%" controls autoPlay loop>
            <source src={effect?.videoUrl} type="video/mp4" />
            <track kind="captions" src="" label="English" default />
            {Strings.common.videoNotSupported}
          </video>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogVideo} color="info">
            {Strings.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
