import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { IDeleteButtonProps } from './deleteButtonProps';
import Strings from '../../../i18n';
import { StyledButtonWithLabel } from '../../layout/main/mainStyles';
import { colors } from '../../../constants/colors';

export const DeleteButton = ({
  onDelete,
  id,
  textConfirmation,
  titleConfirmation,
  textIcon
}: IDeleteButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(id);
    handleClose();
  };

  return (
    <>
      <StyledButtonWithLabel
        onClick={() => handleClickOpen()}
        type="button"
        color={colors.red}
      >
        <Delete />
        <Typography variant="inherit" color="initial" component="p">
          {textIcon || Strings.common.delete}
        </Typography>
      </StyledButtonWithLabel>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxHeight: 435
          }
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleConfirmation}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {textConfirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDelete()}
            color="success"
            variant="contained"
          >
            {Strings.common.ok}
          </Button>
          <Button
            onClick={() => handleClose()}
            color="error"
            variant="contained"
          >
            {Strings.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
