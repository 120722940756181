import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';
import { useSetColorTrimLightMutation } from '../../../../services/trimLights/trimLightsService';
import { SwitchOnOff } from '../../../../components/commons/SwitchOnOff/switchOnOff';
import { BrightnessSelectorBtn } from '../../../../components/commons/BrightnessSelector/brightnessSelector';
import { ColorSelector } from '../../../../components/commons/ColorSelector/ColorSelector';
import { LightCapabilitiesEnum } from '../../../../constants/commonEnums';
import Strings from '../../../../i18n';
import { ILightsZonesListProps } from '../lightsZonesProps';
import { useGetLightsZonesByLocationMutation } from '../../../../services/lightsZones/lightsZonesService';
import { ILightZone } from '../../../../types/Lights/lightZoneInterfaces';
import {
  useSendBrightnessCommandMutation,
  useSendColorCommandMutation,
  useSendOnOffCommandMutation
} from '../../../../services/commandsService/commandsService';
import {
  ICommandBrightness,
  ICommandOnOff,
  ICommandSetColor
} from '../../../../types/commandsInterface';
import { AllLightsAndZonesComponent } from '../allLightsAndZones/allLightsAndZonesComponent';
import { useGetGroupsByLocationIdMutation } from '../../../../services/groups/groupsService';

export const LightsZonesList = ({
  selectionModel,
  setSelectionModel,
  searchValue
}: ILightsZonesListProps) => {
  const [setColor, { isSuccess: isSuccessSetColor }] =
    useSendColorCommandMutation();

  const [setBrightness, { isSuccess: isSuccessSetBrightness }] =
    useSendBrightnessCommandMutation();

  const [setSwitch, { isSuccess: isSuccessSetSwitch }] =
    useSendOnOffCommandMutation();

  const [rowIndex, setRowIndex] = useState(-1);

  const { locationId } = useParams();

  const onRowSelect = (event: DataTableSelectEvent) => {};

  const [getLightsZonesByLocation, { isSuccess, data }] =
    useGetLightsZonesByLocationMutation();

  useEffect(() => {
    if (locationId) {
      getLightsZonesByLocation(+locationId);
    }
  }, []);

  const filteredData = data?.filter((item: ILightZone) => {
    const searchValueLowerCase = searchValue.toLowerCase();
    let includesSearchValue = false;
    includesSearchValue = item.name
      .toLowerCase()
      .includes(searchValueLowerCase);

    return includesSearchValue;
  });

  const getCheckbox = (item: any, options: any) => {
    return (
      <Checkbox
        onClick={() => {
          if (selectionModel.length) {
            setSelectionModel([]);
          } else {
            setSelectionModel([item]);
          }
        }}
        checked={selectionModel.includes(item)}
        style={{
          visibility: rowIndex === options.rowIndex ? 'visible' : 'hidden'
        }}
      />
    );
  };

  const getLastUpdated = (item: ILightZone) => {
    return moment(item.lastUpdated).format('YYYY MM DD - HH:mm:ss');
  };

  const sendBrightnessCommand = (payload: ICommandBrightness) => {
    setBrightness(payload);
  };

  const sendColorCommand = (payload: ICommandSetColor) => {
    setColor(payload);
  };

  const sendSwitchCommand = (payload: ICommandOnOff) => {
    setSwitch(payload);
  };

  useEffect(() => {
    if (isSuccessSetColor) {
      toast.success(Strings.trimLights.setColorSuccess);
    }
  }, [isSuccessSetColor]);

  useEffect(() => {
    if (isSuccessSetBrightness) {
      toast.success(Strings.commands.setBrightnessSuccess);
    }
  }, [isSuccessSetBrightness]);

  useEffect(() => {
    if (isSuccessSetSwitch) {
      toast.success(Strings.commands.commandSentSuccessfully);
    }
  }, [isSuccessSetSwitch]);

  const getCommands = (item: ILightZone) => {
    return (
      <div className="d-flex align-items-center my-1">
        <SwitchOnOff
          lightingStatusId={item.lightingStatusId}
          onSwitch={(value) =>
            sendSwitchCommand({
              id: item.id,
              method: value ? 'On' : 'Off',
              type: item.isZone ? 'Zone' : 'Light'
            })
          }
        />
        <BrightnessSelectorBtn
          lightBrightnessId={item.lightBrightnessId}
          onChange={(brightnessId) =>
            sendBrightnessCommand({
              brightness: brightnessId,
              id: item.id,
              type: item.isZone ? 'Zone' : 'Light'
            })
          }
        />
        <ColorSelector
          status={{
            colorId: item.colorId,
            patternSpeed: item.patternSpeed,
            sparkleDuration: item.sparkleDuration
          }}
          capabilities={[{ name: LightCapabilitiesEnum.EXTENDED_SOLID_COLORS }]}
          onColorChange={(colorId) => {
            sendColorCommand({
              id: item.id,
              colorId,
              type: item.isZone ? 'Zone' : 'Light'
            });
          }}
        />
      </div>
    );
  };

  return (
    <DataTable
      onRowMouseEnter={(e) => {
        setRowIndex(e.index);
      }}
      onRowMouseLeave={() => {
        setRowIndex(-1);
      }}
      rowHover
      header={
        <AllLightsAndZonesComponent
          onSuccessCommand={() =>
            getLightsZonesByLocation(locationId ? +locationId : 0)
          }
        />
      }
      value={filteredData}
      size="small"
      scrollable
      paginator={filteredData && filteredData.length >= 50}
      selectionPageOnly
      rows={50}
      rowsPerPageOptions={[5, 10, 25, filteredData ? filteredData.length : 50]}
      tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
      onRowSelect={onRowSelect}
    >
      <Column body={getCheckbox} headerStyle={{ width: '3rem' }} />
      <Column field="name" header="Name" sortable />
      <Column
        field="lastUpdated"
        header="Last Updated"
        body={getLastUpdated}
        sortable
      />
      <Column field="" header="Commands" sortable body={getCommands} />
    </DataTable>
  );
};
