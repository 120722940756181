import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import './sceneEvent.css';
import { useParams } from 'react-router-dom';
import { useGetSceneListByLocationMutation } from '../../../../../services/scenes/sceneService';

interface SceneEventProps {
  emitSelectedScene: (selectedScene: any) => void;
}

const img = require('../../../../../assets/images/eventsType/ScenesActive.png');

export const SceneEvent = ({ emitSelectedScene }: SceneEventProps) => {
  const { locationId } = useParams();
  const [selectedScene, setSelectedScene] = useState<any>(null);
  const [getScenes, { isSuccess, data }] = useGetSceneListByLocationMutation();

  useEffect(() => {
    if (selectedScene) {
      emitSelectedScene(selectedScene);
    }
  }, [selectedScene]);
  useEffect(() => {
    if (locationId) {
      getScenes(+locationId);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="container-scenes">
          <img src={img} alt="scenes" height="60" />
          {data && (
            <Dropdown
              value={selectedScene}
              options={data}
              onChange={(e) => setSelectedScene(e.value)}
              optionLabel="name"
              placeholder="Select a Scene"
              className="w-100 d-flex align-items-center"
            />
          )}
        </div>
      </div>
      {data && selectedScene && (
        <Accordion>
          <AccordionTab header="View Summary">
            {selectedScene.actions.map((item: any, index: any) => (
              <p key={index} className="summary-scene">
                - {item.summary} {item.targets}
              </p>
            ))}
          </AccordionTab>
        </Accordion>
      )}
    </div>
  );
};
