import { Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const StyledButtonName = styled(Button)<{ needsInformation: boolean }>`
  && {
    color: ${colors.black};
    text-transform: capitalize;
    font-weight: 400;
    font-style: 'Italic';
  }
`;

export const StyledCreateBusinessAccount = styled(Button)`
  && {
    display: flex;
    margin: 20px auto;
  }
`;
