import React, { useEffect } from 'react';
import { SetWifiCredentialsProps } from './setWifiCredentialsProps';
import { useDeviceSetWifiCredentialsMutation } from '../../../../services/device/deviceService';
import { SelectItemMethod } from '../../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';
import {
  ButtonStyled,
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';

export const SetWifiCredentialsComponent = ({
  deviceIds,
  setOpenRightSideBar,
  clearSelectedDevices
}: SetWifiCredentialsProps) => {
  const [ssidName, setSsidName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [setWifiCredentials, { isSuccess, isLoading }] =
    useDeviceSetWifiCredentialsMutation();
  const sendWifiCredentials = () => {
    if (deviceIds.length > 0) {
      setWifiCredentials({
        deviceIds,
        ssid: ssidName,
        password
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setOpenRightSideBar(false);
      clearSelectedDevices();
    }
  }, [isSuccess]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.serverAccess.devices.setWifiCredentialsLabel}
        </StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: Strings.devices.ssidLabel,
            type: 'inputText',
            currentValue: ssidName,
            placeholder: Strings.devices.ssidPlaceholder,
            onChange: (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSsidName(e.target.value.toString())
          }}
        />
        <SideBarEditablePropertyItem
          item={{
            label: Strings.devices.passwordLabel,
            type: 'inputText',
            currentValue: password,
            placeholder: Strings.devices.passwordPlaceholder,
            onChange: (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setPassword(e.target.value.toString())
          }}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            setOpenRightSideBar(false);
            clearSelectedDevices();
          }}
        >
          {Strings.common.cancel}
        </button>
        <button
          type="button"
          className="btn-save"
          disabled={ssidName === '' || password === ''}
          onClick={sendWifiCredentials}
        >
          {Strings.common.add}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
