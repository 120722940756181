import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ContainerFormProfile = styled(Grid)`
  background-color: ${colors.white};
`;
export const ErrorText = styled.p`
  font-size: 10px;
  color: ${colors.red};
`;

export const ContainerForm = styled(Grid)`
  background: ${colors.white};
  border-radius: 20px;
`;
