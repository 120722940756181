import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const sliceLocation = createSlice({
  name: 'systemPermissions',
  initialState: {
    permissionReadWriteUsers: false,
    permissionReadWriteDevice: false,
    permissionReadWriteLocations: false,
    permissionReadWriteOrganizations: false,
    permissionReadWriteDeviceAnnounce: false
  } as {
    permissionReadWriteUsers: boolean;
    permissionReadWriteDevice: boolean;
    permissionReadWriteLocations: boolean;
    permissionReadWriteOrganizations: boolean;
    permissionReadWriteDeviceAnnounce: boolean;
  },
  reducers: {
    setPermissionsReadWriteSystemDevices: (
      state,
      { payload: permissionReadWriteDevices }: PayloadAction<boolean>
    ) => {
      state.permissionReadWriteDevice = permissionReadWriteDevices;
    },
    setPermissionsReadWriteSystemUsers: (
      state,
      { payload: permissionReadWriteUsers }: PayloadAction<boolean>
    ) => {
      state.permissionReadWriteUsers = permissionReadWriteUsers;
    },
    setPermissionsReadWriteLocations: (
      state,
      { payload: permissionReadWriteLocations }: PayloadAction<boolean>
    ) => {
      state.permissionReadWriteLocations = permissionReadWriteLocations;
    },
    setPermissionsReadWriteOrganizations: (
      state,
      { payload: permissionReadWriteOrganizations }: PayloadAction<boolean>
    ) => {
      state.permissionReadWriteOrganizations = permissionReadWriteOrganizations;
    },
    setPermissionReadWriteDeviceAnnounce: (
      state,
      { payload: permissionReadWriteDeviceAnnounce }: PayloadAction<boolean>
    ) => {
      state.permissionReadWriteDeviceAnnounce =
        permissionReadWriteDeviceAnnounce;
    }
  }
});
export default sliceLocation.reducer;
export const selectSystemDevicesReadWrite = (state: RootState) =>
  state.systemPermissions.permissionReadWriteDevice;
export const selectSystemUsersReadWrite = (state: RootState) =>
  state.systemPermissions.permissionReadWriteUsers;
export const selectSystemLocationsReadWrite = (state: RootState) =>
  state.systemPermissions.permissionReadWriteLocations;
export const selectSystemOrganizationsReadWrite = (state: RootState) =>
  state.systemPermissions.permissionReadWriteOrganizations;
export const {
  setPermissionsReadWriteSystemDevices,
  setPermissionsReadWriteSystemUsers,
  setPermissionsReadWriteLocations,
  setPermissionsReadWriteOrganizations
} = sliceLocation.actions;
