import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import Strings from '../../../i18n';
import { selectedLocationsByUser } from '../../../store/slices/locations/location';
import {
  useAssignLocationToOrganizationMutation,
  useDeleteLocationInOrganizationMutation,
  useGetOrganizationLocationsMutation
} from '../../../services/device/deviceService';
import { AssignLocationToOrgProps } from './assignLocationToOrgProps';
import { LocationToAssignItem } from '../../../components/locationToAssignItem/locationToAssignItem';
import { LocationByUser } from '../../../types/location/location';
import { filterLocations } from '../../../utils/locations';
import { selectSystemOrganizationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';

export const AssignLocationToOrg = ({
  idOrganization
}: AssignLocationToOrgProps) => {
  const permissionReadWrite = useSelector(selectSystemOrganizationsReadWrite);
  const locations = useSelector(selectedLocationsByUser);
  const [locationUserFiltered, setLocationUserFiltered] = useState<
    LocationByUser[]
  >([]);

  const [addLocationToOrganization, { isSuccess }] =
    useAssignLocationToOrganizationMutation();
  const [deleteLocationInOrganization, { isSuccess: isSuccessDeleteLocation }] =
    useDeleteLocationInOrganizationMutation();
  const [getLocations, { data, isSuccess: isSuccessLocations }] =
    useGetOrganizationLocationsMutation();
  useEffect(() => {
    if (idOrganization) {
      getLocations(idOrganization.toString());
    }
  }, []);

  useEffect(() => {
    if (isSuccessLocations && data) {
      setLocationUserFiltered(filterLocations(locations, data));
    }
  }, [isSuccessLocations]);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.locations.successAddedLocations);
      getLocations(idOrganization.toString());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessDeleteLocation) {
      toast.success(Strings.locations.successDeleteLocations);
      getLocations(idOrganization.toString());
    }
  }, [isSuccessDeleteLocation]);

  return (
    <Grid container justifyContent="center" textAlign="center">
      <Grid item xs={10}>
        {Strings.locations.assignOrganizationTitle}
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography fontSize={12}>
              {Strings.locations.locationsToAssign}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {locationUserFiltered &&
              locationUserFiltered.map((loc) => {
                return (
                  <LocationToAssignItem
                    location={loc}
                    isToAssign
                    action={() => {
                      if (permissionReadWrite) {
                        addLocationToOrganization({
                          OrganizationId: idOrganization,
                          locationIds: [loc.locationId]
                        });
                      }
                    }}
                  />
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography fontSize={12}>
              {Strings.locations.locationsAssigned}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data &&
              data.map((loc) => {
                return (
                  <LocationToAssignItem
                    location={loc}
                    isToAssign={false}
                    action={() => {
                      if (permissionReadWrite) {
                        deleteLocationInOrganization(
                          loc.organizationLocationId
                        );
                      }
                    }}
                  />
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
