/* eslint-disable no-restricted-syntax */
import { Folder } from '../types/folders/folder';

interface SearchResult {
  path: string[];
  folder: Folder;
}

export function searchFolderById(
  foldersArray: Folder[],
  targetFolderId: number
): SearchResult[] {
  const results: SearchResult[] = [];

  function search(folder: Folder, path: string[] = []) {
    if (folder.id === targetFolderId) {
      results.push({ path, folder });
    }

    if (folder.folders && folder.folders.length > 0) {
      for (const subfolder of folder.folders) {
        search(subfolder, [...path, folder.name]);
      }
    }
  }

  for (const folder of foldersArray) {
    search(folder);
  }

  return results;
}
