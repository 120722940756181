import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectButton } from 'primereact/selectbutton';
import { BrightnessSelectorBtn } from '../../../../components/commons/BrightnessSelector/brightnessSelector';
import { ColorSelector } from '../../../../components/commons/ColorSelector/ColorSelector';
import {
  useSendBrightnessCommandMutation,
  useSendColorCommandMutation,
  useSendOnOffCommandMutation
} from '../../../../services/commandsService/commandsService';
import {
  ICommandBrightness,
  ICommandOnOff,
  ICommandSetColor
} from '../../../../types/commandsInterface';
import { LightCapabilitiesEnum } from '../../../../constants/commonEnums';
import Strings from '../../../../i18n';
import { StyledSelectSwitchAllLightsZones } from './allLightsAndZonesStyles';

interface AllLightsAndZonesComponentProps {
  onSuccessCommand: () => void;
}
export const AllLightsAndZonesComponent = ({
  onSuccessCommand
}: AllLightsAndZonesComponentProps) => {
  const { locationId } = useParams();

  const [switchValue, setswitchValue] = useState(null);

  const options = ['Off', 'On'];

  const [setColor, { isSuccess: isSuccessSetColor }] =
    useSendColorCommandMutation();

  const [setBrightness, { isSuccess: isSuccessSetBrightness }] =
    useSendBrightnessCommandMutation();

  const [setSwitch, { isSuccess: isSuccessSetSwitch }] =
    useSendOnOffCommandMutation();

  const sendBrightnessCommand = (payload: ICommandBrightness) => {
    setBrightness(payload);
  };

  const sendColorCommand = (payload: ICommandSetColor) => {
    setColor(payload);
  };

  const sendSwitchCommand = (payload: ICommandOnOff) => {
    setSwitch(payload);
  };

  useEffect(() => {
    if (isSuccessSetColor) {
      toast.success(Strings.trimLights.setColorSuccess);
      onSuccessCommand();
    }
  }, [isSuccessSetColor]);

  useEffect(() => {
    if (isSuccessSetBrightness) {
      toast.success(Strings.commands.setBrightnessSuccess);
      onSuccessCommand();
    }
  }, [isSuccessSetBrightness]);

  useEffect(() => {
    if (isSuccessSetSwitch) {
      toast.success(Strings.commands.commandSentSuccessfully);
      onSuccessCommand();
    }
  }, [isSuccessSetSwitch]);
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>All Lights / Zones</div>
      <div className="mx-2 d-flex align-items-center">
        <StyledSelectSwitchAllLightsZones
          value={switchValue}
          onChange={(e) =>
            sendSwitchCommand({
              method: e.value,
              type: 'Location',
              id: locationId ? +locationId : 0
            })
          }
          options={options}
        />
        <BrightnessSelectorBtn
          lightBrightnessId={null}
          onChange={(brightnessId) =>
            sendBrightnessCommand({
              brightness: brightnessId,
              id: locationId ? +locationId : 0,
              type: 'Location'
            })
          }
        />
        <ColorSelector
          status={{
            colorId: null,
            patternSpeed: 0,
            sparkleDuration: 0
          }}
          capabilities={[{ name: LightCapabilitiesEnum.EXTENDED_SOLID_COLORS }]}
          onColorChange={(colorId) => {
            sendColorCommand({
              id: locationId ? +locationId : 0,
              colorId,
              type: 'Location'
            });
          }}
        />
      </div>
    </div>
  );
};
