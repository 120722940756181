import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';

export const apiAuth = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrlDealers
  }),
  endpoints: (builder) => ({
    postAddEmailToVerify: builder.mutation<
      null,
      { email: string; urlToVerify: string }
    >({
      query: (payload) => ({
        url: 'api/User/AddEmailToVerify',
        method: 'POST',
        body: payload
      }),
      transformErrorResponse: (response: any) => {
        return response.data.message;
      }
    }),
    postUserInfoWithKey: builder.mutation<
      null,
      {
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        key: string;
      }
    >({
      query: (payload) => ({
        url: 'api/User/UserWithKey',
        method: 'POST',
        body: payload
      }),
      transformErrorResponse: (response: any) => {
        return response.data.message;
      }
    }),
    forgotPassword: builder.mutation<
      null,
      {
        email: string;
      }
    >({
      query: (payload) => ({
        url: 'api/User/RequestResetPassword',
        method: 'POST',
        body: payload
      })
    }),
    resetPassword: builder.mutation<
      null,
      {
        email: string;
        password: string;
        guid: string;
      }
    >({
      query: (payload) => ({
        url: 'api/User/ResetPassword',
        method: 'POST',
        body: payload
      })
    }),
    loginWithPassword: builder.mutation<
      { id: number; refreshToken: string; username: string; token: string },
      { username: string; password: string }
    >({
      query: (payload) => ({
        url: 'api/Auth/Authenticate',
        method: 'POST',
        body: payload
      }),
      transformErrorResponse: (response: any) => {
        return response.data.message;
      }
    }),
    refreshApiToken: builder.mutation<
      { id: number; refreshToken: string; username: string; token: string },
      { userId: number; refreshToken: string }
    >({
      query: (payload) => ({
        url: 'api/Auth/Refresh',
        method: 'POST',
        body: payload
      }),
      transformErrorResponse: (response: any) => {
        return response.data.message;
      }
    })
  })
});

export const {
  usePostAddEmailToVerifyMutation,
  usePostUserInfoWithKeyMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLoginWithPasswordMutation,
  useRefreshApiTokenMutation
} = apiAuth;
