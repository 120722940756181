import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { ItemDetailProps } from './itemDetailProps';

export const ItemDetail = ({
  value,
  nameProperty,
  component
}: ItemDetailProps) => {
  return (
    <Grid container marginBottom={2}>
      <Typography fontSize={14} flex={0.3}>
        {nameProperty}
      </Typography>
      {value && (
        <Typography flex={0.3} fontSize={14}>
          {value}
        </Typography>
      )}
      {component && <Box flex={0.3}>{component}</Box>}
    </Grid>
  );
};
