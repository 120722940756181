/* eslint-disable no-bitwise */
import { IUserInfo } from '../types/Auth/authInterface';
import { BusinessAccountUser } from '../types/businessAccount/businessAccount';

export const getInitials = (firstName: string, lastName: string): string => {
  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.charAt(0);
  return `${firstInitial}${lastInitial}`;
};

export const filterUsers = (
  users: IUserInfo[],
  userInBusinessAccount: BusinessAccountUser[]
) => {
  return users.filter((user) => {
    return !userInBusinessAccount.some(
      (userAccount) => user.userId === userAccount.userId
    );
  });
};

export const separateNumberAndString = (
  arr: string[]
): { number: number; restOfString: string }[] => {
  const result: { number: number; restOfString: string }[] = [];

  arr.forEach((str) => {
    const match = str.match(/(\d+)([^\d]*)/);
    if (match) {
      const number = parseInt(match[1], 10);
      const restOfString = match[2];
      result.push({ number, restOfString });
    }
  });

  return result;
};

export const getInitialsByOneString = (fullName: string): string => {
  const names = fullName.split(' ');
  let initials = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const name of names) {
    initials += name.charAt(0);
    if (initials.length === 2) {
      break;
    }
  }

  return initials;
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}
export const stringAvatar = (name: string) => {
  if (name) {
    const nameParts = name.split(' ');
    const initials = nameParts
      .map((part) => part[0])
      .join('')
      .slice(0, 2);
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: initials.toUpperCase()
    };
  }
  return '';
};
