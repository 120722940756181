import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface DynamicTableProps {
  data: {
    columns: string[];
    values: any[][];
  };
}

export const DbDynamicTable: React.FC<DynamicTableProps> = ({ data }) => {
  const rows = data.values.map((row) => {
    const rowData: { [key: string]: any } = {};
    data.columns.forEach((col, index) => {
      rowData[col] = row[index];
    });
    return rowData;
  });

  return (
    <DataTable
      value={rows}
      scrollable
      rowHover
      scrollHeight="100vh"
      size="small"
    >
      {data.columns.map((col) => (
        <Column key={col} field={col} header={col} />
      ))}
    </DataTable>
  );
};
