import { LoadingButton, TabList } from '@mui/lab';
import { Box, Select, Tab, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const TitleStyled = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 13px;
    margin-top: -25px;
    margin-left: 5px;
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled(Box)`
  && {
    height: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    text-transform: none;
    color: ${colors.white};
  }
`;

export const ButtonStyledCancel = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.lightGray};
    text-transform: none;
    color: ${colors.darkGray};
  }
`;

export const TextFieldStyled = styled(TextField)`
  && {
    background-color: ${colors.lightGray};
  }
`;

export const CustomSelect = styled(Select)`
  && {
    width: 100%;
    margin-bottom: 5px;
    background-color: ${colors.lightGray};
  }
`;

export const ContainerTab = { padding: 0 };

export const StyledTab = styled(Tab)`
  && {
    color: ${colors.blue};
    font-weight: 500;
    font-size: 13px;
    padding: 0;
    margin: 2px;
    background-color: transparent;
    border-radius: 10px;
    opacity: 1;
    min-height: 40px;
    .Mui-selected {
      background-color: ${colors.lightGray};
      color: ${colors.blue};
    }
  }
`;

export const StyledTabList = styled(TabList)`
  && {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  }
`;
