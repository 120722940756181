import { Grid, Select } from '@mui/material';
import { AutoComplete } from 'primereact/autocomplete';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledTitleSideBar = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  text-transform: capitalize;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
`;
export const StyledContainerItems = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-top: 0;
  max-height: 80vh;
`;
export const StyledContainerFooterSideBar = styled.div`
  position: absolute;
  display: inline-block;
  background-color: #d9d9d9 !important;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  bottom: 40px;
  width: 100%;
  z-index: 100;

  && button {
    padding: 0.7rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
    border: none;
  }

  & .btn-cancel {
    background-color: #d0d0d0;
    width: 48%;
    font-weight: bold;
    color: ${colors.strongGray};
    &:hover {
      background-color: #b0b0b0;
      transition: 0.3s;
    }
  }

  & .btn-save {
    background-color: #f3f3f3;
    font-weight: bold;
    width: 48%;
    margin-left: 10px;
    color: ${colors.blue};
    &:hover {
      background-color: #cfcfe3;
      transition: 0.3s;
    }
  }
`;
export const StyledContainerReadOnly = styled(Grid)`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 0.5rem 0;

  && .label {
    font-weight: 400;
    color: #8f8f8f;
    font-size: 0.875rem;
    text-transform: capitalize;
    border: none;
    background-color: #fff;
    text-align: left;
  }
  && .value {
    font-weight: 500;
    color: ${colors.black};
    text-align: right;
    font-size: 0.875rem;
    overflow-wrap: anywhere;
    display: flex;
    align-items: center;
  }
`;

export const StyledContainerSimpleMethod = styled.button`
  padding: 0.7rem 0;
  font-size: 0.875rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 0.5rem 0;
  text-transform: capitalize;
  font-weight: 600;
  color: ${colors.blue};
  border: none;
  &:hover {
    background-color: ${colors.lightGray};
    transition: 0.3s;
  }
`;

export const StyledContainerMethodWithValue = styled(Grid)`
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 0.5rem 0;

  && .label {
    font-weight: 400;
    color: #8f8f8f;
    padding-left: 0.8rem;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
  && .value {
    font-weight: 500;
    color: ${colors.black};
    font-size: 0.875rem;
  }
  && .input-text {
    width: 100%;
    padding: 0.5rem 0.8rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    border: 2px solid #aaa;
    background-color: #f2f2f2;
    font-weight: 400;
    margin: 0;
  }
  && .btn-send {
    padding: 0.5rem 0;
    font-size: 0.875rem;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 100%;
    margin: 0.4rem 0 0 0;
    text-transform: capitalize;
    font-weight: 600;
    color: ${colors.blue};
    border: none;
    &:hover {
      background-color: ${colors.lightGray};
      transition: 0.3s;
    }
  }
`;

export const StyledEditablePropertyItemContainer = styled(Grid)`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 1rem 0;

  && .label {
    font-weight: 400;
    color: #8f8f8f;
    padding-left: 0.8rem;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
  && .hints {
    font-size: 0.7rem;
    padding-left: 0.4rem;
    color: #8f8f8f;
    margin: 10px;
  }
  && .value {
    font-weight: 500;
    color: ${colors.black};
    font-size: 0.875rem;
  }
  && .input-text {
    width: 100%;
    padding: 0.5rem 0.8rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    border: 2px solid #aaa;
    background-color: #f2f2f2;
    font-weight: 400;
    margin: 0;
  }
`;

export const StyledCustomSelect = styled(Select)`
  && {
    width: 100%;
    background-color: ${colors.lightGray};
    border: 1px solid #aaa;
    border-radius: 0.5rem;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  && {
    margin-left: 10px;
    background-color: ${colors.strongGray};
    text-transform: none;
    color: ${colors.white};
  }
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  margin: 0;
  border: 2px solid #aaa;
  border-radius: 0.5rem;
  display: inline-block;
  ul {
    margin: 0;
    padding: 0;
  }
`;

export const StyledSwitchWithLabel = styled(Grid)`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 1rem 0;
  && .label {
    font-weight: 400;
    color: #8f8f8f;
    padding-left: 0.8rem;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
  && .value {
    font-weight: 500;
    color: ${colors.black};
    font-size: 0.875rem;
  }
  && .switch {
    margin: 0;
  }
`;

export const StyledPhoneNumberContainer = styled(Grid)`
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  width: 100%;
  margin: 0.5rem 0;

  && .label {
    font-weight: 400;
    color: #8f8f8f;
    padding-left: 0.8rem;
    font-size: 0.875rem;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: left;
  }
`;
