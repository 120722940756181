import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.darkGray};
  text-align: left;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  i {
    font-size: 1.2rem;
  }
`;

export const StyledCopyButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 5px 0 0;
  display: flex;
`;

export const StyledValue = styled.p`
  font-size: 1rem;
  color: ${colors.strongGray};
  text-align: left;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  word-break: break-all;
  margin-bottom: 1rem;
`;
