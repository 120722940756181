import React from 'react';
import { useDispatch } from 'react-redux';
import Logout from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';

import { MenuItem, ListItemIcon } from '@mui/material';
import { setCredentials } from '../../../store/slices/login/login';
import Strings from '../../../i18n';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    dispatch(
      setCredentials({
        user: null,
        email: '',
        token: '',
        picture: '',
        roleInfo: null,
        userInfo: null,
        refreshToken: '',
        authWithApi: false,
        userId: 0
      })
    );
    logout({
      logoutParams: { returnTo: `${window.location.origin}/callback` }
    });
  };
  return (
    <MenuItem onClick={handleLogOut}>
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      {Strings.navBar.profileIcon.logOut}
    </MenuItem>
  );
};

export default LogoutButton;
