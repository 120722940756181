// eslint-disable-next-line no-shadow
export enum viewRightSideBarEnum {
  empty = -1,
  addDevice,
  singletonDevice,
  staticDevice,
  deviceAnnounce,
  postDebug,
  details,
  editUserPermissions,
  manageUserRoles,
  createUserRole,
  addUserToLocation,
  addAdmin,
  editUserAndRole,
  editMultipleUsers,
  addSystemUser,
  addFolders,
  addLocation,
  assignLocation,
  addOrganization,
  assignLocationToOrg,
  locationDetails,
  transferLocation,
  shareLocation,
  addDeviceToLocation,
  assignUsersInBusinessAccount,
  addBusinessAccount,
  moveLocation,
  multipleLocationsDetails,
  multipleScheduleMethodsDetails,
  editMultipleDevices,
  addDeviceMethodFromTemplate,
  SetWifiCredentials,
  sendFileThroughEmail,
  organizationDetails,
  multipleOrganizationsDetails,
  addGroup,
  groupDetails,
  multipleGroupsDetails,
  addScene,
  sceneDetails,
  eventDetails,
  businessAccountDetails,
  addUserToBusinessAccount,
  usersInBusinessDetails,
  directFOTA,
  singleDetailsEffect,
  multipleDetailsEffect,
  viewRates,
  addNote,
  noteDetails,
  editNote,
  locationDetailsBusinessAccount,
  singleScheduleFirmware,
  multipleScheduleFirmware,
  filters,
  createUserBusinessAccount,
  shareLocationsWithTargets,
  changeUserPassword,
  scheduleFirmwareFilters,
  allLocationsFilters,
  userLocationsAndOrgFilters,
  changeLatLongLocation,
  sendDirectMethodText
}
