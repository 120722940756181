import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { ConfirmModalProps } from './confirmModalProps';
import Strings from '../../../i18n';

export const ConfirmModal = ({
  onClose,
  open,
  onConfirm,
  textConfirmation,
  titleConfirmation,
  showNoButton,
  onNo
}: ConfirmModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435
        }
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleConfirmation}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {textConfirmation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleConfirm()}
          color="success"
          variant="contained"
        >
          {Strings.common.ok}
        </Button>
        {showNoButton && (
          <Button onClick={() => onNo?.()} color="error" variant="contained">
            {Strings.common.no}
          </Button>
        )}
        <Button onClick={() => onClose()} color="error" variant="contained">
          {Strings.common.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
