import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { Lens } from '@mui/icons-material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled(Box)`
  && {
    display: flex;
    border: 1px solid ${colors.lightGray};
    border-radius: 4px;
  }
`;

export const ImgStyled = styled.img`
  && {
    height: 28px;
    margin-top: 2px;
  }
`;

export const ButtonIconStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;

export const paperMenuStyle = {
  elevation: 0,
  sx: {
    width: 250,
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
export const StyledListItemText = styled(Typography)`
  font-size: 14px !important;
  margin-left: 10px !important;
  ellipsis: true;
  color: ${colors.darkGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: block;
  text-align: left;
`;
export const StyledIcon = styled(Lens)`
  svg {
    width: 10px !important;
    height: 10px !important;
    color: ${colors.green};
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  && {
    margin: 2px 0;
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid ${colors.lightGray};
  }
  .MuiListItemIcon-root {
    min-width: 20px !important;
  }
  .css-1efkf0c-MuiSvgIcon-root,
  .css-1ucxkcv-MuiSvgIcon-root {
  }
  .css-1ucxkcv-MuiSvgIcon-root {
  }
  .css-cveggr-MuiListItemIcon-root {
  }
`;

export const StyledBackToLocation = styled.button`
  color: ${colors.darkGray};
  margin: 0 auto;
  background-color: transparent;
  border: none;
  width: 100%;
  font-size: 14px;
  padding: 10px 0;
`;
