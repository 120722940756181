import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';

export const StyledTooltip = styled(Tooltip)`
  font-size: 10px;
`;

export const StyledContainerNote = styled.div`
  background-color: ${colors.lightGray};
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
`;
