import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { ITrimLight } from '../../types/Lights/trimLightInterfaces';
import { ILightZone } from '../../types/Lights/lightZoneInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiLightsZones = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getLightsZonesByLocation: builder.mutation<ILightZone[], number>({
      query: (locationId) => ({
        url: `api/LightAndZones/OrderedList/${locationId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetLightsZonesByLocationMutation } = apiLightsZones;
