import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { IEffectsResponse } from '../../types/effects/effectsStoreInterface';
import { IEffectRate } from '../../types/effects/effectsInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiEffectsStore = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getEffectsStoreList: builder.mutation<IEffectsResponse, void>({
      query: (location) => ({
        url: `api/Effect/ForStore`,
        method: 'GET'
      })
    }),
    deleteEffect: builder.mutation<null, number[]>({
      query: (ids) => ({
        url: `api/Effect?ids=${ids.join(',')}`,
        method: 'DELETE'
      })
    }),
    shareEffect: builder.mutation<null, { effectId: number; email: string }>({
      query: (data) => ({
        url: `api/Effect/ShareEffect`,
        method: 'POST',
        body: data
      })
    }),
    getLatestSharedEffectsUser: builder.mutation<
      { data: string[]; message: string },
      void
    >({
      query: () => ({
        url: `api/Effect/LatestSharedEffects`,
        method: 'GET'
      })
    }),
    reportEffect: builder.mutation<null, { effectId: number; reason: string }>({
      query: (data) => ({
        url: `api/Effect/ReportEffect`,
        method: 'POST',
        body: data
      })
    }),
    effectRating: builder.mutation<null, { effectId: number; rating: number }>({
      query: (data) => ({
        url: `api/Effect/Rating/Store`,
        method: 'POST',
        body: data
      })
    }),
    getEffectRatings: builder.mutation<{ data: IEffectRate[] }, number>({
      query: (effectId) => ({
        url: `api/Effect/Rating/${effectId}`,
        method: 'GET'
      })
    }),
    deleteRatingEffect: builder.mutation<
      null,
      { effectId: number; userId: number }
    >({
      query: (data) => ({
        url: `api/Effect/Rating/${data.effectId}/user/${data.userId}`,
        method: 'DELETE'
      })
    }),
    updateEffect: builder.mutation<
      null,
      { effectId: number; name: string; tags: { id: number; tag: string }[] }
    >({
      query: (data) => ({
        url: `api/Effect/${data.effectId}`,
        method: 'PUT',
        body: { name: data.name, tags: data.tags }
      })
    }),
    putChangeVideoEffect: builder.mutation<
      null,
      { effectId: number; video: FormData }
    >({
      query: (data) => ({
        url: `api/Effect/Video/${data.effectId}`,
        method: 'PUT',
        body: data.video
      })
    })
  })
});

export const {
  useGetEffectsStoreListMutation,
  useDeleteEffectMutation,
  useShareEffectMutation,
  useGetLatestSharedEffectsUserMutation,
  useReportEffectMutation,
  useEffectRatingMutation,
  useGetEffectRatingsMutation,
  useUpdateEffectMutation,
  useDeleteRatingEffectMutation,
  usePutChangeVideoEffectMutation
} = apiEffectsStore;
