import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Strings from '../../../i18n';

import { useMoveDeviceToLocationMutation } from '../../../services/deviceByLocation/deviceByLocationService';
import { colors } from '../../../constants/colors';
import { useGetUserSelectedLocationMutation } from '../../../services/device/deviceService';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { LocationUserSelected } from '../../../types/location/location';
import { ItemLocationSelected } from '../../../components/itemLocationSelected/itemLocationSelected';
import { AddDeviceToLocationProps } from '../addDeviceToLocation/addDeviceToLocationProps';
import { ButtonStyled } from '../addDeviceBar/addDeviceStyles';
import {
  InputStyled,
  noBorderStyle
} from '../addDeviceToLocation/addDeviceToLocationStyles';
import {
  ButtonStyledCancel,
  ButtonsContainer
} from '../detailsDevice/detailsDeviceServiceStyles';
import { TitleStyled } from '../devicesTemplate/devicesTemplateStyle';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';

interface MoveDeviceToLocationProps {
  ids: string[];
  reloadTable: () => void;
  setOpenRightSideBar: (value: boolean) => void;
  clearSelectedDevices: () => void;
}

export const MoveDeviceToLocation = ({
  reloadTable,
  ids = [],
  setOpenRightSideBar,
  clearSelectedDevices
}: MoveDeviceToLocationProps) => {
  const contextUser = useSelector(selectCurrentUser);
  const [filterText, setFilterText] = useState('');
  const [preLimit, setPreLimit] = useState(0);
  const [beforeLimit, setBeforeLimit] = useState(5);
  const [locationSelected, setLocationSelected] = useState(0);
  const [locationsPreferred, setLocationsPreferred] = useState<
    LocationUserSelected[]
  >([]);
  const [getLocations, { isSuccess: isSuccessLocations, data: dataLocations }] =
    useGetUserSelectedLocationMutation();
  const [
    postDeviceToLocations,
    { isSuccess: isSuccessPostDevice, data: dataPost, isLoading }
  ] = useMoveDeviceToLocationMutation();

  useEffect(() => {
    if (isSuccessLocations && dataLocations) {
      setLocationsPreferred(dataLocations.filter((loc) => loc.order !== 0));
    }
  }, [isSuccessLocations]);

  useEffect(() => {
    if (isSuccessPostDevice) {
      toast.success('Moved device(s) to location successfully');
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessPostDevice]);

  useEffect(() => {
    getLocations(contextUser.userId?.toString() || '');
  }, []);

  const filteredItems = dataLocations?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const items = filterText.length ? filteredItems : locationsPreferred;
  return (
    <>
      {isSuccessLocations && dataLocations && (
        <StyledContainerItems>
          <StyledTitleSideBar>
            {Strings.locations.selectExistingLocation}
          </StyledTitleSideBar>
          <Box
            bgcolor={colors.white}
            padding={2}
            marginTop={3}
            borderRadius={2}
          >
            <Grid direction="row" container>
              <Typography
                fontSize={13}
                color={colors.darkGray}
                marginTop={1}
                marginLeft={2}
              >
                {Strings.locations.searchByNameOwnerAddress}
              </Typography>
              <Grid
                item
                xs={12}
                bgcolor={colors.lightGray}
                padding={1}
                borderRadius={2}
              >
                <InputStyled
                  sx={noBorderStyle}
                  fullWidth
                  onChange={(e) => setFilterText(e.target.value)}
                />

                {items &&
                  items.slice(preLimit, beforeLimit).map((loc) => (
                    <ItemLocationSelected
                      selected={loc.id === locationSelected}
                      action={() => {
                        setLocationSelected(loc.id);
                      }}
                      locationName={loc.name}
                      ownerName={loc.owner}
                    />
                  ))}
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                marginTop={1}
                marginBottom={1}
              >
                <ButtonStyledCancel
                  disabled={preLimit === 0 && beforeLimit === 5}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setPreLimit((prev) => prev - 5);
                    setBeforeLimit((prev) => prev - 5);
                  }}
                >
                  {Strings.common.previous}
                </ButtonStyledCancel>
                <ButtonStyled
                  disabled={items && items?.length <= beforeLimit}
                  onClick={() => {
                    setPreLimit((prev) => prev + 5);
                    setBeforeLimit((prev) => prev + 5);
                  }}
                  variant="contained"
                  loading={isLoading}
                  size="small"
                >
                  {Strings.common.next}
                </ButtonStyled>
              </Grid>
            </Grid>
            <StyledContainerFooterSideBar style={{ left: '0' }}>
              <button
                type="button"
                className="btn-cancel"
                onClick={() => {
                  setOpenRightSideBar(false);
                }}
              >
                {Strings.common.cancel}
              </button>
              <button
                type="button"
                className="btn-save"
                onClick={() =>
                  postDeviceToLocations({
                    ids: ids.map((id) => id.toString()),
                    locationId: locationSelected
                  })
                }
                disabled={!locationSelected}
              >
                {Strings.common.send}
              </button>
            </StyledContainerFooterSideBar>
          </Box>
        </StyledContainerItems>
      )}
      <div />
    </>
  );
};
