import React, { useEffect, useState } from 'react';
import InboxIcon from '@mui/icons-material/Inbox';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { LinearProgress, Tooltip } from '@mui/material';
import {
  ButtonIconStyled,
  DesktopNavBar,
  ImageStyled,
  MobileNavBar,
  TitleStyled,
  ToolBarStyled
} from './navBarStyles';
import {
  selectIsLoadingCall,
  selectIsSideBarOpen,
  setOpenSideBar
} from '../../../store/slices/app/app';
import { LocationContextItem } from '../../locationContexItem/locationContexItem';
import { colors } from '../../../constants/colors';
import { ProfileIconAndMenu } from './ProfileMenu/ProfileMenu';
import {
  currentLocation,
  setCurrentLocation
} from '../../../store/slices/locations/location';
import SearchButton from '../../commons/SearchButton/SearchButton';
import Strings from '../../../i18n';
import { GlobalSearch } from '../../commons/GlobalSearch/globalSearch';

const NavBarLogo = require('../../../assets/navBar-logo.png');
const iconLocation = require('../../../assets/location.png');

export const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpenSideBar = useSelector(selectIsSideBarOpen) as boolean;

  const match = useMatch('/locations/:locationId/*');

  const location = useSelector(currentLocation);

  const isLoading = useSelector(selectIsLoadingCall) as boolean;

  const setLocationToNull = () => {
    dispatch(setCurrentLocation(null));
    navigate('/locations');
  };

  const redirectToServer = () => {
    dispatch(setCurrentLocation(null));
    navigate('/server-access');
  };

  useEffect(() => {
    if (!match) {
      dispatch(setCurrentLocation(null));
    }
  }, [match]);

  useEffect(() => {
    if (location?.locationId) {
      dispatch(setOpenSideBar(true));
    } else {
      dispatch(setOpenSideBar(false));
    }
  }, [location]);

  return (
    <>
      <DesktopNavBar position="fixed">
        <ToolBarStyled>
          <ImageStyled
            src={NavBarLogo}
            alt="Navbar Logo"
            onClick={() => navigate('/')}
          />
          {location?.locationId && (
            <LocationContextItem name={location.locationName ?? ''} />
          )}
          <TitleStyled onClick={setLocationToNull} />
          <GlobalSearch />
          <Tooltip title={Strings.navBar.userLocationsTooltip}>
            <ButtonIconStyled
              onClick={() => setLocationToNull()}
              className="targetRedirectToLocation"
            >
              <img src={iconLocation} alt="loc" width="15" />
            </ButtonIconStyled>
          </Tooltip>
          <Tooltip title={Strings.navBar.serverAccessTooltip}>
            <ButtonIconStyled onClick={() => redirectToServer()}>
              <InsertChartIcon htmlColor={colors.mediumGray} />
            </ButtonIconStyled>
          </Tooltip>
          <Tooltip title={Strings.navBar.inboxTooltip}>
            <ButtonIconStyled>
              <InboxIcon htmlColor={colors.mediumGray} />
            </ButtonIconStyled>
          </Tooltip>
          <ProfileIconAndMenu />
        </ToolBarStyled>
        {isLoading && <LinearProgress color="secondary" />}
      </DesktopNavBar>
      <MobileNavBar position="fixed">
        <ToolBarStyled>
          <ImageStyled
            src={NavBarLogo}
            alt="Navbar Logo"
            onClick={() => navigate('/dashboard')}
            onMouseEnter={() => {
              dispatch(setOpenSideBar(!isOpenSideBar));
            }}
          />
          <TitleStyled onClick={setLocationToNull} />
          <GlobalSearch disableShortcuts />
          <ButtonIconStyled onClick={() => setLocationToNull()}>
            <img src={iconLocation} alt="loc" width="15" />
          </ButtonIconStyled>
          <ButtonIconStyled onClick={() => redirectToServer()}>
            <InsertChartIcon htmlColor={colors.mediumGray} />
          </ButtonIconStyled>
          <ButtonIconStyled>
            <InboxIcon htmlColor={colors.mediumGray} />
          </ButtonIconStyled>
          <ProfileIconAndMenu />
        </ToolBarStyled>
        {isLoading && <LinearProgress color="secondary" />}
      </MobileNavBar>
    </>
  );
};
