import { Avatar, Divider, Typography } from '@mui/material';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import moment from 'moment';
import React, { useEffect } from 'react';
import Strings from '../../../i18n';
import {
  CardSubtitle,
  CustomerActivityDetails,
  CustomerActivityDetailsText,
  LastTime
} from '../dashboardStyles';
import { severityEnum } from '../../../constants/commonEnums';
import { useGetCustomActivityFeedMutation } from '../../../services/dashboardService/dashboardService';
import { ICustomActivityFeed } from '../../../types/Dashboard/dashboardInterface';

export const CustomerActivityFeedComponent = () => {
  const [getCustomActivityFeed, { data, isLoading, isSuccess }] =
    useGetCustomActivityFeedMutation();

  useEffect(() => {
    getCustomActivityFeed();
  }, []);

  const getTimeFormat = (date: string) => {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return moment(localDate).calendar(null, {
      sameDay: '[Today at] h:mm A',
      lastDay: '[Yesterday at] h:mm A',
      lastWeek: '[Last] dddd [at] h:mm A',
      sameElse: 'DD/MM/YYYY [at] h:mm A'
    });
  };

  const getTimeFromNow = (date: string) => {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    const time = moment(localDate).fromNow();
    return time.replace(' minutes', 'm').replace(' hours', 'h');
  };

  const customerActivityTemplate = (item: ICustomActivityFeed) => {
    return (
      <div className="col-12 mt-3 ">
        <div className="d-flex justify-content-between align-items-center overflow-auto">
          <div className="d-flex align-items-center">
            <Avatar sx={{ width: 40, height: 40, marginRight: '1rem' }}>
              {item.userName[0]}
            </Avatar>
            <div className="flex-1">
              <h5 className="mb-1">{item.userName}</h5>
              <CustomerActivityDetails>
                {item.action} {item.type}
              </CustomerActivityDetails>
              <CustomerActivityDetailsText>
                {getTimeFormat(item.date)}
              </CustomerActivityDetailsText>
            </div>
          </div>
          <LastTime>{getTimeFromNow(item.date)}</LastTime>
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <Card
      title={
        <>
          <Typography
            variant="h6"
            marginBottom={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {Strings.dashboard.customerActivityFeed}
            <CardSubtitle
              className="text-sm text-gray-500 font-bold ml-2"
              severity={
                data?.length && data?.length > 4
                  ? severityEnum.medium
                  : severityEnum.low
              }
            >
              {Strings.dashboard.newAlerts}: {data?.length}
            </CardSubtitle>
          </Typography>
          <Divider />
        </>
      }
    >
      <DataView value={data ?? []} itemTemplate={customerActivityTemplate} />
    </Card>
  );
};
