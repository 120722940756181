import React, { useEffect, useState, useCallback } from 'react';
import { Slider } from '@mui/material';
import styled from 'styled-components';
import { ColorSelector } from '../../../../components/commons/ColorSelector/ColorSelector';
import { LightCapabilitiesEnum } from '../../../../constants/commonEnums';
import { BrightnessSelectorBtn } from '../../../../components/commons/BrightnessSelector/brightnessSelector';

interface RippleColorAndConfigSelectorItemProps {
  item: {
    colorId: number;
    brightness: number;
    amplitude: number;
    waveLength: number;
    speed: number;
  };
  setConfiguration: (item: any) => void;
}

const StyledItemSelector = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;
  max-width: 600px;
  justify-content: space-around;
`;

const ContainerSliderWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  p {
    font-size: 1rem;
    margin: 0;
    margin-top: 10px;
  }
`;

export const RippleColorAndConfigSelectorItem = ({
  item,
  setConfiguration
}: RippleColorAndConfigSelectorItemProps) => {
  const [configurationItem, setConfigurationItem] = useState({
    colorId: item.colorId,
    brightness: item.brightness,
    amplitude: item.amplitude,
    waveLength: item.waveLength,
    speed: item.speed
  });

  const amplitudeSliderOptions = {
    minSliderValue: 0,
    maxSliderValue: 1,
    steps: 0.1,
    marks: [
      {
        value: 0,
        label: '0'
      },
      {
        value: 1,
        label: '1'
      }
    ]
  };

  const waveLengthSliderOptions = {
    minSliderValue: 3,
    maxSliderValue: 200,
    steps: 10,
    marks: [
      {
        value: 3,
        label: '3'
      },
      {
        value: 200,
        label: '200'
      }
    ]
  };

  const speedSliderOptions = {
    minSliderValue: -10,
    maxSliderValue: 10,
    steps: 1,
    marks: [
      {
        value: -10,
        label: '-10'
      },
      {
        value: 10,
        label: '10'
      }
    ]
  };

  useEffect(() => {
    setConfiguration(configurationItem);
  }, [configurationItem]);

  const handleColorChange = useCallback(
    (colorId: any) => {
      setConfigurationItem((prev) => ({ ...prev, colorId }));
    },
    [setConfigurationItem]
  );

  const handleBrightnessChange = useCallback(
    (brightnessId: any) => {
      setConfigurationItem((prev) => ({ ...prev, brightness: brightnessId }));
    },
    [setConfigurationItem]
  );

  const handleSliderChange = useCallback(
    (name: any, value: any) => {
      setConfigurationItem((prev) => ({ ...prev, [name]: value }));
    },
    [setConfigurationItem]
  );

  return (
    <div className="d-flex justify-content-center">
      <StyledItemSelector>
        <ColorSelector
          status={{
            colorId: configurationItem.colorId,
            patternSpeed: 0,
            sparkleDuration: 0
          }}
          capabilities={[{ name: LightCapabilitiesEnum.EXTENDED_SOLID_COLORS }]}
          onColorChange={handleColorChange}
          showOff
        />
        <BrightnessSelectorBtn
          lightBrightnessId={configurationItem.brightness}
          onChange={handleBrightnessChange}
          disabled={configurationItem.colorId === 0}
        />
        <ContainerSliderWithLabel>
          <Slider
            style={{ margin: 0 }}
            name="amplitude"
            aria-label="Always visible"
            value={configurationItem.amplitude}
            step={amplitudeSliderOptions.steps}
            min={amplitudeSliderOptions.minSliderValue}
            max={amplitudeSliderOptions.maxSliderValue}
            valueLabelDisplay="auto"
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical'
              }
            }}
            orientation="vertical"
            marks={amplitudeSliderOptions.marks}
            onChange={(e, value) =>
              handleSliderChange('amplitude', value as number)
            }
          />
          <p>Amplitude: {configurationItem.amplitude}</p>
        </ContainerSliderWithLabel>
        <ContainerSliderWithLabel>
          <Slider
            name="waveLength"
            style={{ margin: 0 }}
            aria-label="Always visible"
            value={configurationItem.waveLength}
            step={waveLengthSliderOptions.steps}
            valueLabelDisplay="on"
            min={waveLengthSliderOptions.minSliderValue}
            max={waveLengthSliderOptions.maxSliderValue}
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical'
              }
            }}
            orientation="vertical"
            marks={waveLengthSliderOptions.marks}
            onChange={(e, value) =>
              handleSliderChange('waveLength', value as number)
            }
          />
          <p>Wave Length: {configurationItem.waveLength}</p>
        </ContainerSliderWithLabel>
        <ContainerSliderWithLabel>
          <Slider
            name="speed"
            style={{ margin: 0 }}
            aria-label="Always visible"
            value={configurationItem.speed}
            step={speedSliderOptions.steps}
            valueLabelDisplay="on"
            min={speedSliderOptions.minSliderValue}
            max={speedSliderOptions.maxSliderValue}
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical'
              }
            }}
            orientation="vertical"
            marks={speedSliderOptions.marks}
            onChange={(e, value) =>
              handleSliderChange('speed', value as number)
            }
          />
          <p>Speed: {configurationItem.speed}</p>
        </ContainerSliderWithLabel>
      </StyledItemSelector>
    </div>
  );
};
