import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { IDeleteButtonProps } from './deleteButtonWithoutTextProps';
import Strings from '../../../i18n';
import {
  ButtonIconHeaderStyled,
  StyledButtonWithLabel
} from '../../layout/main/mainStyles';
import { colors } from '../../../constants/colors';

export const DeleteButtonWithoutText = ({
  onDelete,
  id,
  textConfirmation,
  titleConfirmation,
  disabled
}: IDeleteButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(id);
    handleClose();
  };

  return (
    <>
      <ButtonIconHeaderStyled
        onClick={() => handleClickOpen()}
        type="button"
        disabled={disabled}
      >
        <Delete />
      </ButtonIconHeaderStyled>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxHeight: 435
          }
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleConfirmation}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {textConfirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDelete()}
            color="success"
            variant="contained"
            disabled={disabled}
          >
            {Strings.common.ok}
          </Button>
          <Button
            onClick={() => handleClose()}
            color="error"
            variant="contained"
          >
            {Strings.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButtonWithoutText.defaultProps = {
  disabled: false
};
