/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ColorItem,
  StyledColorButton,
  StyledColorDialog,
  StyledColorListSelectorBtn,
  StyledEffectSelectorBtn,
  StyledGrid,
  StyledTitleColorList
} from './ColorSelectorStyles';
import {
  OFF_COLOR,
  defaultColor,
  extendColorsList,
  standardColorList,
  whiteColors
} from '../../../constants/ColorList';
import { ColorV2 } from '../../../types/colorsModel';
import {
  LightCapabilitiesEnum,
  TAB_COLOR_INDEX
} from '../../../constants/commonEnums';
import { checkCapability } from '../../../utils/lights';
import Strings from '../../../i18n';
import { AllColorList } from '../../../types/allColorListModel';
import { EffectSelectorList } from '../Effects/EffectSelectorList/effectSelectorList';

const addPlusIcon = require('../../../assets/addPlus.png');
const rippleIcon = require('../../../assets/effects/ripple.png');

interface ColorSelectorProps {
  status: {
    colorId: number | null;
    patternSpeed: number;
    sparkleDuration: number | null;
  };
  selectedItem?: { id: number; type: string };
  capabilities: { name: string }[];
  onColorChange: (colorId: number) => void;
  showOff?: boolean;
}

export const ColorSelector = ({
  status,
  capabilities,
  onColorChange,
  selectedItem,
  showOff
}: ColorSelectorProps) => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [colorSelected, setColorSelected] = useState<ColorV2>(defaultColor);

  const [visible, setVisible] = useState(false);

  const [colorsByCapability, setColorsByCapability] = useState<AllColorList>();

  const [currentView, setCurrentView] = useState(TAB_COLOR_INDEX.colors);

  const [tabStatus, setTabStatus] = useState({
    colors: true,
    patterns: false,
    sparkles: false,
    effects: false,
    tapeMeasure: false,
    addEffect: false
  });

  const findColorById = (colors: ColorV2[]) => {
    setColorSelected(
      colors.find((color) => color.id === status.colorId) || defaultColor
    );
  };

  const setNewColor = (color: ColorV2) => {
    setColorSelected(color);
    onColorChange(color.id);
    setVisible(false);
  };

  useEffect(() => {
    if (status.colorId) {
      findColorById(extendColorsList.allColors);
    }
  }, []);

  useEffect(() => {
    tabStatus.colors = checkCapability(
      [
        LightCapabilitiesEnum.SOLID_COLORS,
        LightCapabilitiesEnum.EXTENDED_SOLID_COLORS,
        LightCapabilitiesEnum.HALLOWEEN_AS_A_SOLID_COLOR,
        LightCapabilitiesEnum.TEMPERATURE,
        LightCapabilitiesEnum.LEGACY_SOLID_COLORS
      ],
      capabilities
    );
    tabStatus.patterns = checkCapability(
      [
        LightCapabilitiesEnum.EXTENDED_PATTERNS,
        LightCapabilitiesEnum.LEGACY_PATTERNS
      ],
      capabilities
    );
    tabStatus.sparkles = checkCapability(
      LightCapabilitiesEnum.SPARKLE,
      capabilities
    );
    tabStatus.effects = checkCapability(
      LightCapabilitiesEnum.TRIM_LIGHT,
      capabilities
    );
    tabStatus.addEffect = checkCapability(
      LightCapabilitiesEnum.TRIM_LIGHT,
      capabilities
    );
    setColorsByCapability(
      checkCapability(LightCapabilitiesEnum.EXTENDED_SOLID_COLORS, capabilities)
        ? extendColorsList
        : standardColorList
    );
  }, []);

  const openDialogSelector = () => {
    setVisible(true);
  };

  const importImage = (icon: string) => {
    const image = require(`../../../assets/images/colors/${icon}`);
    return image;
  };

  const selectedEffect = (effect: string) => {
    navigate(
      `/locations/${locationId}/applications/effect/${effect}?id=${selectedItem?.id}&type=${selectedItem?.type}`
    );
  };

  return (
    <>
      <StyledColorButton
        bgColor={colorSelected.color}
        bgImage={colorSelected.icon ? importImage(colorSelected.icon) : ''}
        colorName={colorSelected.name}
        type="button"
        onClick={openDialogSelector}
      />
      <StyledColorDialog
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: '70vw' }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      >
        <Grid container textAlign="center" marginTop={4} alignItems="center">
          <StyledGrid item xs={4} show={tabStatus.colors}>
            <StyledColorListSelectorBtn
              visible={tabStatus.colors}
              isActive={currentView === TAB_COLOR_INDEX.colors}
              onClick={() => setCurrentView(TAB_COLOR_INDEX.colors)}
            >
              {Strings.colorSelector.color}
            </StyledColorListSelectorBtn>
          </StyledGrid>
          <StyledGrid item xs={4} show={tabStatus.patterns}>
            <StyledColorListSelectorBtn
              visible={tabStatus.patterns}
              isActive={currentView === TAB_COLOR_INDEX.patterns}
              onClick={() => setCurrentView(TAB_COLOR_INDEX.patterns)}
            >
              {Strings.colorSelector.patterns}
            </StyledColorListSelectorBtn>
          </StyledGrid>
          <StyledGrid item xs={4} show={tabStatus.sparkles}>
            <StyledColorListSelectorBtn
              visible={tabStatus.sparkles}
              isActive={currentView === TAB_COLOR_INDEX.sparkles}
              onClick={() => setCurrentView(TAB_COLOR_INDEX.sparkles)}
            >
              {Strings.colorSelector.sparkles}
            </StyledColorListSelectorBtn>
          </StyledGrid>
          <StyledGrid item xs={4} show={tabStatus.effects}>
            <StyledColorListSelectorBtn
              visible={tabStatus.effects}
              isActive={currentView === TAB_COLOR_INDEX.effects}
              onClick={() => setCurrentView(TAB_COLOR_INDEX.effects)}
            >
              {Strings.colorSelector.effects}
            </StyledColorListSelectorBtn>
          </StyledGrid>
          <StyledGrid item xs={4} show={tabStatus.addEffect}>
            <StyledColorListSelectorBtn
              visible={tabStatus.addEffect}
              isActive={currentView === TAB_COLOR_INDEX.addEffect}
              onClick={() => setCurrentView(TAB_COLOR_INDEX.addEffect)}
              className="d-flex align-items-center justify-content-center"
            >
              {Strings.colorSelector.addEffect}{' '}
              <i
                className="pi pi-plus-circle"
                style={{ color: 'orange', paddingLeft: '10px' }}
              />
            </StyledColorListSelectorBtn>
          </StyledGrid>
        </Grid>
        <Divider />

        {/* START OF COLORS MAPPING */}
        {currentView === TAB_COLOR_INDEX.colors && (
          <StyledGrid
            container
            textAlign="center"
            show={currentView === TAB_COLOR_INDEX.colors}
          >
            <StyledGrid item xs={12} show>
              <StyledTitleColorList>
                {Strings.colorSelector.whiteTemperature}
              </StyledTitleColorList>
              <Grid container>
                {whiteColors.map((color) => (
                  <Grid item key={color.id} xs={3} md={2.25} marginBottom={2}>
                    <ColorItem
                      key={color.id}
                      bgColor={color.color}
                      colorName={color.name}
                      bgImage={color.icon ? importImage(color.icon) : ''}
                      active={colorSelected.id === color.id}
                      onClick={() => setNewColor(color)}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider />
            </StyledGrid>
            <StyledGrid item xs={12} show>
              <StyledTitleColorList>
                {Strings.colorSelector.color}
              </StyledTitleColorList>
              <Grid container>
                {showOff && (
                  <Grid
                    item
                    key={OFF_COLOR.id}
                    xs={3}
                    md={2.25}
                    marginBottom={2}
                  >
                    <ColorItem
                      key={OFF_COLOR.id}
                      bgColor={OFF_COLOR.color}
                      colorName={OFF_COLOR.name}
                      bgImage={
                        OFF_COLOR.icon ? importImage(OFF_COLOR.icon) : ''
                      }
                      active={colorSelected.id === OFF_COLOR.id}
                      onClick={() => setNewColor(OFF_COLOR)}
                    />
                  </Grid>
                )}

                {colorsByCapability?.solidColors.map((color) => (
                  <Grid item key={color.id} xs={3} md={2.25} marginBottom={2}>
                    <ColorItem
                      key={color.id}
                      bgColor={color.color}
                      colorName={color.name}
                      bgImage={color.icon ? importImage(color.icon) : ''}
                      active={colorSelected.id === color.id}
                      onClick={() => setNewColor(color)}
                    />
                  </Grid>
                ))}
              </Grid>
            </StyledGrid>
          </StyledGrid>
        )}
        {currentView === TAB_COLOR_INDEX.patterns && (
          <StyledGrid show={currentView === TAB_COLOR_INDEX.patterns}>
            <StyledTitleColorList>
              {Strings.colorSelector.patterns}
            </StyledTitleColorList>
          </StyledGrid>
        )}
        {currentView === TAB_COLOR_INDEX.sparkles && (
          <StyledGrid show={currentView === TAB_COLOR_INDEX.sparkles}>
            <StyledTitleColorList>
              {Strings.colorSelector.sparkles}
            </StyledTitleColorList>
          </StyledGrid>
        )}
        {currentView === TAB_COLOR_INDEX.effects && (
          <StyledGrid show={currentView === TAB_COLOR_INDEX.effects}>
            <StyledTitleColorList>
              {Strings.colorSelector.effects}

              <EffectSelectorList
                locationId={Number(locationId)}
                reloadList={currentView === TAB_COLOR_INDEX.effects}
                selectedItem={selectedItem ?? { id: 0, type: '' }}
                setVisible={setVisible}
              />
            </StyledTitleColorList>
          </StyledGrid>
        )}
        {currentView === TAB_COLOR_INDEX.tapeMeasure && (
          <StyledGrid show={currentView === TAB_COLOR_INDEX.tapeMeasure}>
            <StyledTitleColorList>
              {Strings.colorSelector.tapeMeasure}
            </StyledTitleColorList>
            <Grid container>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Moving"
              />
            </Grid>
          </StyledGrid>
        )}
        {currentView === TAB_COLOR_INDEX.addEffect && (
          <StyledGrid show={currentView === TAB_COLOR_INDEX.addEffect}>
            <StyledTitleColorList>
              {Strings.colorSelector.effectType}
            </StyledTitleColorList>
            <StyledEffectSelectorBtn
              type="button"
              onClick={() => selectedEffect('ripple')}
            >
              <img src={rippleIcon} alt="Ripple" width="70px" />
              <p>Ripple</p>
            </StyledEffectSelectorBtn>
          </StyledGrid>
        )}
        {/* END OF COLORS MAPPING */}
      </StyledColorDialog>
    </>
  );
};

ColorSelector.defaultProps = {
  selectedItem: { id: 0, type: '' },
  showOff: false
};
