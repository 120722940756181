/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Column } from 'primereact/column';
import moment from 'moment';
import { ContentCopy, InfoOutlined } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { SendStaticOrSingletonMethod } from '../sendStaticOrSingleMethod/sendStaticOrSingleMethod';
import {
  StyledAddDeviceToLocationButton,
  StyledIconStatus,
  StyledStatusDevice
} from './devicesTemplateStyle';
import { AddDeviceBar } from '../addDeviceBar/addDeviceBar';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import {
  StyledBoxTable,
  StyledColumnSize
} from '../../../components/layout/main/mainStyles';
import { useGetDevicesMutation } from '../../../services/device/deviceService';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import Strings from '../../../i18n';
import { DetailsDevice } from '../detailsDevice/detailsDevice';
import { AddDeviceToLocation } from '../addDeviceToLocation/addDeviceToLocation';
import { DeleteButtonWithoutText } from '../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { useDeleteDeviceFromLocationMutation } from '../../../services/deviceByLocation/deviceByLocationService';
import { RegisteredDevice } from '../../../types/Device/DeviceInterfaces';
import { DeviceTemplateProps } from './deviceTemplateProps';
import { MultipleDevicesSelectedMethods } from '../multipleDeviceSelectedMethods/multipleDevicesSelectedMethods';
import { SendDirectFOTA } from '../directFOTA/sendDirectFOTA';
import { ShowLastAnnounceInfo } from './showLastAnnounceInfo/showLastAnnounceInfo';
import { AddNoteSideBar } from '../../../components/commons/notes/addNoteSideBar/addNoteSideBar';
import { useDeleteScheduleFirmwareUpdateMutation } from '../../../services/firmwareVersions/firmwareVersionsService';
import { DeviceFiltersSideBar } from '../deviceFilters/deviceFiltersSideBar';
import { StyledButtonName } from '../../usersTemplate/userTemplateStyles';
import { colors } from '../../../constants/colors';
import { FirmwareStatusEnum } from '../../../constants/enums/firmwareEnums';
import { syncStatusEnum } from '../../../constants/enums/deviceEnums';
import { MoveDeviceToLocation } from '../moveDeviceToLocation/moveDeviceToLocation';
import { SendDirectMethod } from '../sendDirectMethod/sendDirectMethod';

export const DevicesTemplate = ({
  searchValue,
  openRightSideBar,
  setOpenRightSideBar,
  sideBarOpenFor,
  setSideBarOpenFor,
  selectedDevices,
  setSelectedDevices,
  refreshDevices,
  setRefreshDevices
}: DeviceTemplateProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [sortValues, setSortValues] = useState<{
    sortField: string;
    sortOrder: 1 | -1 | undefined | null;
  }>({
    sortField: localStorage.getItem('sortField') || '',
    sortOrder:
      localStorage.getItem('sortOrder') === null
        ? null
        : Number(localStorage.getItem('sortOrder')) === 1
        ? 1
        : -1
  });
  const [showFotaStatus, setShowFotaStatus] = useState('');
  const searchQuery = queryParams.get('search');
  const showUnassignedDevicesByUrl = queryParams.get('showUnassignedDevices');
  const filterByModel = queryParams.get('filterByModel');
  const filterByKeyword = queryParams.get('filterByKeyword');
  const showPendingFotaDevicesByUrl = queryParams.get('showPendingFotaDevices');
  const filterByVersion = queryParams.get('filterByVersion');
  const filterByStatus = queryParams.get('filterByStatus');
  const filterByLocationStatus = queryParams.get('filterByLocationStatus');
  const [allDevices, setAllDevices] = useState<RegisteredDevice[]>([]);
  const op = useRef<OverlayPanel>(null);

  const [addSingleDeviceLocation, setAddSingleDeviceLocation] =
    useState<RegisteredDevice>();
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };

  const [getDevices, { isLoading, isSuccess, data }] = useGetDevicesMutation();
  const [deleteFromLocation, { isSuccess: isSuccessDeleteDeviceFromLocation }] =
    useDeleteDeviceFromLocationMutation();

  const [
    deleteScheduledFirmware,
    { isSuccess: isSuccessDeleteScheduledFirmware }
  ] = useDeleteScheduleFirmwareUpdateMutation();

  const clearSelectedDevices = () => {
    setSelectedDevices([]);
    setAddSingleDeviceLocation(undefined);
  };

  const reloadTable = () => {
    getDevices('');
    clearSelectedDevices();
  };

  useEffect(() => {
    reloadTable();
  }, []);

  const deleteDeviceFromLocation = (item: any) => {
    deleteFromLocation([item.id]);
  };
  const openSideBarToAddDevice = (item: RegisteredDevice) => {
    setAddSingleDeviceLocation(item);
    setOpenRightSideBar(true);
    setSideBarOpenFor(viewRightSideBarEnum.addDeviceToLocation);
  };

  useEffect(() => {
    if (isSuccessDeleteDeviceFromLocation) {
      toast.success('Device deleted from location successfully');
      reloadTable();
    }
  }, [isSuccessDeleteDeviceFromLocation]);

  useEffect(() => {
    if (refreshDevices) {
      reloadTable();
      setRefreshDevices(false);
    }
  }, [refreshDevices]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.singletonDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.singletonDevice}
            clearSelectedDevices={clearSelectedDevices}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.staticDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.staticDevice}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.addDevice:
        return (
          <AddDeviceBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.details:
        return (
          <DetailsDevice
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
          />
        );
      case viewRightSideBarEnum.addDeviceToLocation:
        return (
          <AddDeviceToLocation
            reloadTable={reloadTable}
            ids={
              addSingleDeviceLocation
                ? [addSingleDeviceLocation.id]
                : selectedDevices.map((item) => item.id)
            }
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.editMultipleDevices:
        return (
          <MultipleDevicesSelectedMethods
            reloadTable={reloadTable}
            openRightSideBarForMethod={openRightSideBarForMethod}
            selectedDevice={selectedDevices}
            openRightSideBar={openRightSideBar}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSingleDevice={() => {
              setAddSingleDeviceLocation(undefined);
            }}
          />
        );
      case viewRightSideBarEnum.directFOTA:
        return (
          <SendDirectFOTA
            deviceIds={selectedDevices.map((item) => ({
              deviceId: item.deviceId,
              productTypeId: item.productTypeId
            }))}
            reloadTable={async () => reloadTable()}
            clearSelectedDevices={clearSelectedDevices}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.addNote:
        return (
          <AddNoteSideBar
            closeSideBar={() => setOpenRightSideBar(false)}
            targetId={selectedDevices[0].deviceId}
            type="device"
          />
        );
      case viewRightSideBarEnum.filters:
        return (
          <DeviceFiltersSideBar
            reloadTable={reloadTable}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            actualVersionsDevices={[
              ...new Set(allDevices.map((item) => item.firmwareVersion))
            ].map((version) => ({
              id: version,
              name: version
            }))}
          />
        );
      case viewRightSideBarEnum.moveLocation:
        return (
          <MoveDeviceToLocation
            reloadTable={reloadTable}
            ids={selectedDevices.map((item) => item.deviceId)}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.sendDirectMethodText:
        return (
          <SendDirectMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      default:
        return (
          <AddDeviceBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
    }
  };

  const getLocationAssign = (item: RegisteredDevice) => {
    return item.locationId ? (
      <a href={`/locations/${item.locationId}/applications`}>
        {item.locationName}
      </a>
    ) : (
      <StyledAddDeviceToLocationButton
        onClick={() => openSideBarToAddDevice(item)}
      >
        {Strings.locations.assignLocation}
      </StyledAddDeviceToLocationButton>
    );
  };

  useEffect(() => {
    if (selectedDevices.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.details);
    } else if (selectedDevices.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.editMultipleDevices);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenRightSideBar(false);
    }
  }, [selectedDevices]);

  const reorderDevices = (deviceList: RegisteredDevice[], idToMove: string) => {
    const usersArrayCopy = [...deviceList];
    return usersArrayCopy.sort((a, b) => {
      if (a.deviceId === idToMove) return -1;
      if (b.deviceId === idToMove) return 1;
      return 0;
    });
  };
  useEffect(() => {
    if (isSuccess && data) {
      let filteredData = data;

      const applyFilter = (
        condition: boolean,
        filterFn: () => RegisteredDevice[]
      ) => {
        if (condition) {
          filteredData = filterFn();
        }
      };

      applyFilter(!!searchQuery && !showUnassignedDevicesByUrl, () => {
        setSelectedDevices(
          data?.filter((item) => item.deviceId === searchQuery) ?? []
        );
        return reorderDevices(data ?? [], searchQuery ?? '');
      });

      applyFilter(!!filterByKeyword, () =>
        filteredData.filter(
          (item) =>
            (item?.deviceId?.toLowerCase() ?? '').includes(
              filterByKeyword?.toLowerCase() ?? ''
            ) ||
            (item?.locationName?.toLowerCase() ?? '').includes(
              filterByKeyword?.toLowerCase() ?? ''
            )
        )
      );

      applyFilter(!!showUnassignedDevicesByUrl, () =>
        filteredData.filter((item) => !item.locationId)
      );
      applyFilter(!!filterByModel, () =>
        filteredData.filter(
          (item) => item.productType.toString() === filterByModel
        )
      );
      applyFilter(!!showPendingFotaDevicesByUrl, () =>
        filteredData.filter((item) => item.firmwareUpdate)
      );
      applyFilter(!!filterByVersion, () =>
        filteredData.filter((item) => item.firmwareVersion === filterByVersion)
      );
      applyFilter(!!filterByStatus, () =>
        filteredData.filter((item) => item.dataBaseStatus === filterByStatus)
      );
      applyFilter(filterByStatus === 'Offline', () =>
        filteredData.filter((item) => item.dataBaseStatus !== 'Online')
      );
      applyFilter(!!filterByLocationStatus, () => {
        switch (filterByLocationStatus) {
          case 'assignedOnly':
            return filteredData.filter((item) => !!item.locationId);
          case 'unassignedOnly':
            return filteredData.filter((item) => !item.locationId);
          case 'all':
          default:
            return filteredData;
        }
      });
      setAllDevices(filteredData);
    }
  }, [isSuccess, searchQuery]);

  const getLastAnnounce = (rowData: RegisteredDevice) => {
    if (rowData.lastAnnounce) {
      return (
        <ShowLastAnnounceInfo
          deviceId={rowData.deviceId}
          lastAnnounceDate={rowData.lastAnnounce}
        />
      );
    }
    return (
      <ShowLastAnnounceInfo
        deviceId={rowData.deviceId}
        lastAnnounceDate={rowData.lastAnnounce}
      />
    );
  };

  const getDatabaseLocal = (rowData: RegisteredDevice) => {
    if (rowData.dataBaseStatus) {
      const utcDate = moment.utc(rowData.lastUpdate);
      const browserTime = utcDate.local();
      return (
        <StyledStatusDevice status={rowData.connectionStatus}>
          {rowData.statusLabel}
        </StyledStatusDevice>
      );
    }
    return '';
  };

  const getTargetWithDeleteButton = (rowData: RegisteredDevice) => {
    return rowData.firmwareUpdate ? (
      <div className="d-flex align-items-center">
        <DeleteButtonWithoutText
          onDelete={() => {
            deleteScheduledFirmware([
              rowData.firmwareUpdate.firmwareScheduleId
            ]);
          }}
          id={rowData.id.toString()}
          titleConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmwareTitle
          }
          textConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmware
          }
        />
        {rowData.firmwareUpdate.firmwareTarget}
        <div className="d-inline-block">
          <Tooltip title={rowData.firmwareUpdate.status} placement="top" arrow>
            <IconButton
              size="small"
              onClick={(e) => {
                op.current?.toggle(e);
                setShowFotaStatus(rowData.firmwareUpdate.status);
              }}
              color={
                rowData.firmwareUpdate.status ===
                  FirmwareStatusEnum.firmwareUpdateRequestFailed ||
                rowData.firmwareUpdate.status ===
                  FirmwareStatusEnum.firmwareUpdateCancelledBySystem
                  ? 'error'
                  : 'inherit'
              }
            >
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          <OverlayPanel ref={op}>
            <p>{showFotaStatus}</p>
          </OverlayPanel>
        </div>
      </div>
    ) : (
      <span />
    );
  };

  useEffect(() => {
    if (isSuccessDeleteScheduledFirmware) {
      reloadTable();
      toast.success(Strings.serverAccess.scheduleFirmwareUpdate.deleteSuccess);
    }
  }, [isSuccessDeleteScheduledFirmware]);

  const getDeviceId = (item: RegisteredDevice) => {
    return (
      <Grid container>
        <Tooltip title={Strings.common.copyText} placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(item.deviceId);
              toast.success(Strings.common.copiedText);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
        <StyledButtonName needsInformation={false}>
          {item.deviceId}
        </StyledButtonName>
      </Grid>
    );
  };

  const getDetailsSyncStatus = (details: any) => {
    const syncIssues = Object.keys(details).reduce(
      (acc: string[], key: string) => {
        if (details[key] === false) {
          acc.push(`${key}: ${Strings.devices.notSynced}`);
        } else if (details[key] === null) {
          acc.push(`${key}: ${Strings.devices.na}`);
        }
        return acc;
      },
      []
    );
    return syncIssues.length > 0
      ? syncIssues.join(', ')
      : Strings.devices.allSynced;
  };
  const getSyncStatus = (rowData: RegisteredDevice) => {
    switch (rowData.syncStatus.status) {
      case syncStatusEnum.synched:
        return (
          <Tooltip title={Strings.devices.allSynced}>
            <StyledIconStatus
              color={colors.green}
              className="pi pi-check-circle"
            />
          </Tooltip>
        );
      case syncStatusEnum.notSynched:
        return (
          <Tooltip title={getDetailsSyncStatus(rowData.syncStatus.details)}>
            <StyledIconStatus
              color={colors.red}
              className="pi pi-times-circle"
            />
          </Tooltip>
        );
      case syncStatusEnum.noInfo:
        return (
          <Tooltip title={Strings.devices.na}>
            <StyledIconStatus
              color={colors.mediumGray}
              className="pi pi-question-circle"
            />
          </Tooltip>
        );
      default:
        return '';
    }
  };

  return (
    <>
      {isLoading && <LoadingRowsComponent />}
      {isSuccess && data && (
        <StyledBoxTable style={{ width: '100%' }} className="mt-3">
          <DataTable
            removableSort
            sortField={sortValues.sortField}
            sortOrder={sortValues.sortOrder}
            onSort={(e) => {
              setSortValues({
                sortField: e.sortField,
                sortOrder: e.sortOrder === 0 ? null : e.sortOrder
              });
              if (e.sortOrder === 0) {
                setSortValues({
                  sortField: '',
                  sortOrder: null
                });
                localStorage.removeItem('sortOrder');
              } else {
                setSortValues({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder
                });
                localStorage.setItem(
                  'sortOrder',
                  e.sortOrder?.toString() || ''
                );
              }
              localStorage.setItem('sortField', e.sortField || '');
            }}
            selectionMode="checkbox"
            selection={selectedDevices}
            onSelectionChange={(e) => {
              setSelectedDevices(e.value);
            }}
            value={allDevices}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={allDevices && allDevices.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              25,
              allDevices ? allDevices.length : 50
            ]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column selectionMode="multiple" frozen />
            <Column
              field="statusLabel"
              header="Online / Offline"
              sortable
              body={getDatabaseLocal}
            />
            <Column field="productType" header="Model" sortable />
            <Column
              field="deviceId"
              header="Device ID"
              sortable
              body={getDeviceId}
            />
            <Column
              field="firmwareVersion"
              header="Firmware Version "
              sortable
            />
            <StyledColumnSize
              field="firmwareUpdate.firmwareTarget"
              header="Pending FOTA"
              minWidth="200px"
              sortable
              body={getTargetWithDeleteButton}
            />
            <Column
              field="lastAnnounce"
              header="Last Announce"
              sortable
              body={getLastAnnounce}
            />
            <Column
              field="locationName"
              header="Location"
              body={getLocationAssign}
              sortable
            />
            <Column
              field="syncStatus.status"
              header="Sync Status"
              sortable
              body={getSyncStatus}
            />
          </DataTable>
        </StyledBoxTable>
      )}
      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
          setSideBarOpenFor(viewRightSideBarEnum.addDevice);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
