import styled from 'styled-components';
import { Grid } from '@mui/material';
import { colors } from '../../../../constants/colors';

export const StyledTitleLabelAddScene = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.blueTheme};
  text-transform: uppercase;
`;

export const ItemGridAddScene = styled(Grid)`
  padding: 20px;
`;
