import { Grid } from '@mui/material';
import styled from 'styled-components';

const imageContainer = require('../../assets/background.jpg');

export const ContainerImage = styled(Grid)`
  background-image: url(${imageContainer});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
`;
