import React, { useEffect } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from '../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import Strings from '../../i18n';
import { EffectStoreListTable } from '../../templates/store/effectsStore/effectStoreListTable/effectStoreListTable';
import {
  useGetEffectsStoreListMutation,
  useReportEffectMutation,
  useShareEffectMutation
} from '../../services/effects/effectsStoreService';
import { IEffectItemStore } from '../../types/effects/effectsStoreInterface';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { SingleDetailsEffectStore } from '../../templates/store/effectsStore/singleDetailsEffectStore/singleDetailsEffectStore';
import { ShareEffectDialog } from '../../templates/store/effectsStore/shareEffect/shareEffect';
import { ReportEffectDialog } from '../../templates/store/effectsStore/reportEffect/reportEffect';
import { ViewRatesSideBar } from '../../templates/store/effectsStore/viewRates/viewRates';

export const EffectStoreView = () => {
  const [selectedEffects, setSelectedEffects] = React.useState<
    IEffectItemStore[]
  >([]);
  const [openSideBar, setOpenSideBar] = React.useState<boolean>(false);
  const [openShareDialog, setOpenShareDialog] = React.useState<boolean>(false);
  const [openReportDialog, setOpenReportDialog] =
    React.useState<boolean>(false);

  const [shareEffectWithUser, { isSuccess: successShareEffect }] =
    useShareEffectMutation();

  const [sendReportEffect, { isSuccess: successReport }] =
    useReportEffectMutation();

  const navigate = useNavigate();
  const shareWithUser = (email: string) => {
    shareEffectWithUser({ effectId: selectedEffects[0].id, email });
  };

  const sendReport = (text: string) => {
    sendReportEffect({ effectId: selectedEffects[0].id, reason: text });
  };
  const [sideBarOpenFor, setSideBarOpenFor] =
    React.useState<viewRightSideBarEnum>(viewRightSideBarEnum.empty);
  const [getEffectsStoreList, { isSuccess, data }] =
    useGetEffectsStoreListMutation();

  const reloadTable = () => {
    getEffectsStoreList();
    setSelectedEffects([]);
  };
  useEffect(() => {
    getEffectsStoreList();
  }, []);

  const closeSideBar = () => {
    setOpenSideBar(false);
  };

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  useEffect(() => {
    if (selectedEffects.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.singleDetailsEffect);
    } else if (selectedEffects.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.multipleLocationsDetails);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedEffects]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.singleDetailsEffect:
        return (
          <SingleDetailsEffectStore
            effect={selectedEffects[0]}
            closeSideBar={closeSideBar}
            reloadTable={reloadTable}
            setOpenShareDialog={setOpenShareDialog}
            setOpenReportDialog={setOpenReportDialog}
            openSideBarFor={(value) => openRightSideBarForMethod(value)}
          />
        );
      case viewRightSideBarEnum.viewRates:
        return (
          <ViewRatesSideBar
            closeSideBar={closeSideBar}
            effect={selectedEffects[0]}
          />
        );
      default:
        return <p />;
    }
  };

  useEffect(() => {
    if (successShareEffect) {
      setOpenShareDialog(false);
      toast.success(Strings.effects.shareEffectSuccess);
    }
  }, [successShareEffect]);

  useEffect(() => {
    if (successReport) {
      setOpenReportDialog(false);
      toast.success(Strings.effects.reportEffectSuccess);
    }
  }, [successReport]);
  return (
    <>
      {isSuccess && data && (
        <>
          <Header>
            <StyledHeaderContainer>
              <div className="row justify-content-between">
                <div className="col-1 col-sm-4 text-left" />
                <div className="col-6 col-sm-4 text-center">
                  <StyledTitleHeader align="center">
                    {Strings.effects.effectsStore}
                  </StyledTitleHeader>
                </div>
                <div className="col-5 col-sm-4 text-right" />
              </div>
            </StyledHeaderContainer>
          </Header>
          {/* <EffectStoreList effects={data?.effects} reloadTable={reloadTable} /> */}
          <EffectStoreListTable
            selectedEffects={selectedEffects}
            setSelectedEffects={setSelectedEffects}
            reloadTable={reloadTable}
            data={data?.effects}
            openSideBarFor={(value) => openRightSideBarForMethod(value)}
          />
        </>
      )}
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          closeSideBar();
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
      <ShareEffectDialog
        open={openShareDialog}
        setOpen={setOpenShareDialog}
        onShare={(email) => {
          shareWithUser(email);
        }}
      />
      <ReportEffectDialog
        reportHandler={(text: string) => sendReport(text)}
        open={openReportDialog}
        setOpen={setOpenReportDialog}
      />
    </>
  );
};
