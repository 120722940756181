import React, { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDeleteMultipleOrganizationsMutation,
  useGetOrganizationDetailsMutation
} from '../../../services/organizations/organizationsService';
import {
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import Strings from '../../../i18n';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { SideBarReadOnly } from '../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';

interface OrganizationDetailsProps {
  idOrganization: number;
  reloadTable: () => void;
  openRightSideBarForMethod: (method: number) => void;
}

export const OrganizationDetails = ({
  idOrganization,
  reloadTable,
  openRightSideBarForMethod
}: OrganizationDetailsProps) => {
  const navigate = useNavigate();

  const [getOrgDetails, { isSuccess, data, isLoading }] =
    useGetOrganizationDetailsMutation();

  const [deleteOrganizations, { isSuccess: isSuccessDelete }] =
    useDeleteMultipleOrganizationsMutation();

  useEffect(() => {
    getOrgDetails(idOrganization);
  }, []);

  useEffect(() => {
    if (isSuccessDelete) {
      reloadTable();
      toast.success(Strings.organizations.successDeletedOrganization);
    }
  }, [isSuccessDelete]);

  const redirectToOrganizationDetails = () => {
    navigate(`/organizations/${idOrganization}`);
  };

  const deleteOrganization = () => {
    deleteOrganizations([idOrganization]);
  };

  return (
    <>
      {isSuccess && data && (
        <StyledContainerItems>
          <StyledTitleSideBar>{Strings.common.details}</StyledTitleSideBar>
          <SideBarReadOnly
            readOnlyItems={[
              {
                name: Strings.organizations.organizationName,
                value: data?.name
              },
              {
                name: Strings.common.lastUpdated,
                value: moment(data?.lastUpdate).format('DD/MM/YYYY HH:mm')
              },
              {
                name: Strings.common.enabled,
                value: data?.enabled ? Strings.common.yes : Strings.common.no
              }
            ]}
          />
          <SideBarSimpleMethodItem
            name={Strings.organizations.viewLocationsAssigned}
            action={() => {
              redirectToOrganizationDetails();
            }}
            successAction={false}
            textSuccess="success"
          />
          <SideBarSimpleMethodItem
            name={Strings.locations.assignOrganizationTitle}
            action={() => {
              openRightSideBarForMethod(
                viewRightSideBarEnum.assignLocationToOrg
              );
            }}
            successAction={false}
            textSuccess="success"
          />
          <SideBarDeleteActionWithPopup
            onDelete={() => deleteOrganization()}
            titleConfirmation={Strings.organizations.deleteOrganizationTitle}
            textConfirmation={Strings.common.thisActionIsIrreversible}
            textIcon={Strings.organizations.deleteOrganization}
          />
        </StyledContainerItems>
      )}
      {isLoading && <LoadingRowsComponent />}
    </>
  );
};
