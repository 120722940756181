import React from 'react';
import { Typography, Menu, MenuItem, ListItemIcon } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { GridView } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
  Container,
  ImgStyled,
  StyledBackToLocation,
  StyledIcon,
  StyledListItemText,
  StyledMenuItem,
  paperMenuStyle
} from './locationContextItemStyles';
import { LocationContextItemProps } from './locationContextItemProps';
import { colors } from '../../constants/colors';
import { ButtonIconStyled } from '../folderItem/folderItemStyles';
import { userLastLocations } from '../../store/slices/locations/location';
import Strings from '../../i18n';

const onlineLoc = require('../../assets/onlineLoc.png');

export const LocationContextItem = ({ name }: LocationContextItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const lastLocations = useSelector(userLastLocations);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirectToLocation = (locationId: number) => {
    navigate(`/locations/${locationId}/applications`);
  };
  const redirectToAllLocations = () => {
    navigate('/');
  };

  return (
    <>
      <Container onClick={handleClick}>
        <ImgStyled src={onlineLoc} alt="loc" />
        <Typography
          fontSize={15}
          marginTop={0.5}
          marginLeft={2}
          marginRight={4}
          color={colors.darkGray}
          fontWeight={500}
        >
          {name}
        </Typography>
        <ButtonIconStyled size="small" onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </ButtonIconStyled>
      </Container>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperMenuStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {lastLocations &&
          lastLocations.map((element) => (
            <StyledMenuItem
              onClick={() => redirectToLocation(element.locationId)}
              key={element.locationId}
            >
              <ListItemIcon>
                <StyledIcon
                  fontSize="small"
                  color="success"
                  style={{ width: '10px', height: '10px', color: colors.green }}
                />
              </ListItemIcon>
              <StyledListItemText>{element.locationName}</StyledListItemText>
            </StyledMenuItem>
          ))}
        <MenuItem className="text-center">
          <StyledBackToLocation onClick={redirectToAllLocations} type="button">
            <GridView fontSize="small" className="mr-2" />
            {Strings.navBar.allMyLocations}
          </StyledBackToLocation>
        </MenuItem>
      </Menu>
    </>
  );
};
