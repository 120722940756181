import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const ButtonStyledText = styled(Button)`
  && {
    color: ${colors.strongGray};
    text-transform: none;
  }
`;

export const ContainerArrow = styled(Box)`
  && {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: ${colors.lightGray};
  }
`;
