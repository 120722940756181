import React from 'react';
import { Menu, Avatar, Divider, Typography, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonIconStyled } from '../navBarStyles';
import LogoutButton from '../../../auth/LogOut/LogOut.component';
import { selectCurrentUser } from '../../../../store/slices/login/login';
import {
  AvatarStyled,
  ButtonStyled,
  ContainerItem,
  StyledVersionParagraph
} from './ProfileMenu.styles';
import Strings from '../../../../i18n';
import { colors } from '../../../../constants/colors';
import { RightSideBar } from '../../rightSideBar/rightSideBar';
import { EditProfileSidebar } from '../../../../templates/profile/editProfileSidebar/editProfileSidebar';
import {
  selectIsEditUserSideBarOpen,
  setOpenEditUserSideBar
} from '../../../../store/slices/app/app';
import packageJson from '../../../../../package.json';

export const ProfileIconAndMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpenEditUserSideBar = useSelector(
    selectIsEditUserSideBarOpen
  ) as boolean;
  const user = useSelector(selectCurrentUser);
  const [showProfileMenu, setShowProfileMenu] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(showProfileMenu);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowProfileMenu(event.currentTarget);
  };
  const handleClose = () => {
    setShowProfileMenu(null);
  };

  const sendToEditProfile = () => {
    dispatch(setOpenEditUserSideBar(true));
  };

  const sendToEditBusinessAccount = () => {
    navigate({
      pathname: '/business-account'
    });
  };
  const sendToViewNotes = () => {
    navigate({
      pathname: '/my-notes'
    });
  };
  return (
    <>
      <Tooltip title={Strings.navBar.profileTooltip}>
        <ButtonIconStyled
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AvatarStyled src={user.picture ?? ''} />
        </ButtonIconStyled>
      </Tooltip>
      <Menu
        anchorEl={showProfileMenu}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 1,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography
          fontWeight={200}
          paddingTop={1}
          fontSize={12}
          paddingX={3}
          textAlign="center"
          margin="auto"
          component="p"
          color={colors.mediumGray}
        >
          {user.email}
        </Typography>
        <ContainerItem>
          <Avatar
            sx={{
              width: '60px',
              height: '60px'
            }}
            src={user?.picture ?? ''}
          />
        </ContainerItem>
        <Typography
          paddingTop={1}
          fontSize={15}
          paddingX={3}
          textAlign="center"
          margin="auto"
          component="p"
          color={colors.black}
        >
          {`Hi, ${user.userInfo?.firstName} ${user.userInfo?.lastName}!`}
        </Typography>
        <ContainerItem>
          <ButtonStyled variant="contained" onClick={() => sendToEditProfile()}>
            {Strings.navBar.profileIcon.manageYourAccount}
          </ButtonStyled>
        </ContainerItem>
        <ContainerItem>
          <ButtonStyled
            variant="contained"
            onClick={() => sendToEditBusinessAccount()}
          >
            {Strings.navBar.manageBusinessAccount}
          </ButtonStyled>
        </ContainerItem>
        <ContainerItem>
          <ButtonStyled variant="contained" onClick={() => sendToViewNotes()}>
            {Strings.navBar.viewMyNotes}
          </ButtonStyled>
        </ContainerItem>
        <Divider />

        <LogoutButton />
        <StyledVersionParagraph>v{packageJson.version}</StyledVersionParagraph>
      </Menu>
      <RightSideBar
        open={isOpenEditUserSideBar}
        onMenuClose={() => {
          dispatch(setOpenEditUserSideBar(false));
        }}
      >
        <EditProfileSidebar />
      </RightSideBar>
    </>
  );
};
