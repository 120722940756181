import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RotateLeft, SystemUpdate } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import { Badge, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Header } from '../../components/header/header';
import { ButtonIconStyled } from './devicesStyles';
import Strings from '../../i18n';
import { DevicesTemplate } from '../../templates/device/devicesTemplate/devicesTemplate';
import { selectCurrentUser } from '../../store/slices/login/login';
import {
  systemModuleEnum,
  systemModulePermissionsEnum
} from '../../constants/systemRolesEnums';
import { setPermissionsReadWriteSystemDevices } from '../../store/slices/systemPermissions/systemPermissions';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { RegisteredDevice } from '../../types/Device/DeviceInterfaces';
import {
  useDeviceRebootCommandMutation,
  useRefreshStatusDevicesMutation
} from '../../services/device/deviceService';

export const Devices = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const filtersCount = Array.from(queryParams.keys()).length;
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const [refreshDevices, setRefreshDevices] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<RegisteredDevice[]>(
    []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addDevice
  );
  const [rebootDevicesCommand, { isSuccess: isSuccessRebotDevice }] =
    useDeviceRebootCommandMutation();
  const contextUser = useSelector(selectCurrentUser);
  const modulePermissions = contextUser.roleInfo.permissionsByModule.find(
    (module) => module.systemModuleName === systemModuleEnum.devices
  );

  const permissionReadWrite = !!modulePermissions?.permissions.includes(
    systemModulePermissionsEnum.readWrite
  );

  const openRightSideBarForMethod = (sideBarFor: number) => {
    setSideBarOpenFor(sideBarFor);
    setOpenRightSideBar(true);
  };

  const rebootDevices = () => {
    rebootDevicesCommand(selectedDevices.map((device) => device.deviceId));
  };

  useEffect(() => {
    if (isSuccessRebotDevice) {
      toast.success(Strings.serverAccess.devices.successRebootDevice);
      setSelectedDevices([]);
    }
  }, [isSuccessRebotDevice]);

  dispatch(setPermissionsReadWriteSystemDevices(permissionReadWrite));
  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.devices}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              {permissionReadWrite && (
                <ButtonIconStyled
                  size="small"
                  onClick={() => {
                    setSideBarOpenFor(viewRightSideBarEnum.addDevice);
                    setOpenRightSideBar(true);
                  }}
                >
                  <AddCircleOutlineIcon />
                </ButtonIconStyled>
              )}
              {permissionReadWrite && (
                <ButtonIconStyled
                  size="small"
                  onClick={() => {
                    openRightSideBarForMethod(viewRightSideBarEnum.filters);
                  }}
                >
                  <Badge badgeContent={filtersCount} color="warning">
                    <TuneIcon />
                  </Badge>
                </ButtonIconStyled>
              )}
              {selectedDevices.length > 0 && (
                <Tooltip title={Strings.serverAccess.devices.directFOTA}>
                  <ButtonIconStyled
                    size="small"
                    onClick={() => {
                      openRightSideBarForMethod(
                        viewRightSideBarEnum.directFOTA
                      );
                    }}
                  >
                    <SystemUpdate />
                  </ButtonIconStyled>
                </Tooltip>
              )}
              {selectedDevices.length > 0 && (
                <Tooltip
                  title={Strings.serverAccess.devices.rebootDevicesLabel}
                >
                  <ButtonIconStyled size="small" onClick={rebootDevices}>
                    <RotateLeft />
                  </ButtonIconStyled>
                </Tooltip>
              )}
              <ReOpenSideBarIcon
                isOpenRightSideBar={openRightSideBar}
                selectedItems={selectedDevices}
                singleItemMethod={() => {
                  openRightSideBarForMethod(viewRightSideBarEnum.details);
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.editMultipleDevices
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <DevicesTemplate
        searchValue={searchValue}
        openRightSideBar={openRightSideBar}
        setOpenRightSideBar={setOpenRightSideBar}
        sideBarOpenFor={sideBarOpenFor}
        setSideBarOpenFor={setSideBarOpenFor}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        refreshDevices={refreshDevices}
        setRefreshDevices={setRefreshDevices}
      />
    </div>
  );
};
