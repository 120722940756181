import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { JsonViewItem } from '../../../../components/commons/JsonViewItem/jsonViewItem';
import { useGetLastDeviceAnnounceByDeviceIdMutation } from '../../../../services/device/deviceService';
import Strings from '../../../../i18n';
import {
  IPayloadInfoDeviceAnnounce,
  IResponseInfoDeviceAnnounce
} from '../../../../types/Device/DeviceInterfaces';
import './showLastAnnounceInfo.css';

interface ShowLastAnnounceInfoProps {
  deviceId: string;
  lastAnnounceDate: string;
}
export const ShowLastAnnounceInfo = ({
  deviceId,
  lastAnnounceDate
}: ShowLastAnnounceInfoProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [
    getLastDeviceAnnounceByDeviceId,
    {
      isSuccess: isSuccessGetDeviceAnnounceById,
      data: dataLastDeviceAnnounce,
      isError: isErrorGetDeviceAnnounceById,
      error: errorGetDeviceAnnounceById
    }
  ] = useGetLastDeviceAnnounceByDeviceIdMutation();

  const payloadValues = [
    'deviceId',
    'uniqueProductName',
    'firmwareVersions',
    'hardwareVersions',
    'utcDate'
  ];
  const responseValues = ['sessionId', 'methods'];

  const getValuesForResponse = (
    info: IResponseInfoDeviceAnnounce | null,
    key: string
  ) => {
    if (info) {
      return JSON.stringify(info[key]);
    }
    return '';
  };

  const getValuesForPayload = (
    info: IPayloadInfoDeviceAnnounce,
    key: string
  ) => {
    return JSON.stringify(info[key]);
  };
  return (
    <>
      {lastAnnounceDate !== '' ? (
        <button
          type="button"
          className="lastAnnounceBtn"
          onClick={() => {
            getLastDeviceAnnounceByDeviceId(deviceId);
            setShowDialog(true);
          }}
        >
          {moment(lastAnnounceDate).format('YYYY/MM/DD HH:mm:ss')}
        </button>
      ) : (
        <div className="noData">{Strings.common.noData}</div>
      )}
      <Dialog
        header={Strings.devices.lastAnnounceInfo}
        visible={showDialog}
        style={{ width: '70vw' }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
        onHide={() => setShowDialog(false)}
      >
        {dataLastDeviceAnnounce && (
          <div className="row">
            <div className="col-12 col-md-6">
              <h5 className="text-center">{Strings.common.payload}</h5>
              {payloadValues.map((value) => {
                return (
                  <div key={value}>
                    <JsonViewItem
                      title={value}
                      values={getValuesForPayload(
                        dataLastDeviceAnnounce['payload'],
                        value
                      )}
                    />
                  </div>
                );
              })}
            </div>
            <div className="col-12 col-md-6">
              <h5 className="text-center">{Strings.common.response}</h5>
              {responseValues.map((value) => {
                return (
                  <div key={value}>
                    <JsonViewItem
                      title={value}
                      values={getValuesForResponse(
                        dataLastDeviceAnnounce
                          ? dataLastDeviceAnnounce['response']
                          : null,
                        value
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
