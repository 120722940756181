import styled from 'styled-components';

export const Margin = styled.span<{
  margin: number;
  mt: number;
  mr: number;
  mb: number;
  ml: number;
}>`
  margin: ${({ margin }) => margin};
  margin-top: ${({ mt }) => `${mt}px`};
  margin-right: ${({ mr }) => `${mr}px`};
  margin-bottom: ${({ mb }) => `${mb}px`};
  margin-left: ${({ ml }) => `${ml}px`};
`;
