/* eslint-disable no-shadow */
export enum eventTypes {
  OFF_EVENT = 1,
  COLOR_EVENT = 2,
  PATTERN_EVENT = 4,
  SCENE_EVENT = 5,
  LIGHT_SHOW_EVENT = 6,
  SPARKLE_EVENT = 7
}
export const dayOfWeeksInitial = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export enum DATE_TYPE_STRING {
  ALL_YEAR = 'ALL YEAR',
  DATE_RANGE = 'DATE RANGE',
  HOLIDAY = 'HOLIDAY'
}
export enum TIME_TYPE_ENUM {
  ABSOLUTE = 1,
  SUNSET = 2,
  SUNRISE = 3
}
export enum DATE_TYPE_ENUM {
  ALL_YEAR = 1,
  DATE_RANGE = 2,
  HOLIDAY = 3
}

export enum WeekDayBase2representation {
  MON = 64,
  TUE = 32,
  WED = 16,
  THU = 8,
  FRI = 4,
  SAT = 2,
  SUN = 1,
  reduce
}
